<template>
    <v-card class="inv-object-card inv-impact-diagrams" :class="chainsDrawerClosed ? 'expanded': 'collapsed'" flat>
        <div :class="{ 'flex-container': true, 'no-gutters': true, 'object-content-bg': false }" style="height: 100%">
            <div>
                <v-card flat class="general-info-card">
                    <v-card-title style="padding: 4px 16px; min-height: 40px;">
                        <h4>{{'impact_visualization_in_diagram' | translate}}
                        </h4>
                    </v-card-title>
                    <v-card-text v-if="impactData">
                        <inv-object-form    
                            :groups="impactData.fieldsGrouped" 
                            :edit-mode="false" 
                            :edit-mode-switch="false" 
                            :object-id="impactData.diagramId"
                        />
                    </v-card-text>
                </v-card>
            </div>
            <div class="inv-object-tabs impact">
                <div v-if="impactData" flat class="tabs-content">
                    <v-tabs v-model="diagramTabModel" grow color="accent">
                        <v-tab style="padding: 10px;">
                            <v-icon style="margin-right: 10px">fal fa-chess</v-icon>
                            {{serviceArchitectureDiagram}}
                        </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="diagramTabModel">
                        <v-tab-item class="inv-object-tab-item" >
                            <v-card flat>
                                <v-card-text style="overflow: auto">
                                    <div ref="svgDiagram" v-if="diagramData" v-html="diagramData" @mouseover="processDiagramMouseOver" />
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </div>
            </div>
        </div>

        <v-navigation-drawer
            absolute
            right
            :mini-variant.sync="chainsDrawerClosed"
            width="500px"
            v-if="impactData"
        >
            <v-app-bar flat>
                <v-app-bar-nav-icon @click="chainsDrawerClosed = !chainsDrawerClosed">
                </v-app-bar-nav-icon>
                <v-spacer/>
                <v-toolbar-title>{{'impact_chains' | translate}}</v-toolbar-title>
                <v-spacer/>
            </v-app-bar>

            <v-divider/>
            
            <div v-if="!chainsDrawerClosed">
                <ul  class="obj-impact-chains">
                    <li 
                        v-for="(chain, i) in impactData.impactChains" 
                        :key="i"    
                    >
                        <p style="margin-bottom: 0px">
                            <span :class="isChainActive(i) ? 'chain-active fa-stack' : 'fa-stack'">
                                <v-icon>fal fa-bolt fa-stack-2x</v-icon>
                                <strong class="fa-stack-1x" style="margin: 6px 4px 0 0; font-size: 12px;">
                                    {{i+1}} 
                                </strong>
                            </span>
                            <span @click="processChainClick(i)">
                                {{chain.elements[0].name}}
                                <v-icon>fal fa-long-arrow-alt-right</v-icon>
                                {{chain.elements[chain.elements.length - 1].name}}
                            </span>

                            <v-icon style="float: right; margin-right: 20px;">
                                {{isChainActive(i) ? 'fal fa-chevron-up' : 'fal fa-chevron-down' }}
                            </v-icon>
                        </p>

                        <v-expand-transition>
                            <ul v-show="isChainActive(i)" style="padding-top: 8px">
                                <li
                                    v-for="(chainElement, j) in chain.elements" 
                                    :key="j"
                                >   
                                    <v-icon v-if="j === 0 || j === chain.elements.length-1">fal fa-route</v-icon>
                                    <v-icon v-else>fal fa-long-arrow-alt-right</v-icon>
                                    <inv-object-link :object="chainElement" :style="hoverdObjectId === chainElement.globalId ? 'font-weight: bold' : ''"/>
                                </li>
                            </ul>
                        </v-expand-transition>
                        <v-divider/>
                    </li>
                </ul>
            </div>
            
            <ul class="obj-impact-chains" v-else>
                <li 
                    v-for="(chain, i) in impactData.impactChains" 
                    :key="i"    
                    :class="isChainActive(i) ? 'chain-active' : ''"
                >
                    <p style="margin-bottom: 0px">
                        <span class="fa-stack">
                            <v-icon>
                                fal fa-bolt fa-stack-2x
                            </v-icon>
                            <strong class="fa-stack-1x" style="margin: 6px 4px 0 0; font-size: 12px;">
                                {{i+1}} 
                            </strong>
                        </span>
                    </p>
                </li>
            </ul>
        </v-navigation-drawer>
	</v-card>
</template>

<script>
    
    import locals from 'obj-fe/services/localisation';
    import InvObjectForm from '../inventory/inv-object-form.vue';
    import InvObjectLink from '../inventory/inv-object-link.vue';

    export default {
        components:{
            InvObjectForm,
            InvObjectLink
        },
        data(){
            return {
                activeChains: [],
                chainsDrawerClosed: false,
                hoverdObjectId: null,
                diagramTabModel: 0
            };
        },
        computed:{
            impactData(){
                return this.$store.state.IMPACT_DIAGRAMS.impactData;
            },
            diagramData(){
                return this.$store.state.IMPACT_DIAGRAMS.diagramData;
            },
            chainElementsWithChainIds(){
                let chainElements = []

                this.impactData.impactChains.forEach((chain, index) => {    
                    chain.elements.forEach(element=>{
                        chainElements.push({element, index})
                    })
                });
                return chainElements
            },
            serviceArchitectureDiagram(){
                if(!this.impactData) return locals.translate('service_architecture_diagram');
                return this.impactData.fieldsGrouped[0].fields.filter(e=>e.displayName==='Diagram').pop().displayString;
            }
        },
        methods:{
            processDiagramMouseOver(event){
                let target = event.target;
                if(!target.attributes) return;

                let attributes = Object.values(target.attributes)
                if(attributes.map(attr=>attr.name).indexOf('xlink:href') > -1){
                    let parentElement = target.parentElement || null;
                    if(!!parentElement && !!parentElement.href && parentElement.href.baseVal !== '') this.activateChain(parentElement.href.baseVal.split('/').pop())
                }
                else this.activateChain(null); 
            },
            activateChain(elementId){
                if(typeof elementId === undefined) return;
                this.chainElementsWithChainIds
                    .filter(e => e.element.globalId === elementId)
                    .forEach(e=>{
                        if(this.activeChains.indexOf(e.index) === -1) this.activeChains.push(e.index)
                    })
                this.hoverdObjectId = elementId;
            },
            processChainClick(index, who){
                let newActiveChains = this.activeChains;
                let selectedChainIndex = newActiveChains.indexOf(index);
                
                if(selectedChainIndex > -1) newActiveChains.splice(selectedChainIndex, 1);
                else newActiveChains.push(index)
            
                this.activeChains = newActiveChains;
            },
            isChainActive(index){
                let activeChains = this.activeChains;
                return activeChains.indexOf(index) > -1; 
            }
        }
    };
</script>
<style scoped>

.chain-active,
.chain-active .v-icon{
    color: var(--v-accent-base);
}
.inv-impact-diagrams{
    padding: 8px none 8px 8px;
    transition: all ease-in 200ms;
}
.inv-impact-diagrams.expanded{
    padding-right: 60px !important;
}
.inv-impact-diagrams.collapsed{
    padding-right: 500px !important;
}


.obj-impact-chains{
    padding-left: 0px!important;
    margin-left: 16px;
    user-select: none;
    margin-top: 20px;
}
.obj-impact-chains p {
    cursor: pointer;
    width: 100%;
}
.obj-impact-chains li{
    list-style-type: none;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
}
.obj-impact-chains ul{
    margin-left: 16px;
}
.inv-object-tabs.impact{
    height: 100%;
}
.inv-object-tabs.impact .v-tabs{
    flex: 0 1 auto;
}
.inv-object-tabs.impact .tabs-content{
    width: 100%;
}
.inv-object-tabs.impact .v-window__container,
.inv-object-tabs.impact .v-window.v-item-group{
    height: 100%;
}
.inv-object-card.inv-impact-diagrams .flex-container{
    height: 100% !important;
    
    display: flex;
    flex-direction: column;

}
</style>