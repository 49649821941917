<template>
  <inv-object-tab-item :tab="tab" :tabIsLoading="tabIsLoading">
    <template v-if="expanded && tab" slot="header">
      <div class="inv-object-button-group" :style="selected ? 'padding-right: 480px' : 0">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="toggleKeepSize" :color="keepSize ? 'accent' : ''">
              <v-icon>fal fa-expand-arrows</v-icon>
            </v-btn>
          </template>
          <span>
            {{ "fit_svg" | translate }}
          </span>
        </v-tooltip>

        <v-divider vertical style="margin: 0 8px" />

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              :to="{ name: 'inventory-object', params: { id: tab.parameters.diagramId } }"
              target="_blank"
              icon
              exact
              v-on="on"
            >
              <v-icon>fal fa-link</v-icon>
            </v-btn>
          </template>
          <span>
            {{ "diagram_source_object" | translate }}
          </span>
        </v-tooltip>
      </div>
    </template>
    <template slot="body">
      <div
        ref="svgContainer"
        :id="'svg-container-' + tab.parameters.dataSource"
        class="svg-container"
        :class="{ 'tab-body-disabled': tabIsLoading, 'svg-container__panning': isPanning }"
        @click.prevent
        @contextmenu="handleContextMenu"
        @mouseup.capture="handleMouseUp"
      >
        <SvgPanZoom
          v-if="svgContent !== ''"
          :zoomEnabled="true"
          :dblClickZoomEnabled="false"
          :fit="false"
          :center="true"
          :panEnabled="true"
          :minZoom="0.0001"
          :zoomScaleSensitivity="0.5"
          :maxZoom="1000"
          @svgpanzoom="registerSvgPanZoom"
          :on-pan="handlePan"
          :on-zoom="handleZoom"
        >
          <svg ref="svgDiagram" id="real-svg" v-html="svgContent" />
          <svg v-if="browserSupported !== 'firefox'" slot="thumbnail" v-html="svgContent" style="background: white" />
        </SvgPanZoom>
        <v-menu v-model="contextMenuShown" :position-x="contextMenu.x" :position-y="contextMenu.y" absolute offset-y>
          <v-list>
            <v-list-item @click="selected = contextMenu.objectId">
              <v-list-item-icon>
                <v-icon>fal fa-address-card</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Inspect in drawer</v-list-item-title>
            </v-list-item>

            <v-list-item :to="{ name: 'inventory-object', params: { id: contextMenu.objectId } }" target="_blank" exact>
              <v-list-item-icon>
                <v-icon>fal fa-external-link-alt</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Open in new tab</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-navigation-drawer
        floating
        :value="!!selected"
        class="object-side-drawer"
        absolute
        right
        clipped
        z-index="1"
        :width="!!selected ? '480' : 0"
        :style="!!selected ? 'padding: 8px' : 0"
      >
        <inv-mini-object v-if="selected" :id="selected" @close="selected = null" />
      </v-navigation-drawer>
    </template>
  </inv-object-tab-item>
</template>

<script>
import backend from "obj-fe/app/backend";
import SvgPanZoom from "vue-svg-pan-zoom";

import tabBehaviour from "./inv-object-tab-behaviour";
import InvObjectTabItem from "./inv-object-tab-item.vue";

export default {
  mixins: [tabBehaviour],
  components: {
    InvObjectTabItem,
    SvgPanZoom,
  },
  data() {
    return {
      svgPanObject: null,
      svgContent: "",
      selected: null,
      contextMenuShown: false,
      contextMenu: {
        object: null,
        x: 0,
        y: 0,
      },
      isPanning: false,
      keepSize: true,
      svgContainerResizeObserver: null,
    };
  },
  computed: {
    browserSupported() {
      let userAgent = navigator.userAgent;
      let browserName;

      if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = "chrome";
      } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = "firefox";
      } else if (userAgent.match(/safari/i)) {
        browserName = "safari";
      } else if (userAgent.match(/opr\//i)) {
        browserName = "opera";
      } else if (userAgent.match(/edg/i)) {
        browserName = "edge";
      } else {
        browserName = "No browser detected";
      }
      return browserName;
    },
  },
  watch: {
    selected: function (selected) {
      if (selected)
        this.$store.dispatch("INVENTORY/LOAD_OBJECT_DETAIL_PREVIEW", {
          objectId: selected,
          errorCb: () => {
            this.selected = null;
          },
        });
      this.syncSVGDataProps();
    },
    svgPanObject: {
      immediate: true,
      handler(newSvgPanObject) {
        if (newSvgPanObject) {
          if (this.svgContainerResizeObserver) this.svgContainerResizeObserver.disconnect();
          this.svgContainerResizeObserver = new ResizeObserver(this.fitWhenKeepSize);
          this.svgContainerResizeObserver.observe(this.$refs.svgContainer);
          this.fitWhenKeepSize();
        }
      },
    },
  },
  methods: {
    handlePan() {
      this.isPanning = true;
      this.contextMenuShown = false;
    },
    handleZoom() {
      this.contextMenuShown = false;
    },
    onFirstTimeExpandLoad(successCb, errorCb) {
      let restUrl = new String(this.tab.parameters.url).slice(5);
      let app = this;
      backend.objects.impactSvgDiagram(
        { customRestUrl: restUrl },
        (data) => {
          app.svgContent = data;
          this.syncSVGDataProps(true);

          successCb();
        },
        errorCb
      );
    },
    handleContextMenu(e) {
      e.preventDefault();
      e.stopPropagation();

      // context menu invoked while still open
      if (this.contextMenuShown) this.contextMenu.object = null;

      let sourceElement = e.srcElement,
        objectId = sourceElement.getAttribute("xlink:href");

      while (!sourceElement.classList.contains("svg-container")) {
        objectId = sourceElement.getAttribute("xlink:href");

        if (objectId) break;
        else sourceElement = sourceElement.parentElement;
      }

      if (!!objectId) {
        this.contextMenu.objectId = objectId.split("\/").pop();

        this.contextMenu.x = e.clientX;
        this.contextMenu.y = e.clientY;

        this.$nextTick(() => {
          this.contextMenuShown = true;
        });
      }
    },
    parseObjectIdFromLinkValue(xmlinkHrefValue){
      return (xmlinkHrefValue || '').split('\/').pop();
    },
    syncSVGDataProps(markAllNodes){
      this.$nextTick(() => requestAnimationFrame(() => {

        const svgDiagramElm = this.$refs.svgDiagram;
        if(!svgDiagramElm) return;
        
        if(markAllNodes) {
          const links = Array.from(svgDiagramElm.querySelectorAll('a') || []);
          links.forEach(a => {
            let objectId = this.parseObjectIdFromLinkValue(a.getAttribute('xlink:href'));

            if(objectId) {
              a.dataset.objectId = objectId;

              let isRelation = !!findNearestParentWithClass(a, 'edge', 4);
              let isNode = !isRelation;

              if(isNode) a.dataset.objectType = 'node';
              else if(isRelation) a.dataset.objectType = 'relation';
            }
          });
        }

        function findNearestParentWithClass(element, className, stopLevel) {
            let i = 0;
            while (element && element !== document && i < stopLevel) {
                if (element.classList.contains(className)) {
                    return element;
                }
                element = element.parentNode;
                i++;
            }
            return null;
        }

        // sync selected
        function toggleSelect(a, isSelected){
          a.dataset.selected = !!isSelected;
        }

        svgDiagramElm.querySelectorAll('[data-selected="true"]').forEach(a => toggleSelect(a, false));

        if(this.selected){
          svgDiagramElm.querySelectorAll('[data-object-id="'+this.selected+'"]').forEach(a => toggleSelect(a, true));
        }
      }));
    },
    handleMouseUp(e) {
      if(e.button === 2) return; // mouse right button mouseup - after contextmenu click
      
      this.$nextTick(() => {
        if (!this.isPanning && this.contextMenuShown != true) {
          this.invokeObjectDrawer(e.srcElement);
        }
        this.isPanning = false;
      });
    },
    registerSvgPanZoom(svgpanzoom) {
      this.svgPanObject = svgpanzoom;
    },
    elementIsValid(element) {
      if (!element || !element.tagName || !element.classList || !element.id) return false;

      return (
        element.tagName === "g" &&
        (element.classList.contains("node") ||
          element.classList.contains("edge") ||
          element.classList.contains("cluster")) &&
        element.id !== ""
      );
    },
    invokeObjectDrawer(element) {
      let sourceElement = element,
        objectId = sourceElement.getAttribute("xlink:href");

      while (!sourceElement.classList.contains("svg-container")) {
        objectId = sourceElement.getAttribute("xlink:href");

        if (objectId) break;
        else sourceElement = sourceElement.parentElement;
      }

      if (!objectId) return;

      objectId = objectId.split("\/").pop();
      this.selected = objectId;
    },
    fit() {
      if (!this.svgPanObject) return;

      this.svgPanObject.resize();
      this.svgPanObject.fit();
      this.svgPanObject.center();

      this.isPanning = false;
    },
    fitWhenKeepSize() {
      if (this.keepSize) {
        this.fit();
      }
    },
    toggleKeepSize() {
      if (!this.keepSize) {
        this.fit();
      }

      this.keepSize = !this.keepSize;
    },
  },
  destroyed() {
    if (this.svgContainerResizeObserver) this.svgContainerResizeObserver.disconnect();
  },
};
</script>
<style>
.inv-object-button-group {
  transition: all 200ms;
}
.object-side-drawer {
  bottom: 0px;
  top: unset !important;
  box-shadow: none !important;
  border-left: 1px solid ghostwhite;
  border-top: 1px solid ghostwhite;
}
.svg-container {
  width: 100%;
  height: 100%;
  cursor: grab;
}
.svg-container > div,
.svg-container > div > svg {
  height: 100%;
  width: 100%;
}
.svg-container > svg * {
  font-family: unset !important;
}
.svg-container > div {
  border: none;
}
.svg-container #thumbView {
  z-index: 110;
  background: white;
}
.svg-container #scopeContainer {
  z-index: 120;
}
.svg-container .thumbViewClass,
.svg-container .thumbnail {
  padding: 0 !important;
  margin: 0 !important;
}
.svg-container .thumbViewClass rect.scope {
  fill: var(--v-accent-base);
  stroke: var(--v-accent-base);
}
.svg-container > svg * {
  font-family: unset !important;
}
.svg-container .edge,
.svg-container .node,
.svg-container path {
  transition: all 250ms ease-in-out;
  cursor: pointer;
}
.edge {
  stroke-width: 1.5;
}
.svg-container__panning,
.svg-container__panning * {
  cursor: grabbing;
}

.svg-container [data-selected="true"],
.svg-container [data-selected="true"] text {
  text-decoration: underline;
  fill: var(--v-anchor-base);
  font-weight: bold;
}

.svg-container [data-selected="true"][data-object-type="relation"] path {
  stroke: var(--v-anchor-base);
}

</style>
