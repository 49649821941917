import {
    Lexer,
    createToken as origCreateToken
} from 'chevrotain';

function createToken(opts) {
    let token = origCreateToken(opts);
    token.typeId = opts.typeId;
    token.text = opts.text;
    token.field = opts.field;

    return token;
}

let tokens = {};



tokens.SquareBracketLeft = createToken({
    name: 'SquareBracketLeft',
    pattern: '[',
    text: '[',
    typeId: 'SQUARE_BRACKET_LEFT'
});
tokens.SquareBracketRight = createToken({
    name: 'SquareBracketRight',
    pattern: ']',
    text: ']',
    typeId: 'SQUARE_BRACKET_RIGHT'
});
tokens.CurlyBracketLeft = createToken({
    name: 'CurlyBracketLeft',
    pattern: '{',
    text: '{',
    typeId: 'CURLY_BRACKET_LEFT'
});
tokens.CurlyBracketRight = createToken({
    name: 'CurlyBracketRight',
    pattern: '}',
    text: '}',
    typeId: 'CURLY_BRACKET_RIGHT'
});
tokens.LeftParenthesis = createToken({
    name: 'LeftParenthesis',
    pattern: '(',
    text: '(',
    typeId: 'PARENTHESIS_LEFT'
});
tokens.RightParenthesis = createToken({
    name: 'RightParenthesis',
    pattern: ')',
    text: ')',
    typeId: 'PARENTHESIS_RIGHT'
});
tokens.Comma = createToken({
    name: 'Comma',
    pattern: ',',
    line_breaks: false,
    text: ',',
    typeId: 'COMMA'
});
tokens.Semicolon = createToken({
    name: 'Semicolon',
    pattern: ';',
    line_breaks: false,
    text: ';',
    typeId: 'SEMICOLON'
});
tokens.Comment = createToken({
    name: 'Comment',
    pattern: /\/\/.*/,
    line_breaks: false,
    text: '//',
    typeId: 'COMMENT',
    group: Lexer.SKIPPED
});
tokens.Identifier = createToken({
    name: 'Identifier',
    pattern: /[a-zA-Z0-9$\-_:]+/,
    typeId: 'IDENTIFIER'
});
tokens.entity = createToken({
    name: 'Entity',
    pattern: 'entity',
    text: 'entity',
    typeId: 'ENTITY_DEFINITION',
    longer_alt: tokens.Identifier
});
tokens.column = createToken({
    name: 'Column',
    pattern: 'column',
    text: 'column',
    typeId: 'COLUMN_DEFINITION',
    longer_alt: tokens.Identifier
});
tokens.join = createToken({
    name: 'Join',
    pattern: 'join',
    text: 'join',
    typeId: 'JOIN_DEFINITION',
    longer_alt: tokens.Identifier
});
tokens.where = createToken({
    name: 'Where',
    pattern: 'where',
    text: 'where',
    typeId: 'WHERE_DEFINITION',
    longer_alt: tokens.Identifier
});
tokens.objectAssignment = createToken({
    name: 'ObjectAssignment',
    pattern: 'objectAssignment',
    text: 'objectAssignment',
    typeId: 'OBJECT_ASSIGNMENT_DEFINITION',
    longer_alt: tokens.Identifier
});
tokens.String = createToken({
    name: 'String',
    pattern: /[a-zA-Z0-9\-\_:]+/,
    line_breaks: false,
    typeId:'VALUE'
});

tokens.StringQuoted = createToken({
    name: 'StringQuoted',
    pattern: /\"((-| |\S)+?(?!\n|\r\n))\"/,
    line_breaks: false,
    typeId: 'VALUE'
});

tokens.StringQuotedSingle = createToken({
    name: 'StringQuotedSingle',
    pattern: /\'((-| |\S)+?(?!\n|\r\n))\'/,
    line_breaks: false,
    typeId: 'VALUE'
});

tokens.Dot = createToken({
    name: 'Dot',
    pattern: /\./,
    text: '.',
    typeId: 'DOT'
});
tokens.Equal = createToken({
    name: 'Equal',
    pattern: /=/,
    text: '=',
    typeId: 'EQ'
});
tokens.lessThan = createToken({
    name: 'lessThan',
    pattern: /</,
    text: '<',
    typeId: 'LT'
});
tokens.greaterThan = createToken({
    name: 'greaterThan',
    pattern: />/,
    text: '>',
    typeId: 'GT'
});
tokens.WhiteSpace = createToken({
    name: 'WhiteSpace',
    pattern: /\s+/,
    group: Lexer.SKIPPED,
    text: ' ',
    typeId: 'SPACE'
});
tokens.andOperator = createToken({
    name: 'AndOperator',
    pattern: /AND/,
    text: 'AND',
    typeId: 'AND'
});
tokens.orOperator = createToken({
    name: 'orOperator',
    pattern: /OR/,
    text: 'OR',
    typeId: 'OR'
});

function createTokenList() {
    return [
        tokens.Comment,
        tokens.Semicolon,
        tokens.SquareBracketLeft,
        tokens.SquareBracketRight,
        tokens.CurlyBracketLeft,
        tokens.CurlyBracketRight,
        tokens.LeftParenthesis,
        tokens.RightParenthesis,
        tokens.Comma,
        tokens.Dot,
        tokens.column,
        tokens.entity,
        tokens.join,
        tokens.where,
        tokens.objectAssignment,
        tokens.Equal,
        tokens.lessThan,
        tokens.greaterThan,
        tokens.andOperator,
        tokens.orOperator,
        tokens.WhiteSpace,
        tokens.Identifier,
        tokens.String,
        tokens.StringQuoted,
        tokens.StringQuotedSingle,
    ];
}

export {
    createTokenList,
    createToken,
    tokens
};