<template>
    <v-card class="inv-object-card" v-if="object" flat>
        <div :class="{ 'flex-container': true, 'no-gutters':fullScreenView, 'object-content-bg':!fullScreenView }">
            <div>
                <v-card v-if="!(hideObjectTitleWithButtons && fieldsGrouped.length === 0)" flat class="general-info-card">
                    <v-card-title v-if="!hideObjectTitleWithButtons">
                        <div class="flex align-center">
                            <h2>
                                <inv-object-link 
                                    :disabled="(object.isNew || fullScreenView || object.panelSourceType === 'ARTIFICIAL')" 
                                    :object="object"
                                    class="inline mr-1"
                                />
                            </h2>

                            <bookmark 
                                v-if="!object.isNew && object.panelSourceType !== 'ARTIFICIAL'"
                                :bookmarked="object.bookmarked"
                                @bookmarked="bookmark"
                            />
                        </div>

                        <!-- more, save and discard changes buttons -->
                        <div v-if="!disableButtons" class="object-main-buttons">
                            <v-tooltip top>
                                <template v-slot:activator="{on}">
                                    <v-switch
                                        v-on="on"
                                        :disabled="isHideEmptyFieldsSwitchDisabled"
                                        :input-value="hideEmptyFields"
                                        @change="toggleHideEmptyFields"
                                        color="accent"
                                    />
                                </template>
                                {{ ( hideEmptyFields ? 'show_all_object_fields' : 'hide_empty_object_fields' ) | translate }}
                            </v-tooltip>

                            <!-- print -->
                            <v-menu v-if="object.panelSourceType === 'REGULAR_ENTITY' && !object.isNew" offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        large
                                        v-bind="attrs"
                                        v-on="on"
                                        color="info"
                                        :disabled="printTemplates.length === 0"
                                    >
                                        <v-icon>fal fa-print</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item
                                        v-for="(template, i) in printTemplates" 
                                        :key="i"
                                        :to="{ name:'print', params:{ globalId: objectId, dataSource: template.dataSource } }"
                                        target="_blank"
                                    >
                                        <v-list-item-title>{{ template.displayString }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>

                            <!-- excel button -->
                            <inv-object-btn-export-send
                                :object-id="objectId"
                                :reports="excelEnabledReports"
                                :tab-groups="tabGroups"
                                :displayBlock="true"
                            />

                            <v-divider vertical v-if="object.panelSourceType === 'REGULAR_ENTITY' && !object.isNew"/>

                            <!-- expand / collapse -->
                            <v-tooltip top>
                                <template v-slot:activator="{on}">
                                    <v-btn
                                        v-if="detailAndTabbedDesign"
                                        @click="expand = !expand" 
                                        icon 
                                        large
                                        class="expand-actions-button"
                                        color="info"
                                        v-on="on"
                                        data-test="expand-object-description-button"
                                    >
                                        <v-icon>fal {{expand ? 'fa-compress-wide' : 'fa-expand-wide'}}</v-icon>
                                    </v-btn>
                                </template>
                                <span>
                                    {{ ( expand ? 'collapse' : 'expand' ) | translate }} {{ 'object_detail_panel' | translate}}
                                </span>
                            </v-tooltip>

                            <!-- more_actions -->
                            <v-menu
                                content-class="button-menu"
                                offset-y
                                v-model="buttonBarExpanded"
                                v-if="useHistorization || (object.buttons && object.buttons.length > 0)"
                                :nudge-width="200"
                                offset-x
                            >
                                <template v-slot:activator="{ on: menu }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{on: tooltip}">
                                            <v-btn
                                                v-on="{...tooltip, ...menu}"
                                                class="expand-actions-button"
                                                icon
                                                large
                                                color="accent"
                                            >
                                                <v-icon>
                                                    fal fa-bars
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>
                                            {{ "more_actions" | translate }}
                                        </span>
                                    </v-tooltip>
                                </template>

                                <v-card>
                                    <v-card-text>
                                        <v-btn
                                            block
                                            text
                                            v-for="button in object.buttons"
                                            :key="button.buttonId"
                                            @click="objectPanelButtonClicked(button)"
                                        >
                                            <v-icon>
                                                {{button.icon ? 'fal ' + button.icon : 'fal fa-address-card'}}
                                            </v-icon>
                                            {{button.displayString}}
                                        </v-btn>

                                        <v-divider style="margin: 16px 0" v-if="useHistorization && !!object.buttons && object.buttons.length > 0"/>

                                        <div class="historization-buttons" v-if="useHistorization">
                                            <v-menu
                                                v-if="useHistorization" 
                                                offset-y
                                            >
                                                <template v-slot:activator="{ on:on }">
                                                    <v-btn 
                                                        text
                                                        block
                                                        v-on="on" 
                                                        @click="loadHistoryItems()"
                                                    >
                                                        <v-icon>fal fa-calendar-alt</v-icon>
                                                        <span>{{'choose_from_history_snapshots' | translate}}</span>
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-item>
                                                        <v-list-item-action style="margin-left:-14px;">
                                                            <v-btn icon @click="$event.stopPropagation();loadHistoryItems(-1)" :disabled="!historyPagination.prev">
                                                                <v-icon>fal fa-chevron-left</v-icon>
                                                            </v-btn>
                                                        </v-list-item-action>
                                                        <v-list-item-title>&nbsp;</v-list-item-title>
                                                        <v-list-item-action style="margin-right:-6px;">
                                                            <v-btn icon @click="$event.stopPropagation();loadHistoryItems(1)" :disabled="!historyPagination.next">
                                                                <v-icon>fal fa-chevron-right</v-icon>
                                                            </v-btn>
                                                        </v-list-item-action>
                                                    </v-list-item>

                                                    <v-list-item v-for="(item, i) in historyItems" :key="i" @click="switchHistoryView(item.value)">
                                                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                            
                                            <div class="history-timestamp" v-if="useHistorization" style="margin-top: 16px">
                                                <obj-date-field v-model="historyTimestamp" @open="loadHistoryMinDate" :min="historyMinDate" hide-details hide-label clearable time :placeholder="'history_snapshot_date' | translate"/>
                                            </div>

                                        </div>

                                    </v-card-text>

                                </v-card>
                            </v-menu>

                            <v-divider vertical v-if="detailAndTabbedDesign"/>
                            
                            <!-- trash -->
                            <v-tooltip top>
                                <template v-slot:activator="{on}">
                                    <v-btn
                                        v-if="!object.isNew && object.panelSourceType === 'REGULAR_ENTITY' && object.crudOperations && object.crudOperations.deleteUrl"
                                        :disabled="object.isNew || (!object.crudOperations && !object.crudOperations.deleteUrl)" 
                                        @click="openDeleteConfirmModal" 
                                        icon 
                                        large
                                        color="error"
                                        v-on="on"
                                    >
                                        <v-icon>fal fa-trash</v-icon>
                                    </v-btn>
                                </template>
                                <span>
                                    {{'delete' | translate}}
                                </span>
                            </v-tooltip>

                            <!-- inventory management -->
                            <emdsl-initiator 
                                v-if="useEmdsl" 
                                :isDrawerCollapsed="!expand" 
                                :objectContext="true" 
                                @templateSelected="buttonBarExpanded = false"
                                style="margin-left: 16px"
                            />
                            
                            <!-- discard changes button -->
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn 
                                        v-on="on"
                                        @click="resetObjectDetailsForm"
                                        v-if="detailAndTabbedDesign" 
                                        :disabled="isDiscardButtonDisabled"
                                        :elevation="!hasDirtyFields ? 0 : 24"
                                        large
                                        :x-large="hasDirtyFields"
                                        :outlined="hasDirtyFields"
                                        min-width="44px"
                                        color="error"
                                        icon
                                        data-test="discard-changes-button"
                                    >
                                        <v-icon>fal fa-undo-alt</v-icon>
                                    </v-btn>
                                </template>
                                <span>
                                    {{'discard_changes'|translate}}
                                </span>
                            </v-tooltip>

                            <!-- save changes button -->
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-on="on"
                                        @click="saveObjectDetails" 
                                        v-if="detailAndTabbedDesign"
                                        :disabled="isSaveButtonDisabled"
                                        :elevation="!hasDirtyFields ? 0 : 24"
                                        large
                                        :x-large="hasDirtyFields"
                                        :outlined="hasDirtyFields"
                                        min-width="44px"
                                        color="success"
                                        icon
                                        data-test="save-changes-button"
                                    >
                                        <v-icon>fal fa-save</v-icon>
                                    </v-btn>
                                </template>
                                <span>
                                    {{'save'|translate}}
                                </span>
                            </v-tooltip>

                            <v-divider v-if="!fullScreenView" vertical/>

                            <!-- close button -->
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-if="!fullScreenView"
                                        @click="close"
                                        v-on="on"
                                        icon
                                        outlined
                                        color="error"  
                                        class="object-close-button" 
                                        data-test="close-button"
                                    >
                                        <v-icon>fal fa-times</v-icon>
                                    </v-btn>
                                </template>
                                <span>
                                    {{'close' | translate}}
                                </span>
                            </v-tooltip>

                        </div>

                    </v-card-title>

                    <v-expand-transition v-if="detailAndTabbedDesign">
                        <v-card-text v-show="expand">
                            <inv-object-form
                                :key="fieldsGrouped.length"
                                :groups="fieldsGrouped"
                                @field-input="changeDetailsFieldValue" 
                                :edit-mode="areFieldsEditable"
                                :edit-mode-switch="false" 
                                :object-id="object.id"
                                :max-cols="fullScreenView ? null : 2"
                            />
                        </v-card-text>
                    </v-expand-transition>

                </v-card>
            </div>
            <inv-object-tabs 
                v-if="object.panels && object.panels.length > 0" 
                :object="object" 
                :tab-states="objectTabStates" 
                :readonly="historyView" 
                :disableCRUD="disableButtons"
                :height="tabsHeight"
                @tab-states-changed="objectPanelTabsChanged"
                @resource-reload-needed="loadObject"
                @is-dirty="currentTabIsDirty = $event;"
                @data-changes="$emit('tab-data-changes', $event)"
            />
        </div>
        <v-dialog 
            v-model="deleteConfirmModal.opened" 
            width="500px"
            content-class="small-dialog"
        >
			<v-card flat>
				<v-card-title><h2>{{'confirm_delete' | translate}}</h2></v-card-title>

				<v-card-text>
                    {{'delete' | translate}} "<inv-object-link disabled :object="object"></inv-object-link>" ?
                </v-card-text>

				<v-card-actions>
                    <v-spacer></v-spacer>
					<v-btn 
                        text
                        @click="deleteConfirmModal.opened = false"
                    >
                        {{'cancel'|translate}}
                    </v-btn>
					<v-btn 
                        color="error" 
                        outlined
                        @click="deleteObject"
                    >
                        {{'delete'|translate}}
                    </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
        <v-dialog 
            v-model="forceDeleteModal.opened" 
            width="500px"
            content-class="small-dialog"
        >
            <v-card flat>
                <v-card-title><h2>{{'confirm_delete' | translate}}</h2></v-card-title>

                <v-card-text v-if="forceDeleteModal.message">
                    <strong 
                        v-for="(par, index) in forceDeleteModal.message.split('\n')"
                        :key="index" 
                        class="error--text"
                    >
                        {{ par }} <br>
                    </strong>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn 
                        text
                        @click="forceDeleteModal.opened = false"
                    >
                        {{'cancel'|translate}}
                    </v-btn>
                    <v-btn 
                        color="error" 
                        outlined
                        @click="forceDelete"
                    >
                        {{'delete_anyway'|translate}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <obj-leave-confirm  :is-dirty="hasDirtyFields || currentTabIsDirty" 
                            on-path-change 
                            :watch="closeTriggered" 
                            @fallback="continueClosing"
                            @ignore="continueClosing"/>
    </v-card>
</template>

<style scoped>
    .inv-object-card{
        height: 100%;
        padding: 0 !important;
        background: white;
        overflow-x: hidden; /* hide drawers when using inv-object inside dialog window (e.g. wizard) */
    }
    .inv-object-card .general-info-card .v-card__title{
        padding: 16px ;

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: baseline;
    }
    .inv-object-card .general-info-card .v-card__title .object-close-button{
        margin-right: 16px;
    }

    .inv-object-card .general-info-card .v-card__title .object-main-buttons{
        min-height: 44px;
        justify-self: flex-end;
        
        display: flex;
        flex-direction: row;
        align-items: center;
        z-index: 3;
    }
    .inv-object-card .general-info-card .v-card__title .object-main-buttons .v-btn:not(:first-child),
    .inv-object-card .general-info-card .v-card__title .object-main-buttons >>> .v-btn:not(:first-child),
    .inv-object-card .general-info-card .v-card__title .object-main-buttons hr{
        margin-left: 16px;
    }

    .inv-object-card .general-info-card.v-card--flat > .v-card__text{
        width: 100%;
        padding: 0 16px 8px 16px;
    }
    


    .inv-object-card .general-info-card .v-card__actions{
        width: 100%;
        padding: 0 16px;

        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-start;
    }
    .inv-object-card .general-info-card .v-card__actions .expand-actions-button{
        justify-self: flex-end;
    }

    .inv-object-card .general-info-card .v-card__actions .inv-object-button-group{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
    .inv-object-card .general-info-card .inv-object-button-group{
        margin-right: 18px;
    }
    .general-info-card .v-card__title .history-timestamp{
        width: 175px;
    }
    .general-info-card .v-card__title .v-icon:focus::after{
        opacity: 0;
    }
    .general-info-card .v-card__title .v-card__actions{
        padding: 0;
    }
    .inv-object-card .general-info-card .v-card__title .v-card__actions .v-btn--text,
    .inv-object-card .general-info-card .v-card__title .v-card__actions .history-timestamp{
        margin-left: 0px;
    }
    .inv-object-card .general-info-card .v-card__text{
        padding: 0;
    }
    .inv-object-card .general-info-card form .container{
        padding: 0px !important;
    }
    .inv-object-card .general-info-card form .container .row,
    .inv-object-card .general-info-card form .container .row .col-12{
        padding: 0 !important;
    }
    .inv-object-card .flex-container{
        height: 100% !important;
        
        display: flex;
        flex-direction: column;

    }

    .history-background {
        background-repeat: repeat;
        background-size: 300px;
        background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' opacity='0.02' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.28 2.54.72-1.21-3.5-2.08V8H12z'/%3E%3C/svg%3E ");
    }

    /* .object-content-bg {
        padding: 0px 1px;
    } */

    .no-gutters {
        padding:0px;
    }

    >>> .v-input--selection-controls__ripple {
        height: 24px !important;
        width: 24px !important;
        left: -2px !important;
        top: calc(50% - 12px) !important;
        margin: 0px !important;
    }
</style>

<script>
    import backend from 'obj-fe/app/backend';
    import notify from 'obj-fe/services/notifications';
    import router from 'obj-fe/app/router';

    import InvObjectForm from './inv-object-form.vue';
    import InvObjectLink from './inv-object-link.vue';
    import InvObjectTabs from './inv-object-tabs.vue';
    import emdslInitiator from '../dsl/emdsl/emdsl-initiator/emdsl-initiator.vue';
    import InvObjectBtnExportSend from "./inv-object-btn-export-send.vue";

    export default {
        components:{
            InvObjectBtnExportSend,
            InvObjectForm,
            InvObjectLink,
            InvObjectTabs,
            emdslInitiator
        },
        props:{
            id: String,
            enableDelete: Boolean,
            customSave: Boolean,
            createUrlTemplate: String,
            updateUrlTemplate: String,
            disableButtons: Boolean,
            tabsHeight:{}
        },
        data(){
            return {
                historyItems: null,
                historyPagination:{},
                historyMinDate: null,
                generalInfoPanel: 0,
                expand: true,
                deleteConfirmModal:{
                    opened: false
                },
                forceDeleteModal: {
                    opened: false,
                    message: '',
                },
                closeTriggered: 0,
                currentTabIsDirty: false,
                printTemplates: [],

                beObjPanelButton: null,

                buttonBarExpanded: false,
                hideEmptyFieldsState: null
            };
        },
        computed:{
            pageObjectId(){
                return this.$store.state.INVENTORY.pageObjectId;
            },
            objectId(){
                if(this.id) return this.id;
                else return this.pageObjectId;
            },
            object(){
                return this.$store.state.INVENTORY.objectsById[ this.objectId ];
            },
            fullScreenView(){
                return this.object.id === this.pageObjectId;
            },
            objectTabStates(){
                return this.$store.state.INVENTORY.pageObjectPanelStates || {};
            },
            historyTimestamp:{
                get(){
                    if(!this.useHistorization) return null; 
                    let timestamp = this.$store.state.INVENTORY.historyTimestamp;
                    return (timestamp !== null && timestamp >= 0) ? new Date(timestamp * 1000) : null;
                },
                
                set(date){
                    if(!this.useHistorization) return;
                    this.$store.dispatch('INVENTORY/SET_HISTORY_TIMESTAMP', date ? Math.round(date.getTime()/1000) : null);
                }
            },
            historyView(){
                return this.historyTimestamp !== null && this.historyTimestamp >= 0;
            },
            useEmdsl(){
                return this.$store.state.settings.useEmdsl;
            },
            useHistorization(){
                return this.$store.state.settings.historizationEnabled;
            },
            detailAndTabbedDesign(){
                let design = this.object.pageDesign || '';
                if(design === 'TABBED_ONLY_DESIGN') return false;
                return true
            },
            excelEnabledReports(){
                return (this.object.panels || []).filter(panel => panel.excelEnabled);
            },
            tabGroups() {
                return this.object.tabGroups || [];
            },
            areFieldsEditable() {
                return !this.historyView && !this.hideEmptyFields;
            },
            fieldsGrouped() {
                let fieldsGrouped = [];
                for (let fieldGroup of this.object.fieldsGrouped) {
                    const { fields, ...rest } = fieldGroup;
                    let nonEmptyFields = fields.filter(field => field.value)

                    if (this.hideEmptyFields && nonEmptyFields.length > 0) {
                        let resultFieldGroup = { fields: nonEmptyFields, ...rest }
                        fieldsGrouped.push(resultFieldGroup)
                    } else if (!this.hideEmptyFields) {
                        let resultFieldGroup = { fields: fields, ...rest }
                        fieldsGrouped.push(resultFieldGroup)
                    }
                }

                return fieldsGrouped;
            },
            hideEmptyFieldsSetting() {
                return this.$store.state.settings.hideEmptyObjectFields
            },
            hideEmptyFields() {
                if(this.object?.isNew) return false;

                if (this.hideEmptyFieldsState == null) {
                    return this.hideEmptyFieldsSetting;
                }

                return this.hideEmptyFieldsState;
            },
            hasDirtyFields(){
                return this.object?.fieldsDirty;
            },
            isDirty(){
                return this.hasDirtyFields || this.currentTabIsDirty;
            },
            isSaveButtonDisabled() {
                return !this.hasDirtyFields || this.historyView;
            },
            isDiscardButtonDisabled() {
                return this.isSaveButtonDisabled
            },
            isHideEmptyFieldsSwitchDisabled() {
                return !this.fieldsGrouped || !this.isSaveButtonDisabled || this.object?.isNew;
            },
            hideObjectTitleWithButtons(){
                return this.disableButtons && !this.object.name;
            }
        },
        watch:{
            object:{
                immediate: true,
                deep: true,
                handler(newObject){
                    this.printTemplates = [];

                    if(!newObject) return;
                    
                    if((!!this.objectId || this.object.panelSourceType === 'REGULAR_ENTITY') && !this.object.isNew) {
                        this.loadPrintTemplates();
                    }
                }
            },
            isDirty(isDirty){
                this.$emit('is-dirty', isDirty);
            }
        },
        methods:{
            close(){
                this.closeTriggered++; // just for leave confirm triggering via value watcher
            },
            continueClosing(){
                this.resetObjectDetailsForm();
                this.$emit('close');
            },
            loadObject(){
                this.$store.dispatch('INVENTORY/LOAD_OBJECT', this.$route);
            },
            loadPrintTemplates(){
                backend.objects.printTemplates({globalId: this.objectId}, data => {
                    this.printTemplates = data.templates;
                })
            },
            objectPanelTabsChanged(newStates){
                if(this.fullScreenView) this.$store.dispatch('INVENTORY/SET_PAGE_OBJECT_PANEL_STATE', newStates);
            },
            changeDetailsFieldValue(field, value){
                this.$store.dispatch('INVENTORY/SET_OBJECT_DETAIL_FIELD', { objectId:this.objectId, field, value });
            },
            resetObjectDetailsForm(){
                this.$store.dispatch('INVENTORY/RESET_OBJECT_DETAIL_FORM', this.objectId);
            },
            saveObjectDetails(){
                if(this.customSave) return this.$emit('save', this.object);

                this.$store.dispatch('INVENTORY/SAVE_OBJECT_DETAILS', {
                    objectId: this.objectId,
                    urlTemplate: this.saveUrlTemplate,
                    createUrlTemplate: this.createUrlTemplate,
                    updateUrlTemplate: this.updateUrlTemplate
                }).then(newObjectId => {
                    if(this.object.isNew) this.$emit('created', newObjectId);
                    else this.$emit('updated', newObjectId);
                });
            },

            loadHistoryItems(pageIncrement){
                if(!this.useHistorization) return;
                if(!pageIncrement && this.historyItems) return;

                backend.objects.historicalSnapshots({
                    id: this.objectId,
                    page: (this.historyPagination.page || 1) + (pageIncrement || 0),
                    limit: 10
                }, data => {
                    this.historyItems = data.map(item => {
                        return {
                            value: parseInt(item.name, 10),
                            text: item.title
                        };
                    });
                    this.historyPagination = data.pagination;
                });
            },
            loadHistoryMinDate(){
                if(!this.useHistorization) return;
                if(this.historyMinDate) return;

                backend.objects.firstSnapshot(this.objectId, timestampSeconds => {
                    this.historyMinDate = new Date(timestampSeconds * 1000);
                });
            },
            switchHistoryView(timestamp){
                if(!this.useHistorization) return;
                this.$store.dispatch('INVENTORY/SET_HISTORY_TIMESTAMP', timestamp);
            },
            openDeleteConfirmModal(){
                this.deleteConfirmModal.opened = true;
            },
            toggleHideEmptyFields(){
                this.hideEmptyFieldsState = !this.hideEmptyFields;
            },
            deleteObject(){
                let deleteUrls = this.object.crudOperations;
                if(!deleteUrls.deleteUrl) return;

                backend.customRestUrls.deleteRestUrl(
                    { customRestUrl: deleteUrls.deleteUrl},
                    () => { 
                        notify.success('object_deleted')
                        this.deleteConfirmModal.opened = false;

                        if(this.$route.name === 'inventory-object') router.push({name: 'inventory-search'});
                        else this.$emit('delete');
                    },
                    (data, status) => {
                        this.deleteConfirmModal.opened = false;
                        if(!deleteUrls.forceDeleteUrl) notify.error('object_could_not_be_deleted')
                        
                        // show force remove dialog when status 412
                        if(status === 412) {
                            this.forceDeleteModal = {
                                opened: true,
                                message: data,
                            };
                            return true;
                        }
                    }
                )
            },
            forceDelete(){
                let deleteUrls = this.object.crudOperations;
                if(!deleteUrls.forceDeleteUrl) return;

                backend.customRestUrls.deleteRestUrl(
                    { customRestUrl: deleteUrls.forceDeleteUrl},
                    () => { 
                        notify.success('object_deleted')
                        this.forceDeleteModal.opened = false;

                        if(this.$route.name === 'inventory-object') router.push({name: 'inventory-search'});
                        else this.$emit('forceDelete');
                    },
                    (error) => {
                        notify.error(error);
                        this.forceDeleteModal.opened = false;
                    }
                )

            },
            processDescriptionExpand(){
                this.expand = !this.expand;
                
                // map tab doesnt respond to its container's dimensions. hence this workaround  
                setTimeout(()=>{ window.dispatchEvent(new Event('resize'))}, 250) 
            },
            bookmark(){
                this.$store.dispatch('INVENTORY/APPOINT_OBJECT_BOOKMARKED', this.objectId);
            },
            objectPanelButtonClicked(button){
                backend.objects.objectPanelButtonAction({
                    globalId: this.objectId,
                    buttonId: button.buttonId
                }, (response) => {
                    if(response.status === 'OK') notify.success(response.response)
                    if(response.status === 'NOK') notify.error(response.response)
                    
                    if(response.action === 'RELOAD') {
                        let objectIdentifier = {
                            params: {
                                id: this.objectId
                            }
                        }
                        this.$store.dispatch('INVENTORY/LOAD_OBJECT', objectIdentifier);
                    }
                    
                })
            }
        },
        beforeDestroy: function(){
            this.$store.dispatch('INVENTORY/SET_HEAD_TITLE', '');
        }
    };
</script>