<template>
    <div>
        <!-- displayed in inventory-objects -->
        <div v-if="objectContext">
            <v-menu 
                :disabled="!!contextTemplates && contextTemplates.length === 1"    
                offset-y
            >
                <template v-slot:activator="{ on:menu }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip}">
                            <v-btn 
                                icon
                                color="info"
                                large
                                v-on="{ ...menu, ...tooltip }"
                                @click="activatorClicked" 
                                :disabled="!contextTemplates.length > 0"
                                data-test="emdsl-button"    
                            >
                                <v-icon>fal fa-inventory</v-icon>
                            </v-btn>
                        </template>
                        <span>
                            {{'inventory_management' | translate}}
                        </span>
                            
                        
                    </v-tooltip>
                    
                </template>
                <v-list>
                    <v-list-item 
                        @click="selectTemplate(template)" 
                        v-for="template in contextTemplates" 
                        :key="template.id"
                    >
                        <v-list-item-icon>
                            <v-icon>fal fa-puzzle-piece</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            {{template.displayString}}
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>

        <!-- displayed in drawer -->
        <div v-else-if="contextlessTemplates.length > 0">
            <hr style="border: none; border-top: 1px solid rgba(0,0,0,0.12); margin-bottom:6px"/>
            <v-list-item @click.stop="" inactive :ripple="false">
                <v-list-item-icon>
                    <v-tooltip right :disabled="!shouldShowTooltip">
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on">
                                fal fa-inventory
                            </v-icon>
                        </template>
                        <span>
                            {{ 'inventory_management' | translate }}
                        </span>
                    </v-tooltip>
                </v-list-item-icon>
                <v-list-item-title>
                    {{ 'inventory_management' | translate }}
                </v-list-item-title>
            </v-list-item>
                
            <v-list-item 
                @click="selectTemplate(template)" 
                v-for="template in contextlessTemplates" 
                :key="template.id"
                :class="{ 'template-item-expanded' : !isDrawerCollapsed, 'template-item-collapsed' : isDrawerCollapsed }"
                >
                <v-list-item-icon>
                    <v-tooltip right :disabled="!shouldShowTooltip">
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on">
                                fal fa-puzzle-piece
                            </v-icon>
                        </template>
                        <span>
                            {{ template.displayString }}
                        </span>
                    </v-tooltip>
                </v-list-item-icon>
                <v-list-item-title>
                    {{template.displayString}}
                </v-list-item-title>
            </v-list-item>
        </div>

        <v-dialog
                v-if="dialogOpen"
                v-model="dialogOpen"
                fullscreen
                persistent
                hide-overlay
                transition="dialog-bottom-transition"
                content-class="editor-dialog"
                data-test="editor-dialog"
            >
            <v-toolbar 
                dark 
                color="primary"
                flat   
            >
                <v-btn 
                    icon
                    outlined
                    color="error"
                    @click="closeDialog"
                >
                    <v-icon>fal fa-times</v-icon>
                </v-btn>

                <v-toolbar-title>Inventory Objects Editor {{ selectedTemplate ? (' - ' + selectedTemplate.displayString) : '' }}</v-toolbar-title>
                <v-spacer></v-spacer>

                <v-btn 
                    x-large
                    @click="save"
                    :disabled="!isValid || shouldSave"
                    :color="editingScope === 'ADD_NEW' ? 'success lighten-1' : 'accent'"
                >
                    <v-icon left v-if="!shouldSave"> {{shouldSave ? 'fal fa-spinner fa-spin' : editingScope === 'ADD_NEW' ? 'fal fa-plus' : 'fal fa-retweet'}}</v-icon>
                    <span>{{editingScope === 'ADD_NEW' ? 'add_new' : 'replace_all' | translate}}</span>
                </v-btn>

            </v-toolbar>
            <v-card>
                <emdsl-component 
                    v-if="!!selectedTemplate" 
                    :template="selectedTemplate"
                    :objectContext="objectContext"
                    :globalId="globalId"
                    :shouldSave="shouldSave"
                    @saved="shouldSave = false"
                    @save-error="shouldSave = false"
                    @valid="(valid) => isValid = valid"
                    @editing-scope="(scope) => editingScope = scope"    
                />
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import EmdslComponent from './emdsl-component.vue'

export default {
    props:{
        objectContext: Boolean,
        isDrawerCollapsed: Boolean,
        displayAsBlock: Boolean
    },
    components: {
        EmdslComponent
    },
    data(){
        return {
            dialogOpen: false,
            selectedTemplate: null,
            shouldSave: false,
            isValid: false,
            editingScope: null
        }
    },
    computed:{
        contextlessTemplates: function(){
            return this.$store.state.EMDSL.contextlessTemplates;
        },
        contextTemplates: function(){
            return this.$store.state.EMDSL.contextTemplates;
        },
        globalId: function(){
            return this.$route.params.id
        },
        shouldShowTooltip(){
            return this.isDrawerCollapsed;
        }
    },
    watch:{
        objectContext: {
            immediate: true,
            handler: function(){
                if(this.objectContext) this.loadTemplates();
            }
        },
        dialogOpen: function(){
            if(!this.dialogOpen) this.selectedTemplate = null;
        }
    },
    methods:{
        loadTemplates(){
            if(this.objectContext) this.$store.dispatch('EMDSL/LOAD_CONTEXT_TEMPLATES', {route: this.$route, objectContext: this.objectContext});
            else this.$store.dispatch('EMDSL/LOAD_CONTEXTLESS_TEMPLATES');
        },
        selectTemplate(template){
            this.$emit('templateSelected')
            this.selectedTemplate = template;
            this.dialogOpen = true;
        },
        save(){
            this.shouldSave = true;
        },
        closeDialog(){
            this.selectedTemplate = null;
            this.dialogOpen = false;
        },
        activatorClicked(){
            if(!!this.contextTemplates && this.contextTemplates.length === 1 ) this.selectTemplate(this.contextTemplates[0])
        }
    },
    mounted(){
        this.loadTemplates();
    }
}
</script>

<style>
.editor-dialog{
    height: 100%;
    overflow: hidden;
}
.editor-dialog .v-card{
    min-height: unset;
    height: calc(100vh - 64px);
}
.editor-dialog .v-card .emdsl-component-container{
    padding: 10px;
}
.editor-dialog .v-card .emdsl-component-container .inv-object-tab-graphviz-svg{
    padding: 10px
}

.template-item-expanded { 
    padding-left: 27px;
    transition: 0.15s padding-left ease;
}

.template-item-collapsed { 
    padding-left: auto;
    transition: 0.15s padding-left ease;
}

</style>