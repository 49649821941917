<template>
    <v-menu
        content-class="button-menu"
        offset-y
        :nudge-width="200"
        offset-x
        v-if="buttons && buttons.length > 0"
    >
        <template v-slot:activator="{ on: menu }">
            <v-tooltip top>
                <template v-slot:activator="{on: tooltip}">
                    <v-btn
                        v-on="{...tooltip, ...menu}"
                        class="expand-actions-button obj-report-button"
                        icon
                        color="accent"
                        :x-small="iconSizes.iconSizeXSmall"
                        :small="iconSizes.iconSizeSmall"
                        :large="iconSizes.iconSizeLarge"
                        :x-large="iconSizes.iconSizeXLarge"
                    >
                        <v-icon>
                            fal fa-bars
                        </v-icon>
                    </v-btn>
                </template>
                <span>
                    {{ "more_actions" | translate }}
                </span>
            </v-tooltip>
        </template>

        <v-card>
            <v-card-text>
                <inv-custom-report-button-group
                    :buttons="buttons"
                    @clicked="button => $emit('clicked', button)"
                    :displayBlock="true"
                />
            </v-card-text>
        </v-card>
    </v-menu>
</template>

<script>
import InvCustomReportButtonGroup from './inv-report-button-group.vue'

export default {
    components: {
        InvCustomReportButtonGroup
    },
    props: {
        buttons: Array,
        iconSizes: {
            iconSizeXSmall: Boolean,
            iconSizeSmall: Boolean,
            iconSizeLarge: Boolean,
            iconSizeXLarge: Boolean
        }
    }
}
</script>

<style scoped>
.inv-object-tab-item-action-buttons button{
    margin-left: 8px;
}
</style>
