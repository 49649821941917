import { Lexer, createToken as origCreateToken } from 'chevrotain';

function createToken(opts){
    let token = origCreateToken(opts);
    token.typeId = opts.typeId;
    token.text = opts.text;
    token.field = opts.field;

    return token;
}

let tokens = {};

// nwdsl example syntax:
// link AB (40x33) {id:10000000001213944} = LINK:10000000001213944:AB (40x33)
// node byt1 = 10000000006579954:UNIVERSO_F-059-BA-RK-01-OD-02 (FC moduly na ukončenie monovlákien od bytov)

// node - node
// node --- link - node

// tokens.NumericField = createToken({ name: 'NumericField', pattern: Lexer.NA, typeId: 'FIELD' });

// // NumericFieldPlaceholder
// createToken({
//     name: 'NumericFieldPlaceholder',
//     pattern: Lexer.NA,
//     categories: tokens.NumericField,
//     typeId: 'FIELD'
// });


tokens.SquareBracketLeft = createToken({ name: 'SquareBracketLeft', pattern: '[', text:'[', typeId:'SQUARE_BRACKET_LEFT' });
tokens.SquareBracketRight = createToken({ name: 'SquareBracketRight', pattern: ']', text:']', typeId:'SQUARE_BRACKET_RIGHT' });

tokens.CurlyBracketLeft = createToken({ name: 'CurlyBracketLeft', pattern: '{', text:'{', typeId:'CURLY_BRACKET_LEFT' });
tokens.CurlyBracketRight = createToken({ name: 'CurlyBracketRight', pattern: '}', text:'}', typeId:'CURLY_BRACKET_RIGHT' });

tokens.Comma = createToken({
    name: 'Comma',
    pattern:',',
    line_breaks: false,
    text:',',
    typeId:'COMMA'
});
tokens.Semicolon = createToken({
    name: 'Semicolon',
    pattern:';',
    line_breaks: false,
    text:';',
    typeId:'SEMICOLON'
});

// tokens.EOL = createToken({
//     name: 'EOL',
//     pattern: /\n|\r\n?/,
//     line_breaks: true,
//     text: 'EOL',
//     typeId: 'EOL'
// });

tokens.Comment = createToken({
    name: 'Comment',
    pattern:/\/\/.*/,
    line_breaks: false,
    text:'//',
    typeId:'COMMENT',
    group: Lexer.SKIPPED
});

// tokens.CommentMultiline = createToken({
//     name: 'CommentMultiline',
//     pattern: /\*(.|[\r\n])*?\*/,
//     line_breaks: true,
//     text:'/*',
//     typeId:'COMMENT_MULTILINE',
//     group: Lexer.SKIPPED
// });

tokens.Identifier = createToken({ name: 'Identifier', pattern: /[a-zA-Z0-9\-_:]+/, typeId:'ALIAS' });

tokens.Link = createToken({ name: 'Link', pattern: 'link', text:'link', typeId:'LINK_DEFINITION', longer_alt: tokens.Identifier});
tokens.Node = createToken({ name: 'Node', pattern: 'node', text:'node', typeId:'NODE_DEFINITION', longer_alt: tokens.Identifier});
tokens.Group = createToken({ name: 'Group', pattern: 'group', text:'group', typeId:'GROUP_DEFINITION', longer_alt: tokens.Identifier });
tokens.EntitiesDefinition = createToken({ name: 'EntitiesDefinition', pattern: 'definitions', text:'definitions', typeId:'ENTITIES_DEFINITION', longer_alt: tokens.Identifier });
tokens.ConnectionsDefinition = createToken({ name: 'ConnectionsDefinition', pattern: 'connections', text:'connections', typeId:'CONNECTIONS_DEFINITION', longer_alt: tokens.Identifier });
tokens.Redundant = createToken({ name: 'Redundancy', pattern: 'redundant', text:'redundant', typeId:'REDUNDANCY', longer_alt: tokens.Identifier });
tokens.RedundanciesDefinition = createToken({ name: 'RedundanciesDefinition', pattern: 'redundancies', text:'redundancies', typeId:'REDUNDANCIES_DEFINITION', longer_alt: tokens.Identifier });

tokens.Connection = createToken({
    name: 'Connection',
    pattern: /[~]+/,
    text: '~',
    typeId: 'CONNECTION',
});

tokens.String = createToken({
    name: 'String',
    pattern: /[a-zA-Z0-9\-\_:]+/,
    typeId:'VALUE'
});

tokens.StringQuoted = createToken({
    name: 'StringQuoted',
    pattern: /\"((-| |\S)+?(?!\n|\r\n))\"/,
    line_breaks: false,
    typeId:'VALUE'
});

tokens.StringQuotedSingle = createToken({
    name: 'StringQuotedSingle',
    pattern: /\'((-| |\S)+?(?!\n|\r\n))\'/,
    line_breaks: false,
    typeId:'VALUE'
});

tokens.Equal = createToken({ name: 'Equal', pattern: /=/, text:'=', typeId:'EQUAL' });

tokens.WhiteSpace = createToken({
    name: 'WhiteSpace',
    pattern: /\s+/,
    group: Lexer.SKIPPED,
    text:' ',
    typeId:'SPACE'
});

function createTokenList(){
    return [
        // tokens.EOL,
        tokens.Comment,
        tokens.Semicolon,
        // tokens.CommentMultiline,
        tokens.SquareBracketLeft,
        tokens.SquareBracketRight,
        tokens.CurlyBracketLeft,
        tokens.CurlyBracketRight,
        tokens.Comma,
        tokens.EntitiesDefinition,
        tokens.ConnectionsDefinition,
        tokens.Redundant,
        tokens.RedundanciesDefinition,
        tokens.Link,
        tokens.Node,
        tokens.Group,
        tokens.Connection,
        tokens.Equal,
        tokens.WhiteSpace,
        tokens.Identifier,
        tokens.String,
        tokens.StringQuoted,
        tokens.StringQuotedSingle
    ];
}

export { createTokenList, createToken, tokens };