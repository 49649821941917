<template>
    <div style="text-align:center" @click="$event.stopPropagation()">
        <input type="checkbox" v-model="model" @mousedown="$event.stopPropagation()" :disabled="disabled">
    </div>
</template>

<script>
    import spreadsheetRendererMixin from './spreadsheet-renderer-mixin.js';

    export default {
        mixins:[
            spreadsheetRendererMixin
        ],
        computed:{
            model:{
                get(){
                    return this.value;
                },
                set(v){
                    this.$emit('input', typeof v === 'boolean' ? v : undefined)
                }
            },
            disabled(){
                if(this.column.disabled === true || this.column.readonly === true) return true;
                else return this.row._state.disabledCells[this.colIndex] || this.row._state.readonlyCells[this.colIndex];
            }
        }
    }
</script>