import { Lexer, CstParser } from 'chevrotain';
import { createInterpreter } from './nwdsl-interpreter.js';
import { createTokenList, createToken, tokens } from './nwdsl-tokens.js';


class NwdslParser extends CstParser { //} EmbeddedActionsParser { //CstParser {
    constructor() {
        super(createTokenList(), {
            // by default the error recovery flag is **false**
            // use recoveryEnabled flag in the IParserConfig object to enable enable it.
            recoveryEnabled: true,
            dynamicTokensEnabled: true
        });

        const $ = this;

        $.RULE('rootExpression', () => {
            
            $.SUBRULE($.entitiesDefinition, { LABEL: 'expression'});
            
            $.OPTION2(() => {
                $.SUBRULE($.connectionsDefinition, { LABEL: 'expression'});
            });

        });
        
        $.RULE('entitiesDefinition', () => {
            $.CONSUME(tokens.EntitiesDefinition, { LABEL: 'type' });
            $.CONSUME(tokens.CurlyBracketLeft, {LABEL: 'curlyBracketLeft'});
            
            $.MANY1(() => {
                $.OR([
                    { ALT: () => $.SUBRULE($.nodeDefinition, { LABEL: 'expression' }) },
                    { ALT: () => $.SUBRULE($.linkDefinition, { LABEL: 'expression' }) },
                    { ALT: () => $.SUBRULE($.redundant, { LABEL: 'expression'})},
                    { ALT: () => $.SUBRULE($.group, { LABEL: 'expression'})},
                ]);
            });
            
            $.CONSUME(tokens.CurlyBracketRight, {LABEL: 'curlyBracketRight'});
        });

        $.RULE('connectionsDefinition', () => {
            $.CONSUME(tokens.ConnectionsDefinition, { LABEL: 'type' });
            $.CONSUME(tokens.CurlyBracketLeft, {LABEL: 'curlyBracketLeft'});
            
            $.OPTION(() => {
                $.MANY(()=>{
                    $.SUBRULE($.connection, {LABEL: 'expression'});
                });
            });
            
            $.CONSUME(tokens.CurlyBracketRight, {LABEL: 'curlyBracketRight'});
        });

        // group "group_name" {...}
        $.RULE('group', () => {
            $.CONSUME(tokens.Group, { LABEL:'type' }); 
            $.OPTION1(()=>{
                $.SUBRULE($.stringValue, { LABEL:'value' }); 
            });
            $.OPTION2(() => {
                $.SUBRULE($.attributes, {LABEL: 'attributes'});
            });
            $.CONSUME(tokens.CurlyBracketLeft, {LABEL: 'curlyBracketLeft'});
            
            $.MANY(()=>{
                $.OR([
                    { ALT: () => $.SUBRULE($.nodeDefinition, { LABEL: 'expression' }) },
                    { ALT: () => $.SUBRULE($.redundant, { LABEL: 'expression'})},
                    { ALT: () => $.SUBRULE($.group, { LABEL: 'subGroup' }) }
                ]);
            });
            $.CONSUME(tokens.CurlyBracketRight, {LABEL: 'curlyBracketRight'});
        });

        $.RULE('redundant', () => {
            $.CONSUME(tokens.Redundant, { LABEL:'type' }); 
            $.SUBRULE($.stringValue, { LABEL:'value' }); 
            $.OPTION2(() => {
                $.SUBRULE($.attributes, {LABEL: 'attributes'});
            });
            $.CONSUME(tokens.CurlyBracketLeft, {LABEL: 'curlyBracketLeft'});
            
            $.MANY(()=>{
                $.OR([
                    { ALT: () => $.SUBRULE($.nodeDefinition, { LABEL: 'expression' }) },
                    { ALT: () => $.SUBRULE2($.redundant, { LABEL: 'subRedundancies' }) }
                ]);
            });
            $.CONSUME(tokens.CurlyBracketRight, {LABEL: 'curlyBracketRight'});
        });

        // link "LINK name from BE" [ id = 123, alias = "asd" ]
        $.RULE('linkDefinition', () => {
            $.CONSUME(tokens.Link, { LABEL:'type' });
            $.SUBRULE($.stringValue, { LABEL:'value' });
            $.OPTION(() => {
                $.SUBRULE($.attributes);
            });
        });

        // link "LINK name from BE" [ id = 123, alias = "asd" ]
        $.RULE('nodeDefinition', () => {
            $.CONSUME(tokens.Node, { LABEL:'type' });
            $.SUBRULE($.stringValue, { LABEL:'value' });
            $.OPTION(() => {
                $.SUBRULE($.attributes);
            });
        });

        $.RULE('stringValue', () => {
            $.OR([
                { ALT: () => $.CONSUME(tokens.Identifier, { LABEL:'value' }) },
                { ALT: () => $.CONSUME(tokens.String, { LABEL:'value' }) },
                { ALT: () => $.CONSUME(tokens.StringQuoted, { LABEL:'value' }) },
                { ALT: () => $.CONSUME(tokens.StringQuotedSingle, { LABEL:'value' }) }
            ]);
        });

        // node1 --- node2
        // node1 --- line1 --- node2
        $.RULE('connection', () => {
            $.SUBRULE1($.stringValue, { LABEL:'nodeFrom' });
            $.CONSUME2(tokens.Connection, { LABEL:'connectionFrom' });
            $.SUBRULE2($.stringValue, { LABEL:'nodeOrLink' });

            $.OPTION(() => {
                $.CONSUME3(tokens.Connection, { LABEL:'connectionTo' });
                $.SUBRULE3($.stringValue, { LABEL:'nodeTo' });
            });

            $.OPTION2(() => {
                $.SUBRULE4($.attributes);
            });
            // $.CONSUME3(tokens.Semicolon);
        });

        // [ attr1 = val1, attr2 = val2 ]
        $.RULE('attributes', () => {
            $.CONSUME(tokens.SquareBracketLeft, { LABEL:'bracketLeft' });
            $.MANY_SEP({
                SEP: tokens.Comma,
                DEF: () => {
                    $.SUBRULE($.attribute);
                }
            });
            $.CONSUME(tokens.SquareBracketRight, { LABEL:'bracketRight' });
        });

        $.RULE('attribute', () => {
            $.CONSUME(tokens.Identifier, { LABEL:'name' });
            $.CONSUME(tokens.Equal, { LABEL:'equal' });
            $.SUBRULE($.stringValue, { LABEL:'value' });
        });

        this.performSelfAnalysis();
    }
}

// wrapping it all together
// reuse the same parser instance.
const nwdslParser = new NwdslParser();

// Obtains the default CstVisitor constructor to extend.
const BaseCstVisitor = nwdslParser.getBaseCstVisitorConstructor();
const nwdslInterpreter = createInterpreter(BaseCstVisitor);

function createLexerInstance(){
    this.queryLexer = new Lexer(createTokenList());
}

function repairTokenColumnsPositions(lexResult, text){

    let lineStartOffsets = { '0':0 };
    let lines = text.split('\n');
    lines.forEach((line, index) => {
        let prevLine = lines[ index-1 ];
        if(index > 0) lineStartOffsets[ index ] = lineStartOffsets[ index - 1 ] + prevLine.length + 1;
    });

    lexResult.tokens = lexResult.tokens.map(token => {
        token.startColumn = token.startOffset - lineStartOffsets[ token.startLine - 1 ] + 1;
        token.endColumn = token.endOffset - lineStartOffsets[ token.endLine - 1 ] + 1;
        return token;
    });

    return lexResult;
}

function parse(text){
    // 1. Tokenize the input.
    // need to repairTokenPosColumns, because in fault tolerant mode, columns are moved and are not in sync with source text
    const lexResult = repairTokenColumnsPositions(this.queryLexer.tokenize(text), text);

    // 2. Parse the Tokens vector.
    nwdslParser.input = lexResult.tokens;
    const cst = nwdslParser.rootExpression();

    // 3. Perform semantics using a CstVisitor.
    // Note that separation of concerns between the syntactic analysis (parsing) and the semantics.
    const value = nwdslInterpreter.visit(cst);

    this.text = text;

    this.result = {
        
        tokens: lexResult.tokens,
        lexErrors: lexResult.errors,
        parseErrors: nwdslParser.errors.concat(value.errors || []), // append interpreter errors
        value: value,
        isValid: lexResult.errors.length === 0 && nwdslParser.errors.length === 0, 
        validated: false,
        validate: validateResources.bind(this),

        validQueue:[],
        onValidated(cb){
            if(this.validated) cb();
            else this.validQueue.push(cb);
        },
        validationFinished(){
            this.isValid = this.lexErrors.length === 0 && this.parseErrors.filter(e => !e.isWarning).length === 0;
            this.validated = true;
            this.validQueue.forEach(cb => cb());
            this.validQueue = [];
        }
    };

    // trigger aditional async validations
    this.result.validate();

    return this.result;
}

function validateResources(cb){
    let resourcesToValidate = [];

    function recursivelyValidateGroupMembers(groupMembers){
        groupMembers.forEach(data=>{
            let resourceData = data.data;
            if(resourceData.type === 'LINK' || resourceData.type === 'NODE') resourcesToValidate.push(data);
            if(resourceData.type === 'GROUP') recursivelyValidateGroupMembers(resourceData.groupMembers);
        });
    }

    recursivelyValidateGroupMembers(this.result.value.data);

    let parser = this;

    function done(){
        parser.result.validationFinished();
        if(cb) cb();
    }

    if(!parser.validateResources) return done();

    parser.validateResources(resourcesToValidate.map(e => e.data), result => {

        result.forEach((err, index) => {
            if(err) {
                let data = resourcesToValidate[index];
                err.token = data.data.token;
                this.result.parseErrors.push(err);
            }
        });

        done();
    });
}

function checkQuoteText(text){
    if(typeof text !== 'string') return text;
    else if(!text.match(/^[a-zA-Z0-9\-_:]*$/)) return '"' + text.replace(/"/g,'\\"') + '"';
    else return text;
}

function checkUnquoteText(str = ''){
    let quoteChar = str[0];
    let lastChar = str[ str.length-1 ];
    if(quoteChar === '"' || quoteChar === '\'') return str.slice(1, lastChar === quoteChar ? str.length-1 : undefined);
    else return str;
}



function findNearestPrevTokenByTypeId(tokens, typeId, maxLookahead = 0, currTokenIndex = tokens.length-1){
    let lookAheadCount = 0;
    for(let i=currTokenIndex;i>=0;i--){
        let token = tokens[i];
        if(token.tokenType.typeId === typeId) return token;
        if(maxLookahead && (lookAheadCount >= maxLookahead)) return;
        lookAheadCount++;
    }
}

let ConnectionToken = tokens.Connection;
let SquareBracketLeftTokenText = tokens.SquareBracketLeft.text;

function getNextTokenSuggestions(line = 0, column = 0, cb){
    let parser = this;
    let lines = this.text.split('\n');
    let lineText = lines[line] || '';
    let text = lineText.slice(0, column);
    let lineStartOffset = lines.slice(0, line).reduce((sum, lineStr) => sum + lineStr.length + 1, 0);
    let lineEndOffset = lineStartOffset + lineText.length;
    let offset = lineStartOffset + text.length;

    // find token which contains desired column
    let tokens = this.result.tokens.slice();
    let targetToken = this.result.tokens.filter(token => token && token.startOffset <= offset && offset <= token.endOffset).pop();
    if(targetToken) tokens = tokens.slice(0, tokens.indexOf(targetToken));
    else {
        tokens = this.queryLexer.tokenize(this.text.slice(0, offset)).tokens;
        targetToken = tokens.pop();
    }

    let startOffset = targetToken ? targetToken.startOffset : undefined;
    let endOffset = targetToken ? targetToken.endOffset : undefined;
    let isAfterToken = offset > endOffset+1;
    let isAtEOL = offset === this.text.length;
    let replacingCurrentToken = !isAfterToken && !isAtEOL;
    let currTokenText = targetToken ? targetToken.image.slice(0, offset - startOffset) : '';
    if(isAfterToken) {
        tokens.push(targetToken);
        targetToken = null;
        startOffset = endOffset = offset;
    }

    // backend-communicating functions
    function getSuggestResourcesRequestObject(){
        let linkDefinition = findNearestPrevTokenByTypeId(tokens, 'LINK_DEFINITION', 1);
        let nodeDefinition = findNearestPrevTokenByTypeId(tokens, 'NODE_DEFINITION', 1);
        let type = linkDefinition ? 'LINK' : 'NODE';

        // if this is not value after definition, skip it
        if(!linkDefinition && !nodeDefinition){
            let connectionFrom = findNearestPrevTokenByTypeId(tokens, 'CONNECTION', 1);
            let connectionTo = findNearestPrevTokenByTypeId(tokens, 'CONNECTION', 1, tokens.indexOf(connectionFrom) - 1);
            if(connectionFrom && !connectionTo) type = '';
            else type = 'NODE';
        }        
        let valueToken = (targetToken && ['VALUE', 'ALIAS'].indexOf(targetToken.tokenType.typeId) > -1) ? targetToken : null;
        let text = checkUnquoteText((valueToken || {}).image);
        let prefix = checkUnquoteText(valueToken ? currTokenText : '');

        // suggestions should act like autocompletion, when you click inside value, it should not filter it by prefix
        if(text !== prefix) prefix = '';
        
        return {
            prefix, 
            text, 
            token: valueToken, 
            type
        };
    }
    function fetchResources(cb){
        if(parser.suggestResources){
            parser.suggestResources(
                getSuggestResourcesRequestObject(),
                suggestions => {
                    cb(
                        suggestions
                            .map(sugg => {
                                sugg = sugg.hasOwnProperty('text') ? 
                                    sugg : 
                                    { 
                                        text: sugg, 
                                        displayText: sugg, 
                                        className: 'BE-suggestion custom-suggestion', 
                                        type: 'BE'
                                    };
                                sugg.wasFiltered = true; // don't filter this suggestions by front-end
                
                                if(sugg.exactText) return sugg;
                                else return checkQuoteSugg(sugg);
                            }), 
                        startOffset, 
                        endOffset
                    );
                }
            );
        } 
    }

    function fetchResourcesForAliases(cb){
        parser.suggestResourcesForAliases(
            getSuggestResourcesRequestObject(),
            suggestions => {
                cb(
                    suggestions
                        .map(sugg => {
                            sugg = sugg.hasOwnProperty('text') ? 
                                sugg : { 
                                    text: sugg, 
                                    displayText: sugg, 
                                };
                            sugg.className = 'BE-suggestion custom-suggestion', 
                            sugg.type = 'ALIAS-BE';
                            sugg.wasFiltered = true; // don't filter this suggestions by front-end
            
                            if(sugg.exactText) return sugg;
                            else return checkQuoteSugg(sugg);
                        }), 
                    startOffset, 
                    lineEndOffset
                );
            }
        );
    }

    function loadAttributesIfNeeded(cb){
        // collect all the data about the nearest entity. this data in then used for building the  
        // to do that, we need to consider looking for every type of entity definition
        let linkDefinition = findNearestPrevTokenByTypeId(tokens, 'LINK_DEFINITION');
        let nodeDefinition = findNearestPrevTokenByTypeId(tokens, 'NODE_DEFINITION');
        let groupDefinition = findNearestPrevTokenByTypeId(tokens, 'GROUP_DEFINITION');
        let redundanciesDefinition = findNearestPrevTokenByTypeId(tokens, 'REDUNDANCIES_DEFINITION');
        let redundancyDefinition = findNearestPrevTokenByTypeId(tokens, 'REDUNDANCY');
        let connectionDefinition = findNearestPrevTokenByTypeId(tokens, 'CONNECTION');

        // now we get the most recent entity
        let foundEntities = [
            linkDefinition, 
            nodeDefinition, 
            groupDefinition, 
            redundancyDefinition, 
            redundanciesDefinition, 
            connectionDefinition
        ].sort((a,b)=>{
            if(!a && !b) return 0;
            if(!a && b) return 1;
            if(a && !b) return -1;
            
            return a.startOffset >= b.startOffset ? -1 : 1; 
        });

        
        // process important parts of the entity
        let entity = foundEntities[0];
        let entityIndex = tokens.indexOf(entity);
        let attributesTokens = tokens.slice(entityIndex);
        let attributesBeginningIndex = attributesTokens.map(e=>e.tokenType.typeId).indexOf('SQUARE_BRACKET_LEFT');
        

        let entityIdentifierToken = tokens[entityIndex + 1];
        let entityIdentifierValue = '';
        if(entityIdentifierToken.tokenType.typeId === 'VALUE' || entityIdentifierToken.tokenType.typeId === 'ALIAS') entityIdentifierValue = entityIdentifierToken.image;
        
        // process attributes - prepare them for backend
        attributesTokens = attributesTokens.slice(attributesBeginningIndex + 1);
        let processedAttributes = {};
        if(attributesTokens.length > 2){
            while(attributesTokens.length > 2){
                // read tokens as attributes
                let attributeNameToken = attributesTokens.shift();  
                let attributeEqualToken = attributesTokens.shift();
                let attributeValueToken = attributesTokens.shift();
                
                //perform necessary validation of tokens
                if(
                    (attributeNameToken && attributeEqualToken && attributeValueToken) &&
                    (attributeNameToken.tokenType.typeId === 'ALIAS') &&
                    (attributeEqualToken.tokenType.typeId === 'EQUAL') &&
                    (attributeValueToken.tokenType.typeId === 'ALIAS')
                )
                // assign tokens as attibuteName = attributeValue
                    processedAttributes[attributeNameToken.image] = attributeValueToken.image;

                // skip the comma separator
                if(attributesTokens.length > 0 && attributesTokens[0].image === ',') attributesTokens.shift();
            }
        }

        // prepare remaining data about the remaining attribute name or value
        let remainingAttribute = '';

        let valueToken = (targetToken && ['VALUE', 'ALIAS'].indexOf(targetToken.tokenType.typeId) > -1) ? targetToken : null;
        let text = checkUnquoteText((valueToken || {}).image);
        let prefix = checkUnquoteText(valueToken ? currTokenText : '');

        if(attributesTokens.length > 0){
            let aliasToken = attributesTokens.shift();
            let equalToken = attributesTokens.shift();

            if( 
                aliasToken && aliasToken.tokenType.typeId === 'ALIAS' && 
                equalToken && equalToken.tokenType.typeId === 'EQUAL'){
                remainingAttribute = aliasToken.image;
            } 
        }
        
        // perform attribute suggestions call
        if(entity){
            let suggestRequestData = {
                'attribute': remainingAttribute,
                'context': {
                    'alias': entityIdentifierValue,
                    'attributes': processedAttributes,
                    'type': entity.tokenType.name.toUpperCase()
                },
                'name' : entityIdentifierValue,
                'searchToken': text !== prefix ? prefix : text
            };

            // attributes and attribute values suggesting
            // value has "stringValue" in rulestack
            let suggestAttributeValue = syntacticSuggestions
                .filter(e => e.ruleStack.indexOf('stringValue') > -1)
                .length > 0;

            if(suggestAttributeValue){
                if(parser.suggestAttributeValues){
                    parser.suggestAttributeValues(suggestRequestData, suggestions => {
                        cb(suggestions, startOffset, endOffset);
                    });
                }
            }
            else{
                if(parser.suggestAttributes){
                    parser.suggestAttributes(suggestRequestData, suggestions => {
                        cb(suggestions, startOffset, endOffset);
                    });
                }
            }
        }
    }

    let syntacticSuggestions = nwdslParser.computeContentAssist('rootExpression', tokens);
    let stringSuggestingOverride = syntacticSuggestions
        .filter(e=> 
            e.ruleStack.indexOf('stringValue') > -1 || 
            e.nextTokenType.typeId === 'ALIAS' || 
            e.nextTokenType.typeId === 'VALUE'
        )
        .length > 0;
    

    if(stringSuggestingOverride){
        // custom for attributes and values
        // resolve whether to suggest attributes-related resources
        let shouldSuggestAttribute = syntacticSuggestions
            .filter(e=>e.ruleStack.indexOf('attribute') > -1)
            .length > 0;

        // custom for redundants
        // resolve whether to suggest node aliases inside redundancies
        let shouldSuggestAliasesForRedundants = syntacticSuggestions
            .filter(e=>e.nextTokenType.typeId === 'REDUNDANCY')
            .length > 0;

        // custom for connections
        // resolve whether to suggest node/link aliases inside redundancies 
        let shouldSuggestAliasesForConnections = syntacticSuggestions
            .filter(e=>e.nextTokenType.typeId === 'ALIAS' && e.ruleStack.indexOf('connection') !== -1)
            .length > 0;

        // custom for definitions
        // resolve wheter to suggest nodes/links from BE 
        let shouldSuggestAliasesForEntitiesDefinitions = syntacticSuggestions
            .filter(
                e => e.nextTokenType.typeId === 'ALIAS' && 
                ( e.ruleStack.indexOf('nodeDefinition') !== -1 || e.ruleStack.indexOf('linkDefinition') !== -1 ))
            .length > 0;

        if(shouldSuggestAttribute){
            loadAttributesIfNeeded((valueTokens, startOffset, endOffset) => {
                let attributesSuggestions = [].concat(valueTokens).concat(prepareSyntacticSuggestions());
                attributesSuggestions.map(e=>{
                    let elementText = e;
                    if(/\s/g.test(elementText.text)) elementText = checkQuoteSugg(elementText);
                    return elementText;
                });
    
                
                return cb(
                    attributesSuggestions, 
                    getCursorPosFromOffset(startOffset), 
                    getCursorPosFromOffset(endOffset || startOffset),
                    replacingCurrentToken
                );
            });
        } 
        else if(shouldSuggestAliasesForRedundants){
            fetchResourcesForAliases((valueTokens)=>{
                let retSuggestions = []
                    .concat(prepareSyntacticSuggestions())
                    .concat(getAliases('NODE'))
                    .concat(getAliases('REDUNDANCY'))
                    .sort((a,b) => b.className.localeCompare(a.className))
                    .concat(valueTokens);
                
                return cb(
                    filterAccordingToPrefix(retSuggestions), 
                    getCursorPosFromOffset(startOffset), 
                    getCursorPosFromOffset(endOffset || startOffset),
                    replacingCurrentToken
                );
            });
            
            
        }
        else if(shouldSuggestAliasesForConnections){
            let type = 'LINK';
            let connectionFrom = findNearestPrevTokenByTypeId(tokens, 'CONNECTION', 1);
            let connectionTo = findNearestPrevTokenByTypeId(tokens, 'CONNECTION', 1, tokens.indexOf(connectionFrom) - 1);
            if(connectionFrom && !connectionTo) type = '';
            else type = 'NODE';
            let connectionSuggestions = [];
            
            // no text @ line before cursor = suggest only NODES as these represent the beggining of a connection
            if(!lineText.replace(/\s/g, '').length){
                connectionSuggestions = getAliases('NODE').concat(getAliases('REDUNDANCY'));
            }
            // generic behaviour - tweaks for better suggestions 
            else {
                let connection = findNearestPrevTokenByTypeId(tokens, 'CONNECTION');
                let alias = findNearestPrevTokenByTypeId(tokens, 'ALIAS');

                if(!!alias && !! connection && alias.endOffset > connection.endOffset) connectionSuggestions = [].concat(prepareSyntacticSuggestions()).concat(getAliases('NODE').concat(getAliases('REDUNDANCY')));
                else connectionSuggestions = prepareSyntacticSuggestions().concat(getAliases(type));
            }
            fetchResourcesForAliases((valueTokens)=>{
                let retSuggestions = connectionSuggestions
                    .sort((a,b) => b.className.localeCompare(a.className))
                    .concat(valueTokens);
                
                return cb(
                    filterAccordingToPrefix(retSuggestions), 
                    getCursorPosFromOffset(startOffset), 
                    getCursorPosFromOffset(endOffset || startOffset),
                    replacingCurrentToken
                );
            });
        }
        else if(shouldSuggestAliasesForEntitiesDefinitions){
            fetchResources((valueTokens, startOffset, endOffset) => {
                let splitText = text.split(' ');
                let finalText = splitText[splitText.length - 1].replace(/\s/g, '');
        
                // syntactical suggestions: prepare syntactical suggestions
                syntacticSuggestions = syntacticSuggestions
                    .map(item => item.nextTokenType)
                    .filter(item => item && (item.text !== undefined))
                    .map(item => {
                        return { 
                            text: item.text, 
                            displayText: item.displayText || item.text,
                            className: 'SYNTAX-suggestion custom-suggestion',
                            type: 'SYNTAX'
                        };
                    });
                
                // syntactical suggestions: filter attributes left square bracket on newline if the preceding token was of value or alias type 
                let precedingValueToken = findNearestPrevTokenByTypeId(tokens, 'VALUE');
                let precedingAliasToken = findNearestPrevTokenByTypeId(tokens, 'ALIAS');
                let precedingAliasOrValueTokenEndLine = Math.max(
                    precedingValueToken ? precedingValueToken.endLine : 0,
                    precedingAliasToken ? precedingAliasToken.endLine : 0 );
                if(
                    precedingAliasOrValueTokenEndLine !== 0 && 
                    line + 1 > precedingAliasOrValueTokenEndLine
                ){
                    syntacticSuggestions = syntacticSuggestions
                        .filter(item => !!item.text && !item.text.includes(SquareBracketLeftTokenText));
                }
        
        
                // 3. add rest of syntactic suggestions
                valueTokens = valueTokens.concat(prepareSyntacticSuggestions());
        
                // 4. finally filter suggestions by text of last token - it should act like autocomplete
                // filter syntactical suggestions by preceding text - prefix
                if(finalText !== ''){
                    let filteredSuggestions = valueTokens
                        .filter(item => {
                            if(!item.text) return false;
                            if(finalText !== '') return  item.text.toLowerCase().includes(finalText.toLowerCase()) || checkUnquoteText(item.text.toLowerCase()).includes(finalText.toLowerCase());  
        
                            return true;
                        });
                    if(filteredSuggestions.length !== 0){
                        valueTokens = filteredSuggestions;
                    }
                }
                
                return cb(
                    filterAccordingToPrefix(valueTokens
                        .map(item => { 
                            return { 
                                id:item.id, 
                                displayText:item.displayText || item.text, 
                                text:item.text,
                                className: item.className,
                                type: item.type
                            };
                        })
                    )
                    , 
                    getCursorPosFromOffset(startOffset), 
                    getCursorPosFromOffset(endOffset || startOffset),
                    replacingCurrentToken
                );
            });
        }
    }
    else showSyntacticSuggestions((suggestions, startOffset, endOffset) => {
        let disallowedSuggestions = ['['];
        let topLevelSyntacticSuggestion = ['definitions', 'connections'];

        if(!lineText.replace(/\s/g, '').length){
            suggestions = suggestions.filter(e => disallowedSuggestions.indexOf(e.text) === -1);
        }

        suggestions.forEach(e =>{
            if(topLevelSyntacticSuggestion.indexOf(e.text) > -1) e.text += ' {\n\n}';
        });


        cb(
            suggestions
                .map(item => { 
                    return { 
                        id: item.id, 
                        displayText: item.displayText || item.text, 
                        text: item.text,
                        className: 'SYNTAX-suggestion custom-suggestion',
                        type: 'SYNTAX'
                    };
                })
            , 
            getCursorPosFromOffset(startOffset), 
            getCursorPosFromOffset(endOffset || startOffset),
            replacingCurrentToken
        );
    });
    
    
    function getCursorPosFromOffset(offset){
        let lines = parser.text.slice(0, offset).split('\n');
        let line = lines.length - 1;
        let column = lines.pop().length;
        return { line, column };
    }
    function checkQuoteSugg(sugg){
        sugg.text = checkQuoteText(sugg.text);
        return sugg;
    }
    function getAliases(type){
        let aliases = parser.result.value.aliases;

        // this here is a satan's making. 
        // so redundancies should be suggested whenever nodes are 
        // and this unholy approach has been selected so that this requirement is met
        
        Object.keys(aliases).forEach(alias=>{
            aliases[alias][0].customType = aliases[alias][0].type;
            if(aliases[alias][0].type === 'REDUNDANCY') aliases[alias][0].customType = 'NODE'; 
        });

        return Object.keys(aliases)
            .filter(key => type ? aliases[key][0].customType === type : true)
            .map(key => {
                let suffix = '';
                if(aliases[key][0].type === 'LINK') suffix = ' ' + ConnectionToken.text;
                let text = checkQuoteText(key) + suffix;
                return { 
                    text, 
                    displayText: text, 
                    className: aliases[key][0].type + '-suggestion custom-suggestion',
                    type: 'ALIAS' 
                };
            });
    }
    function showSyntacticSuggestions(cb){
        cb(
            prepareSyntacticSuggestions(syntacticSuggestions), 
            startOffset, 
            endOffset
        );
    }
    function prepareSyntacticSuggestions(){
        return nwdslParser.computeContentAssist('rootExpression', tokens)
            .map(item => item.nextTokenType)
            .filter(item => item && (item.text !== undefined))
            .map(item => {
                return { 
                    text: item.text, 
                    displayText: item.displayText || item.text,
                    className: 'SYNTAX-suggestion custom-suggestion',
                    type: 'SYNTAX'
                };
            });
    }
    function filterAccordingToPrefix(arr){
        let splitText = text.split(' ');
        let finalText = splitText[splitText.length - 1].replace(/\s/g, '');
        if(finalText !== ''){
            let filteredSuggestions = arr
                .filter(item => {
                    if(!item.text) return false;
                    if(finalText !== '') return  item.text.toLowerCase().includes(finalText.toLowerCase()) || checkUnquoteText(item.text.toLowerCase()).includes(finalText.toLowerCase());  

                    return true;
                });
            if(filteredSuggestions.length !== 0){
                arr = filteredSuggestions;
            }
        }

        return arr;
    }
}

export default function Parser(opts = {}){
    this.parse = parse;
    this.getNextTokenSuggestions = getNextTokenSuggestions;

    this.validateResources = opts.validateResources;
    this.suggestResources = opts.suggestResources;
    this.suggestAttributes = opts.suggestAttributes,
    this.suggestAttributeValues = opts.suggestAttributeValues;
    this.suggestResourcesForAliases = opts.suggestResourcesForAliases;

    this.createLexerInstance = opts.createLexerInstance || createLexerInstance;
    this.createLexerInstance();
}
