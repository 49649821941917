export default {
    props:{
        value:{},
        column:{},
        row:{},
        dataTypes:{},
        dataTypeId:{},
        rowIndex: Number,
        colIndex: Number,
        getViewValue: Function,
        getSpreadsheetComponent: Function
    },
    computed:{
        viewValue(){
            return this.row._state.cellViewValues[ this.colIndex ] || '';
        }
    }
};