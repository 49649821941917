import { CONTEXT_TYPES, checkUnquoteText } from './bpdsl-types.js';

class DslTokenContext {
    constructor(data = {}){
        this.token = data.token;
        this.contextType = data.contextType;
        this.value = data.value;
        this.parent = data.parent;
        this.children = [];
    }

    isFirst(){
        if(!this.parent) return true;
        else return this.parent.children.indexOf(this) === 0;
    }

    isLast(){
        if(!this.parent) return true;
        else return this.parent.children.indexOf(this) === this.parent.children.length - 1;
    }

    getChildrenByContextType(contextType){
        return this.children.filter(tokenCtx => tokenCtx.contextType === contextType);
    }

    getParentByContextType(contextTypes){
        if(!Array.isArray(contextTypes)) contextTypes = [contextTypes];

        let parent = this.parent;
        while(parent){
            if(contextTypes.includes(parent.contextType)) return parent;
            parent = parent.parent;
        }
    }

    getThisOrParentByContextType(contextTypes){
        if(!Array.isArray(contextTypes)) contextTypes = [contextTypes];
        return contextTypes.includes(this.contextType) ? this : this.getParentByContextType(contextTypes);
    }

    getPreviousByContextType(contextTypes){
        if(!Array.isArray(contextTypes)) contextTypes = [contextTypes];

        let prev = this.getPrevious();
        while(prev){
            if(contextTypes.includes(prev.contextType)) return prev;
            prev = prev.getPrevious();
        }
    }

    getThisOrPreviousByContextType(contextTypes){
        if(!Array.isArray(contextTypes)) contextTypes = [contextTypes];
        return contextTypes.includes(this.contextType) ? this : this.getPreviousByContextType(contextTypes);
    }

    getAllDescendants(){
        let descendants = [];
        this.children.forEach(child => {
            descendants.push(child);
            descendants = descendants.concat(child.getAllDescendants());
        });
        return descendants;
    }

    getAllPrevious(){
        if(!this.parent) return [];
        let index = this.parent.children.indexOf(this);
        return this.parent.children.slice(0, index);
    }

    getPrevious(){
        if(!this.parent) return;
        let index = this.parent.children.indexOf(this);
        return this.parent.children[ index-1 ];
    }

    getNext(){
        if(!this.parent) return;
        let index = this.parent.children.indexOf(this);
        return this.parent.children[ index+1 ];
    }

    getTokenBoundaries(){
        const token = this.token;
        if(token && !isNaN(token.startOffset)) {
            return {
                startLine: token.startLine - 1,
                startColumn: token.startColumn - 1,
                endLine: token.endLine - 1,
                endColumn: token.endColumn
            };
        }
    }

    sortChildrenByTokenOrder(){
        this.children.forEach(child => child.sortChildrenByTokenOrder());

        this.children = this.children.sort((a,b) => {
            let aToken = a.getNearestToken();
            let bToken = b.getNearestToken();
            if(!aToken || !bToken) return 0;

            if(aToken.startLine < bToken.startLine) return -1;
            else if (aToken.startLine > bToken.startLine) return 1;
            else if (aToken.startColumn < bToken.startColumn) return -1;
            else if (aToken.startColumn > bToken.startColumn) return 1;
            else return 0;
        });

        return this;
    }

    debugChildTree(level = 0){
        let token = this.getNearestToken() || {};
        console.warn(Array(level).fill('  ').join('') + this.contextType, this.value, token.startLine, token.startColumn);
        this.children.forEach(child => child.debugChildTree(level+1));
    }

    debugChildrenTokenOrder(){
        this.children.forEach(child => console.warn(child.contextType, child.token, child));
    }

    getNearestToken(){
        return this.token || (this.children.find(child => child.getNearestToken()) || {}).token;
    }
}

function createInterpreter(BaseCstVisitor){

    // All our semantics go into the visitor, completly separated from the grammar.
    class DslInterpreter extends BaseCstVisitor {
        constructor() {
            super();

            this.rootTokenContext = new DslTokenContext({ contextType: CONTEXT_TYPES.ROOT });

            // This helper will detect any missing or redundant methods on this visitor
            this.validateVisitor();
        }

        /*
         * Interpreter Methods
         */

        /*
         * End methods in parsing chain, they only consume tokens and have no children, returning only parsed value and value token
         */

        // identifierValue(ctx){
        //     let token = ctx.Identifier || ctx.StringQuoted;
        //     if(Array.isArray(token)) token = token[0];
        //     if(!token) return;

        //     return {
        //         token,
        //         value: checkUnquoteText(token.image)
        //     };
        // }

        // attributeValue(ctx){
        //     let token = Array.isArray(ctx.value) ? ctx.value[0] : ctx.value;
        //     if(!token) return;

        //     return {
        //         token,
        //         value: checkUnquoteText(token.image)
        //     };
        // }

        // attributeName(ctx, attribute){
        //     let attributeNameToken = ctx.attributeName ? ctx.attributeName[0] : null
        //     if(attributeNameToken){
        //         this.addTokenContext(attributeNameToken, CONTEXT_TYPES.ATTRIBUTE_NAME, attributeNameToken.image, attribute);
        //     }
        // }

        /*
         * Non-end methods in parsing chain, they are generating result - data structure
         */

        rootExpression(ctx){
            ctx.relation?.forEach(relation => this.visit(relation));
            ctx.renderer?.forEach(renderer => this.visit(renderer));
            ctx.hop?.forEach(hop => this.visit(hop));

            return this.rootTokenContext.sortChildrenByTokenOrder(); // ensure tokenCtx are sorted by their tokens positions
        }

        relation(ctx){
            let relation = this.addTokenContext(null, CONTEXT_TYPES.RELATION, null);

            if(ctx.relationNodeLeft) this.visit(ctx.relationNodeLeft, true, relation);
            if(ctx.relationEdge) this.visit(ctx.relationEdge, relation);
            if(ctx.relationNodeRight) this.visit(ctx.relationNodeRight, false, relation);

            if(ctx.arrowLine) this.addTokenContext(ctx.arrowLine, CONTEXT_TYPES.RELATION_LINE, null, relation);
            if(ctx.arrowRight) this.addTokenContext(ctx.arrowRight, CONTEXT_TYPES.RELATION_ARROW_RIGHT, null, relation);
            if(ctx.arrowLeft) this.addTokenContext(ctx.arrowLeft, CONTEXT_TYPES.RELATION_ARROW_LEFT, null, relation);
        }

        relationNode(ctx, isLeftSide, parentRelation){
            let relationNode = this.addTokenContext(null, isLeftSide ? CONTEXT_TYPES.RELATION_NODE_LEFT : CONTEXT_TYPES.RELATION_NODE_RIGHT, parentRelation);

            if(ctx.relationVarsDeclaration) this.visit(ctx.relationVarsDeclaration, relationNode);
            
            if(ctx.bracketLeft) this.addTokenContext(ctx.bracketLeft, CONTEXT_TYPES.RELATION_NODE_START, null, relationNode);
            if(ctx.bracketRight) this.addTokenContext(ctx.bracketRight, CONTEXT_TYPES.RELATION_NODE_END, null, relationNode);
        }

        relationEdge(ctx, parentRelation){
            let relationEdge = this.addTokenContext(null, CONTEXT_TYPES.RELATION_EDGE, parentRelation);
            if(ctx.relationVarsDeclaration) this.visit(ctx.relationVarsDeclaration, relationEdge);

            if(ctx.squareBracketLeft) this.addTokenContext(ctx.squareBracketLeft, CONTEXT_TYPES.RELATION_EDGE_START, null, relationEdge);
            if(ctx.squareBracketRight) this.addTokenContext(ctx.squareBracketRight, CONTEXT_TYPES.RELATION_EDGE_END, null, relationEdge);
        }

        relationVarsDeclaration(ctx, parent){
            let relationVarsDeclaration = this.addTokenContext(null, CONTEXT_TYPES.RELATION_VARS_DECLARATION, parent);

            // tag, type, tagTypesSeparator
            ctx.tag?.forEach(token => {
                this.addTokenContext(token, CONTEXT_TYPES.RELATION_VARS_DECLARATION_TAG, token.image, relationVarsDeclaration);
            });

            ctx.type?.forEach(token => {
                this.addTokenContext(token, CONTEXT_TYPES.RELATION_VARS_DECLARATION_TYPE, token.image, relationVarsDeclaration);
            });

            if(ctx.tagTypesSeparator) this.addTokenContext(ctx.tagTypesSeparator, CONTEXT_TYPES.RELATION_VARS_DECLARATION_SEPARATOR, null, relationVarsDeclaration);

            ctx.Pipe?.forEach(token => {
                this.addTokenContext(token, CONTEXT_TYPES.RELATION_VARS_DECLARATION_PIPE, null, relationVarsDeclaration);
            });
        }

        renderer(ctx){
            let renderer = this.addTokenContext(null, CONTEXT_TYPES.RENDERER);

            if(ctx.prefix) this.addTokenContext(ctx.prefix, CONTEXT_TYPES.RENDERER_PREFIX, null, renderer);
            if(ctx.collon) this.addTokenContext(ctx.collon, CONTEXT_TYPES.RENDERER_COLLON, null, renderer);
            if(ctx.name) this.addTokenContext(ctx.name, CONTEXT_TYPES.RENDERER_NAME, ctx.name[0].image, renderer);

            ctx.attributes?.forEach(attribute => this.visit(attribute, renderer));
        }
        
        hop(ctx){
            let hop = this.addTokenContext(null, CONTEXT_TYPES.HOP);

            if(ctx.prefix) this.addTokenContext(ctx.prefix, CONTEXT_TYPES.HOP_PREFIX, null, hop);
            if(ctx.collon) this.addTokenContext(ctx.collon, CONTEXT_TYPES.HOP_COLLON, null, hop);
            if(ctx.type) this.addTokenContext(ctx.type, CONTEXT_TYPES.HOP_TYPE, ctx.type[0].image, hop);
            
            ctx.name?.forEach(name => this.addTokenContext(name, CONTEXT_TYPES.HOP_NAME, name.image, hop));

            ctx.attributes?.forEach(attributes => this.visit(attributes, hop));
        }

        attributes(ctx, parent){
            let attributes = this.addTokenContext(null, CONTEXT_TYPES.ATTRIBUTES, null, parent);

            if(ctx.curlyBracketsLeft) this.addTokenContext(ctx.curlyBracketsLeft, CONTEXT_TYPES.ATTRIBUTES_START, null, attributes);
            if(ctx.curlyBracketsRight) this.addTokenContext(ctx.curlyBracketsRight, CONTEXT_TYPES.ATTRIBUTES_END, null, attributes);

            ctx.attribute?.forEach(attribute => this.visit(attribute, attributes));

            ctx.SemiCollon?.forEach(token => {
                this.addTokenContext(token, CONTEXT_TYPES.ATTRIBUTES_SEPARATOR, null, attributes);
            });
        }

        attribute(ctx, parent){
            let attribute = this.addTokenContext(null, CONTEXT_TYPES.ATTRIBUTE, null, parent);

            if(ctx.name) this.addTokenContext(ctx.name, CONTEXT_TYPES.ATTRIBUTE_NAME, ctx.name[0].image, attribute);
            if(ctx.equal) this.addTokenContext(ctx.equal, CONTEXT_TYPES.ATTRIBUTE_EQUAL, null, attribute);

            ctx.attributeValue?.forEach(attributeValue => this.visit(attributeValue, attribute));
        }

        attributeValue(ctx, attribute){
            ctx.value?.forEach(valuePartToken => {
                this.addTokenContext(valuePartToken, CONTEXT_TYPES.ATTRIBUTE_VALUE, valuePartToken.image, attribute);
            });
        }
        
        /*
         * Helpers
         */

        addTokenContext(token, contextType, value, parent){
            if(Array.isArray(token)) {
                token = token[0];
                if(!token) return;
            }

            // tokens with flag "isInsertedInRecovery" are virtual, without line and column, skip it
            if(token && token.isInsertedInRecovery) return;

            if(!CONTEXT_TYPES[ contextType ]) throw new Error('CONTEXT_TYPES does not contains "'+contextType+'"');

            const tokenContext = new DslTokenContext({
                token,
                contextType,
                value,
                parent: parent || this.rootTokenContext
            });

            if(parent) parent.children.push(tokenContext);
            this.rootTokenContext.children.push(tokenContext);

            return tokenContext;
        }
    }

    // We only need a single interpreter instance because our interpreter has no state.
    return new DslInterpreter();
}

export { createInterpreter };