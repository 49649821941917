import backend from 'obj-fe/app/backend';
import locals from 'obj-fe/services/localisation';

// server-side enums
// public enum Renderer {​​​​​
//     LABEL, HIDDEN, NONE, PLACEMENT, TABLE, TEXT_WRAP, UPLOAD_FILES, SHOW_PASSWORD, URL;
// }​​​​​

// public enum Editor {​​​​​
//     AUTO_DETECT, READ_ONLY, TEXT, TEXTAREA, NUMERIC, REAL, COMBO_BOX, LIST_VALUE, DATE_PICKER, DATE_PICKER_EPOCH, DATETIME_PICKER_EPOCH, DATETIME_PICKER, BOOLEAN, MULTISTRING, LONGITUDE, LATITUDE, COMBO_BOX_HIERARCHICAL, PASSWORD, AUTO_COMPLETE, SYNC_ONLY, UPLOAD_FILES, URL, SHOW_PASSWORD;
// }​​​​​
// TODO: not implemented, will act like string cell: LONGITUDE, LATITUDE, COMBO_BOX_HIERARCHICAL, SYNC_ONLY, UPLOAD_FILES;

// used just for view formatting such as text align
// public enum ColumnType {​​​​​
//     TEXT, NUMERIC, DATE;
// }​​​​​


import DrawerEditorBigPicture from './editors/inv-drawer-editor-big-picture.vue';

function dateFromSeconds(dt){
    if(!dt) return null;
    else return new Date(dt*1000);
}

function dateToSeconds(dt){
    if(!dt) return null;
    else return Math.round(dt.getTime()/1000);
}

export const globalInvWatchers = {
    validateTextValueObjectPerColumn:{
        async: true,
        runPerChange: false,
        handler(opts, changes, cb){
            if(!opts) return cb();

            let colIndex = changes[0].colIndex;
            let column = this.getColumn(null, colIndex);

            // because of cell type is dynamic, we have to validate by editor value url
            let changesByEditorUrl = {};
            let changesWithResolverId = [];
            changes.forEach(change => {
                let row = this.getRow(change.rowIndex);
                let field = row._origFields[ column.dataKey ];

                // because not all dataTypes in column are same, need to check on watcher run
                let dataType = typeof column.dataType === 'function' ? column.dataType.call(this, { ...change, row, column, rowContext:this.getRowContext(change.rowIndex) }) : column.dataType;
                if(dataType !== 'textValueObject') return;

                // also filter cells with no validation rest url
                if(!field.editorValuesUrl) return;

                // filter changes that seems to be complete - no need to validate throught BE call
                if(!change.value || change.value.text || change.value.value) return;

                if(field.resolverId) {
                    changesWithResolverId.push({ field, change });
                }
                else {
                    let text = change.value?.text || change.value + '';
                    let url = field.editorValuesUrl.replace('{token}', encodeURIComponent(text));
                    changesByEditorUrl[ url ] = changesByEditorUrl[ url ] || [];
                    changesByEditorUrl[ url ].push(change);
                }
            });

            let validationPromises = Object.entries(changesByEditorUrl).map(([url, changes]) => {
                return new Promise(resolve => {
                    backend.genericForms.editorValues({ url }, data => {
                        changes.forEach(change => {
                            let text = change.value?.text || change.value + '';
                            let targets = data.values.filter(item => {
                                let textMatched = item.title === text || item.entityName === text;
                                if(textMatched && change.value?.value) return item.name === change.value?.value;
                                else return textMatched;
                            });
                            
                            if(targets.length > 1) {
                                this.addCellError(change.rowIndex, change.colIndex, locals.translate('found_multiple_in_database'));
                                return;
                            }
        
                            let target = targets[0];

                            if(target) {
                                this.setCellValue(change.rowIndex, change.colIndex, {
                                    text: target.entityName || target.title,
                                    value: target.name
                                });
                            }
                            else this.addCellError(change.rowIndex, change.colIndex, locals.translate('not_found_in_database'));
                        });

                        resolve();
                    });
                });
            });

            let resolverPromise = new Promise(resolve => {
                if(changesWithResolverId.length === 0) return resolve();

                let reqValidationValuesByKey = {};
                changesWithResolverId.forEach(({ field, change }) => {
                    let value = {
                        displayString: change.value?.text || change.value + '',
                        resolverId: field.resolverId
                    };

                    reqValidationValuesByKey[ JSON.stringify(value) ] = value;
                });

                backend.spreadsheetValidation.validate(column.reportId, Object.values(reqValidationValuesByKey), data => {
                    // [
                    //     {
                    //       "displayString": "string",
                    //       "resolverId": "string",
                    //       "userValue": "string",
                    //       "value": "string"
                    //     }
                    // ]

                    changesWithResolverId.forEach(({ field, change }) => {
                        let text = change.value?.text || change.value + '';
                        let matchedValues = {}; // because there may be one entity multiple times with different displayString - get only first, ignore another with same value (ID)
                        let targets = data.filter(item => {
                            if(item.resolverId !== field.resolverId) return;

                            let matched = item.displayString === text || item.userValue === text;
                            if(matched && change.value?.value) matched = item.value === change.value?.value;
                            
                            if(matched) {
                                if(matchedValues[item.value]) return false;
                                matchedValues[item.value] = true;
                            }

                            return matched;
                        });
                        
                        if(targets.length > 1) {
                            this.addCellError(change.rowIndex, change.colIndex, locals.translate('found_multiple_in_database'));
                            return;
                        }
    
                        let target = targets[0];

                        if(target) {
                            this.setCellValue(change.rowIndex, change.colIndex, {
                                text: target.displayString || target.userValue,
                                value: target.value
                            });
                        }
                        else this.addCellError(change.rowIndex, change.colIndex, locals.translate('not_found_in_database'));
                    });

                    resolve();
                });
            });

            Promise.all(validationPromises.concat([resolverPromise])).then(() => cb());
        }
    }
};

export const drawerComponentByFieldEditor = {
    'BIG_PICTURE_DSL': DrawerEditorBigPicture
};

export const dataTypeByEditor = {
    // default fallback if no match
    'default': 'string',

    // string
    'null': 'string',
    'undefined': 'string',
    'READ_ONLY': 'string',
    'TEXT': 'string',
    'TEXTAREA': 'string',
    'BIG_PICTURE_DSL': 'string',
    'PASSWORD': 'string',
    'SHOW_PASSWORD': 'string',
    'URL': 'string',

    // numbers
    'REAL': 'number',
    'NUMERIC': 'integer',

    // bool
    'BOOLEAN': 'boolean',
    
    // dates
    'DATETIME_PICKER_EPOCH': 'datetime',
    'DATE_PICKER_EPOCH': 'date',
    'DATETIME_PICKER': 'datetime',
    'DATE_PICKER': 'date',

    // multi
    'MULTISTRING': 'stringArray',

    // dropdowns
    'COMBO_BOX': 'textValueObject',
    'LIST_VALUE': 'textValueObject',
    'AUTO_COMPLETE': 'textValueObject'
};

let stringFieldValue = {
    get(field){
        return field.displayString;
    }
};

let epochFieldValue = {
    get(field){
        return dateFromSeconds(field.value);
    },
    set(value){
        if(value instanceof Date) return dateToSeconds(value);
        else return value;
    }
};

let dropdownFieldValue = {
    get(field){
        return field.value ? {
            text: field.displayString,
            value: field.value
        } : null;
    },
    set(value, field){
        return value?.value || null;
    }
};

let dateFieldValue = {
    get(field){
        return field.value ? new Date(field.value) : field.value;
    }
};

export const fieldValueByEditor = {
    // default fallback if no match
    'default': {},

    //string
    'null': stringFieldValue,
    'undefined': stringFieldValue,
    'READ_ONLY': stringFieldValue,
    'TEXT': stringFieldValue,
    'TEXTAREA': stringFieldValue,
    'BIG_PICTURE_DSL': stringFieldValue,
    'PASSWORD': stringFieldValue,
    'SHOW_PASSWORD': stringFieldValue,
    'URL': stringFieldValue,
    'REAL': stringFieldValue,

    // numbers
    'REAL':{
        get(field){
            // sometimes numbers are sent as string from BE, and it triggers spreadsheet changes when parsing
            return parseFloat(field.value);
        }
    },
    'NUMERIC':{
        get(field){
            // sometimes numbers are sent as string from BE, and it triggers spreadsheet changes when parsing
            return parseInt(field.value, 10);
        }
    },

    // bool
    'BOOLEAN': {
        get(field){
            if(typeof field.value === 'boolean') return field.value;
            else if(field.value === 'true') return true;
            else if(field.value === 'false') return false;
            else return field.value;
        }
    },

    // dates
    'DATETIME_PICKER_EPOCH': epochFieldValue,
    'DATE_PICKER_EPOCH': epochFieldValue,
    'DATETIME_PICKER': dateFieldValue,
    'DATE_PICKER': dateFieldValue,

    // multi
    'MULTISTRING': {
        get(field){
            return field.value && !Array.isArray(field.value) ? [ field.value ] : field.value;
        }
    },

    // dropdowns
    'COMBO_BOX': dropdownFieldValue,
    'LIST_VALUE': dropdownFieldValue,
    'AUTO_COMPLETE': dropdownFieldValue
};