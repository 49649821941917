import { CONTEXT_TYPES, ATTRIBUTE_VALUE_TYPES } from './bpdsl-types.js';

function arrayToObject(array, propName){
    let obj = {};
    array.forEach(item => obj[ propName ? item[propName] : item ] = item);
    return obj;
}

export default class DslProcessor {
    constructor(dslDefinition){
        this.dslEntitiesDefByName = {};

        // dslDefinition.nodes.forEach(node => {
        //     let attributesByName = {};
        //     node.attributes.forEach(attribute => {
        //         attributesByName[ attribute.name ] = attribute;
        //     });

        //     let nodeRelationsByName = {};
        //     node.relations.forEach(relation => {
        //         relation.entityTypeName = node.name;
        //         nodeRelationsByName[relation.name] = {
        //             ...relation,
        //             attributes: arrayToObject(relation.attributes || [], 'name')
        //         }
        //     });

        //     this.dslEntitiesDefByName[ node.name ] = {
        //         attributes: attributesByName,
        //         relations: nodeRelationsByName
        //     };
        // });
    }

    process(rootTokenContext){

        const errors = [];
        function addError(msg, token){
            let err = new Error(msg);
            err.token = token;
            errors.push(err);
        }

        // // 1. validate entities definitions, aliases, join partial definitions, collect entities with valid definition
        // const validEntityDefinitions = this.getValidEntityDefinitions(rootTokenContext, this.dslEntitiesDefByName, addError);

        // // 2. validate entity attributes (values, names - maxCardinality, known names, uniqueIdentifier, referenceTypes)
        // this.validateEntitiesAttributes(validEntityDefinitions, this.dslEntitiesDefByName, addError);

        // // 3. validate relations and relations attributes
        // this.validateRelationDefinitions(rootTokenContext, validEntityDefinitions, addError);
        
        // 4. calc token boundaries for color theme
        const tokenBoundaries = {};
        rootTokenContext.children.forEach(tokenCtx => this.addTokenBoundary(tokenBoundaries, tokenCtx));

        // // 5. calc pure data for BE
        // const data = {
        //     entitiesDefinitions: validEntityDefinitions.map(entity => {
        //         const relations = [];
        //         (entity.relations || []).forEach(relation => {
        //             relation.relatedTo.forEach(alias => {
        //                 relations.push({
        //                     relationName: relation.typeName,
        //                     target: alias,
        //                     attributes: relation.attributes.map(attr => ({ name: attr.name, type: attr.valueType, value: attr.value }))
        //                 });
        //             });
        //         });

        //         return {
        //             alias: entity.alias,
        //             entityType: entity.typeName,
        //             attributes: entity.attributes.map(attr => ({ name: attr.name, type: attr.valueType, value: attr.value })),
        //             relations
        //         }
        //     })
        // };

        // pure data - for BE
        // tokenBoundaries - tokenTypes for highlighting classes
        // contextBoundaries - for suggestion purposes
        // errors - for highlighting errors
        // rootTokenContext - for suggester or another data processing
        return {
            data:{},
            errors,
            tokenBoundaries,
            rootTokenContext
        };
    }

    // { lineNo:{ colNo:type, colNo:type, colNo:type, ... } }
    addTokenBoundary(boundaries, tokenCtx){
        const tokenBoundaries = tokenCtx.getTokenBoundaries();

        if(!tokenCtx.token, !tokenBoundaries) return;
        let tokenType = tokenCtx.contextType;

        // if(tokenCtx.contextType === CONTEXT_TYPES.ATTRIBUTE_VALUE) {
        //     tokenType = [ tokenType, tokenCtx.valueTypeName ];
        // }

        boundaries[ tokenBoundaries.startLine ] = boundaries[ tokenBoundaries.startLine ] || {};
        boundaries[ tokenBoundaries.startLine ][ tokenBoundaries.startColumn ] = tokenType;

        boundaries[ tokenBoundaries.endLine ] = boundaries[ tokenBoundaries.endLine ] || {};
        boundaries[ tokenBoundaries.endLine ][ tokenBoundaries.endColumn ] = null;
    }

    getValidEntityDefinitions(rootTokenContext, dslEntitiesDefByName, addError){
        const validEntityDefinitions = [];
    
        const entityDefsByAlias = {};
    
        const allEntityDefinitions = rootTokenContext.getChildrenByContextType(CONTEXT_TYPES.ENTITY);
    
        function iterateAllEntityDefinitions(cb){
            allEntityDefinitions.forEach(entityDef => {
                const typeNameTokenCtx = entityDef.getChildrenByContextType(CONTEXT_TYPES.ENTITY_TYPE)[0] || {};
                const aliasTokenCtx = entityDef.getChildrenByContextType(CONTEXT_TYPES.ENTITY_ALIAS)[0] || {};
                cb(entityDef, typeNameTokenCtx, aliasTokenCtx);
            });
        }
        
        // first iterate full entity definitions - type alias { ... }
        iterateAllEntityDefinitions((entityDef, typeNameTokenCtx, aliasTokenCtx) => {
            // cache props
            let typeName = entityDef.typeName = typeNameTokenCtx.value;
            let alias = entityDef.alias = aliasTokenCtx.value;

            if(alias === '') {
                addError('Alias is empty text ""', aliasTokenCtx.token);
            }
            
            // full definition
            else if(typeName && alias) {
    
                // valid entity definition has valid, existing entity type
                entityDef.type = dslEntitiesDefByName[ typeName ];
                entityDef.partials = [];
                entityDef.nameToken = typeNameTokenCtx.token;
    
                if(!entityDef.type) {
                    addError('Unrecognized entity type "'+typeName+'"', typeNameTokenCtx.token);
                }
                else {
                    entityDefsByAlias[alias] = entityDefsByAlias[alias] || [];
                    entityDefsByAlias[alias].push(entityDef);
    
                    if(entityDefsByAlias[alias].length > 1) {
                        addError('Duplicate entity alias "'+alias+'"', aliasTokenCtx.token);
                    }
                    else validEntityDefinitions.push(entityDef);
                }
            }
        });
    
        // then iterate partial definitions - alas { ... }
        iterateAllEntityDefinitions((entityDef, typeNameTokenCtx, aliasTokenCtx) => {
            let typeName = entityDef.typeName = typeNameTokenCtx.value;
            let alias = entityDef.alias = aliasTokenCtx.value;
    
            // may be partial entity definition - must be paired with orig entity definition
            if(!typeName && alias) {
                let origEntityDef = (entityDefsByAlias[alias] || [])[0];
    
                if(!origEntityDef) {
                    addError('Cannot extend entity, alias "'+alias+'" not found', aliasTokenCtx.token);
                }
                else {
                    origEntityDef.partials = origEntityDef.partials || [];
                    origEntityDef.partials.push(entityDef);
                    entityDef.isPartial = true;
                    entityDef.originalDefinition = origEntityDef;
                    entityDef.typeName = origEntityDef.typeName;
                    entityDef.type = dslEntitiesDefByName[ origEntityDef.typeName ];
                    entityDef.nameToken = aliasTokenCtx.token;
                }
            }
        });
    
        return validEntityDefinitions;
    }

    validateEntityAttributes(entityDef, attributeDefinitions, parentNameToken, validEntityDefinitionsByAlias, addError){
        const attributes = [];
        const attributeCountByName = {};
        const attributeTokenCtxs = entityDef.getChildrenByContextType(CONTEXT_TYPES.ATTRIBUTE);
        (entityDef.partials || []).forEach(partialEntityDef => Array.prototype.push.apply(attributeTokenCtxs, partialEntityDef.getChildrenByContextType(CONTEXT_TYPES.ATTRIBUTE)));

        function addErrorOnEntityName(msg){
            addError(msg, parentNameToken);
            (entityDef.partials || []).forEach(partialEntityDef => addError(msg, partialEntityDef.nameToken));
        }
    
        // attribute value validation
        attributeTokenCtxs.forEach(attributeTokenCtx => {
            const attrNameTokenCtx = attributeTokenCtx.getChildrenByContextType(CONTEXT_TYPES.ATTRIBUTE_NAME)[0] || {};
            const attrValueTokenCtx = attributeTokenCtx.getChildrenByContextType(CONTEXT_TYPES.ATTRIBUTE_VALUE)[0] || {};
            const attrName = attrNameTokenCtx.value;
            const attrValue = attrValueTokenCtx.value;
            const attrDef = attributeDefinitions[attrName];
            const attrValueValidator = attrDef ? ATTRIBUTE_VALUE_TYPES[ attrDef.type ] : null;
            const parsedAttrValue = attrValueValidator ? attrValueValidator.parse(attrValue) : attrValue;
            const referencedEntityTypeName = (validEntityDefinitionsByAlias[ parsedAttrValue ] || {}).typeName;

            // cache value for codemirror theme
            attrValueTokenCtx.valueTypeName = (attrDef || {}).type;

            if(!attrDef) {
                addError('Entity type "'+entityDef.typeName+'" has no attribute with name "'+attrName+'"', attrNameTokenCtx.token);
            }
            else if(attrValue === undefined) {
                addError('Missing value', attrNameTokenCtx.token);
            }
            else if(!attrValueValidator){
                addError('Unrecognized value type "'+attrDef.type+'"', attrValueTokenCtx.token);
            }
            else if(!attrValueValidator.validate(attrValue)){
                addError('Invalid value for type "'+attrDef.type+'"', attrValueTokenCtx.token);
            }
            else if(attrDef.type === 'REFERENCE' && attrDef.referenceTypes && !attrDef.referenceTypes.includes(referencedEntityTypeName)){
                addError('Referenced entity type "'+referencedEntityTypeName+'" is not allowed here', attrValueTokenCtx.token);
            }
            else {
                attributes.push({
                    name: attrName,
                    nameToken: attrNameTokenCtx.token,
                    value: parsedAttrValue,
                    valueType: attrDef.type,
                    attrDef: attrDef,
                    valueToken: attrValueTokenCtx.token
                });
                attributeCountByName[ attrName ] = (attributeCountByName[ attrName ] || 0) + 1;
            }
        });
    
        entityDef.attributes = attributes;

        // validate attribute props
        for(let attrName in attributeDefinitions) {
            const attrDef = attributeDefinitions[ attrName ];
            attributeCountByName[attrName] = attributeCountByName[attrName] || 0;

            if(attrDef.required && !attributeCountByName[attrName]) {
                addErrorOnEntityName('Attribute "'+attrName+'" is required');
            }
            if(attrDef.minCardinality > -1 && attrDef.minCardinality > attributeCountByName[attrName]) {
                addErrorOnEntityName('Attribute "'+attrName+'" is set fewer times ('+attributeCountByName[attrName]+'/'+attrDef.minCardinality+') than allowed');
            }
            if(attrDef.maxCardinality > 0 && attrDef.maxCardinality < attributeCountByName[attrName]) {
                addErrorOnEntityName('Attribute "'+attrName+'" is set more times ('+attributeCountByName[attrName]+'/'+attrDef.minCardinality+') than allowed');
            }
        }
    }

    validateEntitiesAttributes(validEntityDefinitions, dslEntitiesDefByName, addError){
        const validEntityDefinitionsByAlias = arrayToObject(validEntityDefinitions, 'alias');

        validEntityDefinitions.forEach(entityDef => {
            const attributeDefinitions = (dslEntitiesDefByName[entityDef.typeName] || {}).attributes || {};
            this.validateEntityAttributes(entityDef, attributeDefinitions, entityDef.nameToken, validEntityDefinitionsByAlias, addError);
        });
    }

    validateRelationDefinitions(rootTokenContext, validEntityDefinitions, addError){
        const relationTokenCtxs = rootTokenContext.getChildrenByContextType(CONTEXT_TYPES.RELATION);
        const validEntityDefinitionsByAlias = arrayToObject(validEntityDefinitions, 'alias');

        relationTokenCtxs.forEach(relationTokenCtx => {
            const relationTypeCtx = relationTokenCtx.getChildrenByContextType(CONTEXT_TYPES.RELATION_TYPE)[0] || {};
            const relationType = relationTypeCtx.value;
            const parentEntityTokenCtx = relationTokenCtx.getParentByContextType(CONTEXT_TYPES.ENTITY);
            const relationDef = parentEntityTokenCtx && parentEntityTokenCtx.type ? parentEntityTokenCtx.type.relations[ relationType ] : null;

            relationTokenCtx.typeName = relationType;
            relationTokenCtx.type = relationDef;
            relationTokenCtx.relatedTo = [];

            // 1. relation must be inside entity body
            if(!parentEntityTokenCtx) {
                addError('Relation must be inside entity body', relationTypeCtx.token);
            }

            // 2. relation type must be listed in entity relations
            else if(!relationDef) {
                addError('Relation "'+relationType+'" is not defined in entity type "'+parentEntityTokenCtx.typeName+'"', relationTypeCtx.token);
            }

            // 3. all related entities must be listed in relation referenceTypes
            else {
                const validEntityDefsByAlias = arrayToObject(validEntityDefinitions, 'alias');
                const allowedReferenceTypes = arrayToObject(relationDef.referenceTypes || []);
                
                // 1. validate all related aliases
                relationTokenCtx.getChildrenByContextType(CONTEXT_TYPES.RELATION_ENTITY_ALIAS).forEach(aliasTokenCtx => {
                    const alias = aliasTokenCtx.value;
                    const typeName = (validEntityDefsByAlias[ alias ] || {}).typeName;

                    relationTokenCtx.relatedTo.push(alias);

                    // alias does not exists
                    if(!typeName) {
                        addError('Alias "'+alias+'" does not exist', aliasTokenCtx.token);
                    }

                    // referenced type is not listed as allowed
                    else if(!allowedReferenceTypes[ typeName ]){
                        addError('Entity type "'+typeName+'" is not allowed in this type of relation', aliasTokenCtx.token);
                    }
                });

                // 2. validate all related inline entity types
                relationTokenCtx.getChildrenByContextType(CONTEXT_TYPES.ENTITY).forEach(relatedEntityCtx => {

                    relationTokenCtx.relatedTo.push(relatedEntityCtx.alias);
                    
                    // referenced type is not listed as allowed
                    if(!allowedReferenceTypes[ relatedEntityCtx.typeName ]){
                        addError('Entity type "'+relatedEntityCtx.typeName+'" is not allowed in this type of relation', relatedEntityCtx.nameToken);
                    }
                });

                // 3. validate relation attributes
                this.validateEntityAttributes(relationTokenCtx, relationDef.attributes || {}, relationTypeCtx.token, validEntityDefinitionsByAlias, addError);

                parentEntityTokenCtx.relations = parentEntityTokenCtx.relations || [];
                parentEntityTokenCtx.relations.push(relationTokenCtx);
            }
        });
    }
};