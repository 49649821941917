import CodeMirror from 'codemirror';
import 'codemirror/lib/codemirror.css';

import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/lint.css';

import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/hint/show-hint.css';
import './custom-cm-css.css';

import 'codemirror/addon/fold/foldcode';
import 'codemirror/addon/fold/brace-fold';
import 'codemirror/addon/fold/foldgutter';
import 'codemirror/addon/fold/foldgutter.css';

import 'codemirror-formatting/formatting';
 
import EmDslParser from './emdsl-parser/emdsl-parser.js';

import locals from 'obj-fe/services/localisation';

import { CONTEXT_TYPES } from './emdsl-parser/emdsl-types';

const modeName = 'inv-emdsl-mode';


CodeMirror.defineMode(modeName, function(cmConfig, modeConfig) {

    const indentChangeTypes = {
        [ CONTEXT_TYPES.ENTITY_BODY_START ]: 1,
        [ CONTEXT_TYPES.ENTITY_BODY_END ]: -1,
        [ CONTEXT_TYPES.RELATION_LIST_START ]: 1,
        [ CONTEXT_TYPES.RELATION_LIST_END ]: -1,

        [ CONTEXT_TYPES.RELATION_ATTRIBUTES_START ]: 1,
        [ CONTEXT_TYPES.RELATION_ATTRIBUTES_END ]: -1,

        [ CONTEXT_TYPES.RELATION_LIST_START ]: 1,
        [ CONTEXT_TYPES.RELATION_LIST_END ]: -1,
    };

    return {
        token(stream, state) {
            let streamPosTypes = modeConfig.getParsedResultValue().tokenBoundaries; // createStreamPosTypes(modeConfig.getParsedResultValue());
        
            let linePosTypes, posType;
            // switch type when stream is on the edge of types
            if(stream.lineOracle){
                linePosTypes = streamPosTypes[ stream.lineOracle.line ];
                posType = linePosTypes ? linePosTypes[ stream.pos ] : undefined;
            } 
        
            state.type = posType === undefined ? state.type : posType;
            if(Array.isArray(state.type)) state.type = state.type.join(' ');

            if(indentChangeTypes[ state.type ]) {
                state.indent += indentChangeTypes[ state.type ];
            }

            stream.next();

            // codemirror is ignoring new line chars
            // if stream standing on end of line and type is ending here, we need to reset state.type for next line
            if(stream.eol()){
                if(stream.lineOracle){
                    linePosTypes = streamPosTypes[ stream.lineOracle.line ];
                    let nextPosType = linePosTypes ? linePosTypes[ stream.pos ] : undefined;
                    if(nextPosType === null) {
                        let prevType = state.type;
                        state.type = null;
                        return prevType;
                    }
                }
            }

            return state.type;
        },
        startState() {
            return {
                type: null,
                indent: 0,
            }
        },
        
        indentation: 1,

        indent: function(state, textAfter, lineText) {

            // TODO: remove dirty fix
            if(textAfter.charAt(0) === '}' || textAfter.charAt(0) === ']') {
                return Math.max(0, (state.indent - 1) * 4);
            }

            return state.indent * 4;
        },
    };
});


CodeMirror.extendMode(modeName, {
    newlineAfterToken: function(_type, content, textAfter, state) {
        return /^[{},\[\]]$/.test(content) || /^}/.test(textAfter) || /^]/.test(textAfter);
    }
});

CodeMirror.registerHelper('lint', modeName, function(text, cb, opts, cm) {
    cm.parsedResult.onValidated(() => {
        const reservedTokens = cm.parsedResult.dslDefinition.entityTypes;

        if(!cm.parsedResult) return cb([]);

        if(cm.parsedResult.isValid) {
            let parseWarnings = cm.parsedResult.parseErrors
                .map((err)=>prepareError(err, text));
            return cb(parseWarnings);
        } 

        let lexErrors = cm.parsedResult.lexErrors.map(err => {
            return {
                severity: 'error',
                from: CodeMirror.Pos(err.line - 1, err.column - 1),
                to: CodeMirror.Pos(err.line - 1, err.length + err.column - 1),
                message: locals.translate(err.message)
            };
        });

        function enrichParseErrors(error){
            const 
                type = error.name,
                tokenText = error.token.image;

            if(type === "NoViableAltException") {
                if(reservedTokens.includes(tokenText)) {
                    error.message = `${tokenText} is a reserved word (entity type).\nPlease use another alias.`;
                }
            }

            return error;
        }

        let parseErrors = cm.parsedResult.parseErrors
            .map(err => enrichParseErrors(err))
            .map(err => prepareError(err, text));
    
        cb(lexErrors.concat(parseErrors));
    });
});



export default {
    init
};

function init(cm, parserOpts){
    cm.emDslParser = new EmDslParser(parserOpts); // parserOpts contain the dslDefintion required by 

    // TODO: trigger first time change callback sync, then async debounced
    cm.on('change', () => {
        let value = cm.getValue();
        cm.parsedResult = cm.emDslParser.parse(value);
    });

    // immediatelly parse query value
    cm.parsedResult = cm.emDslParser.parse(cm.getValue());

    cm.setOption('mode', { // will re-initialize the mode
        name: modeName,
        getParsedResultValue() {
            return cm.parsedResult ? cm.parsedResult.value : [];
        }
    });

    cm.setOption('lint', {
        delay: 150,
        async: true
    });

    // hint
    cm.setOption('hintOptions', {
        hint: autocomplete,
        completeSingle: false
    });

    cm.setOption('extraKeys', {
        'Ctrl-Space': 'autocomplete',
        'Cmd-Space': 'autocomplete',
        'Ctrl-/': comment,
        'Ctrl-.': autoFormatSelection,
    });

    cm.setOption('foldGutter', {
        rangeFinder: CodeMirror.fold.brace
    });

    cm.setOption('gutters', ['CodeMirror-linenumbers', 'CodeMirror-foldgutter']);

    // TODO: decide if autocompletion will show on type or space instead of ctrl+space
    // let showAutocompletions = object.debounce(function(cm) {
    //     let cursor = cm.getCursor();
    //     let lineText = cm.getLine(cursor.line);
    //     let cursorIsAtLineStart = !!lineText.slice(0, cursor.ch).match(/^\s*$/);

    //     if(!cursorIsAtLineStart) cm.showHint(cm);
    // }, 150);

    // let hintHandlersRegistered = false;
    // function registerHintEventHandlers(){
    //     if(hintHandlersRegistered) return;
    //     hintHandlersRegistered = true;
    //     setTimeout(() => {
    //         // cm.on('cursorActivity', showAutocompletions);
    //         // cm.on('focus', showAutocompletions);
    //         // if(cm.hasFocus()) showAutocompletions(cm);
    //     });
    // }
    
    // // cm.on('focus', registerHintEventHandlers);
}

function autocomplete(cm) {
    let cursor = cm.getCursor();

    return new Promise((resolve, reject) => {
        cm.emDslParser.getNextTokenSuggestions(cursor.line, cursor.ch, (suggestions, startPos, endPos, replaceOldText) => {
            let result = {
                list: suggestions
            };

            // auto indent completion
            CodeMirror.on(result, 'pick', function autoIndentCompletion(completion){
                CodeMirror.off(result, 'pick', autoIndentCompletion);

                if(completion.disabled) return;

                // settimeout is needed to ensure new snippet is parsed and indent calculated for new lines of code
                setTimeout(function(){
                    // select autocompletion
                    cm.setSelection(cursor, cm.getCursor(), { scroll: false });
                    
                    //auto indent the selection
                    cm.indentSelection("smart");

                    // unselect
                    cm.setSelection(cm.getCursor(), cm.getCursor(), { scroll: false });
                });
            });
            
            if(result.list.length > 0){
                result.from = CodeMirror.Pos(startPos.line, startPos.column);
                result.to = CodeMirror.Pos(endPos.line, replaceOldText ? endPos.column : startPos.column);
                resolve(result);
            }
            else resolve();
        });
    });
}
function comment(cm){
    let selection = cm.getDoc().getSelection();

    // is there anything selected ? 
    // no, there isnt - we only need to process one line
    if(selection.length < 1){
        let cursor = cm.getCursor();
        let line = cm.getDoc().getLine(cursor.line);
    
        // either comment a line 
        if(isLineCommented(line)) {
            cm.getDoc().replaceRange(
                uncommentLine(line), {
                    line: cursor.line,
                    ch: 0
                },{
                    line: cursor.line,
                    ch: line.length
                });
        }
        // or uncomment it
        else {
            cm.getDoc().replaceRange(
                ('//' + line), {
                    line: cursor.line,
                    ch: 0
                },{
                    line: cursor.line,
                    ch: line.length
                });
        }
    } 
    // yes, there is - we need to process all the lines
    else {
        let selectionStartLineIndex = cm.getCursor('from').line;
        let selectionEndLineIndex = cm.getCursor('to').line;
        let shouldUncommentSelection = true;

        // let selection = selection.split('\n');
        for (let lineIndex = selectionStartLineIndex; lineIndex < selectionEndLineIndex; lineIndex++) {
            let line = cm.getDoc().getLine(lineIndex);
            if(!isLineCommented(line)) shouldUncommentSelection = false;
        }

        if(shouldUncommentSelection){
            for (let lineIndex = selectionStartLineIndex; lineIndex <= selectionEndLineIndex; lineIndex++) {
                let line = cm.getDoc().getLine(lineIndex);

                cm.getDoc().replaceRange(
                    uncommentLine(line), {
                        line: lineIndex,
                        ch: 0
                    },{
                        line: lineIndex,
                        ch: line.length
                    });
            }   
        }
        else{
            for (let lineIndex = selectionStartLineIndex; lineIndex <= selectionEndLineIndex; lineIndex++) {
                let line = cm.getDoc().getLine(lineIndex);

                cm.getDoc().replaceRange(
                    ('//' + line), {
                        line: lineIndex,
                        ch: 0
                    },{
                        line: lineIndex,
                        ch: line.length
                    });
            }   
        }
    }

}

function autoFormatSelection(cm) {
    let range = {
        from: cm.getCursor(true),
        to: cm.getCursor(false)
    }
    cm.autoFormatRange(range.from, range.to); // this be ok
}

function isLineCommented(line){
    let cleanLine = line.trim();
    if(cleanLine.length < 2) return false;
    if(cleanLine[0] === '/' && cleanLine[1] === '/') return true;
    return false;
}
function uncommentLine(line){
    let retval = line;
    let firstForwardSlashIndex = findForwardSlashInString(line);
    retval = retval.substr(0, firstForwardSlashIndex) + retval.substr(firstForwardSlashIndex + 1);
    let secondForwardSlashIndex = findForwardSlashInString(retval);
    retval = retval.substr(0, secondForwardSlashIndex) + retval.substr(secondForwardSlashIndex + 1);

    return retval;
}
function findForwardSlashInString(line){
    let index = 0;
    while(index < line.length){
        if(line[index] === '/') {
            return index;
        }
        index++;
    }
    return -1;
}
function prepareError(err, text){
    let errToken = err.token || {};
    let startColumn = errToken.startColumn;
    let endColumn = errToken.endColumn;
    let startLine = errToken.startLine;
    let endLine = errToken.endLine;

    if(isNaN(startColumn) && err.previousToken) {
        startColumn = err.previousToken.startColumn;
        endColumn = err.previousToken.endColumn;
        startLine = err.previousToken.startLine;
        endLine = err.previousToken.endLine;
    }
    
    if(isNaN(startColumn)) {
        startColumn = 0;
        endColumn = text.length - 1;
    }

    return {
        severity: err.isWarning ? 'warning' : 'error',
        from: CodeMirror.Pos((startLine || 1) - 1, startColumn - 1),
        to: CodeMirror.Pos((endLine || 1) - 1, endColumn),
        message: locals.translate(err.message)
    };
}


