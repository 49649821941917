import {
    Lexer,
    createToken as origCreateToken
} from 'chevrotain';

function createToken(opts) {
    let token = origCreateToken(opts);
    token.typeId = opts.typeId;
    token.text = opts.text;
    token.field = opts.field;

    return token;
}

let tokens = {};

tokens.LineBreak = createToken({
    name: 'LineBreak',
    pattern: /\r?\n|\r/
});

tokens.Comment = createToken({
    name: 'Comment',
    pattern: /\/\/.*/,
    line_breaks: false,
    text: '//',
    group: Lexer.SKIPPED
});

tokens.CurlyBracketLeft = createToken({
    name: 'CurlyBracketLeft',
    pattern: /\{/,
    text: '{'
});

tokens.CurlyBracketRight = createToken({
    name: 'CurlyBracketRight',
    pattern: /\}/,
    text: '}'
});

tokens.SquareBracketLeft = createToken({
    name: 'SquareBracketLeft',
    pattern: /\[/,
    text: '['
});

tokens.SquareBracketRight = createToken({
    name: 'SquareBracketRight',
    pattern: /\]/,
    text: ']'
});

tokens.Equal = createToken({
    name: 'Equal',
    pattern: /=/,
    text: '='
});

tokens.True = createToken({
    name: 'True',
    pattern: /(TRUE)|(True)|(true)/,
    text: 'TRUE'
});

tokens.False = createToken({
    name: 'False',
    pattern: /(FALSE)|(False)|(false)/,
    text: 'FALSE'
});

tokens.Comma = createToken({
    name: 'Comma',
    pattern: /,(,|\s)*/, // allowed multiple commas, include whitespace between them
    text: ',',
    line_breaks: true
});

tokens.String = createToken({
    name: 'String',
    pattern: /[a-zA-Z0-9\-\_:]+/,
    line_breaks: false
});

tokens.StringQuoted = createToken({
    name: 'StringQuoted',
    pattern: createQuotedStringPattern('"'), // /\"((-| |\S)*?(?!\n|\r\n))\"/,
    line_breaks: false
});

tokens.Number = createToken({
    name: 'Number', 
    pattern: /-?(0|[1-9]\d*)(\.\d+)?(0|[1-9]\d*)?/
});

tokens.WhiteSpace = createToken({
    name: 'WhiteSpace',
    pattern: /[\r\n\t\f\v ]+/, // \s+
    group: Lexer.SKIPPED,
    text: ' '
});

tokens.Identifier = createToken({
    name: 'Identifier',
    pattern: /[a-zA-Z0-9$\-_:@\.\\\/]+/
});

tokens.Arrow = createToken({
    name: 'Arrow', 
    pattern: '->'
});

tokens.EntityTypeAbstract = createToken({
    name: 'EntityTypeAbstract',
    pattern: Lexer.NA
});

tokens.RelationNameAbstract = createToken({
    name: 'RelationNameAbstract',
    pattern: Lexer.NA
});

tokens.AttributeNameAbstract = createToken({
    name: 'AttributeNameAbstract',
    pattern: Lexer.NA
});

function createQuotedStringPattern(QUOTE, allowNewLines){
    return function(text, startOffset){
        let endOffset = startOffset;
        let escaped = false;
        let char = text[endOffset];

        // every quoted string must start with quote
        if(char !== QUOTE) return null;

        while (char !== undefined) {
            endOffset++;
            char = text[endOffset];

            if(char === '\\') escaped = !escaped;
            else if(!escaped && char === QUOTE) {
                return [ text.substring(startOffset, endOffset+1) ];
            }
            else if(!escaped && !allowNewLines && char === '\n') {
                return null;
            }
            else escaped = false;
        }

        return [ text.substring(startOffset) ];
    };
}

function createTokenList(entityTypeNames = [], relationNames = [], attributeNames = []) {

    const entityTypeNameTokens = entityTypeNames.map(typeName => createToken({
        name: typeName,
        categories: tokens.EntityTypeAbstract,
        pattern: typeName,
        text: typeName
    }));

    const relationNameTokens = relationNames.map(name => createToken({
        name: name,
        categories: tokens.RelationNameAbstract,
        pattern: name,
        text: name
    }));

    const attributeNameTokens = attributeNames.map(name => createToken({
        name: name,
        categories: tokens.AttributeNameAbstract,
        pattern: name,
        text: name
    }));

    // must be sorted from longest to shortest and alphabetically to avoid collisions
    const nameTokens = entityTypeNameTokens
                        .concat(relationNameTokens)
                        .concat(attributeNameTokens)
                        .sort((a, b) => b.name.localeCompare(a.name)); // reverse sorting

    return [
        tokens.Comment,
        tokens.Equal,
        tokens.True,
        tokens.False,
        tokens.Arrow,
        tokens.CurlyBracketLeft, 
        tokens.CurlyBracketRight,
        tokens.SquareBracketLeft,
        tokens.SquareBracketRight,

        tokens.EntityTypeAbstract,
        tokens.RelationNameAbstract
    ]
    .concat(nameTokens)
    .concat([
        tokens.Identifier,
        tokens.Number,
        tokens.String,
        tokens.StringQuoted,
        // tokens.LineBreak
        tokens.Comma,
        tokens.WhiteSpace,
    ]);
}

export {
    createTokenList,
    createToken,
    tokens
};