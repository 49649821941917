<template>
    <v-card flat>
        <v-card-text>
            <div class="ma-5">
                <v-row>
                    <v-col 
                        v-for="(widget, index) in availableWidgets" 
                        :key="index"
                        xl="3" lg="4" md="6" sm="12" 
                    >
                        <component 
                            :is="widget.component"
                            v-bind="widget.props"
                        />
                    </v-col>
                </v-row>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import backend from 'obj-fe/app/backend'

import InvDashboardTable from './widgets/inv-dashboard-table.vue';

export default {
    components:{
        InvDashboardTable,
    },
    data(){
        return {
            widgets:[
                {
                    component: InvDashboardTable
                }
            ],
            availableWidgets: []
        };
    },
    mounted(){
        backend.widgets.list(widgets =>{
            if(!widgets) return;
            
            widgets
                .map(widget => ({
                    props: widget, 
                    component: InvDashboardTable
                }))
                .forEach(widget => {
                    this.availableWidgets.push(widget)
                });
        })
    }
}
</script>