import store from 'obj-fe/app/store';
import backend from 'obj-fe/app/backend';
import router from 'obj-fe/app/router';
import object from 'obj-fe/utils/object';
import locals from 'obj-fe/services/localisation';
import Vue from 'vue';
import notify from 'obj-fe/services/notifications';

function InitState(){
    this.useHistorization = true;
    this.pageObjectId = null;
    this.pageObjectPanelStates = {};

    this.objectsById = {};
    this.objectIds = [];

    this.objectPreviews = {};
    
    this.query = {
        text: '*',
        categoriesStr: [],
        page: 1,
        limit: 20
    };
    this.pagination = {
        next: false,
        prev: false,
        page: 1,
        pages: 1,
        limit: 10,
        count: 0
    };

    this.filterCategories = [];

    this.historyTimestamp = null;

    this.headTitle = null;
}

store.registerModule('INVENTORY', {
    namespaced: true,
    state: new InitState(),
    actions:{
        ROUTE_CHANGE_START:{
            root: true,
            handler(ctx, route){
                // clear backend service history headers, it should not be used in any other route
                delete backend.defaultHeaders.snapshotTimestamp;
                ctx.commit('USE_HISTORIZATION', store.state.settings.historizationEnabled)

                // ensure page object id will not be set on any non inventory-object page
                ctx.commit('SET_PAGE_OBJECT_ID', null);

                if(route.name === 'inventory-search') {
                    ctx.dispatch('CLEAR');
                    ctx.dispatch('UPDATE_QUERY', route.query);
                }
                else if(route.name === 'inventory-object') {

                    // update backend history service header
                    if(
                        ctx.state.useHistorization &&
                        route.query.historyTS !== null && 
                        route.query.historyTS >= 0
                    ) backend.defaultHeaders.snapshotTimestamp = route.query.historyTS;
                    else delete backend.defaultHeaders.snapshotTimestamp;
                    
                    // code moved for reusability
                    ctx.dispatch('LOAD_OBJECT', route); 
                }
            }
        },
        CLEAR(ctx){
            ctx.commit('CLEAR');
        },
        LOAD_OBJECT(ctx, route){
            ctx.commit('CLEAR');
            ctx.commit('SET_PAGE_OBJECT_ID', route.params.id);
            if(route.query && route.query.panels) ctx.dispatch('SET_PAGE_OBJECT_PANEL_STATES', JSON.parse(route.query.panels || '{}'));
            if(ctx.state.useHistorization && route.query && route.query.historyTS) ctx.commit('SET_HISTORY_TIMESTAMP', route.query.historyTS);
            ctx.dispatch('LOAD_OBJECT_DETAILS', {objectId: route.params.id, changeHeadTitle: route.name === 'inventory-object'});
        },
        LOAD_OBJECT_DETAIL_PREVIEW(ctx, previewInfo){
            let { objectId, errorCb } = previewInfo;
            backend.objects.detailPreview(
                objectId,
                data => ctx.commit('SET_OBJECT_PREVIEW', data),
                error => { if(errorCb) errorCb() }
            ) 
        },
        APPOINT_OBJECT_BOOKMARKED(ctx, objectId){
            if(!objectId) return;

            let currentObject = ctx.state.objectsById[objectId];
            backend.widgets.bookmarkEntity(
                { 
                    globalId: objectId,
                    clear: currentObject.bookmarked 
                },
                {},
                // success callback
                ()=>{
                    ctx.commit('SET_BOOKMARKED', {objectId, bookmarked: !currentObject.bookmarked})
                }
            )
        },
        SET_PAGE_OBJECT_PANEL_STATES(ctx, panelStates){
            ctx.commit('SET_PAGE_OBJECT_PANEL_STATES', panelStates);
            router.extendQueryNoDispatch({ panels: JSON.stringify(panelStates) });
        },
        SET_PAGE_OBJECT_PANEL_STATE(ctx, panelState){
            ctx.commit('SET_PAGE_OBJECT_PANEL_STATE', panelState);
            router.extendQueryNoDispatch({ panels: JSON.stringify(panelState) });
        },
        LOAD_OBJECT_DETAILS(ctx, objectDetailsData){
            let { objectId, changeHeadTitle, urlTemplate, errorCb } = objectDetailsData;
            backend.objects.detailPanel(
                { id:objectId, urlTemplate },
                data => {
                    // TODO: update icons on BE and remove this overriding
                    const updateIconsMap = {
                        'fal fa-plug':'settings_input_composite',
                        'fal fa-paste':'file_copy',
                        'fal fa-cogs':'settings',
                        'fal fa-map-signs':'location_on',
                        'fal fa-puzzle-piece': 'extension',
                        'fal fa-hand-o-right':'work',
                        'fal fa-hand-o-down':'work_outline',
                        'fal fa-comments':'comment',
                        'fal fa-folder':'folder',
                        'fal fa-history':'history',
                        'fal fa-circle-o-notch': 'swap_calls'
                    };

                    // filter panels special cases
                    data.panels = (data.panels || [])
                        .filter(tab => {
                            tab.icon = updateIconsMap[tab.icon] || tab.icon;
                            if(
                                ['com.obj.inv.server.services.grids.SphinxTimeSlotsGrid', 'com.obj.inv.server.services.grids.SphinxPortChangesHistoryGrid'].indexOf(tab.parameters.dataSource) > -1 && 
                                ['OPL_SPHINX', 'MOB_BE_SPHINX_OBE'].indexOf(object.application) === -1
                            ) return false;
                            else return true;
                        });

                    data.fieldsDirty = false;
                    ctx.commit('EXTEND_OBJECT', data);
                    
                    if(!!data.displayString && data.displayString !== '' && changeHeadTitle) {
                        ctx.dispatch('SET_HEAD_TITLE', data.displayString);
                    }
                },
                error => {
                    if(errorCb) errorCb()
                });
        },
        SET_HEAD_TITLE(ctx, headTitle){
            let newLayout = { ...ctx.rootState.layout };
            newLayout.headTitle = headTitle;
            
            // although the name "SET_LAYOUT" might be misleading, this simply updates the proposed head title   
            // SET_LAYOUT is an action of the rootstore, hence we pass { root: true } as the third argument.
            ctx.dispatch('SET_LAYOUT', newLayout, { root: true });
        },
        SET_OBJECT(ctx, data){
            data.fieldsDirty = false;
            ctx.commit('EXTEND_OBJECT', data);
        },
        REPLACE_OBJECT(ctx, data){
            data.fieldsDirty = false;
            ctx.commit('REPLACE_OBJECT', data);
        },
        SAVE_OBJECT_DETAILS(ctx, { objectId, createUrlTemplate, updateUrlTemplate }){
            let object = ctx.state.objectsById[objectId];
            let dirtyFields = [];

            object.fieldsGrouped.forEach(fieldGroup => {
                fieldGroup.fields.forEach(field => {
                    if(field.hasOwnProperty('origValue')) dirtyFields.push(field);
                });
            });

            return new Promise((resolve, reject) => {
                backend.objects[ object.isNew ? 'detailPanelCreate' : 'detailPanelUpdate' ]({
                    id: object.isNew ? object.type : objectId,
                    type: object.type,
                    urlTemplate: object.isNew ? createUrlTemplate : updateUrlTemplate
                }, dirtyFields, data => {
                    data.fieldsDirty = false;
                    ctx.commit('EXTEND_OBJECT', data);
                    resolve(data.id || data.globalId);
                });
            });
        },
        LOAD_OBJECTS(ctx){
            backend.objects.search(ctx.state.query, data => {
                ctx.commit('SET_FILTER_CATEGORIES', data.categories);
                ctx.commit('SET_PAGINATION', data.pagination);
                ctx.commit('SET_OBJECTS', data);
            });
        },
        ADD_NEW(ctx, { type, urlTemplate }){
            return new Promise((resolve, reject) => {
                // load new entity template
                backend.objects.detailPanelTemplate({
                        type: type.name,
                        urlTemplate
                    },
                    data => {
                        let newObject = {
                            ...data,
                            id: data.id || 'new_' + type,
                            fieldsGrouped: data.groups,
                            panels: data.panels || [],
                            displayString: data.displayString || 'new entity',
                            typeDisplayString: type.displayString,
                            type: type.name,
                            isNew: true
                        };

                        ctx.dispatch('SET_OBJECT', newObject);
                        
                        resolve(newObject);
                    }
                );
            });
        },
        UPDATE_QUERY(ctx, query){
            ctx.commit('UPDATE_QUERY', query);
            router.extendQueryNoDispatch(ctx.state.query);
            ctx.dispatch('LOAD_OBJECTS');
        },
        RESET_QUERY(ctx){
            ctx.dispatch('UPDATE_QUERY', {
                categoriesStr:[],
                text:'*'
            });
        },
        TOGGLE_SELECT_FILTER_TAG(ctx, tag){
            ctx.commit('TOGGLE_SELECT_FILTER_TAG', tag);
            router.extendQueryNoDispatch(ctx.state.query);
            ctx.dispatch('LOAD_OBJECTS');
        },
        TOGGLE_EXPAND_FILTER_CATEGORY(ctx, category){
            ctx.commit('TOGGLE_EXPAND_FILTER_CATEGORY', category);
        },
        TOGGLE_EXPAND_FILTER_TAG(ctx, tag){
            ctx.commit('TOGGLE_EXPAND_FILTER_TAG', tag);
        },
        SET_OBJECT_DETAIL_FIELD(ctx, { objectId, field, value }){
            ctx.commit('SET_OBJECT_DETAIL_FIELD', { objectId, field, value });
        },
        SET_OBJECT_DETAIL_FIELD_CORRECTION(ctx, { field, dataCorrectionRequests }){
            ctx.commit('SET_OBJECT_DETAIL_FIELD_CORRECTION', { field, dataCorrectionRequests });
        },
        RESET_OBJECT_DETAIL_FIELD(ctx, { objectId, field }){
            ctx.commit('RESET_OBJECT_DETAIL_FIELD', { objectId, field });
        },
        RESET_OBJECT_DETAIL_FORM(ctx, objectId){
            ctx.commit('RESET_OBJECT_DETAIL_FORM', objectId);
        },
        SET_HISTORY_TIMESTAMP(ctx, timestamp){
            if(!ctx.state.useHistorization) return;

            ctx.commit('SET_HISTORY_TIMESTAMP', timestamp);

            let extendQuery = { historyTS:timestamp };
            let currQuery = router.currentRoute.query || {};
            let newQuery = object.extend.apply(object, ['data', {}, currQuery].concat(extendQuery));

            // update route to force reload of all object panels and details
            router.push({ query: newQuery });
        },
        EXPORT_REPORTS_SEND(ctx, { objectId, reportIds, reportName }){
            backend.objects.exportExcelBulkAsync(
                { objectId },
                {
                    asyncReportTitle: reportName,
                    dataSources: reportIds
                },
                data => notify.success('export_will_be_generated_and_send_to_your_email', data.email)
            );
        }
    },
    mutations:{
        CLEAR(state){
            let initState = new InitState();
            for(let key in initState) Vue.set(state, key, initState[key]);
        },
        USE_HISTORIZATION(state, useHistorization){
            state.useHistorization = useHistorization;
        },
        SET_PAGE_OBJECT_PANEL_STATES(state, panelStates){
            state.pageObjectPanelStates = panelStates;
        },
        SET_PAGE_OBJECT_PANEL_STATE(state, panelState){
            const panel = Object.entries(panelState)[0];
            state.pageObjectPanelStates[panel[0]] = panel[1];
        },
        SET_PAGE_OBJECT_ID(state, objectId){
            state.pageObjectId = objectId;
        },
        EXTEND_OBJECT(state, objectData){
            objectData.id = objectData.id || objectData.globalId;
            let currObject = state.objectsById[ objectData.id ] || {};

            Vue.set(state.objectsById, objectData.id, { ...currObject, ...objectData });
        },
        REPLACE_OBJECT(state, objectData){
            objectData.id = objectData.id || objectData.globalId;
            Vue.set(state.objectsById, objectData.id, { ...objectData });
        },
        SET_FILTER_CATEGORIES(state, categories){
            function traverseTags(tags, cb, level){
                level = level || 0;

                tags.forEach(tag => {
                    cb(tag, level);
                    if(tag.children) traverseTags(tag.children, cb, level+1);
                });
            }

            // make tags flat
            state.filterCategories = categories.map(cat => {
                let tags = [];
                traverseTags(cat.tags, (tag, level) => {
                    tag.level = level;
                    tag.expanded = true;
                    tag.hidden = false;
                    tags.push(tag);
                });
                cat.expanded = true;
                cat.tags = tags;
                return cat;
            });
        },
        TOGGLE_SELECT_FILTER_TAG(state, tag){
            let catStr = tag.queryString + ':' + tag.value;
            if(!Array.isArray(state.query.categoriesStr)) {
                state.query.categoriesStr = state.query.categoriesStr ? [state.query.categoriesStr] : [];
            }

            if(tag.selected) { // unselect
                let indexOfCatStr = state.query.categoriesStr.indexOf(catStr);
                if(indexOfCatStr > -1) state.query.categoriesStr.splice(indexOfCatStr, 1);
            }
            else state.query.categoriesStr.push(catStr);

            // show user tag selection change immediate
            tag.selected = !tag.selected;
        },
        TOGGLE_EXPAND_FILTER_CATEGORY(state, category){
            category.expanded = !category.expanded;
            recalcHideCats(state.filterCategories);
        },
        TOGGLE_EXPAND_FILTER_TAG(state, tag){
            tag.expanded = !tag.expanded;
            recalcHideCats(state.filterCategories);
        },
        SET_PAGINATION(state, pagination){
            state.pagination = pagination;
        },
        SET_OBJECTS(state, objects){
            let objectIds = [], objectsById = {};

            objects.forEach(obj => {
                obj.id = obj.id || obj.globalId;
                objectIds.push(obj.id);
                objectsById[ obj.id ] = obj;
            });

            state.objectIds = objectIds;
            state.objectsById = objectsById;
        },

        UPDATE_QUERY(state, query){
            for(let key in query) {
                state.query[key] = query[key];
            }
        },

        SET_OBJECT_DETAIL_FIELD(state, { objectId, field, value }){
            let object = state.objectsById[objectId];
            object.fieldsDirty = true;
            if(!field.hasOwnProperty('origValue')) field.origValue = field.value;
            field.value = value;
        },
        RESET_OBJECT_DETAIL_FIELD(state, { objectId, field }){
            field.value = field.origValue;
            delete field.origValue;
            
            let object = state.objectsById[objectId];
            
            let areFieldsDirty = false;
            object.fieldsGrouped.forEach(group => {
                group.fields.forEach(field => {
                    if(field.hasOwnProperty('origValue')) areFieldsDirty = true;
                });
            });
            
            object.fieldsDirty = areFieldsDirty;
        },
        SET_OBJECT_DETAIL_FIELD_CORRECTION(state, { field, dataCorrectionRequests }){
            field.dataCorrectionRequests = dataCorrectionRequests;
        },

        RESET_OBJECT_DETAIL_FORM(state, objectId){
            let object = state.objectsById[objectId];
            if(!object) return;

            object.fieldsGrouped.forEach(group => {
                group.fields.forEach(field => {
                    if(field.hasOwnProperty('origValue')){
                        field.value = field.origValue;
                        delete field.origValue;
                    }
                });
            });
            
            object.fieldsDirty = false;
        },

        SET_HISTORY_TIMESTAMP(state, timestamp){
            if(!state.useHistorization) return;

            state.historyTimestamp = parseInt(timestamp, 10);
        },

        SET_BOOKMARKED(state, {objectId, bookmarked}){
            state.objectsById[objectId].bookmarked = bookmarked;
        },

        SET_OBJECT_PREVIEW(state, objectPreview){
            Vue.set(state.objectPreviews, objectPreview.globalId, objectPreview);
        }
    }
});

function recalcHideCats(categories){
    categories.forEach(cat => {
        let hideUnderLevel = cat.expanded ? undefined : -1;

        cat.tags.forEach(tag => {
            // hide / show tags
            if(!isNaN(hideUnderLevel) && tag.level > hideUnderLevel) {
                if(tag.hidden !== true) tag.hidden = true;
            }
            else {
                // bubbled into sibling of not expanded tag
                if(tag.hidden !== false) tag.hidden = false;

                if(tag.expanded === false) hideUnderLevel = tag.level;
                else hideUnderLevel = undefined; // reset
            }
        });
    });
}