<template>
    <div ref="mapContainer" style="width:100%;height:100%"></div>
</template>

<style scoped>
    /* >>> .ol-zoom {
        position:absolute;
        top:5px;
        left:5px;
        outline:1px solid yellow;
    } */

    /* hide copyright - only for internal purpose */
    >>> .ol-attribution.ol-unselectable.ol-control.ol-uncollapsible {
        display:none;
    }
</style>

<script>
    import 'ol/ol.css';
    import Map from 'ol/Map';
    import View from 'ol/View';
    import Feature from 'ol/Feature';
    import Point from 'ol/geom/Point';
    import Vector from 'ol/source/Vector';
    import {defaults} from 'ol/interaction';
    import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
    import {OSM} from 'ol/source';
    import {Style, Icon} from 'ol/style'; // Circle, Fill, Stroke,
    import {fromLonLat} from 'ol/proj';

    export default {
        props:{
            latitude:{},
            longitude:{},
            zoom: Number,
            resizeNeeded: Boolean,
        },
        data(){
            return {};
        },
        computed:{
            mapLatitude(){
                if(typeof this.latitude === 'string') return this.convertDegress(this.latitude+' N');
                else return this.latitude;
            },
            mapLongitude(){
                if(typeof this.longitude === 'string') this.longitude = this.convertDegress(this.longitude+' E');
                else return this.longitude;
            }
        },
        watch:{
            resizeNeeded: function(){
                if(this.resizeNeeded){
                    setTimeout(()=>{
                        window.dispatchEvent(new Event('resize'));
                    }, 200)
                }
            }
        },
        methods:{
            convertDegress(input) {
                var parts = input.split(/[^\d\w]+/g);
                return this.convertDMSToDD(parts[0], parts[1], parts[2], parts[3], parts[4]);
            },

            convertDMSToDD(degrees, minutes, seconds, miliseconds, direction) {
                var dd = parseInt(degrees,10) + parseInt(minutes,10)/60 + parseInt(seconds,10)/(60*60) + parseInt(miliseconds,10)/(60*60*60);
                if (direction === 'S' || direction === 'W') {
                    dd = dd * -1;
                } // Don't do anything for N or E
                return parseFloat(dd+'');
            },

            createMapInstance(mapContainerElm){
                const defaultProjection = 'EPSG:3857';

                var place = new Feature({
                    geometry: new Point(fromLonLat([ this.mapLongitude, this.mapLatitude ], defaultProjection))
                });
                
                place.setStyle(new Style({
                    // image: new Circle({
                    //     radius: 8,
                    //     fill: new Fill({
                    //         color: 'red'
                    //     }),
                    //     stroke: new Stroke({
                    //         color: 'black',
                    //         width: 2
                    //     })
                    // }),
                    image: new Icon(({
                        color: 'red',
                        crossOrigin: 'anonymous',
                        src: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' aria-hidden='true' focusable='false' width='30px' height='30px' style='-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);' preserveAspectRatio='xMidYMid meet' viewBox='0 0 24 24'%3E%3Cpath d='M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 0 1 0-5a2.5 2.5 0 0 1 0 5z' fill='red'/%3E%3C/svg%3E"
                    }))
                }));

                let map = new Map({
                    // controls: control.defaults({ zoom:true }),
                    interactions: defaults({ mouseWheelZoom:false }),
                    view: new View({
                        center: fromLonLat([ this.mapLongitude, this.mapLatitude ], defaultProjection),
                        zoom: this.zoom || 16
                    }),
                    layers: [
                        new TileLayer({
                            source: new OSM()
                        }),
                        new VectorLayer({
                            source: new Vector({
                                features: [ place ]
                            })
                        })
                    ],
                    target: mapContainerElm
                });

                return map;
            }
        },
        mounted(){
            this.map = this.createMapInstance(this.$refs.mapContainer);
        },
        destroyed(){
            if(this.map) this.map.setTarget(null); // destroy map instance
        }
    };
</script>