<template>
    <div class="chat-app">
        <div class="chat-app-header py-3 d-flex justify-space-between">
            <h2>{{ roomName }}</h2>
            <v-btn icon @click="menu = true">
                <v-icon>fal fa-ellipsis-v</v-icon>
            </v-btn>
        </div>

        <div class="chat-app-body" ref="chat">
            <chat-room-messages
                v-if="room"
                :messages="messages"
                :last-read-message-id="room.lastReadMessageId"
                :no-more-messages="$store.state.CHAT.noMoreMessagesByRoomId[roomId]"
                @load-more="loadMoreMessages"
            />
        </div>
        <div class="chat-app-footer">
            <div class="d-flex align-center">
                <v-text-field
                    type="text"
                    v-model="message"
                    @keydown.enter="submit"
                    placeholder="Type a new message"
                    outlined
                    dense
                    hide-details
                ></v-text-field>
                <v-btn @click="submit" icon>
                    <v-icon color="primary">
                        fad fa-paper-plane
                    </v-icon>
                </v-btn>
            </div>
        </div>

        <v-navigation-drawer v-model="menu" absolute right clipped :width="drawerWidth" z-index="1">
            <v-app-bar elevation="0">
                <v-toolbar-title>{{ "chat_room_settings" | translate }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="menu = false">
                    <v-icon>fal fa-times</v-icon>
                </v-btn>
            </v-app-bar>

            <v-card flat v-if="room && room.recipients">
                <v-card-text>
                    <v-list>
                        <v-subheader>{{ "chat_recipients" | translate }}</v-subheader>
                        <v-list-item
                            v-if="room && room.privateCommunication === false"
                            @click="dialogAddRecipientsToChannel = true"
                        >
                            <v-list-item-icon>
                                <v-icon>fal fa-plus</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ "chat_add_recipients" | translate }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-for="recipient in room.recipients" :key="recipient.id">
                            <v-list-item-content>
                                <v-list-item-title>{{ recipient.name }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action v-if="$store.state.user.personId !== recipient.id">
                                <v-list-item-action-text>
                                    <v-btn
                                        text
                                        icon
                                        @click="
                                            $store.dispatch('CHAT/DELETE_CHATROOM_RECIPIENTS', {
                                                roomId,
                                                recipientIds: [recipient.id],
                                            })
                                        "
                                    >
                                        <v-icon>fad fa-sign-out</v-icon>
                                    </v-btn>
                                </v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>

            <v-card flat>
                <v-card-text>
                    <v-list>
                        <v-subheader>{{ "chat_actions" | translate }}</v-subheader>
                        <v-list-item @click="deleteRoom">
                            <v-list-item-icon>
                                <v-icon>fad fa-trash</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ "chat_delete" | translate }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-navigation-drawer>

        <chat-add-recipients-to-channel-room :room-id="roomId" v-model="dialogAddRecipientsToChannel" />
    </div>
</template>

<script>
import ChatRoomMessages from "./chat-room-messages.vue";
import ChatAddRecipientsToChannelRoom from "./chat-add-recipients-to-channel-room.vue";

export default {
    props: {
        roomId: {
            type: String,
            required: true,
        },
        roomName: {
            type: String,
            required: true,
        },
    },

    components: {
        ChatRoomMessages,
        ChatAddRecipientsToChannelRoom,
    },

    watch: {
        messages: {
            deep: true,
            handler() {
                this.$nextTick(() => {
                    this.$store.dispatch("CHAT/ROOM_READ", { roomId: this.roomId });

                    if (this.$refs.chat.scrollHeight - this.$refs.chat.offsetHeight - 200 > this.$refs.chat.scrollTop) {
                        // do not scrool
                    } else {
                        this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight;
                    }
                });
            },
        },
    },

    mounted() {
        this.$store.dispatch("CHAT/LOAD_CHATROOM", { roomId: this.roomId });
        this.$store.dispatch("CHAT/LOAD_MESSAGES_FOR_CHATROOM", { roomId: this.roomId });
        this.$store.dispatch("CHAT/ROOM_READ", { roomId: this.roomId });

        this.$nextTick(() => {
            this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight;
        });
    },

    data() {
        return {
            menu: false,
            message: "",
            dialogAddRecipientsToChannel: false,
        };
    },

    computed: {
        room() {
            return this.$store.state.CHAT.roomsById[this.roomId];
        },

        messages() {
            const messages = Object.values(this.$store.state.CHAT.messages);
            return messages
                .filter((message) => String(message.roomId) === String(this.roomId))
                .sort((messageA, messageB) => String(messageA.id).localeCompare(String(messageB.id)));
        },

        drawerWidth() {
            return Math.min(window.innerWidth / 1.5, 400);
        },
    },
    methods: {
        loadMoreMessages() {
            const lastMessage = this.messages[0];
            if (lastMessage) {
                this.$store.dispatch("CHAT/LOAD_MESSAGES_FOR_CHATROOM", {
                    roomId: this.roomId,
                    afterMessageId: lastMessage.id,
                });
            }
        },

        deleteRoom() {
            this.$store.dispatch("CHAT/DELETE_CHATROOM", { roomId: this.roomId }).then(() => {
                this.$router.push({ name: "chat" });
            });
        },

        submit() {
            if (this.message && String(this.message).length > 0) {
                this.$store.dispatch("CHAT/SEND_MESSAGE", {
                    roomId: this.roomId,
                    content: this.message,
                });
                this.message = "";
            }
        },
    },
};
</script>

<style scoped>
.chat-app {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.chat-app-header {
    flex: 0 0 auto;
}
.chat-app-body {
    flex: 1 0 0;
    overflow-y: auto;
    border-top: 1px solid #eee;
}
.chat-app-footer {
    flex: 0 0 auto;
    padding: 1rem 2.5rem 2rem 2.5rem;
}
</style>
