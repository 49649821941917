<template>
    <v-card flat>
        <v-row no-gutters style="height: 100%">
            <v-col cols="4" sm="4" md="3" lg="3" xl="2">
                <v-card-title>
                    {{ "chat" | translate }}
                </v-card-title>

                <v-list flat class="px-4">
                    <v-subheader>{{ "chat_channels" | translate }}</v-subheader>
                    <v-list-item-group color="primary">
                        <v-list-item @click="openDialogCreateChannelRoom">
                            <v-list-item-icon>
                                <v-icon>fal fa-plus</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ "chat_add_channel" | translate }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                            v-for="room in channels"
                            :key="room.id"
                            :to="{ name: 'chat-room', params: { roomId: room.id } }"
                            two-line
                            class="my-2"
                            :class="{
                                'font-weight-black': !!unreadMessagesCountByRoomId[room.id],
                            }"
                        >
                            <v-list-item-content
                                :class="{
                                    'font-weight-black': !!unreadMessagesCountByRoomId[room.id],
                                }"
                            >
                                <v-list-item-title>
                                    <span>
                                        {{ getRoomName(room) }}
                                    </span>
                                    <span v-show="unreadMessagesCountByRoomId[room.id]">
                                        ({{ unreadMessagesCountByRoomId[room.id] }})
                                    </span>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    <span>
                                        {{ lastMessagesByRoomId(room.id) && lastMessagesByRoomId(room.id).content }}
                                    </span>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-list-item-action-text>
                                    <!-- {{ lastMessagesByRoomId[room.id] && lastMessagesByRoomId[room.id].timestamp }} -->
                                </v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>

                <v-list flat class="mt-4 px-4">
                    <v-subheader>{{ "chat_direct_messages" | translate }}</v-subheader>
                    <v-list-item-group color="primary">
                        <v-list-item @click="openDialogCreateDirectMessageRoom">
                            <v-list-item-icon>
                                <v-icon>fal fa-plus</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ "chat_add_chat" | translate }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                            v-for="room in directMessages"
                            :key="room.id"
                            :to="{ name: 'chat-room', params: { roomId: room.id } }"
                            class="my-2"
                            :class="{
                                'font-weight-black': !!unreadMessagesCountByRoomId[room.id],
                            }"
                        >
                            <v-list-item-content>
                                <v-list-item-title>
                                    <span>
                                        {{ getRoomName(room) }}
                                    </span>
                                    <span v-show="unreadMessagesCountByRoomId[room.id]">
                                        ({{ unreadMessagesCountByRoomId[room.id] }})
                                    </span>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    <span>
                                        {{ lastMessagesByRoomId(room.id) && lastMessagesByRoomId(room.id).content }}
                                    </span>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-list-item-action-text>
                                    <!-- {{ lastMessagesByRoomId[room.id] && lastMessagesByRoomId[room.id].timestamp }} -->
                                </v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-col>

            <v-col cols="8" sm="8" md="9" lg="9" xl="10">
                <chat-room
                    :key="$route.fullPath"
                    v-if="selectedRoomId"
                    :room-name="getRoomName(selectedRoom)"
                    :room-id="selectedRoomId"
                />
            </v-col>
        </v-row>

        <chat-create-channel-room v-model="dialogCreateChannelRoom" />
        <chat-create-direct-message-room v-model="dialogCreateDirectMessageRoom" />
    </v-card>
</template>

<script>
import ChatRoom from "./chat-room.vue";
import ChatCreateChannelRoom from "./chat-create-channel-room.vue";
import ChatCreateDirectMessageRoom from "./chat-create-direct-message-room.vue";

export default {
    components: {
        ChatRoom,
        ChatCreateChannelRoom,
        ChatCreateDirectMessageRoom,
    },

    mounted() {
        this.$store.dispatch("CHAT/LOAD_MY_CHATROOMS");
        this.$store.dispatch("CHAT/LOAD_UNREAD_MESSAGES_COUNT");
    },

    data() {
        return { message: "", dialogCreateChannelRoom: false, dialogCreateDirectMessageRoom: false };
    },

    computed: {
        selectedRoomId() {
            return this.$route.params.roomId;
        },
        selectedRoom() {
            return this.$store.state.CHAT.roomsById[this.selectedRoomId];
        },
        rooms() {
            const rooms = Object.values(this.$store.state.CHAT.roomsById) || [];
            return rooms.sort((roomA, roomB) => String(roomA.name).localeCompare(String(roomB.name)));
        },
        unreadMessagesCountByRoomId() {
            return this.$store.state.CHAT.unreadMessagesCountByRoomId;
        },
        lastMessagesByRoomId() {
            return (roomId) => this.$store.getters["CHAT/lastMessagesByRoomId"](roomId);
        },
        channels() {
            return this.rooms.filter((room) => room.privateCommunication === false);
        },
        directMessages() {
            return this.rooms.filter((room) => room.privateCommunication === true);
        },
    },
    methods: {
        openDialogCreateChannelRoom() {
            this.dialogCreateChannelRoom = true;
        },
        openDialogCreateDirectMessageRoom() {
            this.dialogCreateDirectMessageRoom = true;
        },
        getRoomName(room) {
            if (!room) {
                return "";
            }

            if (room.privateCommunication === true) {
                const { recipients } = room || {};
                const filtered = (recipients || []).filter(
                    (recipient) => recipient.id !== this.$store.state.user.personId
                );
                const { name } = filtered.pop() || {};
                return name || "";
            } else {
                return room ? room.name : "";
            }
        },
    },
};
</script>

<style></style>
