/*
 * Global Components Register
 */

import Vue from 'vue';
import Bookmark from './bookmark.vue'

import AppComponent from './app.vue';

Vue.component('bookmark', Bookmark);

Vue.directive('custom-click-outside', {
    bind: function (element, binding, vnode) {
        element.clickOutsideEvent = function (event) {
            if (!(element == event.target || element.contains(event.target))) {
                if (vnode.context && vnode.context[binding.expression]) {
                    vnode.context[binding.expression](event);
                }
            }
        };
        document.body.addEventListener('click', element.clickOutsideEvent)
    },
    unbind: function (element) {
        document.body.removeEventListener('click', element.clickOutsideEvent)
    },
});

export default AppComponent;