<template>
    <inv-object-tab-item :tab="tab" :tabIsLoading="tabIsLoading">
        <template v-if="expanded && tab" slot="header">
            <div class="inv-object-button-group">

                <v-tooltip top>
                    <template v-slot:activator="{on}">
                        <v-btn 
                            @click="expandAll = !expandAll" 
                            icon
                            v-on="on"
                        >
                            <v-icon>height</v-icon>
                        </v-btn>
                    </template>
                    <span>
                        {{'expand_collapse_connections'|translate}}
                    </span>
                </v-tooltip>    
                
                <v-tooltip top>
                    <template v-slot:activator="{on}">
                        <v-btn 
                            @click="loadConnections" 
                            icon
                            v-on="on"
                        >
                            <v-icon>fal fa-sync</v-icon>
                        </v-btn>
                    </template>
                    <span>
                        {{'reload'|translate}}
                    </span>
                </v-tooltip>    
                
            </div>
        </template>

        <template slot="body">
            <v-card flat :class="{ 'tab-body-disabled' : tabIsLoading }">
                <v-card-text style="padding: 0 5% 100px 5%; overflow: auto;">
                    <inv-conn-visual :segments="segments" :expanded="expandAll"></inv-conn-visual>
                </v-card-text>
            </v-card>
        </template>
    </inv-object-tab-item>
</template>

<script>
    import backend from 'obj-fe/app/backend';

    import tabBehaviour from './inv-object-tab-behaviour';
    import InvObjectTabItem from './inv-object-tab-item.vue';
    import './connection-visualisation';

    export default {
        mixins:[ tabBehaviour ],
        components:{
            InvObjectTabItem
        },
        data(){
            return {
                segments:[],
                expandAll:false
            };
        },
        methods:{
            onFirstTimeExpandLoad(successCb, errorCb){
                this.loadConnections(successCb, errorCb);
            },
            loadConnections(successCb, errorCb){
                backend.objects.visualisation(this.objectId, data => {
                    this.segments = data.segments;
                    successCb();
                }, errorCb);
            }
        }
    };
</script>