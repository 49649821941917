<template>
    <v-card flat>
		<v-card-title>
            <h2>{{'import_jobs' | translate}}</h2>
		</v-card-title>

        <v-navigation-drawer v-model="detail.opened" absolute right clipped :width="drawerWidth" z-index="1">
            <v-card v-if="detail.job" flat class="fill-height">
                <v-toolbar dense flat>
                    <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                            <v-btn 
                                outlined
                                color="error"
                                icon 
                                @click="detail.opened = false;" 
                                v-on="on"
                            >
                                <v-icon>fal fa-times</v-icon>
                            </v-btn>
                        </template>
                        <span>{{'close' | translate}}</span>
                    </v-tooltip>
                    <v-spacer/>
                    <v-btn
                        color="error" 
                        text 
                        v-if="detail.job.buttonDelete" 
                        @click="openDeleteConfirmModal(detail.job)"
                    >
                        {{'delete' | translate}}
                    </v-btn>
                    <v-btn 
                        color="success"
                        text 
                        v-if="detail.job.buttonConfirm" 
                        @click="openConfirmChangesModal(detail.job)"
                    >
                        {{'import_jobs_confirm_changes' | translate}}
                    </v-btn>
                </v-toolbar>

                <v-card-text style="padding-bottom:0px;height:calc(100% - 48px)">
                    <inv-import-job-detail :job="detail.job"/>
                </v-card-text>
            </v-card>
        </v-navigation-drawer>
        <obj-table :items="importJobs" full-height :active-item="detail.opened ? detail.jobId : null" @active-changed="openDetail">

            <template slot="headers">
                <th>{{'name' | translate }}</th>
                <th>{{'report' | translate }}</th>
                <th>{{'imported_excel_file' | translate }}</th>
                <th>{{'status' | translate }}</th>
                <th>{{'uploaded' | translate }}</th>
                <th>{{'uploaded_by' | translate }}</th>
                <th>{{'confirmed' | translate }}</th>
                <th>{{'imported' | translate }}</th>
            </template>

            <template slot="row" slot-scope="row">
                <td>
                    <v-icon v-if="row.value.jobFinished">fal fa-check-circle</v-icon>
                    <v-icon v-else>fal fa-hourglass</v-icon>
                    &nbsp;
                    {{row.value.name}}
                </td>
                <td>{{row.value.reportTitle}}</td>
                <td>{{row.value.fileName}}</td>
                <td :class="{'red--text': (row.value.status && row.value.status.includes('ERROR')),'indigo--text': row.value.status ==='APPROVED','green--text': row.value.status === 'IMPORTED'}">
                    {{row.value.statusDS}}
                </td>
                <td>{{row.value.created | datetime}}</td>
                <td>{{row.value.userDs}}</td>
                <td>{{row.value.approved | datetime}}</td>
                <td>{{row.value.imported | datetime}}</td>
            </template>
            
            <template slot="footer">
                <obj-table-pagination   :page="pagination.page" 
                                        :pages="pagination.pages" 
                                        :page-size="pagination.limit" 
                                        :total-count="pagination.count" 
                                        :next="pagination.next" 
                                        :prev="pagination.prev" 
                                        @prev="$store.dispatch('IMPORT_JOBS/UPDATE_QUERY', { page:pagination.page-1 })" 
                                        @next="$store.dispatch('IMPORT_JOBS/UPDATE_QUERY', { page:pagination.page+1 })"
                                        @page-size="v => $store.dispatch('IMPORT_JOBS/UPDATE_QUERY', { limit:v, page:1 })">
                </obj-table-pagination>
            </template>

            <template slot="no-data">
                {{'no_ongoing_import_jobs' | translate}}
            </template>
        </obj-table>

        <v-dialog 
            v-model="confirmChangesDialog.opened" 
            width="500px"
            content-class="small-dialog" 
        >
			<v-card flat>
				<v-card-title><h2>{{'import_jobs_confirm' | translate}}</h2></v-card-title>

				<v-card-text>
                    {{'import_jobs_confirm_import_changes' | translate}}
                </v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
                        text
                        color="error" 
                        @click="confirmChangesDialog.opened = false"
                    >
                        {{'cancel'|translate}}
                    </v-btn>
					<v-btn 
                        color="success" 
                        outlined
                        @click="confirmChanges(confirmChangesDialog.job)"
                    >
                        {{'confirm'|translate}}
                    </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

        <v-dialog 
            v-model="deleteConfirmDialog.opened" 
            width="500px"
            content-class="small-dialog" 
        >
			<v-card flat>
				<v-card-title><h2>{{'import_jobs_confirm_delete' | translate}}</h2></v-card-title>

				<v-card-text>
                    {{'import_jobs_are_you_sure' | translate}}
                </v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn 
                        text
                        @click="deleteConfirmDialog.opened = false"
                    >
                        {{'cancel'|translate}}
                    </v-btn>
					<v-btn 
                        color="error"
                        outlined
                        @click="deleteJob(deleteConfirmDialog.job)"
                    >
                        {{'delete'|translate}}
                    </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

	</v-card>
</template>

<script>
    import backend from 'obj-fe/app/backend';
    import notify from 'obj-fe/services/notifications';
    import InvImportJobDetail from './inv-import-job-detail.vue';

    export default {
        components:{
            InvImportJobDetail
        },
        data(){
            return {
                detail:{
                    opened: false,
                    jobId: null,
                    job: null
                },
                confirmChangesDialog:{
                    opened: false,
                    job: null
                },
                deleteConfirmDialog:{
                    opened: false,
                    job: null
                }
            };
        },
        computed:{
            importJobs(){
                let jobsById = this.$store.state.IMPORT_JOBS.jobsById;
                return this.$store.state.IMPORT_JOBS.jobIds.map(jobId => jobsById[jobId]);
            },
            drawerWidth() {
                return Math.min(window.innerWidth / 1.7, 1200);
            },
            pagination(){
                return this.$store.state.IMPORT_JOBS.pagination;
            },
            selectedJobId(){
                return this.$store.state.IMPORT_JOBS.selectedJobId
            }
        },
        watch:{
            selectedJobId:{
                immediate: true,
                handler(val){
                    if(!val) return;

                    let job = this.importJobs.filter(e => e.id === val).pop()
                    if(job) this.openDetail(job);
                }
            },
            detail:{
                deep: true,
                handler(val){
                    if(!val.opened) this.closeDetail()
                }
            }
        },
        methods:{
            closeDetail(){
                this.detail.opened = false;
                this.$store.commit('IMPORT_JOBS/DISPLAY_JOB', null);
                this.$router.push({
                    name: 'import-jobs'
                })
            },
            openDetail(job){
                if(!job) this.closeDetail() 
                else {
                    if(this.$route.params.jobId !== job.id) this.$router.push({
                        name: 'import-jobs-selected',
                        params: {
                            jobId: job.id
                        }
                    })
                    else{
                        this.$nextTick(() => {
                            this.detail.jobId = job.id;
                            this.detail.job = job;
                            this.detail.opened = true;
                            if(!job.changes) this.$store.dispatch('IMPORT_JOBS/LOAD_JOB_CHANGES', job.id);
                        })
                    }
                }
            },
            openConfirmChangesModal(job){
                this.confirmChangesDialog.job = job;
                this.confirmChangesDialog.opened = true;
            },
            openDeleteConfirmModal(job){
                this.deleteConfirmDialog.job = job;
                this.deleteConfirmDialog.opened = true;
            },
            confirmChanges(job){
                backend.importJobs.confirm(job.id, data => {
                    // 'Identified changes were confirmed, you will be notified on your email '+response.email+' after importing'
                    notify.success('changes_confirmed', this.$options.filters.translate('you_will_be_notified_on_email') + ' "'+data.email+'"');
                    this.detail.opened = false;
                    this.confirmChangesDialog.opened = false;
                    this.$store.dispatch('IMPORT_JOBS/LOAD_JOBS');
                });
            },
            deleteJob(job){
                this.$store.dispatch('IMPORT_JOBS/REMOVE_JOB', job.id);
                this.detail.opened = false;
                this.deleteConfirmDialog.opened = false;
            }
        }
    };
</script>