<template>
    <div v-if="job.changes" class="fill-height">
        <v-alert v-if="job.errorMsg" type="error">
            <div v-html="job.errorMsg"></div>
        </v-alert>

        <!-- TODO: decide if repeating job props is needed in this detail -->
        <!-- <div>
            <v-text-field :label="'report' | translate" :value="job.reportTitle" readonly/>
            <v-text-field :label="'imported_excel_file' | translate" :value="job.fileName" readonly/>
            <v-text-field :label="'status' | translate" :value="job.statusDS" readonly/>
            <v-text-field :label="'uploaded' | translate" :value="job.created | datetime" readonly/>
            <v-text-field :label="'uploaded_by' | translate" :value="job.userDs" readonly/>
            <v-text-field :label="'confirmed' | translate" :value="job.approved | datetime" readonly/>
            <v-text-field :label="'imported' | translate" :value="job.imported | datetime" readonly/>
        </div> -->

        <obj-table :items="job.changes.list" full-height>

            <template slot="headers">
                <th>{{'import_id' | translate}}</th>
                <th>{{'name' | translate}}</th>
                <th v-for="(col,i) in job.changes.headers" :key="i">{{col.title}}</th>
            </template>

            <template slot="row" slot-scope="row">
                <td><strong>{{row.value.importId}}</strong></td>
                <td><strong>{{row.value.name}}</strong></td>
                <td v-for="(col,i) in row.value.columns" :key="i">
                    <span v-if="col && col.oldValue" style="text-decoration:line-through;color:#808080">{{col.oldValue}}<br/></span>
                    <span v-if="col && col.newValue" >{{col.newValue}}</span>
                </td>
            </template>
            
            <template slot="footer">
                <obj-table-pagination   :page="job.changes.pagination.page" 
                                        :pages="job.changes.pagination.pages" 
                                        :page-size="job.changes.pagination.limit" 
                                        :total-count="job.changes.pagination.count" 
                                        :next="job.changes.pagination.next" 
                                        :prev="job.changes.pagination.prev" 
                                        @prev="$store.dispatch('IMPORT_JOBS/UPDATE_JOB_CHANGES_QUERY', { jobId:job.id, page:job.changes.pagination.page-1 })" 
                                        @next="$store.dispatch('IMPORT_JOBS/UPDATE_JOB_CHANGES_QUERY', { jobId:job.id, page:job.changes.pagination.page+1 })"
                                        @page-size="v => $store.dispatch('IMPORT_JOBS/UPDATE_JOB_CHANGES_QUERY', { jobId:job.id, limit:v, page:1 })">
                </obj-table-pagination>
            </template>

            <template slot="no-data">
                {{'no_changes_identified' | translate}}
            </template>
        </obj-table>

    </div>
</template>


<script>
    export default {
        props:{
            job:{}
        },
        data(){
            return {};
        }
    }
</script>