import store from 'obj-fe/app/store';
import backend from 'obj-fe/app/backend';


store.registerModule('EMDSL', {
    namespaced: true,
    state:{
        contextTemplates: [],
        contextlessTemplates: []
    },
    actions:{
        LOAD_CONTEXT_TEMPLATES(ctx, emdslContextObject){

            let id, snapshotTimestamp;
            if(backend.defaultHeaders.snapshotTimestamp && store.state.settings.useHistorization){
                snapshotTimestamp = backend.defaultHeaders.snapshotTimestamp;
                delete backend.defaultHeaders.snapshotTimestamp;
            } 
            
            if( emdslContextObject.route.name === 'inventory-object' && 
                emdslContextObject.route.params && 
                emdslContextObject.route.params.id
            ) 
                id = emdslContextObject.route.params.id;  
    
            if(emdslContextObject.objectContext && id) backend.dsl.emdslTemplatesWithContext(
                { globalId: id },
                templates => ctx.commit('SET_CONTEXT_TEMPLATES', templates)
            );

            if(store.state.settings.useHistorization) backend.defaultHeaders.snapshotTimestamp = snapshotTimestamp;
        },
        LOAD_CONTEXTLESS_TEMPLATES(ctx){

            let snapshotTimestamp;
            if(backend.defaultHeaders.snapshotTimestamp && store.state.settings.useHistorization){
                snapshotTimestamp = backend.defaultHeaders.snapshotTimestamp;
                delete backend.defaultHeaders.snapshotTimestamp;
            } 
            
            backend.dsl.emdslTemplatesWithoutContext(
                templates => ctx.commit('SET_CONTEXTLESS_TEMPLATES', templates)
            );

            if(store.state.settings.useHistorization) backend.defaultHeaders.snapshotTimestamp = snapshotTimestamp;
        },
    },
    mutations:{
        SET_CONTEXTLESS_TEMPLATES(state, templates){
            state.contextlessTemplates = templates;
        },
        SET_CONTEXT_TEMPLATES(state, templates){
            state.contextTemplates = templates;
        },
    }
});