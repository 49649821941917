<template>
    <inv-object-tab-item :tab="tab" :tabIsLoading="tabIsLoading" :height="height">
        <template v-if="expanded && tab && description && description.additionalFilters" slot="header-space">
            <!-- filters -->
                <obj-report-filter  
                    v-for="(filter, i) in description.additionalFilters"
                    :key="i"
                    :field="filter" 
                    :value="query.filter ? query.filter[filter.columnName] : undefined" 
                    @input="(v, valueTexts) => $refs.report.setFilterValue(filter.columnName, v, valueTexts)" 
                    :lazy-change="!description.manualTriggerLoad" 
                    :change-debounce="description.manualTriggerLoad ? 400 : 0" 
                    @filters-reloaded="shouldReloadFilters = false"
                    :reloadFilters="shouldReloadFilters"
                    clearable
                />
        </template>

        <template v-if="expanded && tab" slot="header">


            <!-- IHI DSL - specific buttons -->
            <div 
                v-if="tab.parameters.dslEditable" 
                class="inv-object-button-group" 
            >
                
                <v-tooltip top>
                    <template v-slot:activator="{on}">
                        <v-btn 
                            v-if="tab.parameters.dslEditable" 
                            @click="editing = !editing" 
                            icon
                            v-on="on" 
                            color="accent"
                        >
                            <v-icon>fal fa-user-cog</v-icon>
                        </v-btn>
                    </template>
                    <span>
                        {{editing ? 'back_to_grid' : 'configure_grid' | translate}}
                    </span>
                </v-tooltip>
                
                <v-tooltip top>
                    <template v-slot:activator="{on}">
                        <v-btn 
                            v-if="editing" 
                            @click="save" 
                            :disabled="!editorContentIsValid" 
                            icon
                            v-on="on"
                            color="accent"
                        >
                            <v-icon>fal fa-save</v-icon>
                        </v-btn>
                    </template>
                    <span>
                        {{'save' | translate}}
                    </span>
                </v-tooltip>
            
            </div>

            <inv-custom-report-buttons
                :buttons="buttons"
                :icon-size="'default'"
                @clicked="button => reportActionButton = { ...button }"
            />

            <!-- excel buttons  -->
            <v-menu
                content-class="button-menu"
                offset-y
                :nudge-width="200"
                offset-x
                v-if="description && (
                    description.manualTriggerLoad || 
                    description.exportImport ||
                    description.excelEnabled
                )"
            >
                <template v-slot:activator="{ on: menu }">
                    <v-tooltip top>
                        <template v-slot:activator="{on: tooltip}">
                            <v-divider v-if="description.buttons" vertical/>

                            <v-btn
                                v-on="{...tooltip, ...menu}"
                                class="expand-actions-button obj-report-button"
                                icon
                                color="green"
                            >
                                <v-icon>
                                    fal fa-file-spreadsheet
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>
                            {{ "import-export-data" | translate }}
                        </span>
                    </v-tooltip>
                </template>

                <v-card>
                    <v-card-text>
                        <div class="inv-object-button-group inv-object-button-group--column-oriented">
                            <obj-report-btn-load :report-id="tab.parameters.dataSource" :displayBlock="true"></obj-report-btn-load>
                            <obj-report-btn-export-excel :report-id="tab.parameters.dataSource" :displayBlock="true"></obj-report-btn-export-excel>
                            <obj-report-btn-export-send :report-id="tab.parameters.dataSource" :displayBlock="true"></obj-report-btn-export-send>
                            <!-- export-schedule should not be present in object tab context -->
                            <!-- <obj-report-btn-export-schedule :report-id="tab.parameters.dataSource" :displayBlock="true"></obj-report-btn-export-schedule> -->
                            <v-divider class="my-2" v-if="description.exportImport"/>
                            <obj-report-btn-import-data :report-id="tab.parameters.dataSource" :displayBlock="true" :color="'info'"></obj-report-btn-import-data>
                            <obj-report-btn-export-data :report-id="tab.parameters.dataSource" :displayBlock="true" :color="'info'"></obj-report-btn-export-data>
                        </div>
                    </v-card-text>
                </v-card>
            </v-menu>
            
            <!-- add new button -->
            <v-menu
                v-model="addNewButtonModel"
                content-class="button-menu"
                offset-y
                :nudge-width="200"
                offset-x
                v-if="description && ((description.entityDetailTypes && description.entityDetailTypes.length > 0) || description.canCreate)"
            >
                <template v-slot:activator="{ on: menu }">
                    <v-tooltip top>
                        <template v-slot:activator="{on: tooltip}">
                            <v-divider vertical/>

                            <v-btn
                                v-on="{...tooltip, ...menu}"
                                class="expand-actions-button obj-report-button"
                                icon
                                color="accent"
                            >
                                <v-icon>
                                    fal fa-plus
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>
                            {{ "add_new" | translate }}…
                        </span>
                    </v-tooltip>
                </template>

                <v-card>
                    <v-card-text>
                        <obj-report-btn-create :report-id="tab.parameters.dataSource" :displayBlock="true"></obj-report-btn-create>
                        <obj-report-btn-menu :report-id="tab.parameters.dataSource" @add-new-entity="(type) => {$emit('add-new-entity', type); addNewButtonModel = false}" :displayBlock="true"></obj-report-btn-menu>
                    </v-card-text>
                </v-card>
            </v-menu>

            <!-- save changes button  -->
            <v-tooltip top v-if="!disableCRUD">
                <template v-slot:activator="{on}">
                    <v-btn 
                        v-on="on"
                        :disabled="!hasDirtyFields || !isValid" 
                        :outlined="hasDirtyFields && isValid" 
                        :elevation="(hasDirtyFields && isValid) ? 24 : 0" 
                        color="success"
                        @click="saveReport(reportId)" 
                        icon
                    >
                        <v-icon>fal fa-save</v-icon>
                    </v-btn>
                </template>
                <span>
                    {{'save' | translate}}
                </span>                            
            </v-tooltip>

        </template>

        <template slot="body">
            <component 
                :is="useSpreadsheetReport ? 'inv-spreadsheet-report' : 'obj-report'"
                ref="report" 
                fill-parent-height 
                v-if="!editing && reportServicesInited" 
                load-immediately 
                :report-id="tab.parameters.dataSource" 
                :readonly="readonly" 
                :value="query"
                :class="{ 'tab-body-disabled' : tabIsLoading }"
                :reload-filters="shouldReloadFilters"
                @input="onQueryChange" 
                @be-button-clicked="button => reportActionButton = button"
                @open-detail="openDetail"
                @is-dirty="isDirty = $event; $emit('is-dirty', $event)"
                @filters-reloaded="shouldReloadFilters = false">

                <template slot="addNewEntityDrawer">
                    <v-navigation-drawer 
                        v-model="detail.opened" 
                        absolute 
                        right 
                        clipped 
                        :width="drawerWidth" 
                        z-index="1"
                        style="right:-8px"
                    >
                        <inv-object 
                            v-if="detail.objectId" 
                            :id="detail.objectId" 
                            @close="detail.opened = false" 
                            @created="newEntityCreated" 
                            @updated="attemptReload"
                            enable-delete 
                            @delete="removeObject"
                            @forceDelete="removeObject"
                        />
                    </v-navigation-drawer>
                </template>

            </component>

            <inv-report-actions
                v-if="!editing && reportServicesInited" 
                :button="reportActionButton"
                @closed="reportActionButton = null"
                @grid-reload-needed="attemptReload"
                @resource-reload-needed="attemptReload();$emit('resource-reload-needed')"
            />
            <IhiDslInitiator 
                :active="editing"
                :oldIhiDslCode="ihiDslCode"
                :previewReportId="previewReportId"
                :objectId="this.tab.parameters.globalId || this.objectId"
                :reportId="this.tab.parameters.dslSourceId"
                @closed="editing = false"
            />
        </template>
    </inv-object-tab-item>
</template>

<style scoped>
    >>> .obj-table thead > tr:first-child th {
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
        background-color: #fff;
        color: inherit;
        font-weight: normal;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }

    >>> .obj-table thead > tr:first-child th .v-icon {
        color: inherit;
    }
    .inv-object-tab-graphviz-container{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
    }
    hr{
        margin-left: 8px;
    }
    


</style>

<script>

    import object from 'obj-fe/utils/object';
    import backend from 'obj-fe/app/backend';
    import notify from 'obj-fe/services/notifications';
    import tabBehaviour from './inv-object-tab-behaviour';
    
    import IhiDslInitiator from '../../dsl/ihidsl/ihidsl-modal-component.vue'
    import InvIhiDslEditor from '../../dsl/ihidsl/inv-ihidsl-editor.vue';
    import InvObjectTabItem from './inv-object-tab-item.vue';
    import InvObjectForm from '../inv-object-form.vue';
    import InvCustomReportActions from '../../custom-reports/inv-report-actions.vue'
    import InvCustomReportButtons from '../../custom-reports/inv-custom-report-buttons.vue'

    import InvSpreadsheetReport from '../../custom-reports/inv-spreadsheet-report.vue';

    export default {
        mixins:[ tabBehaviour ],
        components:{
            InvObjectTabItem,
            InvObjectForm,
            InvIhiDslEditor,
            InvCustomReportButtons,
            InvCustomReportActions,
            IhiDslInitiator,
            InvSpreadsheetReport
        },
        props: {
            shouldReload: Boolean,
            disableCRUD: Boolean,
            height:{}
        },
        data(){
            return {
                // report specific data
                reportServicesInited: false,
                editing: false,
                // be-modals specific data
                reportActionButton: null,
                reportModals: [],


                // TODO: event up from IhiDslInitiator
                editorContentIsValid: false,
                ihiDslCode: '',

                previewReportId: null,

                isDirty: false,

                detail:{
                    opened: false,
                    objectId: null
                },

                addNewButtonModel: false,
                shouldReloadFilters: false,

                query: {},
            };
        },
        methods:{
            onFirstTimeExpandLoad(successCb, errorCb){
                // compose query
                if(this.$route.query.panels){
                    let panels = JSON.parse(this.$route.query.panels);
                    let query = panels[this.tab.parameters.dataSource];
                    // console.log('onFirstTimeExpandLoad', panels, query);
                    this.query = query;
                }

                this.setupReportConfig(successCb, errorCb);
            },
            setupReportConfig(successCb, errorCb){
                let objectId = this.tab.parameters.globalId || this.objectId;
                let reportId = this.tab.parameters.dataSource;
                let reportDataModifier = this.tab.parameters.reportDataModifier;
                let app = this;

                this.$store.dispatch('REPORTS/SET_REPORT_BACKEND_SERVICES', {
                    reportId,
                    loadData(reportId, query, cb){
                        app.tabIsLoading = true;
                        let cbWithSuccessCb = function(args){
                            cb(args);
                            successCb();
                        }
                        if(reportDataModifier){
                            backend.objects.reportDataWithModifier({ 
                                    objectId: query.parentObjectId || objectId , 
                                    reportId, 
                                    reportDataModifier
                                }, 
                                query, 
                                cbWithSuccessCb, 
                                errorCb
                            );    
                        }
                        else {
                            backend.objects.reportData({ 
                                    objectId: query.parentObjectId || objectId , 
                                    reportId
                                }, 
                                query, 
                                cbWithSuccessCb, 
                                errorCb
                            );
                        } 
                    },
                    save(reportId, data, cb){
                        backend.objects.saveReport({ objectId , reportId }, data, cb);
                    },
                    description(reportId, cb){
                        if(reportDataModifier){
                            backend.objects.reportDescriptionWithModifier({ objectId , reportId, reportDataModifier}, cb);
                        }
                        else{
                            backend.objects.reportDescription({ objectId , reportId }, cb);
                        }
                    },
                    enumLoaderUrl(reportId, columnName){
                        return '/rest/invObjs/grid/' + reportId + '/distinct/' + objectId + '/' + columnName;
                    },
                    exportExcel(reportId, data, cb){
                        backend.objects.exportExcel({ objectId , reportId }, data, cb);
                    },
                    exportSend(reportId, data, cb){
                        backend.objects.exportExcelAsync({ objectId , reportId }, data, cb);
                    },
                    exportData(reportId, data, cb){
                        backend.objects.exportDataAsync({ objectId , reportId }, data, cb);
                    },
                    importData(reportId, data, cb){
                        backend.objects.importDataAsync({ objectId , reportId }, data, cb);
                    },
                    rowTemplate(reportId, cb){
                        backend.reports.rowTemplate(reportId, fields => {
                            // overriding default editorValuesUrl, because '{id}' must be objectId of object page in which report tab is rendered
                            cb(fields.map(field => {
                                if(field.editorValuesUrl) field.editorValuesUrl = field.editorValuesUrl.replace('{id}', objectId)
                                return field
                            }))
                        })
                    }
                });

                this.reportServicesInited = true;

            },
            attemptReload(){
                const
                    reportId = this.tab.parameters.dataSource,
                    reportService = this.$store.state.REPORTS.reportServicesById[reportId],
                    customReportDescriptionService = reportService?.description,
                    customReportLoadDataService = reportService?.loadData;

                // backend report buttons may have changed. 
                // backend report buttons are a part of object details.
                // hence reloading object details is needed.
                this.$store.dispatch('INVENTORY/LOAD_OBJECT_DETAILS', {objectId: this.tab.parameters.globalId || this.objectId , changeHeadTitle: false})
                
                // we registered services in setupReportConfig and so we make use of them
                if(
                    !!customReportDescriptionService && 
                    !!customReportLoadDataService
                ) {
                    customReportDescriptionService(reportId, (data)=>{
                        this.$store.commit('REPORTS/SET_REPORT_DESCRIPTION', { reportId, description: data });
                        this.$store.dispatch('REPORTS/LOAD_REPORT_DATA', reportId)
                    })
                }
            },
            saveReport(reportId){
                this.$store.dispatch('REPORTS/SAVE_REPORT', reportId)
                this.shouldReloadFilters = true;
            },
            save(){
                backend.customGrids.updateGrid(
                    {customGridId: this.tab.parameters.dslSourceId }, { src:this.ihiDslCode, data: this.ihiParserResult.value.data }, 
                    data => {
                        notify.success('saved')
                        this.attemptReload();
                        this.changesSaved = true;
                        this.ihiDslCodeDirty = false;
                    },
                    err => notify.error('saving_grid_error')
                );
            },
            newEntityCreated(newObjectId){
                this.detail.objectId = null;
                // fields in object might have not gotten destroyed
                setTimeout(()=>{
                    this.detail.objectId = newObjectId;
                    this.attemptReload();
                }, 100);
            },
            removeObject(){
                this.attemptReload();
                this.detail.opened = false;
            },
            openDetail(rowData){
                if(!rowData) this.detail.opened = false;
                else {
                    this.detail.objectId = null;
                    this.$nextTick(() => {
                        this.detail.objectId = rowData.id;
                        this.detail.opened = true;
                        this.$store.dispatch('INVENTORY/LOAD_OBJECT_DETAILS', {objectId: this.detail.objectId , changeHeadTitle: false});
                    })
                }
            },
            addNew(type){
                this.$store.dispatch('COLLECTIONS/ADD_NEW', type).then(newObject => {
                    this.detail.objectId = newObject.id;
                    this.detail.opened = true;
                });
            },
            onQueryChange(query){
                this.query = query;
                // console.log('query change', this.$route);
                this.$emit('state-change', query)
            }
        },
        computed:{
            useSpreadsheetReport() {
                return this.$store.state.settings.useSpreadsheetReport;
            },
            reportId(){
                return this.tab.parameters.dataSource;
            },
            reportState(){
                return this.$store.state.REPORTS.reportsById[ this.reportId ] || {};
            },
            isValid(){
                return this.reportState.valid;
            },
            hasDirtyFields(){
                return Object.keys(this.reportState.removedRows || {}).length > 0 || Object.keys(this.reportState.form || {}).length > 0;
            },
            changes(){
                return {
                    removedRows: Object.keys(this.reportState.removedRows || {}),
                    fields: Object.entries(this.reportState.form || {}).map(([key, value]) => value)
                };
            },
            createTypes(){
                return this.description.entityDetailTypes || []
                    .filter(e=>!e.abstract)
            },
            description(){
                return this.reportState.description || {};
            },
            ihiDslAstData(){
                return this.ihiParserResult.value.data;
            },
            drawerWidth() {
                return Math.min(window.innerWidth / 1.5, 1600);
            },
            buttons() {
                if(!this.description || !this.description.buttons) return [];
                return this.description.buttons;
            },
        },
        watch:{
            expanded: function(val){
                // if(!val) console.log(this.$route.query);
                window.dispatchEvent(new CustomEvent('resize'));
            },
            ihiDslCode: {
                deep: true,
                handler: function(newVal, oldVal){
                    if(this.firstTimeLoaded) {
                        this.firstTimeLoaded = false;
                    }
                    else{
                        this.changesSaved = false;
                        this.ihiDslCodeDirty = true;  
                    }
                }
            },
            editing: function(){
                if(this.editing){
                    backend.customGrids.withPreview(
                        { customGridId: this.tab.parameters.dslSourceId },
                        data => {
                            this.ihiDslCode = data.src;
                            this.previewReportId = data.previewId;
                        },
                        error => console.error(error)
                    )
                }
                else{
                    this.attemptReload();
                }
            },
            shouldReload()  {
                this.shouldReload ? this.attemptReload() : null;
            },
            changes(changes){
                this.$emit('data-change', changes);
            }
        }
    };
</script>