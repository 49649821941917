<template>
    <v-app>
        <!-- vue app -->
        <v-navigation-drawer 
            v-if="loggedIn"
            class="celine-drawer"
            color="primary ligthen-5"
            :mini-variant="isDrawerCollapsed"
            permanent
            fixed
        >

            <div class="main-menu" :style="{ top: toolbarMenuHeight + 'px'}" v-custom-click-outside="collapseDrawer" @click="collapseDrawer">
                <div>
                    <obj-menu class="mt-3" :no-legend="true" :is-closed="isDrawerCollapsed">

                        <!-- CHAT ITEM -->
                        <template
                            v-slot:chat 
                            v-if="useChat"
                        >
                            <v-list-item
                                :to="{
                                    name: 'chat',
                                }"
                                :class="{
                                    'font-weight-black': $store.state.CHAT.unreadMessagesCount > 0,
                                }"
                            >
                                <v-list-item-icon class="pl-1">
                                    <v-badge :value="$store.state.CHAT.unreadMessagesCount > 0" overlap dot color="red">
                                        <v-icon>fal fa-comments</v-icon>
                                    </v-badge>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <span>
                                            {{ "chat" | translate }}
                                        </span>
                                        <span v-if="$store.state.CHAT.unreadMessagesCount > 99" class="font-weight-black">
                                            (99+)
                                        </span>
                                        <span v-else-if="$store.state.CHAT.unreadMessagesCount > 0" class="font-weight-black">
                                            ({{ $store.state.CHAT.unreadMessagesCount }})
                                        </span>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>

                    </obj-menu>

                    <emdsl-initiator v-if="useEmdsl && userIsAdmin" :isDrawerCollapsed="isDrawerCollapsed" :objectContext="false"/>
                </div>
            </div>
        </v-navigation-drawer>

        <v-app-bar
            id="main-toolbar"
            :clipped-left="$vuetify.breakpoint.lgAndUp" 
            :clipped-right="$vuetify.breakpoint.lgAndUp" 
            v-if="toolbarVisible" 
            app 
            color="primary"
            dark
        >
            <v-app-bar-nav-icon @click.stop="isDrawerCollapsed = !isDrawerCollapsed"/>
            <v-toolbar-title><span class="hidden-sm-and-down">{{appName}}</span></v-toolbar-title>
            <v-progress-linear
                :active="isLoading"
                :indeterminate="isLoading"
                bottom
                absolute
                color="accent"
                :data-test="'loading-indicator-' + isLoading"
            ></v-progress-linear>

            <v-spacer/>

            <inv-search-component :interractWithObjectsStore="$route.name === 'inventory-search'"/>

            <v-spacer/>
            <obj-current-user/>

        </v-app-bar>
        
        <v-content style="padding-left: 56px">
            <div class="container pa-0 container--fluid">
                <router-view/>
            </div>
        </v-content>

        <!-- login dialog -->
        <v-dialog :value="!loggedIn" width="500" persistent>
            <v-card flat>
                <v-card-text>
                    <obj-login :app-name="appName"/>
                </v-card-text>
            </v-card>
        </v-dialog>

        <inv-wizard></inv-wizard>

        <!-- notifications -->
        <obj-notifications></obj-notifications>

        <div class="loading-overflow" v-if="isLoading"/>
    </v-app>
</template>

<script>
    import loading from 'obj-fe/services/loading';
    import invSearchComponent from '../inventory/obj-search-component.vue';
    import emdslInitiator from '../dsl/emdsl/emdsl-initiator/emdsl-initiator.vue';
    import InvWizard from '../wizard/inv-wizard.vue';

    export default {
        components:{
            invSearchComponent,
            emdslInitiator,
            InvWizard
        },
        data(){
            return {
                isDrawerCollapsed: true,
                toolbarMenuHeight: 0,
                isLoading: false,
            };
        },
        computed:{
            loggedIn(){
                return this.$store.state.auth.loggedIn;
            },
            headTitle(){
                return this.$store.state.layout.headTitle;
            },
            drawerHeight(){
                return window.innerHeight - 64;
            },
            toolbarVisible(){
                return this.$store.state.layout.toolbar;
            },
            appName(){
                return this.$store.state.appName;
            },
            useEmdsl(){
                return this.$store.state.settings.useEmdsl;
            },
            useChat(){
                return this.$store.state.settings.chatEnabled;
            },
            userIsAdmin(){
                let user = this.$store.state.user || {}

                if(
                    !user ||
                    !user.roles ||
                    !user.roles.AdminPermission
                ) return false;

                return this.$store.state.user.roles.AdminPermission
            },
            shouldShowEmdsl() {
                const
                    user = this.$store.state.user || {},
                    userPermissions = user.mainMenu,
                    hasEmdslPermission = !!userPermissions && userPermissions.includes("INVENTORY_MANAGEMENT");
                    
                return userPermissions ? hasEmdslPermission : (this.useEmdsl && this.userIsAdmin);
            },
            
        },
        watch:{
            headTitle: {
                handler: function(){
                    let title = this.headTitle;

                    if(title && title !== '') window.document.querySelector('head > title').innerHTML = title;
                    else window.document.querySelector('head > title').innerHTML = this.appName;
                }
            }
        },
        mounted() {
            this.toolbarMenuHeight = this.$refs.refToolbarMenu ? this.$refs.refToolbarMenu.$el.clientHeight : 0;

            // implement loading showing
            loading.subscribe(state => this.isLoading = state.active);
        },
        methods: {
            collapseDrawer(){
                this.isDrawerCollapsed = true;
            }
        }
    }
</script>

<style scoped>
    .loading-overflow {
        cursor: not-allowed;
        position: fixed;
        z-index: 9999;
        background-color:black;
        opacity: 0;
        top:0px;
        bottom:0px;
        left:0px;
        right:0px;
    }

    #main-toolbar{
        left: 0 !important;
    }
    .celine-drawer{
        top: 64px !important;
        box-shadow: none;
        z-index: 4;
    }
    .celine-drawer.v-navigation-drawer--is-mouseover{
        box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 12%);
    }
</style>