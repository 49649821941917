<template>
    <v-card class="d-flex justify-center align-center flex-column" v-if="loading">
        <v-card-title>{{"generating_print" | translate}}</v-card-title>
    </v-card>
    <v-card v-else-if="printHtml" flat>
        <v-card-actions>
            <v-alert
                dense
                outlined
                dismissible
            >
                {{"print_help" | translate}}
                <v-btn
                    color="accent"
                    icon
                    @click="printPage"
                >
                    <v-icon>fal fa-print</v-icon>
                </v-btn>
            </v-alert>
        </v-card-actions>
        
        <div
            class="print-div" 
            v-html="printHtml"
        />

    </v-card>
    <v-card v-else class="d-flex justify-center align-center flex-column" flat>
        <v-card-title>
            <h3>{{'not_found_page' | translate}}</h3>
        </v-card-title>
        <v-card-actions>
            <v-btn text color="accent" @click="$router.push({name: 'inventory-search'})">
                {{'navigate_home' | translate}}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    computed:{
        printHtml: function(){
            return this.$store.state.PRINT.printTemplate;
        },
        loading: function(){
            return this.$store.state.PRINT.loading;
        }
    },
    methods:{
        printPage(){
            window.print();
        }
    }
}
</script>

<style>
    .print-div{
        width: 100%;
        padding: 10px;
    }
    .print-div h1{
        margin-bottom: 20px;
    }
    .print-div table{
        width: 100%;
    }

    @media print {
        header, aside, .v-alert{
            display: none !important;
        }
    }
</style>