import CodeMirror from 'codemirror';
import 'codemirror/lib/codemirror.css';

import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/lint.css';

import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/hint/show-hint.css';
import './custom-cm-css.css';

import 'codemirror/addon/fold/foldcode';
import 'codemirror/addon/fold/brace-fold';
import 'codemirror/addon/fold/foldgutter';
import 'codemirror/addon/fold/foldgutter.css';

import object from 'obj-fe/utils/object';
import IhiDslParser from './ihidsl-parser/ihidsl-parser.js';

import locals from 'obj-fe/services/localisation';

const modeName = 'inv-ihidsl-mode';

CodeMirror.defineMode(modeName, function(cmConfig, modeConfig) {

    return {
        token(stream, state) {

            let streamPosTypes = modeConfig.getParsedResultValue().tokenBoundaries; // createStreamPosTypes(modeConfig.getParsedResultValue());

            // switch type when stream is on the edge of types
            let linePosTypes = streamPosTypes[ stream.lineOracle.line ];
            let posType = linePosTypes ? linePosTypes[ stream.pos ] : undefined;

            state.type = posType === undefined ? state.type : posType;
            stream.next();

            // codemirror is ignoring new line chars
            // if stream standing on end of line and type is ending here, we need to reset state.type for next line
            if(stream.eol()){
                linePosTypes = streamPosTypes[ stream.lineOracle.line ];
                let nextPosType = linePosTypes ? linePosTypes[ stream.pos ] : undefined;
                if(nextPosType === null) {
                    let prevType = state.type;
                    state.type = null;
                    return prevType;
                }
            }

            return state.type;
        },
        startState() {
            return {
                type: null
            };
        }
    };
});

CodeMirror.registerHelper('lint', modeName, function(text, cb, opts, cm) {
    cm.parsedResult.onValidated(() => {
        if(!cm.parsedResult) return cb([]);

        if(cm.parsedResult.isValid) {
            let parseWarnings = cm.parsedResult.parseErrors
                .map((err)=>prepareError(err, text));
            return cb(parseWarnings);
        } 

        let lexErrors = cm.parsedResult.lexErrors.map(err => {
            return {
                severity: 'error',
                from: CodeMirror.Pos(err.line - 1, err.column - 1),
                to: CodeMirror.Pos(err.line - 1, err.length + err.column - 1),
                message: locals.translate(err.message)
            };
        });
    
        let parseErrors = cm.parsedResult.parseErrors.map(err => prepareError(err, text));
    
        cb(lexErrors.concat(parseErrors));
    });
});



export default {
    init
};

function init(cm, parserOpts){

    cm.ihiDslParser = new IhiDslParser(parserOpts);

    // TODO: trigger first time change callback sync, then async debounced
    cm.on('change', () => {
        let value = cm.getValue();
        cm.parsedResult = cm.ihiDslParser.parse(value);
    });

    // immediatelly parse query value
    cm.parsedResult = cm.ihiDslParser.parse(cm.getValue());

    cm.setOption('mode', { // will re-initialize the mode
        name: modeName,
        getParsedResultValue() {
            return cm.parsedResult ? cm.parsedResult.value : [];
        }
    });

    cm.setOption('lint', {
        delay: 150,
        async: true
    });

    // hint
    cm.setOption('hintOptions', {
        hint: autocompleteWrapper,
        completeSingle: false
    });

    cm.setOption('extraKeys', {
        'Ctrl-Space': 'autocomplete',
        'Ctrl-/': comment
    });

    cm.setOption('foldGutter', {
        rangeFinder: CodeMirror.fold.brace
    });

    cm.setOption('gutters', ['CodeMirror-linenumbers', 'CodeMirror-foldgutter']);

    let showAutocompletions = object.debounce(function(cm) {
        let cursor = cm.getCursor();
        let lineText = cm.getLine(cursor.line);
        let cursorIsAtLineStart = !!lineText.slice(0, cursor.ch).match(/^\s*$/);

        if(!cursorIsAtLineStart) cm.showHint(cm);
    }, 150);

    let hintHandlersRegistered = false;
    function registerHintEventHandlers(){
        if(hintHandlersRegistered) return;
        hintHandlersRegistered = true;
        setTimeout(() => {
            // cm.on('cursorActivity', showAutocompletions);
            // cm.on('focus', showAutocompletions);
            // if(cm.hasFocus()) showAutocompletions(cm);
        });
    }
    
    // cm.on('focus', registerHintEventHandlers);
}
async function autocompleteWrapper(cm){
    let result = await autocomplete(cm);
    if(!result) return;
    CodeMirror.on(result, 'pick', function(completion){
        if(completion.type !== 'ALIAS-BE' ) return;

        let codeValue = cm.getDoc().getValue();
        let splitText = codeValue.split('\n');
        let definitionsLineIndex = 0;
        
        
        // find 'definitions'
        while(definitionsLineIndex < splitText.length){
            if(splitText[definitionsLineIndex].includes('definitions')) break;
            else definitionsLineIndex++;
        }
        // find '{' after 'definitions'
        while(definitionsLineIndex < splitText.length){
            if(splitText[definitionsLineIndex].includes('{')) break;
            else definitionsLineIndex++;
        }

        // let newCodeValue = (codeValue.splice(definitionsLineIndex, 0, completion.text)).join('\n');
        cm.getDoc().replaceRange(completion.definitionText, {
            line: definitionsLineIndex + 1,
            ch: 0
        });
    });
    return result;
}
function autocomplete(cm) {
    let cursor = cm.getCursor();

    return new Promise((resolve, reject) => {
        cm.ihiDslParser.getNextTokenSuggestions(cursor.line, cursor.ch, (suggestions, startPos, endPos, replaceOldText) => {
            let result = {
                list: suggestions
                    .map(s => {
                        return {
                            text: s.text + ' ', // (s.type === 'REFERENCE' ? '' : ' '),
                            displayText: s.displayText || s.text,
                            className: s.className || 'undefined-suggestion custom-suggestion',
                            type: s.type,
                            definitionText: s.definitionText ? s.definitionText : 'undefined'
                        };}
                    )
                    // .sort((a,b)=>{
                    //     return a.className.localeCompare(b.className);
                    // })
            };
            if(result.list.length > 0){
                result.from = CodeMirror.Pos(startPos.line, startPos.column);
                result.to = CodeMirror.Pos(endPos.line, replaceOldText ? endPos.column + 1 : startPos.column);
                resolve(result);
            }
            else resolve();
        });
    });
}
function comment(cm){
    let selection = cm.getDoc().getSelection();

    // is there anything selected ? 
    // no, there isnt - we only need to process one line
    if(selection.length < 1){
        let cursor = cm.getCursor();
        let line = cm.getDoc().getLine(cursor.line);
    
        // either comment a line 
        if(isLineCommented(line)) {
            cm.getDoc().replaceRange(
                uncommentLine(line), {
                    line: cursor.line,
                    ch: 0
                },{
                    line: cursor.line,
                    ch: line.length
                });
        }
        // or uncomment it
        else {
            cm.getDoc().replaceRange(
                ('//' + line), {
                    line: cursor.line,
                    ch: 0
                },{
                    line: cursor.line,
                    ch: line.length
                });
        }
    } 
    // yes, there is - we need to process all the lines
    else {
        let selectionStartLineIndex = cm.getCursor('from').line;
        let selectionEndLineIndex = cm.getCursor('to').line;
        let shouldUncommentSelection = true;

        // let selection = selection.split('\n');
        for (let lineIndex = selectionStartLineIndex; lineIndex < selectionEndLineIndex; lineIndex++) {
            let line = cm.getDoc().getLine(lineIndex);
            if(!isLineCommented(line)) shouldUncommentSelection = false;
        }

        if(shouldUncommentSelection){
            for (let lineIndex = selectionStartLineIndex; lineIndex <= selectionEndLineIndex; lineIndex++) {
                let line = cm.getDoc().getLine(lineIndex);

                cm.getDoc().replaceRange(
                    uncommentLine(line), {
                        line: lineIndex,
                        ch: 0
                    },{
                        line: lineIndex,
                        ch: line.length
                    });
            }   
        }
        else{
            for (let lineIndex = selectionStartLineIndex; lineIndex <= selectionEndLineIndex; lineIndex++) {
                let line = cm.getDoc().getLine(lineIndex);

                cm.getDoc().replaceRange(
                    ('//' + line), {
                        line: lineIndex,
                        ch: 0
                    },{
                        line: lineIndex,
                        ch: line.length
                    });
            }   
        }
    }

}

function isLineCommented(line){
    let cleanLine = line.trim();
    if(cleanLine.length < 2) return false;
    if(cleanLine[0] === '/' && cleanLine[1] === '/') return true;
    return false;
}
function uncommentLine(line){
    let retval = line;
    let firstForwardSlashIndex = findForwardSlashInString(line);
    retval = retval.substr(0, firstForwardSlashIndex) + retval.substr(firstForwardSlashIndex + 1);
    let secondForwardSlashIndex = findForwardSlashInString(retval);
    retval = retval.substr(0, secondForwardSlashIndex) + retval.substr(secondForwardSlashIndex + 1);

    return retval;
}
function findForwardSlashInString(line){
    let index = 0;
    while(index < line.length){
        if(line[index] === '/') {
            return index;
        }
        index++;
    }
    return -1;
}
function prepareError(err, text){
    let errToken = err.token || {};
    let startColumn = errToken.startColumn;
    let endColumn = errToken.endColumn;
    let startLine = errToken.startLine;
    let endLine = errToken.endLine;

    if(isNaN(startColumn) && err.previousToken) {
        startColumn = err.previousToken.startColumn;
        endColumn = err.previousToken.endColumn;
        startLine = err.previousToken.startLine;
        endLine = err.previousToken.endLine;
    }
    
    if(isNaN(startColumn)) {
        startColumn = 0;
        endColumn = text.length - 1;
    }

    return {
        severity: err.isWarning ? 'warning' : 'error',
        from: CodeMirror.Pos((startLine || 1) - 1, startColumn - 1),
        to: CodeMirror.Pos((endLine || 1) - 1, endColumn),
        message: locals.translate(err.message)
    };
}


