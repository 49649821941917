<template>
    <v-row no-gutters>
        <v-col>
            <v-text-field   v-model="internalValue" 
                            @input="v => $emit('input-text', v)"
                            @keyup.enter="$emit('input', internalValue || undefined)"
                            :placeholder="placeholder | translate"
                            hide-details
                            style="margin:0px;padding:0"
                            :loading="isLoading"
                            color="accent">
            </v-text-field>
        </v-col>
        <v-col v-if="canChooseText" class="flex-grow-0">
            <v-btn @click="$emit('input', internalValue || undefined)" text small><v-icon small>fa-check</v-icon></v-btn>
        </v-col>
        <v-col v-if="canChooseText" class="flex-grow-0">
            <v-btn @click="$emit('input', undefined)" text small><v-icon small>fa-close</v-icon></v-btn>
        </v-col>
    </v-row>
    
</template>

<script>
    export default {
        props:{
            value: {},
            isLoading: Boolean,
            multi: Boolean,
            canChooseText: Boolean,
            dataType: {}
        },
        data(){
            return {
                internalValue: undefined
            };
        },
        watch:{
            value:{
                immediate:true,
                handler(v){
                    if(!this.multi) {
                        this.internalValue = v?.text ? v.text : v;
                    }
                }
            }
        },
        computed:{
            placeholder(){
                return (this.dataType || {}).placeholder || 'type';
            }
        }
    };
</script>