<template>
    <v-form novalidate autocomplete="off" style="word-break: break-word;">
        

        <!-- 
            
            
            priklad pouzitia masonry grid layoutu 
            
            
            
        -->
        <!-- <div
            v-if="displayedInTab && masonry"
            class="masonry-attributes-grid"
            ref="attributes-grid"
        >
            <div 
                v-for="(formGroup, i) in groups" 
                :key="i" 
                class="attributes-group"
            >
                <v-subheader 
                    v-if="formGroup.displayString" 
                    class="attributes-group-header"
                >
                    {{ formGroup.displayString }}
                </v-subheader>
                <div class="attributes-group-content">
                    <v-row 
                        no-gutters 
                        v-for="(field, i) in formGroup.fields" 
                        :key="`${i}_${formGroup.displayString}`"
                    >
                        <v-col 
                            v-if="field.displayName" 
                            cols="5" 
                            class="form-field-label"
                        >
                            {{ field.displayName }}
                        </v-col>
                        
                        <v-col 
                            :cols="field.displayName ? 7 : 12" 
                            class="form-field-value"
                        >
                            <obj-generic-field 
                                :field="field" 
                                :value="field.value" 
                                :edit-mode="editMode" 
                                :edit-mode-switch="editModeSwitch" 
                                :meta="{ objectId }" 
                                @input="(v) => $emit('field-input', field, v)" 
                                :class="field.required && !field.value ? 'inv-required' : ''"
                            />
                        </v-col>
                    </v-row>
                </div>
            </div>
        </div> -->

        <v-container 
            v-if="displayedInTab"
            class="tabbed-form-container-content-grid"
            fluid
        >
            <v-row
                no-gutters
            >
                <v-col 
                    cols="3"
                    sm="12"
                    md="6"
                    lg="4"
                    xl="2"
                    v-for="(formGroup, i) in groups" 
                    :key="i" 
                    class="tabs-group"
                >
                    <v-subheader 
                        v-if="formGroup.displayString" 
                        class="tabs-header"
                    >
                        {{ formGroup.displayString }}
                    </v-subheader>

                    <v-row 
                        no-gutters 
                        v-for="(field, i) in formGroup.fields" 
                        :key="`${i}_${formGroup.displayString}`"
                        :data-test="'obj-field_' + field.editor + '_' + (field.displayName ? field.displayName : '')"
                    >
                        <v-col 
                            v-if="field.displayName" 
                            cols="5" 
                            class="form-field-label"
                            :data-test="field.displayName"
                        >
                            {{ field.displayName }}
                        </v-col>
                        
                        <v-col 
                            :cols="field.displayName ? 7 : 12"
                            :data-test="'input_' + field.editor + '_' + (field.displayName ? field.displayName : '')"
                            class="form-field-value"
                        >
                            <obj-generic-field 
                                :field="field" 
                                :value="field.value" 
                                :edit-mode="editMode" 
                                :edit-mode-switch="editModeSwitch" 
                                :meta="{ objectId }" 
                                @input="(v) => $emit('field-input', field, v)" 
                                :class="field.required && !field.value ? 'inv-required' : ''"
                            />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>

        <div v-else class="object-form-container-content" :style="`--grid-columns: ${max_tabs}; --grid-gap: ${colGap || 64}px`">
            <v-container class="object-form-form-container" no-gutters fluid>
                <div
                    class="form-group-legend"
                    :class="{'object-form-container-content-grid': grid}"
                    >
                    <template v-if="groups.length <= max_tabs">
                        <div v-for="(formGroup, i) in groups" :key="i" class="tabs-group">
                            <v-subheader v-if="formGroup.displayString" class="tabs-header">
                                {{ formGroup.displayString }}
                            </v-subheader>
                        </div>
                    </template>

                    <template v-else>
                        <!-- Always visible -->
                        <div v-for="(formGroup, i) in groups.slice(0, max_tabs - 1)" :key="`${i}_${formGroup.displayString}`" class="tabs-group">
                            <v-subheader v-if="formGroup.displayString" class="tabs-header">
                                {{ formGroup.displayString }}
                            </v-subheader>
                        </div>

                        <!-- Visible as tabs -->
                        <div class="tabs-group">
                            <v-tabs class="form-group-tabs" active-class="form-group-tab--active" slider-color="var(--v-accent-base)" show-arrows grow>
                                <v-tab v-for="(formGroup, i) in groups.slice(max_tabs - 1)" :key="`${i}_${formGroup.displayString}`" @click="currentTabIdx = i + max_tabs - 1" class="form-group-tab">
                                    <v-subheader v-if="formGroup.displayString" class="tabs-header">
                                        {{ formGroup.displayString }}
                                    </v-subheader>
                                </v-tab>
                            </v-tabs>
                        </div>
                    </template>
                </div>

                <div :class="{'object-form-container-content-grid': grid}">
                    <div v-for="(formGroup, i) in groups" :key="`${i}_${formGroup.displayString}`" :style="`display: ${canShowTabIdx(i) ? 'block' : 'none'}`">
                        <v-row 
                            no-gutters  
                            v-for="(field, i) in formGroup.fields" 
                            :key="`${i}_${formGroup.displayString}`"
                            :data-test="'obj-field_' + field.editor + '_' + (field.displayName ? field.displayName : '')"
                        >
                            <v-col 
                                v-if="field.displayName" 
                                cols="5" 
                                class="form-field-label"
                                :data-test="'label_' + field.editor + '_' + (field.displayName ? field.displayName : '')"
                            >
                                {{ field.displayName }}
                            </v-col>
                            <v-col 
                                :cols="field.displayName ? 7 : 12" 
                                class="form-field-value"
                                :data-test="'input_' + field.editor + '_' + (field.displayName ? field.displayName : '')"
                            >
                                <obj-generic-field :field="field" :value="field.value" :edit-mode="editMode" :edit-mode-switch="editModeSwitch" :meta="{ objectId }" @input="(v) => $emit('field-input', field, v)" :class="field.required && !field.value ? 'inv-required' : ''"> </obj-generic-field>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </v-container>
        </div>
    </v-form>
</template>

<script>
import object from 'obj-fe/utils/object';

export default {
    props: {
        groups: Array,
        editMode: Boolean,
        editModeSwitch: Boolean,
        objectId: {},
        maxCols: Number,
        colGap: Number,
        displayedInTab: Boolean,
        grid: {
            type: Boolean,
            default: true
        },
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
            this.onResize();
        });
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    data() {
        return {
            max_tabs: 3,
            currentTabIdx: 0
        };
    },
    computed: {
        canShowTabIdx() {
            const alwaysVisibleIdx = Array(this.max_tabs - 1)
                .fill(0)
                .map((item, i) => i);

            return (idx) => [...alwaysVisibleIdx, Number(this.currentTabIdx)].indexOf(Number(idx)) !== -1;
        }
    },
    watch: {
        max_tabs: {
            immediate: true,
            handler() {
                this.currentTabIdx = this.max_tabs - 1;
            },
        }
    },
    methods: {
        onResize: object.debounce(function() {
            this.$nextTick(() => {
                if (window.innerWidth < 1024) {
                    this.max_tabs = 1;
                    this.currentTabIdx = this.max_tabs - 1;
                } else if (window.innerWidth < 1280) {
                    this.max_tabs = 2;
                    this.currentTabIdx = this.max_tabs - 1;
                } else {
                    this.max_tabs = 3;
                    this.currentTabIdx = this.max_tabs - 1;
                }
            });
        }, 100),



        // masonry grid functionality on resize debounce 

        // resizeAllGridItems(){
        //     let allItems = document.getElementsByClassName("attributes-group"); // items in masonry
        //     for (let i = 0; i < allItems.length; i++) {
        //         this.resizeGridItem(allItems[i]);
        //     }
        // },
        // resizeGridItem(item){
        //     let grid = this.$refs['attributes-grid']; // masonry grid
        //     let rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
        //     let rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
        //     let rowSpan = Math.ceil((item.querySelector('.attributes-group-content').getBoundingClientRect().height+rowGap)/(rowHeight+rowGap)); // masonry grid item variable content
        //     item.style.gridRowEnd = "span "+rowSpan;
        // }


    },
};
</script>

<style scoped>
/* // masonry grid css + some form specific styles 

// .masonry-attributes-grid{
//     width: 100%;
//     display: grid;
//     gap: 10px;
//     grid-template-columns: repeat(auto-fill, minmax(400px,1fr));
//     grid-auto-rows: 250px;

//     .attributes-group{
//         padding: 10px;

//         .attributes-group-header {
//             border-bottom: 1px solid #0000001f ;
//             text-transform: uppercase !important;
//             margin-bottom: 16px;
//         }
//         .attributes-group-content .row:hover .form-field-label{
//             color: var(--v-accent-base) !important;
//         }
//         .form-field-label {
//             text-align: left;
//             padding-right: 20px;
//             min-height: 24px;
//             line-height: 24px;
//             word-break: normal;
//             overflow-wrap: anywhere;
//         }
//         .form-field-value {
//             min-height: 24px;
//         }
//     }
// } */

.tabbed-form-container-content-grid {
    width: 100%;
}
.tabbed-form-container-content-grid .tabs-group {
    padding: 10px;
}
.tabbed-form-container-content-grid .tabs-group .row:hover .form-field-label {
    color: var(--v-accent-base) !important;
}
.tabbed-form-container-content-grid .tabs-group .form-field-label {
    text-align: left;
    padding-right: 20px;
    min-height: 24px;
    line-height: 24px;
    word-break: normal;
    overflow-wrap: anywhere;
}
.tabbed-form-container-content-grid .tabs-group .form-field-value {
    min-height: 24px;
}
.tabbed-form-container-content-grid .tabs-header {
    border-bottom: 1px solid #0000001f;
    text-transform: uppercase !important;
    margin-bottom: 16px;
}
/* .object-form-container-content {
    padding-top: 24px;
    padding-bottom: 32px;
} */

.object-form-form-container {
    padding: 0 !important;
}
.object-form-form-container .form-group-legend {
    margin-bottom: 10px;
}
.object-form-form-container .form-field-label {
    text-align: left;
    padding-right: 20px;
    min-height: 24px;
    line-height: 24px;
    word-break: normal;
    overflow-wrap: anywhere;
}
.object-form-form-container .tabs-group {
    border-bottom: 1px solid #0000001f;
    min-width: 275px;
}
.object-form-form-container .tabs-header {
    text-transform: uppercase !important;
}
.object-form-form-container .form-group-tab {
    font-weight: bold;
}
.object-form-form-container .form-group-tab--active .tabs-header {
    color: var(--v-accent-base) !important;
}
.object-form-form-container .tabs-header--active {
    color: var(--v-accent-base) !important;
}
.object-form-form-container .form-field-value {
    min-height: 24px;
}
.object-form-form-container .form-group-tabs .v-slide-group__next, .object-form-form-container .form-group-tabs .v-slide-group__prev {
    border: none !important;
}
 

.object-form-container-content-grid {
    display: grid;
    gap: var(--grid-gap);
    grid-template-columns: repeat(var(--grid-columns), minmax(0, 1fr));
    grid-auto-flow: column;
}
.object-form-container-content-grid .cell-content-grid {
    grid-gap: 0px;
}
 
</style>
