<template>
  <v-card flat>
    <v-card-title>
      <h2>{{ "jobs_dashboard" | translate }}</h2>

      <v-spacer />
      <div class="inv-object-button-group">
        <v-btn text @click="syncGlobalToOpDbModal">
          <v-icon left>fal fa-cogs</v-icon>
          Global -&gt; OPDB
        </v-btn>
        <v-btn text @click="refreshDashboard">
          <v-icon left>fal fa-sync</v-icon>
          {{ "refresh" | translate }}
        </v-btn>
        <v-btn text @click="toggleAutoRefresh">
          <v-icon left v-if="autoRefresh" color="accent">fal fa-toggle-on</v-icon>
          <v-icon left v-else>fal fa-toggle-off</v-icon>
          {{ "auto_refresh" | translate }}
        </v-btn>
      </div>
    </v-card-title>

    <obj-table :items="pendingJobs" style="margin-bottom: 20px">
      <template slot="headers">
        <th>{{ "service" | translate }}</th>
        <th>{{ "scheduled" | translate }}</th>
        <th>{{ "start" | translate }}</th>
        <th>{{ "end" | translate }}</th>
        <th>{{ "status" | translate }}</th>
        <th>{{ "data" | translate }}</th>
        <th style="width: 100px">&nbsp;</th>
        <th style="width: 100px">&nbsp;</th>
        <th style="width: 100px">&nbsp;</th>
      </template>

      <template slot="row" slot-scope="row">
        <td class="nowrap">{{ row.value.serviceName }}</td>
        <td class="nowrap">{{ row.value.scheduled | datetime }}</td>
        <td class="nowrap">
          <span v-if="row.value.startTS">{{ row.value.startTS | datetime }}</span>
          <v-icon v-else>fal fa-hourglass</v-icon>
        </td>
        <td class="nowrap">
          <span v-if="row.value.endTS">{{ row.value.endTS | datetime }}</span>
          <v-icon v-else>fal fa-hourglass</v-icon>
        </td>
        <td>
          <v-icon v-if="row.value.error" color="error">fal fa-exclamation-triangle</v-icon>
          {{ row.value.statusDS }}
        </td>
        <td>{{ row.value.data }}</td>
        <td>
          <v-btn text @click="stopJobShow(row.value.id)">
            {{ "import_jobs_stop" | translate }}
          </v-btn>
        </td>
        <td>
          <v-btn text @click="showLogs(row.value.logFile)">
            {{ "logs" | translate }}
          </v-btn>
        </td>
        <td>
          <v-btn text @click="showSummaryLogs(row.value.id, true)">{{ "summary_info" | translate }}</v-btn>
        </td>
      </template>

      <template slot="no-data">
        {{ "no_pending_jobs_found" | translate }}
      </template>
    </obj-table>

    <obj-table :items="jobs">
      <template slot="headers">
        <th>{{ "service" | translate }}</th>
        <th>{{ "scheduled" | translate }}</th>
        <th>{{ "start" | translate }}</th>
        <th>{{ "end" | translate }}</th>
        <th>{{ "status" | translate }}</th>
        <th>{{ "data" | translate }}</th>
        <th style="width: 100px">&nbsp;</th>
        <th style="width: 100px">&nbsp;</th>
      </template>

      <template slot="row" slot-scope="row">
        <td class="nowrap">{{ row.value.serviceName }}</td>
        <td class="nowrap">{{ row.value.scheduled | datetime }}</td>
        <td class="nowrap">{{ row.value.startTS | datetime }}</td>
        <td class="nowrap">{{ row.value.endTS | datetime }}</td>
        <td>
          <v-icon v-if="row.value.error" color="error">fal fa-exclamation-triangle</v-icon>
          {{ row.value.statusDS }}
        </td>
        <td>{{ row.value.data }}</td>
        <td>
          <v-btn text @click="showLogs(row.value.logFile)">
            {{ "logs" | translate }}
          </v-btn>
        </td>
        <td>
          <v-btn text @click="showSummaryLogs(row.value.id)">{{ "summary_info" | translate }}</v-btn>
        </td>
      </template>

      <template slot="footer">
        <obj-table-pagination
          :page="pagination.page"
          :pages="pagination.pages"
          :page-size="pagination.limit"
          :total-count="pagination.count"
          :next="pagination.next"
          :prev="pagination.prev"
          @prev="$store.dispatch('JOBS_DASHBOARD/UPDATE_QUERY', { page: pagination.page - 1 })"
          @next="$store.dispatch('JOBS_DASHBOARD/UPDATE_QUERY', { page: pagination.page + 1 })"
          @page-size="(v) => $store.dispatch('JOBS_DASHBOARD/UPDATE_QUERY', { limit: v, page: 1 })"
        >
        </obj-table-pagination>
      </template>

      <template slot="no-data">
        {{ "no_jobs_history_found" | translate }}
      </template>
    </obj-table>

    <v-dialog v-model="syncGlobalToOpDbModalOpened" width="400px" content-class="small-dialog">
      <v-card flat>
        <v-card-title>
          <h2>{{ "import_jobs_confirm_sync_global_opdb" | translate }}</h2>
        </v-card-title>

        <v-card-text>
          {{ "are_you_sure" | translate }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="syncGlobalToOpDbModalOpened = false">
            {{ "cancel" | translate }}
          </v-btn>
          <v-btn color="success" outlined @click="syncGlobalToOpDb">
            {{ "sync" | translate }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="stopModal.opened" width="500px" content-class="small-dialog">
      <v-card flat>
        <v-card-title>{{ "import_jobs_stop" | translate }}</v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="stopModal.opened = false">
            {{ "cancel" | translate }}
          </v-btn>
          <v-btn outlined @click="stopJob" color="error">
            {{ "import_jobs_stop" | translate }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="logsModal.opened" content-class="log-dialog">
      <obj-app-logs-view
        v-if="logsModal.opened"
        :path="logsModal.logPath"
        @close="logsModal.opened = false"
        close-bottom
      ></obj-app-logs-view>
    </v-dialog>

    <v-dialog v-model="summaryLogsModal.opened" content-class="log-dialog" scrollable eager>
      <log-summary-info
        v-if="summaryLogsModal.opened"
        :id="summaryLogsModal.id"
        :refresh="summaryLogsModal.hasRefresh"
        @close="summaryLogsModal.opened = false"
      />
    </v-dialog>
  </v-card>
</template>

<style scoped>
.nowrap {
  white-space: nowrap;
}
</style>

<script>
import LogSummaryInfo from "./log-summary-info.vue";
import backend from "obj-fe/app/backend";

export default {
  components: {
    LogSummaryInfo,
  },
  data() {
    return {
      autoRefresh: false,
      syncGlobalToOpDbModalOpened: false,
      stopModal: {
        opened: false,
        id: null,
      },
      logsModal: {
        opened: false,
        logPath: null,
      },
      summaryLogsModal: {
        opened: false,
        id: null,
      },
    };
  },
  computed: {
    jobs() {
      let jobsById = this.$store.state.JOBS_DASHBOARD.jobsById;
      return this.$store.state.JOBS_DASHBOARD.jobIds.map((jobId) => jobsById[jobId]);
    },
    pagination() {
      return this.$store.state.JOBS_DASHBOARD.pagination;
    },
    pendingJobs() {
      return this.$store.state.JOBS_DASHBOARD.pendingJobs;
    },
  },
  methods: {
    syncGlobalToOpDbModal() {
      this.syncGlobalToOpDbModalOpened = true;
    },
    syncGlobalToOpDb() {
      backend.batchJobs.syncGlobalToOpDb((data) => {
        this.syncGlobalToOpDbModalOpened = false;
        this.refreshDashboard();
      });
    },
    refreshDashboard(silent) {
      this.$store.dispatch("JOBS_DASHBOARD/RELOAD_ALL", silent);
    },
    stopJobShow(jobId) {
      this.stopModal.opened = true;
      this.stopModal.id = jobId;
    },
    stopJob() {
      this.stopModal.opened = false;
      backend.jobs.stop({ id: this.stopModal.id });
    },
    showLogs(logPath) {
      this.logsModal.logPath = logPath;
      this.logsModal.opened = true;
    },
    showSummaryLogs(jobId, hasRefresh) {
      this.summaryLogsModal.id = jobId;
      this.summaryLogsModal.opened = true;
      this.summaryLogsModal.hasRefresh = !!hasRefresh;
    },
    toggleAutoRefresh() {
      this.autoRefresh = !this.autoRefresh;
      if (this.autoRefresh) this.setupRefreshInterval();
      else this.clearRefreshInterval();
    },
    clearRefreshInterval() {
      if (this.refreshInterval) {
        clearTimeout(this.refreshInterval);
        this.refreshInterval = null;
      }
    },
    setupRefreshInterval() {
      this.clearRefreshInterval();
      this.refreshInterval = setTimeout(() => {
        this.refreshDashboard(true);
        this.setupRefreshInterval();
      }, 6000);
    },
  },
  mounted() {
    this.toggleAutoRefresh();
  },
  destroyed() {
    this.clearRefreshInterval();
  },
};
</script>
