<template>
    <inv-object-tab-item 
        :tab="tab" 
        :tabIsLoading="tabIsLoading"
        data-test="tab-ATTRIBUTES_PANEL"
    >
        <template v-if="expanded && tab" slot="header">
            <div class="inv-object-button-group">
                
                <v-tooltip top>
                    <template v-slot:activator="{on}">
                        <v-btn 
                            v-on="on"
                            v-if="!readonly" 
                            @click="resetForm" 
                            :disabled="!isDirty"
                            :elevation="isDirty ? 24 : 0"
                            :outlined="isDirty"
                            icon
                            color="error"
                            data-test="tab-discard-button"
                        >
                            <v-icon>fal fa-undo-alt</v-icon>
                        </v-btn>
                    </template>
                    <span>
                        {{'discard_changes'|translate}}
                    </span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{on}">
                        <v-btn 
                            v-on="on"
                            v-if="!readonly" 
                            @click="save" 
                            icon 
                            color="success"
                            :disabled="!isDirty"
                            :elevation="isDirty ? 24 : 0"
                            :outlined="isDirty"
                            data-test="tab-save-button"
                        >
                            <v-icon>fal fa-save</v-icon>
                        </v-btn>
                    </template>
                    <span>
                        {{'save'|translate}}
                    </span>
                </v-tooltip>

            </div>
        </template>

        <template slot="body">
            <v-card flat>
                <v-card-text :class="{ 'tab-body-disabled' : tabIsLoading}">
                    <inv-object-form 
                        @field-input="changeFieldValue"
                        :groups="fieldsGrouped" 
                        :edit-mode="true" 
                        :edit-mode-switch="false" 
                        :object-id="objectId"
                        :displayedInTab="true"
                    />
                </v-card-text>
            </v-card>
        </template>
    </inv-object-tab-item>
</template>

<script>
    import backend from 'obj-fe/app/backend';

    import tabBehaviour from './inv-object-tab-behaviour';
    import InvObjectTabItem from './inv-object-tab-item.vue';
    import InvObjectForm from '../inv-object-form.vue';

    export default {
        mixins:[ tabBehaviour ],
        components:{
            InvObjectTabItem,
            InvObjectForm
        },
        data(){
            return {
                fieldsGrouped: [],
                isDirty: false,
                dirtyFields: []
            };
        },
        methods:{
            onFirstTimeExpandLoad(successCb, errorCb){
                backend.objects.getTabWithAttributeGroups(
                    {
                        globalId: this.objectId,
                        dataSource: this.tab.parameters.dataSource
                    }, 
                    data => {
                        this.fieldsGrouped = data.fieldsGrouped;
                        successCb();
                    }, 
                    errorCb
                );
            },
            changeFieldValue(field, value){
                if(!field.hasOwnProperty('origValue')) field.origValue = field.value;
                field.value = value;
                this.isDirty = true;
                

                let newDirtyFields = [];

                this.fieldsGrouped
                    .forEach(fieldsGroup=>{
                        fieldsGroup.fields
                            .filter(e => e.hasOwnProperty('origValue'))
                            .forEach(e => {
                                newDirtyFields.push(e)
                            })
                    })

                this.dirtyFields = newDirtyFields;
            },
            save(){
                backend.objects.putTabWithAttributeGroups(
                    { 
                        globalId: this.objectId, 
                        dataSource: this.tab.parameters.dataSource,
                    },
                    this.dirtyFields,
                    data => {
                        this.fieldsGrouped = data.fieldsGrouped;
                        this.isDirty = false;
                    }
                );
            },
            resetForm(){
                this.dirtyFields.forEach(field => {
                    field.value = field.origValue;
                    delete field.origValue;
                });
                this.isDirty = false;
            }
        }
    };
</script>