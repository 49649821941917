<template>
    <div 
        ref="editor" 
        :class="{ 'code-readonly':readonly }" 
        style="height: 100%; max-height: 100%; overflow: auto"
        data-test="emdsl-editor"
    ></div>
    <!-- :style="{ height: autoHeight ? 'auto' : null }" -->
</template>

<script>
    import backend from 'obj-fe/app/backend';
    import CodeMirror from 'codemirror';
    import emDslMode from './inv-emdsl-mode.js';
    import 'codemirror/addon/display/placeholder';

    export default {
        props:{
            value:{},
            readonly: Boolean,
            placeholder: String,
            autoHeight: {
                type: Boolean,
                default: true
            },
            minHeight:{},
            returnQueryObj: Boolean,
            json: Boolean,
            autoFocus: Boolean,
            dslDefinition: Object,
            template: Object
        },
        data(){
            return {
                cmValue: null,
                cmText: null
            };
        },
        computed:{
            isDirty(){
                return this.cmText !== this.value;
            }
        },
        methods:{
            updateContent(){
                let ui = this;

                if(!this.$refs.editor) return;

                if(!this.dslDefinition) return;

                if(!this.cm){
                    let cmOpts = {
                        // value: typeof this.value !== 'string' ? this.value : '',
                        gutters: [ "CodeMirror-linenumbers", "CodeMirror-foldgutter" ],

                        lineNumbers: true,
                        lineWrapping: true,
                        
                        indentWithTabs: true,
                        indentUnit: 4,
                        tabSize: 4,

                        placeholder: this.placeholder || '',
                        autofocus: this.autoFocus || false
                    };

                    if(this.autoHeight) cmOpts.viewportMargin = Infinity;

                    this.cm = CodeMirror(this.$refs.editor, cmOpts);

                    emDslMode.init(this.cm, {
                        autoFocus: this.autoFocus,
                        dslDefinition: this.dslDefinition,
                        suggestEntityName: this.suggestEntityName,
                        suggestAttributeValue: this.suggestAttributeValue
                    });

                    // set value before emiting to on change
                    this.setEditorValue(this.value, true);
                    this.cm.on('change', this.onChange);

                    setTimeout(() => this.cm.refresh());

                    // set min height if defined
                    if(this.minHeight) {
                        let scrollPlaceholder = this.$refs.editor.querySelector('.CodeMirror-scroll');
                        if(scrollPlaceholder) scrollPlaceholder.style.minHeight = this.minHeight+'px';
                    }
                }
                else if(this.cmValue !== this.value) this.setEditorValue(this.value);

                this.cmText = this.cm.getValue();
                
                this.cm.setOption('readOnly', this.readonly ? true : false);
            },
            onChange(){
                this.cm.parsedResult.onValidated(() => {
                    this.$emit('valid', this.cm.parsedResult.isValid);
                    
                    if(this.cm.parsedResult.isValid){
                        let value;

                        if(this.returnQueryObj && this.json) {
                            value = JSON.stringify(this.cm.parsedResult.value);
                        }
                        else value = this.cm.getValue();
                        this.cmValue = value;

                        if(this.returnQueryObj) {
                            if(this.json) this.$emit('input', value);
                            else this.$emit('input', this.cm.parsedResult.value);
                        }
                        else this.$emit('input', value);

                        // sometimes parent component needs raw parser result
                        this.$emit('parser-result', this.cm.parsedResult);
                    }
                    else this.$emit('input-invalid');
                });

                this.cmText = this.cm.getValue();
            },
            setEditorValue(value, firstTime){
                if(this.json) value = JSON.parse(value || '[]');

                if(Array.isArray(value)) {
                    this.cm.queryParser.render(value, renderedText => {
                        this.cm.setValue(renderedText);
                        if(firstTime && !renderedText) this.onChange(); // manually trigger on change if empty text
                    });
                }
                else {
                    this.cm.setValue(value || '');
                    if(firstTime && !value) this.onChange(); // manually trigger on change if empty text
                }

                this.cmText = this.cm.getValue();
            },
            isHintActive(){
                return this.cm && this.cm.state.completionActive && !!this.cm.state.completionActive.widget;
            },
            suggestEntityName(data, cb){
                backend.dsl.emdslEntitySuggest(
                    {
                        templateName: this.template.templateName || 'default',
                        ...data
                    }, 
                    entities => { cb(entities || []);}
                );
            },
            suggestAttributeValue(data, cb){
                backend.dsl.emdslAttributeValueSuggest(
                    {
                        templateName: this.template.templateName || 'default',
                        ...data
                    }, 
                    entities => { cb(entities || []);}
                );
            }
        },
        watch:{
            value(){
                this.$nextTick(() => {
                    this.updateContent();
                });
            },
            placeholder(val){
                if(this.cm) this.cm.setOption('placeholder', val || '');
            },
            dslDefinition: {
                immediate: true,
                handler(val){
                    this.$nextTick(() => {
                        this.updateContent();
                    });
                }
            }
        },
        mounted(){
            this.$nextTick(() => {
                this.updateContent();
            });
        },
        destroyed(){
            // TODO: find better solution to destroy hints properly, because if there will be more editor instances, it will remove all hints
            document.querySelectorAll('.CodeMirror-hints').forEach(elm => elm.remove());
        }
    }
</script>

<style scoped>
    >>> .CodeMirror .CodeMirror-lines{
        font-family: Consolas,"courier new" !important;
        font-size: 16px !important;
        line-height: 18px !important;
    }
    /* >>> .CodeMirror .CodeMirror-lines{
        padding: 12px !important;
    } */
    >>> .CodeMirror {
        height: auto;
        font-family: inherit;
    }
    >>> .CodeMirror .CodeMirror-foldmarker{
        color: #000;
        font-weight: bold;
        text-shadow: none;
    }
    


    >>> .CodeMirror .cm-ENTITY_TYPE,
    >>> .CodeMirror .cm-RELATION_TYPE,
    >>> .CodeMirror .cm-RELATION_OPERATOR {
        font-weight: bold;
    }

    >>> .CodeMirror .cm-ENTITY_ALIAS,
    >>> .CodeMirror .cm-ATTRIBUTE_VALUE {
        font-style: italic;
    }

    >>> .CodeMirror .cm-ENTITY_TYPE {
        color: #000;

    }
    >>> .CodeMirror .cm-ENTITY_ALIAS {
        color: #1E88E5;
        font-weight: bold;

    }
    >>> .CodeMirror .cm-ATTRIBUTE_VALUE.cm-GLOBAL_ID,
    >>> .CodeMirror .cm-ATTRIBUTE_VALUE.cm-NUMERIC,
    >>> .CodeMirror .cm-ATTRIBUTE_VALUE.cm-NUMBER,
    >>> .CodeMirror .cm-ATTRIBUTE_VALUE.cm-REAL,
    >>> .CodeMirror .cm-ATTRIBUTE_VALUE.cm-INT,
    >>> .CodeMirror .cm-ATTRIBUTE_VALUE.cm-NUMBER_LONG {
        color: #760000;
    }
    >>> .CodeMirror .cm-RELATION_TYPE,
    >>> .CodeMirror .cm-RELATION_OPERATOR  {
        color: #43A047;
    }
    
    >>> .CodeMirror .cm-ATTRIBUTE_VALUE {
        color:  #42A5F5; 
    }


    /* end */

    >>> .CodeMirror .cm-REFERENCE_VALUE,
    >>> .CodeMirror .cm-RELATION_ENTITY_ALIAS,
    >>> .CodeMirror .cm-IDENTIFIER {
        font-weight: bold;
    }

    >>> .CodeMirror .cm-REFERENCE_ENTITY,
    >>> .CodeMirror .cm-IDENTIFIER,
    >>> .CodeMirror .cm-RELATION_ENTITY_ALIAS {
        color: #1E88E5;
        font-style: italic;

    }
    
    >>> .CodeMirror pre.CodeMirror-placeholder {
        color: #999;
    }
    
    .code-readonly .CodeMirror-cursors {
        display: none !important;
    }

    

    
    >>> .cm-RELATION_LIST_START,
    >>> .cm-RELATION_LIST_END,
    >>> .cm-RELATION_ENTITY_ALIAS, 
    >>> .cm-ATTRIBUTE_VALUE,
    >>> .cm-ATTRIBUTE_NAME,
    >>> .cm-ATTRIBUTE_SEPARATOR,
    >>> .cm-ATTRIBUTE_OPERATOR {
        color: #607D8B;
        /* text-decoration: underline; */
    }

    >>> .cm-RELATION_ENTITY_ALIAS.CodeMirror-lint-mark-error {
        text-decoration: underline !important;
    }


    >>> .CodeMirror-lint-mark-warning{
        background-image: none !important;
        box-shadow: inset 0 -0.1em #FFC107;
    }
    >>> .CodeMirror-lint-mark-error{
        background-image: none !important;
        box-shadow: inset 0 -0.1em #D50000
    }
    
</style>