export default {
    suggestions: [{
        description: 'root suggestions',
        input: '  ',
        expected: ['definitions']
    },

    // definitions
    {
        description: 'definitions - opening curly bracket suggestion',
        input: 'definitions  ',
        expected: ['{']
    },
    {
        description: 'definitions - content suggestion',
        input: 'definitions {  ',
        expected: ['node', 'link', 'group', 'redundant', '}']
    },

    // definitions - nodes
    {
        description: 'definitions - after node definition suggestion',
        input: `definitions { 
                node n1  `,
        expected: ['[', 'node', 'link', 'group', 'redundant', '}']
    },
    {
        description: 'definitions - node with attributes suggestion',
        input: `definitions { 
                node n1 [ ]
                node n2  `,
        expected: ['[', 'node', 'link', 'group', 'redundant', '}']
    },
    {
        description: 'definitions - multiple nodes suggestion',
        input: `definitions { 
                node n1
                node n2  `,
        expected: ['[', 'node', 'link', 'group', 'redundant', '}']
    },
    // definitions - links
    {
        description: 'definitions - link suggestion',
        input: `definitions { 
                link l1  `,
        expected: ['[', 'node', 'link', 'group', 'redundant', '}']
    },
    {
        description: 'definitions - link with attributes suggestion',
        input: `definitions { 
                link l1 [ ]  `,
        expected: ['node', 'link', 'group', '}', 'redundant',]
    },
    {
        description: 'definitions - multiple links suggestion',
        input: `definitions { 
                link l1
                link l2  `,
        expected: ['[', 'node', 'link', 'group', 'redundant', '}']
    },

    // definitions - group
    {
        description: 'definitions - named group suggestion',
        input: `definitions { 
                group g1 `,
        expected: ['[', '{']
    },
    {
        description: 'definitions - group with attributes suggestion',
        input: `definitions { 
                group [ ]  `,
        expected: ['{']
    },
    {
        description: 'definitions - named group with attributes suggestion',
        input: `definitions { 
                group g1 [ ]  `,
        expected: ['{']
    },
    {
        description: 'definitions - group inner suggestion',
        input: `definitions { 
                group {  `,
        expected: ['node', 'group', 'redundant', '}']
    },
    {
        description: 'definitions - group inner suggestion',
        input: `definitions { 
                group {  `,
        expected: ['node', 'group', 'redundant', '}']
    },
    {
        description: 'definitions - group with inner node suggestion',
        input: `definitions { 
                group { 
                    node n1  `,
        expected: ['[', 'node', 'group', 'redundant', '}']
    },
    {
        description: 'definitions - group with inner group suggestion',
        input: `definitions { 
                group { 
                    group g1  `,
        expected: ['[', '{']
    },
    {
        description: 'definitions - group with inner group with attributes suggestion',
        input: `definitions { 
                group { 
                    group g1 [ ]  `,
        expected: ['{']
    },
    // definitions - redundants
    {
        description: 'definitions - redundant suggestion',
        input: `definitions { 
            redundant r1 `,
        expected: ['[', '{']
    },
    {
        description: 'definitions - redundant with attributes suggestion',
        input: `definitions { 
            redundant r1 [ ]  `,
        expected: ['{']
    },
    {
        description: 'definitions - named redundant with attributes suggestion',
        input: `definitions { 
            redundant r1 [ ]  `,
        expected: ['{']
    },
    {
        description: 'definitions - redundant inner suggestion',
        input: `definitions { 
            redundant r1 {  `,
        expected: ['node', 'redundant', '}']
    },
    {
        description: 'definitions - redundant inner suggestion',
        input: `definitions { 
            redundant r1 {  `,
        expected: ['node', 'redundant', '}']
    },
    {
        description: 'definitions - redundant with inner node suggestion',
        input: `definitions { 
            redundant r1 { 
                    node n1  `,
        expected: ['[', 'node', 'redundant', '}']
    },
    {
        description: 'definitions - redundant with inner redundant suggestion',
        input: `definitions { 
            redundant r1 { 
                redundant r2  `,
        expected: ['[', '{']
    },
    {
        description: 'definitions - group with inner group with attributes suggestion',
        input: `definitions { 
            redundant r1 { 
                redundant r2 [ ]  `,
        expected: ['{']
    },
    // connections
    {
        description: 'connections - suggesting node aliases ',
        input: `definitions {
                    node n1 
                    node n2 
                    node n3 
                    node n4
                    link l1
                    link l2
                }
                connections {  `,
        expected: ['BCM10T(Rack) [ id = 10000000015241272]','BCM10T(Rack) [ id = 10000000015241279]','F-001-BA-RK-03(Rack) [ id = 10000000015241295]','F-001-DULU-RK-01(Rack) [ id = 10000000015241286]','F-001-ZEMO-RK-01(Rack) [ id = 10000000015241291]','F-008-PD-RK-01(Rack) [ id = 10000000015241290]','F-012-PO-RK-01(Rack) [ id = 10000000015241294]','F-017-BB-RK-01(Rack) [ id = 10000000015241288]','F-070-BB-RK-01(Rack) [ id = 10000000015241287]','F-181-BA-RK-01(Rack) [ id = 10000000015241289]','KSBM-TN(Rack) [ id = 10000000015241292]','R 424(Rack) [ id = 10000000015241271]','R 426(Rack) [ id = 10000000015241280]','R 427(Rack) [ id = 10000000015241282]','R 455(Rack) [ id = 10000000015241281]','R 5107(Rack) [ id = 10000000015241285]','R-305(Rack) [ id = 10000000015241273]','R-719(Rack) [ id = 10000000015241284]','UD(Rack) [ id = 10000000015241283]','Vila Matuskova(Rack) [ id = 10000000015241293]','n1','n2','n3','n4','}','}'] ,
        type: 'BE-suggest'
    },
    {
        description: 'connections - suggesting connection connector [probably incorrect suggestion retrieval]',
        input: `definitions {
                    node n1 
                    node n2 
                    node n3 
                    node n4
                    link l1
                    link l2
                }
                connections {
                    n1  `,
        expected: ['~']
    },
    {
        description: 'connections - suggesting node or link aliases',
        input: `definitions {
                    node n1 
                    node n2 
                    node n3 
                    node n4
                    link l1
                    link l2
                }
                connections {
                    n1 ~  `,
        expected: ['BCM10T(Rack) [ id = 10000000015241272]','BCM10T(Rack) [ id = 10000000015241279]','F-001-BA-RK-03(Rack) [ id = 10000000015241295]','F-001-DULU-RK-01(Rack) [ id = 10000000015241286]','F-001-ZEMO-RK-01(Rack) [ id = 10000000015241291]','F-008-PD-RK-01(Rack) [ id = 10000000015241290]','F-012-PO-RK-01(Rack) [ id = 10000000015241294]','F-017-BB-RK-01(Rack) [ id = 10000000015241288]','F-070-BB-RK-01(Rack) [ id = 10000000015241287]','F-181-BA-RK-01(Rack) [ id = 10000000015241289]','KSBM-TN(Rack) [ id = 10000000015241292]','R 424(Rack) [ id = 10000000015241271]','R 426(Rack) [ id = 10000000015241280]','R 427(Rack) [ id = 10000000015241282]','R 455(Rack) [ id = 10000000015241281]','R 5107(Rack) [ id = 10000000015241285]','R-305(Rack) [ id = 10000000015241273]','R-719(Rack) [ id = 10000000015241284]','UD(Rack) [ id = 10000000015241283]','Vila Matuskova(Rack) [ id = 10000000015241293]','l1 ~','l2 ~','n1','n2','n3','n4'] ,
        type: 'BE-suggest'
    },
    {
        description: 'connections - suggesting connection connector',
        input: `definitions {
                    node n1 
                    node n2 
                    node n3 
                    node n4
                    link l1
                    link l2
                }
                connections {
                    n1 ~ l1  `,
        expected: ['BCM10T(Rack) [ id = 10000000015241272]','BCM10T(Rack) [ id = 10000000015241279]','F-001-BA-RK-03(Rack) [ id = 10000000015241295]','F-001-DULU-RK-01(Rack) [ id = 10000000015241286]','F-001-ZEMO-RK-01(Rack) [ id = 10000000015241291]','F-008-PD-RK-01(Rack) [ id = 10000000015241290]','F-012-PO-RK-01(Rack) [ id = 10000000015241294]','F-017-BB-RK-01(Rack) [ id = 10000000015241288]','F-070-BB-RK-01(Rack) [ id = 10000000015241287]','F-181-BA-RK-01(Rack) [ id = 10000000015241289]','KSBM-TN(Rack) [ id = 10000000015241292]','R 424(Rack) [ id = 10000000015241271]','R 426(Rack) [ id = 10000000015241280]','R 427(Rack) [ id = 10000000015241282]','R 455(Rack) [ id = 10000000015241281]','R 5107(Rack) [ id = 10000000015241285]','R-305(Rack) [ id = 10000000015241273]','R-719(Rack) [ id = 10000000015241284]','UD(Rack) [ id = 10000000015241283]','Vila Matuskova(Rack) [ id = 10000000015241293]','[','n1','n2','n3','n4','}','~'],
        type: 'BE-suggest'
    },
    {
        description: 'connections - suggesting node aliases',
        input: `definitions {
                    node n1 
                    node n2 
                    node n3 
                    node n4
                    link l1
                    link l2
                }
                connections {
                    n1 ~ l1 ~  `,
        expected: ['BCM10T(Rack) [ id = 10000000015241272]','BCM10T(Rack) [ id = 10000000015241279]','F-001-BA-RK-03(Rack) [ id = 10000000015241295]','F-001-DULU-RK-01(Rack) [ id = 10000000015241286]','F-001-ZEMO-RK-01(Rack) [ id = 10000000015241291]','F-008-PD-RK-01(Rack) [ id = 10000000015241290]','F-012-PO-RK-01(Rack) [ id = 10000000015241294]','F-017-BB-RK-01(Rack) [ id = 10000000015241288]','F-070-BB-RK-01(Rack) [ id = 10000000015241287]','F-181-BA-RK-01(Rack) [ id = 10000000015241289]','KSBM-TN(Rack) [ id = 10000000015241292]','R 424(Rack) [ id = 10000000015241271]','R 426(Rack) [ id = 10000000015241280]','R 427(Rack) [ id = 10000000015241282]','R 455(Rack) [ id = 10000000015241281]','R 5107(Rack) [ id = 10000000015241285]','R-305(Rack) [ id = 10000000015241273]','R-719(Rack) [ id = 10000000015241284]','UD(Rack) [ id = 10000000015241283]','Vila Matuskova(Rack) [ id = 10000000015241293]','n1','n2','n3','n4'] ,
        type: 'BE-suggest'
    },
    {
        description: 'connections - suggesting attribute bracket',
        input: `definitions {
                    node n1 
                    node n2 
                    node n3 
                    node n4
                    link l1
                    link l2
                }
                connections {
                    n1 ~ l1 ~ n2  `,
        expected: ['BCM10T(Rack) [ id = 10000000015241272]','BCM10T(Rack) [ id = 10000000015241279]','F-001-BA-RK-03(Rack) [ id = 10000000015241295]','F-001-DULU-RK-01(Rack) [ id = 10000000015241286]','F-001-ZEMO-RK-01(Rack) [ id = 10000000015241291]','F-008-PD-RK-01(Rack) [ id = 10000000015241290]','F-012-PO-RK-01(Rack) [ id = 10000000015241294]','F-017-BB-RK-01(Rack) [ id = 10000000015241288]','F-070-BB-RK-01(Rack) [ id = 10000000015241287]','F-181-BA-RK-01(Rack) [ id = 10000000015241289]','KSBM-TN(Rack) [ id = 10000000015241292]','R 424(Rack) [ id = 10000000015241271]','R 426(Rack) [ id = 10000000015241280]','R 427(Rack) [ id = 10000000015241282]','R 455(Rack) [ id = 10000000015241281]','R 5107(Rack) [ id = 10000000015241285]','R-305(Rack) [ id = 10000000015241273]','R-719(Rack) [ id = 10000000015241284]','UD(Rack) [ id = 10000000015241283]','Vila Matuskova(Rack) [ id = 10000000015241293]','[','n1','n2','n3','n4','}'] ,
        type: 'BE-suggest'
    },
    {
        description: 'connections - suggesting connection connector',
        input: `definitions {
                    node n1 
                    node n2 
                    node n3 
                    node n4
                    link l1
                    link l2
                }
                connections {
                    n1 ~ n2  `,
        expected: ['BCM10T(Rack) [ id = 10000000015241272]','BCM10T(Rack) [ id = 10000000015241279]','F-001-BA-RK-03(Rack) [ id = 10000000015241295]','F-001-DULU-RK-01(Rack) [ id = 10000000015241286]','F-001-ZEMO-RK-01(Rack) [ id = 10000000015241291]','F-008-PD-RK-01(Rack) [ id = 10000000015241290]','F-012-PO-RK-01(Rack) [ id = 10000000015241294]','F-017-BB-RK-01(Rack) [ id = 10000000015241288]','F-070-BB-RK-01(Rack) [ id = 10000000015241287]','F-181-BA-RK-01(Rack) [ id = 10000000015241289]','KSBM-TN(Rack) [ id = 10000000015241292]','R 424(Rack) [ id = 10000000015241271]','R 426(Rack) [ id = 10000000015241280]','R 427(Rack) [ id = 10000000015241282]','R 455(Rack) [ id = 10000000015241281]','R 5107(Rack) [ id = 10000000015241285]','R-305(Rack) [ id = 10000000015241273]','R-719(Rack) [ id = 10000000015241284]','UD(Rack) [ id = 10000000015241283]','Vila Matuskova(Rack) [ id = 10000000015241293]','[','n1','n2','n3','n4','}','~'] ,
        type: 'BE-suggest'
    },
    
    // attributes
    // definitions
    // node
    {
        description: 'attributes - definitions - node',
        input: `definitions {
                    node n1 [ color  `,
        expected: ['=']
    },
    {
        description: 'attributes - definitions - node',
        input: `definitions {
                    node n1 [ color = red  `,
        expected: [',', ']']
    },
    // link
    {
        description: 'attributes - definitions - link',
        input: `definitions {
                    link l1 [ color  `,
        expected: ['=']
    },
    {
        description: 'attributes - definitions - link',
        input: `definitions {
                    link l1 [ color = red  `,
        expected: [',', ']']
    },
    // group
    {
        description: 'attributes - definitions - group 1',
        input: `definitions {
                    group g1 [ color  `,
        expected: ['=']
    },
    {
        description: 'attributes - definitions - group 2',
        input: `definitions {
                    group g1 [ color = red  `,
        expected: [',', ']']
    },
    {
        description: 'attributes - connections - = suggest',
        input: `definitions {
                    node n1
                    node n2
                }
                connections {
                    n1 ~ n2 [ color  `,
        expected: ['=']
    },{
        description: 'BE - nodes',
        input: `definitions {
            node    `,
        expected: ['BCM10T(Rack) [ id = 10000000015241272]','BCM10T(Rack) [ id = 10000000015241279]','F-001-BA-RK-03(Rack) [ id = 10000000015241295]','F-001-DULU-RK-01(Rack) [ id = 10000000015241286]','F-001-ZEMO-RK-01(Rack) [ id = 10000000015241291]','F-008-PD-RK-01(Rack) [ id = 10000000015241290]','F-012-PO-RK-01(Rack) [ id = 10000000015241294]','F-017-BB-RK-01(Rack) [ id = 10000000015241288]','F-070-BB-RK-01(Rack) [ id = 10000000015241287]','F-181-BA-RK-01(Rack) [ id = 10000000015241289]','KSBM-TN(Rack) [ id = 10000000015241292]','R 424(Rack) [ id = 10000000015241271]','R 426(Rack) [ id = 10000000015241280]','R 427(Rack) [ id = 10000000015241282]','R 455(Rack) [ id = 10000000015241281]','R 5107(Rack) [ id = 10000000015241285]','R-305(Rack) [ id = 10000000015241273]','R-719(Rack) [ id = 10000000015241284]','UD(Rack) [ id = 10000000015241283]','Vila Matuskova(Rack) [ id = 10000000015241293]'] ,
        type: 'BE-suggest'
    },{
        description: 'BE - nodes',
        input: `definitions {
            node a`,
        expected: ['A-016 miestnost(Room) [ id = 10000000015252183]','A-SBC_Oracle(Functionality) [ id = 10000000016354811]','A1(Marked Point) [ id = 10000000009672787]','A10(Marked Point) [ id = 10000000009672842]','A2-MTOJKA(Duct Closure) [ id = 10000000009269334]','A2-SPOJKA(Marked Point) [ id = 10000000009672760]','A3-MTOJKA(Duct Closure) [ id = 10000000009269323]','A3-MTOJKA(Duct Closure) [ id = 10000000009269407]','A3-SPOJKA(Marked Point) [ id = 10000000009672841]','AAA(testbed2)_Nokia(Functionality) [ id = 10000000016354809]','AAA_Nokia(Functionality) [ id = 10000000016354515]','AACU-C(OLT Card) [ id = 10000000009984764]','AACU-C(OLT Card) [ id = 10000000009984765]','AACU-C(OLT Card) [ id = 10000000009984766]','AACU-C(OLT Card) [ id = 10000000009984767]','AACU-C(OLT Card) [ id = 10000000009984768]','aaa01ba(Server Functionality) [ id = 10000000007654346]','aaa01bb(Server Functionality) [ id = 10000000007654319]','aaa01test1(Server Functionality) [ id = 10000000007654366]','aaa01test2(Server Functionality) [ id = 10000000007654728]'],
        type: 'BE-suggest'
    },{
        description: 'BE - links',
        input: `definitions {
            link an`,
        expected: ['ANT-M2.8/10VC64(Server Trail) [ id = 10000000015341844]','ANT-M2.8/11VC64(Server Trail) [ id = 10000000015341845]','ANT-M2.8/17VC64(Server Trail) [ id = 10000000015341846]','ANT-M2.8/18VC64(Server Trail) [ id = 10000000015341847]','ANT-M2.8/29VC64(Server Trail) [ id = 10000000015341848]','ANT-M2.8/30VC64(Server Trail) [ id = 10000000015341849]','ANT-M2.8/31VC64(Server Trail) [ id = 10000000015341850]','ANT-M2.8/33VC64(Server Trail) [ id = 10000000015341851]','ANT-M2.8/36VC64(Server Trail) [ id = 10000000015341852]','ANT-M2.8/37VC64(Server Trail) [ id = 10000000015341853]','ANT-M2.8/38VC64(Server Trail) [ id = 10000000015341854]','ANT-M2.8/39VC64(Server Trail) [ id = 10000000015341855]','ANT-M2.8/3VC64(Server Trail) [ id = 10000000015341856]','ANT-M2.8/40VC64(Server Trail) [ id = 10000000015341857]','ANT-M2.8/41VC64(Server Trail) [ id = 10000000015341858]','ANT-M2.8/49VC64(Server Trail) [ id = 10000000015341859]','ANT-M2.8/4VC64(Server Trail) [ id = 10000000015341860]','ANT-M2.8/52VC64(Server Trail) [ id = 10000000015341861]','ANT-M2.8/61VC64(Server Trail) [ id = 10000000015341862]','ANT2-BBC5/TS2.10-LS2.2(Multiplex Section) [ id = 10000000009892911]'],
        type: 'BE-suggest'
            
    },{
        description: 'attributes - definitions - node',
        input: `definitions {
                    node n1 [ `,
        expected: [']','alias','color','id','objectType','role']
    },{
        description: 'attributes - definitions - node',
        input: `definitions {
                    node n1 [ color =  `,
        expected: ['red', 'green', 'glue', 'white', 'black', 'lime']
    },{
        description: 'attributes - definitions - node',
        input: `definitions {
                    node n1 [ color = red,  `,
        expected: ['alias','id','objectType','role']
    },{
        description: 'attributes - definitions - link',
        input: `definitions {
                    link l1 [  `,
        expected: [']','alias','color','id','objectType','role']
    },{
        description: 'attributes - definitions - link',
        input: `definitions {
                    link l1 [ color =  `,
        expected: ['red', 'green', 'glue', 'white', 'black', 'lime']
    },{
        description: 'attributes - definitions - link',
        input: `definitions {
                    link l1 [ color = red,  `,
        expected: ['alias','id','objectType','role']
    },{
        description: 'attributes - definitions - group 12',
        input: `definitions {
                    group g1 [  `,
        expected: [']','border','color','verticalHint']
    },{
        description: 'attributes - definitions - group 13',
        input: `definitions {
                    group g1 [ color = red,  `,
        expected: ['border', 'verticalHint']
    },{
        description: 'attributes - definitions - group',
        input: `definitions {
                    group g1 [ color =  `,
        expected: ['red', 'green', 'glue', 'white', 'black', 'lime']
    }],
    codeModifiers:[{
        description: 'connections - import node A10',
        input: 
`definitions {

} 
connections {
a10`,
        expected: 
`definitions {
	node A10 [ id = 10000000009672842 ]

} 
connections {
a10`
    },{
        description: 'connections - import node Antolska to already auto-defined nodes',
        input: 
`definitions {
node n1
node A10 [ id = 10000000009672842 ]
} 
connections {
A10 ~ n1
Antols`,
        expected: 
`definitions {
	node "Antolska (0418BR)" [ id = 10000000008654152 ]
node n1
node A10 [ id = 10000000009672842 ]
} 
connections {
A10 ~ n1
Antols`
    },{
        description: 'connections - import link Antolska to already auto-defined nodes',
        input: 
`definitions {
node n1
node n2
} 
connections {
n1 ~ CPEQ_2461_BA-P`,
        expected: 
`definitions {
	link CPEQ_2461_BA-PO [ id = 10000000015341487 ]
node n1
node n2
} 
connections {
n1 ~ CPEQ_2461_BA-P`
    }],
};