<template>
    <inv-object-tab-item :tab="tab" :tabIsLoading="tabIsLoading">
        
        <template v-if="expanded && tab" slot="header">
            <div class="inv-object-button-group">

                <v-tooltip left>
                    <template v-slot:activator="{on}">
                        <v-btn 
                            v-on="on"
                            @click="save" 
                            :disabled="!editorContentIsValid"
                            :outlined="editorContentIsValid"
                            :elevation="editorContentIsValid ? 24 : 0"
                            :color="editingScope ? 'success' : 'accent'"
                            icon
                        >
                            <v-icon> {{editingScope === 'ADD_NEW' ? 'fal fa-plus' : 'fal fa-retweet'}}</v-icon>
                        </v-btn>
                    </template>
                    <span>
                        {{editingScope === 'ADD_NEW' ? 'add_new' : 'replace_all' | translate}}
                    </span>
                </v-tooltip>

            </div>
        </template>
        
        <template slot="body">
            <div class="inv-object-tab-graphviz-container" :class="{ 'tab-body-disabled' : tabIsLoading }">
                <div class="inv-object-tab-graphviz" ref="editor">
                    <inv-emdsl-editor   
                        v-model="emdslCode" 
                        :placeholder="'enter_graph_code_here' | translate"
                        @valid="isValid => editorContentIsValid = isValid"
                        auto-height 
                        @parser-result="result => emdslParserResult = result"
                        :dslDefinition="emdslDefinition"
                        @is-dirty="editorIsDirty = $event"
                        :template="template"
                    />
                </div>
                <div class="dragbar" ref="dragbar"/>
                <div class="inv-object-tab-graphviz-svg not-dragged" ref="graph">
                    <div v-html="svgContent"></div>
                </div>
            </div>
        </template>

        <v-dialog 
            v-model="saveModal.opened" 
            width="500px"
            content-class="small-dialog"
            persistent
        >
            <v-card flat>
                <v-card-title><h2>{{'confirm_changes' | translate}}</h2></v-card-title>

                <v-card-text v-if="saveModal.message">
                    <strong 
                        v-for="(par, index) in saveModal.message.split('\n')"
                        :key="index" 
                        class="error--text"
                    >
                        {{ par }} <br>
                    </strong>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn 
                        text
                        @click="saveModal.opened = false"
                    >
                        {{'cancel'|translate}}
                    </v-btn>
                    <v-btn 
                        color="success" 
                        outlined
                        @click="forceSave"
                    >
                        {{'confirm'|translate}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </inv-object-tab-item>
</template>


<script>
    
    import object from 'obj-fe/utils/object';
    import backend from 'obj-fe/app/backend';
    import notify from 'obj-fe/services/notifications';
    
    import tabBehaviour from './inv-object-tab-behaviour';
    import InvObjectTabItem from './inv-object-tab-item.vue';
    import InvEmdslEditor from '../../dsl/emdsl/inv-emdsl-editor.vue';

    export default {
        mixins:[ tabBehaviour ],
        components:{
            InvObjectTabItem,
            InvEmdslEditor
        },
        data(){
            return {
                emdslCode: '',
                emdslParserResult: null,
                emdslDefinition: null,
                svgContent: '',
                editorContentIsValid: false,
                cachedResourceDefinitions:{},
                firstTimeLoaded: true,
                sampleCode: `Site s1 {
                    Description = "s1 description",
                    Id = 123456
                }

                Building b1 {
                    Description = "b1 description",
                    Id = 10000000007474048
                }

                Functionality f1 {
                    Description = "f1 description",
                    Id = 123453
                }

                Rack r1 {
                    Description = "r1 description",
                    Id = 123455,
                    Name = "rack_r1",
                    ModelReference = "r1_mr" 
                }

                RackRow rr1 {	
                    Description = "b1 description",
                    Id = 123456
                }

                Building used_building { 
                    Description = "here, the entities are used"

                    Location -> s1,
                    Has -> b1,
                    Has -> f1,
                    Has -> r1,
                    Has -> rr1
                }
                `,
                editorIsDirty: false,
                saveModal: {
                    opened: false,
                    message: ''
                },
                editingScope: 'ADD_NEW',
                template: {}
            };
        },
        computed:{
            dslAST(){
                return this.emdslParserResult?.value?.data;
            }
        },
        methods:{
            onFirstTimeExpandLoad(successCb, errorCb){
                backend.dsl.emdslDefinition(
                    { context: this.tab.parameters.emdslContext || 'default' }, 
                    data => {
                        this.emdslDefinition = data;
                        this.editingScope = data.editingScope;
                        this.template = data;
                        successCb()
                    },  
                    err => {
                        console.log(err)
                        errorCb()
                    }
                );

                backend.dsl.emdslTemplate(
                    { context: this.tab.parameters.emdslContext || 'default', globalId: this.objectId},
                    data => {
                        this.emdslCode = data;
                        this.initValue = data;
                    },
                    err => {
                        console.log(err)
                    }
                )
            },
            
            save(){
                backend.dsl.emdslCreate(
                    { 
                        context: this.tab.parameters.emdslContext || 'default',
                        globalId: this.objectId,
                    }, 
                    { src:this.emdslCode, data: this.emdslParserResult.value.data }, 
                    data => {
                        this.initVal = this.emdslCode;
                        notify.success('saved');
                    },
                    (err, status) => {
                        // re-enables save changes button
                        this.$emit('saved');

                        if(status === 412) {
                            this.saveModal.message = err;
                            this.saveModal.opened = true;
                        }
                    }
                );
            },
            forceSave(){
                backend.dsl.emdslCreateForce(
                    {
                        context: this.tab.parameters.emdslContext || 'default',
                        globalId: this.objectId,
                    },
                    data => {
                        this.initVal = this.emdslCode;
                        this.saveModal.opened = false;
                        notify.success('saved');
                    },
                    err => {
                        this.saveModal.opened = false;
                        notify.error('saving_grid_error')
                    }
                )
            },
            loadSvgDiagramPreview(){
                backend.dsl.emdslPreview(
                    { context: this.tab.parameters.emdslContext}, 
                    { data: this.emdslParserResult.value.data }, 
                    data => this.svgContent = data
                );
            },
        },
        watch:{
            emdslCode(newVal){
                if(this.firstTimeLoaded) this.firstTimeLoaded = false;    
            },
            dslAST:{
                deep: true,
                handler(newVal, oldVal){
                    setTimeout(() => {
                        if(this.editorContentIsValid && JSON.stringify(oldVal) !== JSON.stringify(newVal) && !!newVal) this.loadSvgDiagramPreview();
                    }, 1000)
                } 
            } 
        },
        computed:{
            isDirty(){
                return this.editorIsDirty && this.initValue !== this.emdslCode;
            },
        }
    };
</script>

<style scoped>
    .inv-object-tab-graphviz-container{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
    }
    .inv-object-tab-graphviz-container .inv-object-tab-graphviz{
        max-width: 50%;
        width: 50%;
        overflow: hidden;
    }
    .dragbar{
        padding: 2px;
        cursor: col-resize;
        background-color: rgba(0, 0, 0, 0.12);;
        user-select: none;
        transition: all 200ms;
    }
    .dragbar:hover{
        background-color: rgba(0, 0, 0, 0.25);;
    }
    .inv-object-tab-graphviz-svg{
        display: flex;
        flex-grow: 10;
        max-height: 100%;
        width: 50%;
        flex-direction: column;
        flex-wrap: wrap;
    }

    .inv-object-tab-graphviz{
        height: 100%;
        overflow: hidden;
    }
    .inv-object-tab-graphviz-container .inv-object-tab-graphviz-svg{
        overflow: auto;
    }
    .graph-preview {
        border-left: 1px solid #ddd;
        max-height: 100%;
    }
</style>
