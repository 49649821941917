<template>
    <div style="position: relative; height:100%; width: 100%">
        <v-card
            elevation="12"
            :class="'layers-container ' + (selected ? 'offset' : '')"
        >
            <v-navigation-drawer
                permanent
                expand-on-hover
                mini-variant-width="40px"
                height="auto"
            >
                <v-list
                    rounded
                    class="layer-list"
                >
                    <v-list-item-group
                        v-model="selectedLayers"
                        multiple
                        active-class="accent--text"
                    >
                        <v-list-item
                            v-for="layer in layers" 
                            :key="layer.id"
                            @click="toggleLayer(layer)"
                        >
                            <template v-slot:default="{ active }">

                                <v-list-item-icon class="layer-icon-container">
                                    <v-icon>{{ active ? 'far' : 'fal' }} fa-layer-group </v-icon> 
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title>{{ layer.displayString }}</v-list-item-title>
                                </v-list-item-content>
           
                            </template>
                        </v-list-item>
                    </v-list-item-group>
                        
                </v-list>
            </v-navigation-drawer>
        </v-card>

        <v-navigation-drawer
            :value="!!selected" 
            class="document-side-panel" 
            absolute 
            right 
            :width="!!selected ? '480' : 0"
            :style="!fullScreen && !!selected ? 'padding: 8px' : 0"
        >
            <inv-mini-object 
                v-if="selected"
                :id="selected" 
                @close="$store.dispatch('INVENTORY_GIS_MAP/SET_SELECTED', null)" 
            />
        </v-navigation-drawer>

        <div 
            ref="mapContainer" 
            style="width:100%;height:100%"
        />

    </div>
</template>

<script>

    import 'ol/ol.css';
    import Map from 'ol/Map';
    import View from 'ol/View';
    import { Tile as TileLayer} from 'ol/layer';
    import { OSM, TileWMS} from 'ol/source';
    import { fromLonLat } from 'ol/proj';
    

    export default {
        props:{
            fullScreen: Boolean,
            resizeNeeded: Boolean,
            layers: {},
            computeCenter: Boolean,
            latitudeDecimal: Number,
            longitudeDecimal: Number
        },
        data(){
            return {
                defaultProjection: 'EPSG:3857',
                defaultZoom: 8,
                mapOptionsOpened: true,
                geoserverInfo: {
                    url: '/geoserver/celine-workspace/wms',
                    serverType: 'geoserver' 
                },
                fab: false,
                selectedLayers: [],
                layersInfo: {
                    allLayers: 'All layers',
                    defaultLayer: 'OSM-WMS'
                }
            };
        },
        computed:{
            selected(){
                return this.$store.state['INVENTORY_GIS_MAP'].selected;
            } 
        },
        watch:{
            layers: {
                immediate:true,
                handler(layers){
                    this.$nextTick(() => this.processLayersChange(layers));
                }
            },
            resizeNeeded: function(){
                if(this.resizeNeeded){
                    setTimeout(()=>{
                        window.dispatchEvent(new Event('resize'));
                    }, 200)
                }
            },
            selected: function(selected){
                if(selected) this.$store.dispatch('INVENTORY/LOAD_OBJECT_DETAIL_PREVIEW', {
                    objectId: selected, 
                    errorCb: () => { this.selected = null }
                });
            }
        },
        methods:{
            processLayersChange(layers){
                if(!layers) return;
                
                // first time loading
                if(!this.map){
                    let baseLayer = layers.filter(e=>e.id === this.layersInfo.defaultLayer)[0];
                    this.createMapInstance(() => {
                        this.selectedLayers = [layers.map(e=>e.id).indexOf(baseLayer.id)];
                        this.toggleLayer(baseLayer);
                    });

                } 
                
                // remove previous layers
                this.map
                    .getLayers()
                    .getArray()
                    .forEach(layer => this.map.removeLayer(layer))
                
                // add selected layers
                let selectedLayers = layers
                    .filter(layer => layer.selected);

                if(selectedLayers.length > 0){

                    let baseLayer = selectedLayers
                        .filter(e=>e.id === 'OSM-WMS')[0];

                    if(!!baseLayer) {
                        this.map.addLayer(new TileLayer({
                            source: new TileWMS({
                                ...this.geoserverInfo,
                                params: {
                                    LAYERS: baseLayer.id
                                },
                            })
                        })); 
                    }
                    
                    selectedLayers
                        .filter(layer => layer.id !== 'OSM-WMS')
                        .map(layer => {
                            let processedLayer = new TileLayer({
                                source: new TileWMS({
                                    ...this.geoserverInfo,
                                    params: {
                                        LAYERS: layer.id
                                    },
                                })
                            })

                            this.map.addLayer(processedLayer);
                        })
                }
                
            },
            createMapInstance(cb){
                
                let map = new Map({
                    target: this.$refs['mapContainer'],
                    
                    layers: [],
                    view: new View({
                        center: [0,0],
                        zoom: 0,
                    }),
                    
                });

                map.addEventListener('click', this.processMapClick)
                this.map = map;

                if(this.computeCenter && (!!this.longitudeDecimal && !!this.latitudeDecimal)){

                    this.map.getView().setCenter(fromLonLat([this.longitudeDecimal, this.latitudeDecimal], this.defaultProjection))
                    this.map.getView().setZoom(15);

                }

                if(cb) cb();

            },
            processMapClick(event){
                let resolution = this.map.getView().getResolution();
                let selectedLayersString = this.selectedLayers
                    .map(e => this.layers[e].id)
                    .filter(e => e !== this.layersInfo.defaultLayer)
                    .join(',');

                this.$store.dispatch('INVENTORY_GIS_MAP/PROCESS_EVENT', {
                    event, 
                    resolution, 
                    projection: this.defaultProjection,
                    selectedLayers: selectedLayersString
                });
            },
            toggleLayer(layer){
                this.$store.dispatch('INVENTORY_GIS_MAP/TOGGLE_LAYER', layer);
            }
        },
        destroyed(){
            if(this.map) this.map.setTarget(null); // destroy map instance
        }
    };
</script>

<style scoped>
    /* hide copyright - only for internal purpose */
    >>> .ol-attribution.ol-unselectable.ol-control.ol-uncollapsible > ul{
        display:none;
    }

    .menu-button {
        position:fixed;
        right:0px;
        top: 74px;
        z-index:1;
        width: 530px;
        height: 50px;
        background-color: #fff;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
        cursor: pointer;
        /* transform: translateX(97%); */

        transform: translateX(96%);
        transition-duration: 0.2s;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        will-change: transform;
        transition-property: transform, width;
    }

    .menu-button.expanded {
        transform: translateX(0%);
    }

    .layer-list .v-list-item{
        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        
    }
    .layer-icon-container{
        display: flex;
        justify-content: center;
    }
    .document-side-panel{
        box-shadow: none;
    }
    .layers-container{
        position: absolute; 
        top: 16px; 
        right: 16px; 
        z-index: 3; 
        height: auto;
        transition: right 200ms ease-in-out;
    }
    .layers-container.offset{
        right: 496px;
    }
    /* .layer-list .v-list-item .v-list-item__icon{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
    } */
</style>

