<template>
    <v-flex>
        <v-autocomplete
            v-model="searchModel" 
            :search-input.sync="searchSync"
            :items="interractWithObjectsStore ? [] : objects"
            item-text="name"
            item-value="globalId"
            prepend-inner-icon="search"
            @keyup.enter="navigateToSearchInventory" 
            :placeholder="'search_inventory_objects_fulltext' | translate" 
            :menu-props="{ closeOnContentClick: true }"
            ref="searchAutocomplete"
            :hide-no-data="interractWithObjectsStore || (searchModel && searchModel.length < 4)"
            :no-data-text="'no_inventory_objects' | translate"
            hide-details 
            light
            clearable
            clear-icon="fal fa-times-circle"
            :filter="(item) => true"
            solo
            flat
            @update:list-index="index => selectedItem = index"
            data-test="app-search-input"
        >
            <template v-slot:item="data">
                <v-list-item 
                    :to="{ name:'inventory-object', params:{ id: (data.item.id || data.item.globalId) + '' } }"
                    data-test="app-search-result"    
                >
                    <v-list-item-content>
                        {{data.item.name}}
                    </v-list-item-content>
                </v-list-item>
            </template>

        </v-autocomplete>
    </v-flex>
</template>

<script>
import object from 'obj-fe/utils/object';
import backend from 'obj-fe/app/backend';

export default {
    data(){
        return {
            searchModel: '',
            searchSync: null,
            objects: [],
            selectedItem: null
        }
    },
    props:{
        interractWithObjectsStore: Boolean
    },
    computed:{
        query(){
            return this.$store.state.INVENTORY.query;
        },
        queryText(){
            return this.query.text;
        },
        route(){
            return this.$route;
        }
    },
    watch:{
        searchModel(){
            if(!this.searchModel) this.objects = [];
        },
        searchSync(searchModel){
            if(!this.searchSync || this.searchSync.length < 4) this.objects = []
            this.searchDebounced(searchModel);
        },
        queryText:{
            immediate:true,
            handler(text){
                this.searchSync = text === '*' ? '' : text;
            }
        },
        route(){
            if(this.route.name === 'inventory-search' && (!this.route.query.text || this.route.query.text === '')) this.searchSync = ''
        }
    },
    methods:{
        searchDebounced: object.debounce(function(val){
            if(!val && this.queryText !== '*') this.search('*');
            else {
                if(this.interractWithObjectsStore) this.search(val);
                else if(!!val && val.length >= 3) this.search(val);
            }
        }, 150),
        search(searchModel){
            if(this.interractWithObjectsStore){
                this.$store.dispatch('INVENTORY/UPDATE_QUERY', { text:searchModel || '*' });
                if(this.$store.state.settings.useDms) this.$store.dispatch('DMS_INVENTORY/UPDATE_QUERY', searchModel || '*');
            } 
            else{
                this.lastSearchRequestId = backend.objects.search({ 
                    text:searchModel || '*', categoriesStr: [],
                    page: 1,
                    limit: 20 
                }, (data, status, headers, rawData, requestId) => {
                    // because of debouncing, ignore responses which are from not last request
                    if(this.lastSearchRequestId !== requestId) return;

                    let fetchedObjects = (data || []).filter(e => !!e.globalId).map(object => {
                        if(object.typeDisplayString) object.name = object.name + ' (' + object.typeDisplayString + ')';
                        return object;
                    });

                    this.objects = fetchedObjects;
                });
            }
        },
        navigateToSearchInventory(){
            if(this.selectedItem > -1) return;
            this.$refs['searchAutocomplete'].isMenuActive = false;

            if(!this.interractWithObjectsStore) this.$router.push({ 
                name: 'inventory-search', 
                query: {
                    text: this.searchSync || '*',
                    page: 1,
                    limit: 20
            }})
        },
    }
}
</script>

<style>

</style>