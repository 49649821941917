<template>
    <v-tooltip top>
        <template v-slot:activator="{ on }">
            <v-btn
                v-on="on"
                icon
                @click="$emit('bookmarked')"
            >
                <v-icon
                    color="accent"
                    class="inv-bookmark"
                >
                    {{ (bookmarked ? 'fas ' : 'fal ') + 'fa-star' }}
                </v-icon>
            </v-btn>
        </template>
        <span>
            {{'bookmark' | translate}}
        </span>
    </v-tooltip>
</template>

<script>
export default {
    props: {
        bookmarked: Boolean
    }
}
</script>
<style scoped>
.inv-bookmark{
    margin: 0 10px;
    cursor: pointer;
}
.inv-bookmark.v-icon::after{
    display: none
}
</style>