<template>
    <v-card 
        flat 
        class="inv-report-card"
    >
        <div>
            <v-card flat class="general-info-card">
                <v-card-title>
                    <div class="flex align-center">
                        <h2 
                            v-if="description"
                            data-test="report-name"
                            class="inline mr-1"
                        >
                            {{ description.displayString }}
                        </h2>

                        <bookmark 
                            v-if="description"
                            :bookmarked="description.bookmarked"
                            @bookmarked="bookmark"
                        />
                    </div>

                    <v-spacer/>

                    <!-- filters -->
                    <div 
                        class="report-filters"
                        v-if="description && description.additionalFilters"
                    >
                        <obj-report-filter  
                            v-for="(filter, i) in description.additionalFilters"
                            :key="i"
                            :field="filter" 
                            :value="query.filter ? query.filter[filter.columnName] : undefined" 
                            @input="(v, valueTexts) => setFilterValue(filter.columnName, v, valueTexts)" 
                            :lazy-change="!description.manualTriggerLoad" 
                            :change-debounce="description.manualTriggerLoad ? 400 : 0" 
                            clearable
                            :data-test="'report_additional-filter_' + filter.columnName"
                        />
                    </div>

                    <v-spacer/>

                    <div class="report-main-buttons">

                        <inv-custom-report-buttons
                            :buttons="buttons"
                            @clicked="button => reportActionButton = { ...button }"
                        />

                        <!-- excel buttons -->
                        <v-menu
                            content-class="button-menu"
                            offset-y
                            :nudge-width="200"
                            offset-x
                            v-if="description && (
                                description.manualTriggerLoad || 
                                description.exportImport ||
                                description.excelEnabled
                            )"
                        >
                            <template v-slot:activator="{ on: menu }">
                                <v-divider vertical/>
                                

                                <v-tooltip top>
                                    <template v-slot:activator="{on: tooltip}">
                                        <v-btn
                                            v-on="{...tooltip, ...menu}"
                                            class="expand-actions-button obj-report-button"
                                            icon
                                            large
                                            color="green"
                                        >
                                            <v-icon>
                                                fal fa-file-spreadsheet
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>
                                        {{ "import-export-data" | translate }}
                                    </span>
                                </v-tooltip>
                            </template>

                            <v-card>
                                <v-card-text>
                                    <div class="inv-object-button-group inv-object-button-group--column-oriented">
                                        <obj-report-btn-load :report-id="reportId" :displayBlock="true"></obj-report-btn-load>
                                        <obj-report-btn-export-excel :report-id="reportId" :displayBlock="true"></obj-report-btn-export-excel>
                                        <obj-report-btn-export-send :report-id="reportId" :displayBlock="true"></obj-report-btn-export-send>
                                        <obj-report-btn-export-schedule :report-id="reportId" :displayBlock="true"></obj-report-btn-export-schedule>
                                        <v-divider class="my-2" v-if="description.exportImport"/>
                                        <obj-report-btn-import-data :report-id="reportId" :displayBlock="true"></obj-report-btn-import-data>
                                        <obj-report-btn-export-data :report-id="reportId" :displayBlock="true"></obj-report-btn-export-data>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-menu>

                        <!-- add new buttons -->
                        <v-menu
                            v-model="addNewButtonModel"
                            content-class="button-menu"
                            offset-y
                            :nudge-width="200"
                            offset-x
                            v-if="description && ((description.entityDetailTypes && description.entityDetailTypes.length > 0) || description.canCreate)"
                        >
                            <template v-slot:activator="{ on: menu }">

                                <v-tooltip top>
                                    <template v-slot:activator="{on: tooltip}">
                                        <v-btn
                                            v-on="{...tooltip, ...menu}"
                                            class="expand-actions-button obj-report-button"
                                            icon
                                            large
                                            color="accent"
                                            data-test="add-new-entity"
                                        >
                                            <v-icon>
                                                fal fa-plus
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>
                                        {{ "add_new" | translate }}…
                                    </span>
                                </v-tooltip>
                            </template>

                            <v-card>
                                <v-card-text>
                                    <div class="inv-object-button-group inv-object-button-group--column-oriented">
                                        <obj-report-btn-create :report-id="reportId" :displayBlock="true"></obj-report-btn-create>
                                        <obj-report-btn-menu :report-id="reportId" @add-new-entity="(type) => {addNew(type); addNewButtonModel = false}" :displayBlock="true"></obj-report-btn-menu>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-menu>

                        <!-- save button -->
                        <v-tooltip top>
                            <template v-slot:activator="{on}">
                                <v-btn 
                                    v-on="on"
                                    :disabled="!hasDirtyFields || !isValid" 
                                    @click="saveReport(reportId)" 
                                    x-large
                                    :outlined="hasDirtyFields && isValid"
                                    :elevation="(hasDirtyFields && isValid) ? 24 : 0"
                                    icon
                                    min-width="44px"
                                    color="success"
                                    data-test="save-report"
                                >
                                    <v-icon>fal fa-save</v-icon>
                                </v-btn>
                            </template>
                            <span>
                                {{'save' | translate}}
                            </span>                            
                        </v-tooltip>
                    </div>

                    <!-- TODO getToolbarAddonComponent -->
                    
                </v-card-title>
            </v-card>
        </div>
        
        <v-card-text>
            
            <component
                :is="useSpreadsheetReport ? 'inv-spreadsheet-report' : 'obj-report'"
                :report-id="reportId" 
                full-height 
                @be-button-clicked="button => reportActionButton = button"
                @is-dirty="isDirty = $event; $emit('is-dirty', isDirty)" 
                @open-detail="openDetail"
                :reload-filters="shouldReloadFilters"
                @filters-reloaded="shouldReloadFilters = false"
                :data-test="'obj-report_' + reportId"
            />
            
            <v-navigation-drawer 
                v-model="detail.opened" 
                absolute 
                right clipped 
                :width="drawerWidth" 
                z-index="1"
                data-test="report-object-drawer"
                >
                <inv-object v-if="detail.objectId" 
                            :id="detail.objectId" 
                            @close="detail.opened = false" 
                            @created="newEntityCreated" 
                            @updated="reloadReport"
                            enable-delete 
                            @delete="removeObject"
                            @forceDelete="removeObject"
                            :create-url-template="description ? description.entityDetailCreateUrl : null"
                            :update-url-template="description ? description.entityDetailExpandUrl : null">
                </inv-object>
            </v-navigation-drawer>
            
            <inv-report-actions
                :button="reportActionButton"
                @closed="reportActionButton = null"
                @grid-reload-needed="reloadReport"
            />

            <obj-leave-confirm :is-dirty="isDirty" on-path-change/>
        </v-card-text>
    </v-card>
</template>

<style scoped>
    .report-filters {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .report-main-buttons {
        display: flex;
        align-items: center;
        gap: 4px;
    }
</style>

<script>
import backend from 'obj-fe/app/backend';
    
import ObjReportPage from 'obj-fe/modules/reports/components/obj-report-page.vue';
import InvCustomReportActions from './inv-report-actions.vue';
import InvCustomReportButtons from './inv-custom-report-buttons.vue';
import InvSpreadsheetReport from './inv-spreadsheet-report.vue';

export default {
    components:{
        ObjReportPage,
        InvCustomReportActions,
        InvCustomReportButtons,
        InvSpreadsheetReport
    },
    data(){
        return {
            reportActionButton: null,
            detail:{
                opened: false,
                objectId: null
            },

            addNewButtonModel: false,

            isDirty: false,
            shouldReloadFilters: false,
        }
    },
    computed:{
        useSpreadsheetReport() {
            return this.$store.state.settings.useSpreadsheetReport;
        },
        reportId(){
            return this.$route.params.reportId;
        },
        reportState(){
            return this.$store.state.REPORTS.reportsById[ this.reportId ];
        },
        description(){
            return this.reportState.description;
        },
        query(){
            return this.reportState.query;
        },
        isValid(){
            return !!this.reportState.valid;
        },
        hasDirtyFields(){
            return Object.keys(this.reportState.removedRows || {}).length > 0 || Object.keys(this.reportState.form || {}).length > 0;
        },
        drawerWidth() {
            return Math.min(window.innerWidth / 1.5, 1600);
        },
        buttons() {
            if(!this.description || !this.description.buttons) return [];
            return this.description.buttons;
        },
    },
    methods:{
        saveReport(){
            this.$store.dispatch('REPORTS/SAVE_REPORT', this.reportId);
            this.shouldReloadFilters = true;
        },
        reloadReport(){
            this.$store.dispatch('REPORTS/LOAD_REPORT_DATA', this.reportId);
        },
        bookmark(){
            this.$store.dispatch('REPORTS/APPOINT_REPORT_BOOKMARKED', this.reportId);
        },
        newEntityCreated(newObjectId){
            this.detail.objectId = null;
            // fields in object might have not gotten destroyed
            setTimeout(()=>{
                this.detail.objectId = newObjectId;
                this.reloadReport();
            }, 100)
        },
        removeObject(){
            this.detail.opened = false;
            this.reloadReport();
        },
        setFilterValue(columnName, value, valueTexts){
            this.$store.dispatch('REPORTS/SET_REPORT_QUERY', {
                reportId: this.reportId,
                query:{
                    filter:{
                        [columnName]: value
                    },
                    filterValueTexts:{
                        ...this.query.filterValueTexts,
                        [columnName]: valueTexts
                    },
                    page: 1
                }
            });
        },
        openDetail(rowData){
            if(!rowData) this.detail.opened = false;
            else {
                this.detail.objectId = null;
                // consider this.$nextTick(() => {})
                setTimeout(()=>{
                    this.detail.objectId = rowData.id;
                    this.detail.opened = true;
                    this.$store.dispatch('INVENTORY/LOAD_OBJECT_DETAILS', {
                        objectId: this.detail.objectId,
                        changeHeadTitle: false,
                        urlTemplate: this.description.entityDetailExpandUrl
                    });
                }, 100)
            }
        },
        addNew(type){
            this.$store.dispatch('INVENTORY/ADD_NEW', { type, urlTemplate:this.description.entityDetailCreateUrl }).then(newObject => {
                this.detail.objectId = newObject.id;
                this.detail.opened = true;
            });
        },
    }
}
</script>