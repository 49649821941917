import store from 'obj-fe/app/store';
import backend from 'obj-fe/app/backend';
import Vue from 'vue';

function InitState(){
    this.dmsIsUp = true;
    this.dmsErrorMessage = null;

    this.objectsById = {};
    this.objectIds = [];

    this.query = {
        text: '*',
    };
    this.pagination = {
        next: false,
        prev: false,
        page: 1,
        pages: 1,
        limit: 10,
        count: 0
    };

}

store.registerModule('DMS_INVENTORY', {
    namespaced: true,
    state: new InitState(),
    actions:{
        ROUTE_CHANGE_START:{
            root: true,
            handler(ctx, route){
                if(!ctx.rootState.settings.useDms) return;

                // would search
                if(route.name === 'inventory-search') {
                    ctx.dispatch('CLEAR');
                    ctx.dispatch('UPDATE_QUERY', route.query.text);
                }
            }
        },
        CLEAR(ctx){
            ctx.commit('CLEAR');
        },
        LOAD_OBJECTS(ctx){
            
            let requestObject = {
                fullTextFilter: {
                    filter: ctx.state.query.text 
                },
                pageNumberFromOne: ctx.state.pagination.page,
                pageSize: ctx.state.pagination.limit
            }
            
            backend.dms.documents(
                requestObject,
                // on success
                (documentsObject) => {
                    if(documentsObject.status === 'NOK') {
                        ctx.commit('SET_DMS_IS_UP', false);
                        ctx.commit('SET_ERROR_MESSAGE', documentsObject.statusMessage )
                    }
                    ctx.commit('SET_OBJECTS', documentsObject.resultList);
                    ctx.commit('SET_PAGINATION_COUNT', documentsObject.totalNumberFound)
                    ctx.commit('SET_PAGINATION_NEXT', ctx.state.pagination.page * ctx.state.pagination.limit < documentsObject.totalNumberFound)
                    ctx.commit('SET_PAGINATION_PREV', ctx.state.pagination.page > 1)
                }
            )
        },
        UPDATE_QUERY(ctx, query){
            ctx.commit('UPDATE_QUERY', query);
            ctx.dispatch('LOAD_OBJECTS');
        },
        RESET_QUERY(ctx){
            ctx.commit('CLEAR');
            ctx.dispatch('LOAD_OBJECTS');
        },
        UPDATE_PAGINATION(ctx, pagination){
            ctx.commit('SET_PAGINATION', pagination);
            ctx.dispatch('LOAD_OBJECTS');
        },
        UPDATE_PAGE(ctx, page){
            ctx.commit('SET_PAGE', page);
            ctx.dispatch('LOAD_OBJECTS');

        }
    },
    mutations:{
        CLEAR(state){
            let initState = new InitState();
            for(let key in initState) Vue.set(state, key, initState[key]);
        },
        SET_PAGINATION(state, pagination){
            state.pagination = pagination;
        },
        SET_PAGE(state, page){
            state.pagination.page = page;
        },
        SET_PAGINATION_COUNT(state, newCount){
            state.pagination.count = newCount;
        },
        SET_OBJECTS(state, objects){
            let objectIds = [], objectsById = {};
            
            objects.forEach(obj => {
                obj.id = obj.id || obj.globalId;
                objectIds.push(obj.id);
                objectsById[ obj.id ] = obj;
            });

            state.objectIds = objectIds;
            state.objectsById = objectsById;
        },

        UPDATE_QUERY(state, query){
            state.query.text = query;
        },
        SET_PAGINATION(state, pagination){
            state.pagination = pagination;
        },
        SET_DMS_IS_UP(state, newDmsStatus){
            state.dmsIsUp = newDmsStatus;
        },
        SET_PAGINATION_PREV(state, newPrev){
            state.pagination.prev = newPrev;  
        },
        SET_PAGINATION_NEXT(state, newNext){
            state.pagination.next = newNext;     
        },
        SET_ERROR_MESSAGE(state, message){
            state.dmsErrorMessage = message
        }
    }
});