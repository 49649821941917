<template>
    <div :style="{ 'white-space': field.editor === 'TEXTAREA' || field.renderer === 'TEXT_WRAP' ? 'pre' : null }">{{viewValue}}<span v-if="field.buttons" class="cell-buttons-container">
            <obj-report-btn-be v-for="(beButton, i) in field.buttons" :key="i" :beButton="beButton" @be-button-clicked="emitButtonClicked(beButton)"/>
        </span>
    </div>
</template>

<style scoped>
    .cell-buttons-container {
        display: inline-block;
        margin: -8px 0px -8px -6px;
    }
</style>

<script>
    import rendererMixin from '../../components/spreadsheet/spreadsheet-renderer-mixin';

    export default {
        mixins:[ rendererMixin ],
        computed:{
            field(){
                return this.row._origFields[ this.column.dataKey ];
            }
        },
        methods:{
            emitButtonClicked(beButton){
                this.getSpreadsheetComponent().$emit('be-button-clicked', beButton);
            }
        }
    }
</script>