import locals from 'obj-fe/services/localisation';
import isEmptyValue from './is-empty-value';

export default {
    dataType:{
        async: false,
        runPerChange: true,
        handler(opts, change){
            let rowIndex = change.rowIndex;
            let colIndex = change.colIndex;
            let rowContext = this.getRowContext(rowIndex);
            let row = this.getRow(rowIndex);
            let column = this.getColumn(row.rowType, colIndex);

            if(typeof opts === 'function') opts = opts.call(this, { ...change, row, column, rowContext });

            let dataType = this.columnDataTypes[opts || 'string'];
            if(!dataType) throw new Error('Data type "'+opts+'" not registered');
            
            if(dataType.validate && !dataType.validate.call(this, { ...change, row, column, rowContext })) {
                // invalid value, decide if it should be cleared or no
                let sanitizedValue = dataType.sanitize ? dataType.sanitize.call(this, { ...change, row, column, rowContext }) : change.value;

                if((sanitizedValue !== change.value) && (JSON.stringify(sanitizedValue) !== JSON.stringify(change.value))) {
                    change.value = sanitizedValue; // overriding change value for all next change watchers
                    this.setCellValue(rowIndex, colIndex, sanitizedValue, true);
                }

                this.addCellError(rowIndex, colIndex, locals.translate('value_is_not_valid') + ' "'+locals.translate(opts)+'"');
                return;
            }

            let parsedValue = dataType.parse ? dataType.parse.call(this, { ...change, row, column, rowContext }) : change.value;
            if((parsedValue !== change.value) && (JSON.stringify(parsedValue) !== JSON.stringify(change.value))) {
                change.value = parsedValue; // overriding change value for all next change watchers
                this.setCellValue(rowIndex, colIndex, parsedValue, true);
            }
        }
    },
    watchDataKeys:{
        async: false,
        runPerChange: true,
        handler(opts, change){
            let rowIndex = change.rowIndex;
            let colIndex = change.colIndex;
            let row = this.getRow(rowIndex);
            let column = this.getColumn(row.rowType, colIndex);
            if(typeof opts === 'function') opts = opts.call(this, { ...change, row, column, rowContext:this.getRowContext(rowIndex) });
            
            if(!opts) opts = [];
            if(typeof opts === 'string') opts = [opts];
            if(!Array.isArray(opts)) opts = [];

            let cellDataWatchers = row._state.cellDataWatchers || {};
            let allDataKeys = { ...cellDataWatchers };
            opts.forEach(dataKey => { allDataKeys[ dataKey ] = true; });

            for(let dataKey in allDataKeys){
                let isWatched = allDataKeys[dataKey] === true;
                if(!cellDataWatchers[ dataKey ]) cellDataWatchers[ dataKey ] = {};

                if(isWatched) cellDataWatchers[ dataKey ][ colIndex ] = true;
                else delete cellDataWatchers[ dataKey ][ colIndex ];
            }

            this.$set(row._state, 'cellDataWatchers', cellDataWatchers);
        }
    },
    required:{
        async: false,
        runPerChange: true,
        handler(opts, change){
            let rowIndex = change.rowIndex;
            let colIndex = change.colIndex;
            let row = this.getRow(rowIndex);
            let column = this.getColumn(row.rowType, colIndex);
            if(typeof opts === 'function') opts = opts.call(this, { ...change, row, column, rowContext:this.getRowContext(rowIndex) });

            this.$set(row._state.requiredCells, colIndex, opts);

            // not required
            if(opts !== true) return;

            if(opts === true) {
                if(isEmptyValue(change.value)) {
                    this.addCellError(rowIndex, colIndex, locals.translate('value_is_required'));
                    return;
                }

                // checking empty values is now defined in dataType
                let dataType = typeof column.dataType === 'function' ? column.dataType.call(this, { ...change, row, column, rowContext:this.getRowContext(rowIndex) }) : column.dataType;
                let isNotEmpty = (this.columnDataTypes[dataType] || {}).isNotEmpty;
                if(typeof isNotEmpty === 'function' && !isNotEmpty.call(this, { ...change, row, column, rowContext:this.getRowContext(rowIndex) })){
                    this.addCellError(rowIndex, colIndex, locals.translate('value_is_required'));
                }
            }
        }
    },
    unique:{
        async: false,
        runPerChange: false,
        handler(opts, changes){
            if(opts !== true) return;

            let errMessage = locals.translate('value_must_be_unique_in_this_column');
            let rowType = this.getRow( changes[0].rowIndex ).rowType;
            let colValuesByIndex = {};
            changes.forEach(change => colValuesByIndex[ change.colIndex ] = {});

            this.rows.forEach((row, rowIndex) => {
                if(row.rowType !== rowType) return; // compare only values from target row type

                for(let colIndex in colValuesByIndex){
                    let dataKey = this.columns[colIndex].dataKey;
                    let cellValue = row[dataKey];
                    if(isEmptyValue(cellValue)) return;
                    if(colValuesByIndex[colIndex][cellValue]) this.addCellError(rowIndex, colIndex, errMessage);
                    else {
                        colValuesByIndex[colIndex][cellValue] = true;
                        this.removeCellError(rowIndex, colIndex, errMessage);
                    }
                }
            });
        }
    },
    values:{
        async: false,
        runPerChange: true,
        handler(opts, change){
            let rowIndex = change.rowIndex;
            let colIndex = change.colIndex;
            let row = this.getRow(rowIndex);
            let column = this.getColumn(row.rowType, colIndex);
            if(typeof opts === 'function') opts = opts.call(this, { ...change, row, column, rowContext:this.getRowContext(rowIndex) });
            if(!Array.isArray(opts) || opts.length === 0) return;

            // empty value
            if(isEmptyValue(change.value)) return;

            // single value
            if(!Array.isArray(change.value)) {
                if(opts.indexOf(change.value) === -1) this.addCellError(rowIndex, colIndex, locals.translate('value') + ' "'+change.value+'" ' + locals.translate('is_not_in') +': ' + opts.join(', '));
                return;
            }

            // multi value
            change.value.forEach(value => {
                if(opts.indexOf(value) === -1) {
                    this.addCellError(rowIndex, colIndex, locals.translate('value') +' "'+value+'" '+ locals.translate('is_not_in') + ': ' + opts.join(', '));
                }
            });
        }
    },
    notEmptyLocalObject:{
        async: false,
        runPerChange: true,
        handler(opts, change){
            let isEmptyLocale = true;
            for(let key in (change.value || {})){
                if(change.value[key]) return;
            }

            if(isEmptyLocale) {
                this.addCellError(change.rowIndex, change.colIndex, locals.translate('missing_translations'));
            }
        }
    },
    // triggerColumnWatchers:{
    //     async: true,
    //     runPerChange: true,
    //     handler(opts, change, done){
    //         if(!Array.isArray(opts) || opts.length === 0) return done();

    //         // trigger watchers on columns in opts
    //         setImmediate(() => this.triggerColumnWatchers(opts, change, done));
    //     }
    // },
    render:{
        global: true,
        async: false,
        runPerChange: true,
        handler(opts, change){
            let colIndex = change.colIndex;
            let rowIndex = change.rowIndex;

            let row = this.getRow(rowIndex);
            let column = this.getColumn(row.rowType, colIndex);
            
            let rowContext = this.getRowContext(rowIndex);
            let dataType = typeof column.dataType === 'function' ? column.dataType.call(this, { ...change, row, column, rowContext }) : column.dataType;

            let value = (isEmptyValue(change.value)) ? '' : change.value + '';

            if(typeof opts === 'function') {
                value = opts.call(this, { ...change, row, column, rowContext });
            }
            else {
                let render = (this.columnDataTypes[dataType] || {}).render;
                if(typeof render === 'function') value = render.call(this, { ...change, row, column, rowContext });
            }
            
            this.$set(row._state.cellViewValues, change.colIndex, value);
        }
    },
    href:{
        global: true,
        async: false,
        runPerChange: true,
        handler(opts, change){
            let colIndex = change.colIndex;
            let rowIndex = change.rowIndex;

            let row = this.getRow(rowIndex);
            let column = this.getColumn(row.rowType, colIndex);
            let rowContext = this.getRowContext(rowIndex);

            let hrefOpts = opts;
            if(typeof opts === 'function') {
                hrefOpts = opts.call(this, { ...change, row, column, rowContext });
            }

            // hrefOpts = { url:'http://...', target:'_blank/_self/...' };
            // default target is _blank
            if(typeof hrefOpts === 'string') {
                hrefOpts = { url:hrefOpts }
            }
            if(hrefOpts && !hrefOpts.url) hrefOpts = null;
            
            this.$set(row._state.cellHrefs, change.colIndex, hrefOpts);
        }
    },
    info:{
        global: true,
        async: false,
        runPerChange: true,
        handler(opts, change){
            let colIndex = change.colIndex;
            let rowIndex = change.rowIndex;

            let row = this.getRow(rowIndex);
            let column = this.getColumn(row.rowType, colIndex);
            let rowContext = this.getRowContext(rowIndex);

            let infos = opts;
            if(typeof opts === 'function') {
                infos = opts.call(this, { ...change, row, column, rowContext });
            }

            if(infos && !Array.isArray(infos)) infos = [ infos ];
            
            this.$set(row._state.cellInfos, change.colIndex, infos);
        }
    },
    readonly:{
        global: true,
        async: false,
        runPerChange: true,
        handler(opts, change){
            let rowIndex = change.rowIndex;
            let colIndex = change.colIndex;
            let row = this.getRow(rowIndex);
            let column = this.getColumn(row.rowType, colIndex);
            let dataType = typeof column.dataType === 'function' ? column.dataType.call(this, { ...change, row, column, rowContext:this.getRowContext(rowIndex) }) : column.dataType;

            let value = false;
            if(typeof opts === 'boolean') value = opts;
            else if(typeof opts === 'function') {
                value = opts.call(this, { ...change, row, column, rowContext:this.getRowContext(rowIndex) });
            }
            else {
                let readonly = (this.columnDataTypes[dataType] || {}).readonly;
                if(typeof readonly === 'boolean') value = readonly;
                else if(typeof readonly === 'function') value = readonly.call(this, { ...change, row, column, rowContext:this.getRowContext(rowIndex) });
            }
            
            this.$set(row._state.readonlyCells, colIndex, !!value);
        }
    },
    editable:{
        global: true,
        async: false,
        runPerChange: true,
        handler(opts, change){
            let rowIndex = change.rowIndex;
            let colIndex = change.colIndex;
            let row = this.getRow(rowIndex);
            let column = this.getColumn(row.rowType, colIndex);
            let dataType = typeof column.dataType === 'function' ? column.dataType.call(this, { ...change, row, column, rowContext:this.getRowContext(rowIndex) }) : column.dataType;

            let value = true;
            if(typeof opts === 'boolean') value = opts;
            else if(typeof opts === 'function') {
                value = opts.call(this, { ...change, row, column, rowContext:this.getRowContext(rowIndex) });
            }
            else {
                let editable = (this.columnDataTypes[dataType] || {}).editable;
                if(typeof editable === 'boolean') value = editable;
                else if(typeof editable === 'function') value = editable.call(this, { ...change, row, column, rowContext:this.getRowContext(rowIndex) });
            }
            
            this.$set(row._state.editableCells, colIndex, !!value);
        }
    },
    disabled:{
        global: true,
        async: false,
        runPerChange: true,
        handler(opts, change){
            let rowIndex = change.rowIndex;
            let colIndex = change.colIndex;
            let row = this.getRow(rowIndex);
            let column = this.getColumn(row.rowType, colIndex);
            let dataType = typeof column.dataType === 'function' ? column.dataType.call(this, { ...change, row, column, rowContext:this.getRowContext(rowIndex) }) : column.dataType;
            let value = false;
            if(typeof opts === 'boolean') value = opts;
            else if(typeof opts === 'function') {
                value = opts.call(this, { ...change, row, column, rowContext:this.getRowContext(rowIndex) });
            }
            else {
                let disabled = (this.columnDataTypes[dataType] || {}).disabled;
                if(typeof disabled === 'boolean') value = disabled;
                else if(typeof disabled === 'function') value = disabled.call(this, { ...change, row, column, rowContext:this.getRowContext(rowIndex) });
            }
            
            this.$set(row._state.disabledCells, colIndex, !!value);
        }
    },
    renderer:{
        global: true,
        async: false,
        runPerChange: true,
        handler(opts, change){
            let rowIndex = change.rowIndex;
            let colIndex = change.colIndex;
            let row = this.getRow(rowIndex);
            let column = this.getColumn(row.rowType, colIndex);
            let dataType = typeof column.dataType === 'function' ? column.dataType.call(this, { ...change, row, column, rowContext:this.getRowContext(rowIndex) }) : column.dataType;
            dataType = this.columnDataTypes[dataType];
            
            let defaultValue = (dataType ? dataType.renderer : null) || 'SpreadsheetRendererDefault';
            let value;

            if(typeof opts === 'function') {
                value = opts.call(this, { ...change, row, column, rowContext:this.getRowContext(rowIndex) });
            }
            else if(opts) value = opts;

            this.$set(row._state.cellRenderers, colIndex, value || defaultValue);
        }
    },
    editor:{
        global: true,
        async: false,
        runPerChange: true,
        handler(opts, change){
            let rowIndex = change.rowIndex;
            let colIndex = change.colIndex;
            let row = this.getRow(rowIndex);
            let column = this.getColumn(row.rowType, colIndex);
            let dataType = typeof column.dataType === 'function' ? column.dataType.call(this, { ...change, row, column, rowContext:this.getRowContext(rowIndex) }) : column.dataType;
            dataType = this.columnDataTypes[dataType];
            
            let defaultValue = (dataType ? dataType.editor : null) || 'SpreadsheetEditorDefault';
            let value;

            if(typeof opts === 'function') {
                value = opts.call(this, { ...change, row, column, rowContext:this.getRowContext(rowIndex) });
            }
            else if(opts) value = opts;
            
            this.$set(row._state.cellEditors, colIndex, value || defaultValue);
        }
    },
    label:{
        global: true,
        async: false,
        runPerChange: true,
        handler(opts, change){
            let rowIndex = change.rowIndex;
            let colIndex = change.colIndex;
            let row = this.getRow(rowIndex);
            let column = this.getColumn(row.rowType, colIndex);
            let label = typeof column.label === 'function' ? column.label.call(this, { ...change, row, column, rowContext:this.getRowContext(rowIndex) }) : column.label;
            
            if(label) label = locals.translate(label);
            this.$set(row._state.cellLabels, colIndex, label);
        }
    },
    style:{
        global: true,
        async: false,
        runPerChange: true,
        handler(opts, change){
            let rowIndex = change.rowIndex;
            let colIndex = change.colIndex;
            let row = this.getRow(rowIndex);
            let column = this.getColumn(row.rowType, colIndex);
            let style = typeof column.style === 'function' ? column.style.call(this, { ...change, row, column, rowContext:this.getRowContext(rowIndex) }) : column.style;
            
            if(style || row._state.cellStyles[colIndex]) {
                this.$set(row._state.cellStyles, colIndex, style);
            }
        }
    },
    adhocWatch:{
        global: true,
        async: false,
        runPerChange: false,
        handler(opts, changes){
            if(Object.keys(this.adhocWatchers).length === 0) return;

            changes.forEach(change => {
                // row watch
                let rowWatcher = this.adhocWatchers[ change.rowIndex + ':' ];
                if(rowWatcher) rowWatcher(change);

                // col watch
                let colWatcher = this.adhocWatchers[ ':' + change.colIndex ];
                if(colWatcher) colWatcher(change);

                // cell watch
                let cellWatcher = this.adhocWatchers[ change.rowIndex + ':' + change.colIndex ];
                if(cellWatcher) cellWatcher(change);
            });
        }
    }
};