<template>
    <v-card flat data-test="collectionsList-card">
        <v-card-title>
            <v-text-field   v-model="searchPhrase"
                            :label="'filter_types' | translate"
                            prepend-inner-icon="search">
            </v-text-field>
        </v-card-title>

        <obj-table :headers="headers" :items="reportsList" item-key="treePath" full-height >
            <template slot="no-data">
                <div style="text-align:center">
                    <span v-if="!!searchPhrase">{{ 'no_reports_match' | translate }} "{{ searchPhrase }}"</span>
                    <span v-else-if="noReportsAvailable">{{ 'no_reports_available_to_run' | translate }}</span>
                    <span v-else>{{ 'loading' | translate }}</span>
                </div>
            </template>

            <template slot="row" slot-scope="row">
                <!-- <tr :style="{ 'background-color': row.item.isFolder ? 'white' : 'none' }"> -->
                <td style="white-space: nowrap"
                    :style="{'padding-left': (row.value.folders ? (row.value.folders.length - (row.value.isFolder ? 1 : 0)) * 3 * 20 : 0) + 30 + 'px'}">
                    <template v-if="row.value.isFolder">
                        <v-icon class="report-list-standard-icon">fal fa-folder-open</v-icon>
                        {{ row.value.name }}
                    </template>
                    <router-link v-else :to="row.value.href" class="report-list-href">
                        <v-icon style="margin-right:5px">{{ 'fal ' + row.value.icon ? row.value.icon : 'insert_drive_file' }}</v-icon>
                        <span>{{ row.value.name }}</span>
                    </router-link>
                </td>
                <td>
                    {{ row.value.description }}
                </td>
            </template>
        </obj-table>
    </v-card>
</template>

<script>
    export default {
        data() {
            return {
                searchPhrase: '',
                headers: [
                    {
                        text: 'Collections',
                        align: 'left',
                        sortable: false,
                        value: 'name'
                    }, {
                        text: 'Description',
                        align: 'left',
                        sortable: false,
                        value: 'description'
                    }
                ]
            };
        },
        computed: {
            reportsList() {
                const searchPhrase = this.searchPhrase.toLowerCase().trim();
                const reportsList = this.$store.state['COLLECTIONS'].collectionsList;

                const matchedPaths = [];
                reportsList.forEach(item => {
                    if (item.isFolder !== true && item.name.toLowerCase().indexOf(searchPhrase) > -1) matchedPaths.push(item.orderIndex);
                });

                return reportsList.filter(item => {
                    for (let matchedPath of matchedPaths) {
                        if (matchedPath.indexOf(item.orderIndex) === 0) return true;
                    }
                    return false;
                });
            },
            noReportsAvailable() {
                return this.$store.state['REPORTS'].reportsList.length === 0;
            }
        }
    }
</script>

<style scoped>
.report-list-href{
    text-decoration: none;
}
.report-list-href span {
    text-decoration: underline;
}
.report-list-href .v-icon,
.report-list-standard-icon{
    text-decoration: none;
    margin-right: 10px;
    color: #000000de;
}    
</style>
