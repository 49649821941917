<template>
    <div ref="editor" :class="{ 'code-readonly':readonly }" style="height: 100%; max-height: 100%; overflow: auto"></div>
    <!-- :style="{ height: autoHeight ? 'auto' : null }" -->
</template>

<script>
    import CodeMirror from 'codemirror';
    import nwdslMode from './inv-nwdsl-mode.js';
    import 'codemirror/addon/display/placeholder';
    

    export default {
        props:{
            value:{},
            readonly: Boolean,
            placeholder: String,
            autoHeight: {
                type: Boolean,
                default: true
            },
            minHeight:{},
            returnQueryObj: Boolean,
            json: Boolean,
            validateResources: Function,
            suggestResources: Function,
            suggestAttributes: Function,
            suggestAttributeValues: Function,
            suggestResourcesForAliases: Function,
            autoFocus: Boolean
        },
        data(){
            return {
                cmValue: null,
                cmText: null
            };
        },
        computed:{
            isDirty(){
                return this.cmText !== this.value;
            }
        },
        methods:{
            updateContent(){
                let ui = this;

                if(!this.$refs.editor) return;

                if(!this.cm){
                    let cmOpts = {
                        // value: typeof this.value !== 'string' ? this.value : '',
                        gutters: [ "CodeMirror-linenumbers", "CodeMirror-foldgutter" ],

                        lineNumbers: true,
                        lineWrapping: true,
                        
                        indentWithTabs: true,
                        indentUnit: 2,
                        tabSize: 2,

                        placeholder: this.placeholder || '',
                        autofocus: this.autoFocus || false
                    };

                    if(this.autoHeight) cmOpts.viewportMargin = Infinity;

                    this.cm = CodeMirror(this.$refs.editor, cmOpts);

                    // TODO: set mode after whole query instance configuration loaded - include enums
                    // nwdslMode.test(this.cm, {
                    //     autoFocus: this.autoFocus,
                    //     validateResources: this.validateResources,
                    //     suggestResources: this.suggestResources,
                    //     suggestAttributes: this.suggestAttributes,
                    //     suggestAttributeValues: this.suggestAttributeValues,
                    //     suggestResourcesForAliases: this.suggestResourcesForAliases
                    // });

                    nwdslMode.init(this.cm, {
                        autoFocus: this.autoFocus,
                        validateResources: this.validateResources,
                        suggestResources: this.suggestResources,
                        suggestAttributes: this.suggestAttributes,
                        suggestAttributeValues: this.suggestAttributeValues,
                        suggestResourcesForAliases: this.suggestResourcesForAliases
                    });

                    // set value before emiting to on change
                    this.setEditorValue(this.value, true);
                    this.cm.on('change', this.onChange);

                    setTimeout(() => this.cm.refresh());

                    // set min height if defined
                    if(this.minHeight) {
                        let scrollPlaceholder = this.$refs.editor.querySelector('.CodeMirror-scroll');
                        if(scrollPlaceholder) scrollPlaceholder.style.minHeight = this.minHeight+'px';
                    }
                }
                else if(this.cmValue !== this.value) this.setEditorValue(this.value);

                this.cmText = this.cm.getValue();
                
                this.cm.setOption('readOnly', this.readonly ? true : false);
            },
            onChange(){
                this.cm.parsedResult.onValidated(() => {
                    this.$emit('valid', this.cm.parsedResult.isValid);
                    
                    if(this.cm.parsedResult.isValid){
                        let value;

                        if(this.returnQueryObj && this.json) {
                            value = JSON.stringify(this.cm.parsedResult.value);
                        }
                        else value = this.cm.getValue();
                        this.cmValue = value;

                        if(this.returnQueryObj) {
                            if(this.json) this.$emit('input', value);
                            else this.$emit('input', this.cm.parsedResult.value);
                        }
                        else this.$emit('input', value);

                        // sometimes parent component needs raw parser result
                        this.$emit('parser-result', this.cm.parsedResult);
                    }
                    else this.$emit('input-invalid');
                });

                this.cmText = this.cm.getValue();
            },
            setEditorValue(value, firstTime){
                if(this.json) value = JSON.parse(value || '[]');

                if(Array.isArray(value)) {
                    this.cm.queryParser.render(value, renderedText => {
                        this.cm.setValue(renderedText);
                        if(firstTime && !renderedText) this.onChange(); // manually trigger on change if empty text
                    });
                }
                else {
                    this.cm.setValue(value || '');
                    if(firstTime && !value) this.onChange(); // manually trigger on change if empty text
                }

                this.cmText = this.cm.getValue();
            },
            isHintActive(){
                return this.cm && this.cm.state.completionActive && !!this.cm.state.completionActive.widget;
            }
        },
        watch:{
            isDirty(val){
                this.$emit('is-dirty', val);
            },
            value(){
                this.$nextTick(() => {
                    this.updateContent();
                });
            },
            placeholder(val){
                if(this.cm) this.cm.setOption('placeholder', val || '');
            }
        },
        mounted(){
            this.$nextTick(() => {
                this.updateContent();
            });
        },
        destroyed(){
            // TODO: find better solution to destroy hints properly, because if there will be more editor instances, it will remove all hints
            document.querySelectorAll('.CodeMirror-hints').forEach(elm => elm.remove());
        }
    }
</script>

<style scoped>
    >>> .CodeMirror .CodeMirror-lines{
        font-family: Consolas,"courier new" !important;
        font-size: 16px !important;
        line-height: 18px !important;
    }
    /* >>> .CodeMirror .CodeMirror-lines{
        padding: 12px !important;
    } */
    >>> .CodeMirror {
        height: auto;
        font-family: inherit;
    }
    >>> .CodeMirror .CodeMirror-foldmarker{
        color: #000;
        font-weight: bold;
        text-shadow: none;
    }

    >>> .CodeMirror .cm-NODE_DEFINITION,
    >>> .CodeMirror .cm-LINK_DEFINITION,
    >>> .CodeMirror .cm-GROUP_DEFINITION,
    >>> .CodeMirror .cm-ENTITIES_DEFINITION,
    >>> .CodeMirror .cm-CONNECTIONS_DEFINITION,
    >>> .CodeMirror .cm-REDUNDANCIES_DEFINITION,
    >>> .CodeMirror .cm-REDUNDANCY{
        font-weight: bold;
    }

    >>> .CodeMirror .cm-LINK_NAME,
    >>> .CodeMirror .cm-NODE_NAME,
    >>> .CodeMirror .cm-GROUP_NAME,
    >>> .CodeMirror .cm-REDUNDANCY_NAME,
    >>> .cm-VALUE{
        font-style: italic;
    }

    >>> .CodeMirror .cm-LINK_NAME,
    >>> .CodeMirror .cm-NODE_NAME,
    >>> .CodeMirror .cm-GROUP_NAME,
    >>> .CodeMirror .cm-REDUNDANCY_NAME{
        font-weight: bolder;
    }

    >>> .CodeMirror .cm-LINK,
    >>> .CodeMirror .cm-NODE,
    >>> .CodeMirror .cm-GROUP{
        font-style: italic;
        font-weight: bold;
    }
    
    >>> .CodeMirror pre.CodeMirror-placeholder {
        color: #999;
    }

    >>> .CodeMirror-gutters{
        z-index: 0;
    }
    >>> .CodeMirror-code .CodeMirror-gutter-wrapper{
        z-index: 3;
    }
    
    .code-readonly .CodeMirror-cursors {
        display: none !important;
    }

    
    >>> .cm-SQUARE_BRACKET_LEFT,
    >>> .cm-SQUARE_BRACKET_RIGHT,
    >>> .cm-VALUE, 
    >>> .cm-ATTRIBUTE_VALUE,
    >>> .cm-ATTRIBUTE_NAME,
    >>> .cm-COMMA,
    >>> .cm-EQUAL{
        color: #607D8B;
        /* text-decoration: underline; */
    }

    >>> .cm-VALUE.CodeMirror-lint-mark-error {
        text-decoration: underline !important;
    }


    >>> .cm-SCONNECTION,
    >>> .cm-CONNECTION{
        font-weight: bolder;
        font-size: 16px;
        line-height: 16px;
        color: #009688
    }

    >>> .cm-GROUP_DEFINITION{
        color: #FF5722;
    }
    >>> .cm-GROUP_NAME {
        color: #F4511E;
    }

    >>> .cm-NODE_DEFINITION {
        color: #42A5F5;
    }
    >>> .cm-NODE_NAME,
    >>> .cm-NODE{
        color: #1E88E5;
    }

    >>> .cm-LINK_DEFINITION{
        color: #4CAF50;
    }
    >>> .cm-LINK_NAME,
    >>> .cm-LINK {
        color: #43A047;
        
    }

    >>> .cm-LOGIC_OP {
        /* background-color: white;
        padding: 2px;
        border-radius: 4px; */
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
    }

    >>> .cm-COMPARE_OP {
        color: orange;
    }
    >>> .CodeMirror-lint-mark-warning{
        background-image: none !important;
        
        box-shadow: inset 0 -0.1em #FFC107;
    }
    >>> .CodeMirror-lint-mark-error{
        background-image: none !important;
        
        box-shadow: inset 0 -0.1em #D50000
    }
    


    /* >>> .CodeMirror-hints {
        position: absolute;
        z-index: 10;
        overflow: hidden;
        list-style: none;

        margin: 0;
        padding: 2px;

        -webkit-box-shadow: 2px 3px 5px rgba(0,0,0,.2);
        -moz-box-shadow: 2px 3px 5px rgba(0,0,0,.2);
        box-shadow: 2px 3px 5px rgba(0,0,0,.2);
        border-radius: 3px;
        border: 1px solid silver;

        background: white;
        font-size: 90%;
        font-family: monospace;

        max-height: 20em;
        overflow-y: auto;
    }

    >>> .CodeMirror-hint {
        margin: 0;
        padding: 0 4px;
        border-radius: 2px;
        white-space: pre;
        color: black;
        cursor: pointer;
    }

    >>> li.CodeMirror-hint-active {
        background: #08f;
        color: white;
    } */
    
    
</style>