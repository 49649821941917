<template>
    <div>
        <span class="cell-buttons-container">
            <v-btn v-if="!row.isNew" text @click="openDetail()" @mousedown="$event.stopPropagation()" :color="row._state.active ? 'accent' : null">
                <v-icon v-if="row._state.active">fal fa-solid fa-square-info</v-icon>
                <v-icon v-else>fal fa-light fa-square-info</v-icon>
                {{'open' | translate}}
            </v-btn>
        </span>
    </div>
</template>

<style scoped>
    .cell-buttons-container {
        display: inline-block;
        margin: -8px 0px -8px -6px;
    }
</style>

<script>
    import rendererMixin from '../../components/spreadsheet/spreadsheet-renderer-mixin';

    export default {
        mixins:[ rendererMixin ],
        methods:{
            openDetail(){
                this.getSpreadsheetComponent().$emit('open-detail', this.row);
                this.getSpreadsheetComponent().$emit('active-changed', this.row);
            }
        }
    }
</script>