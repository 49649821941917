import Vue from 'vue';

import router from 'obj-fe/app/router';

import './store';
import './dms-store';
import InvSearch from './inv-search.vue';
router.addRoutes([{ name:'inventory-search', path: '/search', component: InvSearch, props: true }]);

import InvObject from './inv-object.vue';
import NotFoundPage from '../components/not-found-page.vue';
router.addRoutes([{ name:'inventory-object', path: '/objects/:id', component: InvObject, props: true }]);
router.addRoutes([{ name:'inventory-object-notfound', path: '/objects/', component: NotFoundPage }]);

// extend obj generic form
import genericFormsModule from 'obj-fe/modules/generic-forms';

import InvObjectFormFieldPlacement from './inv-object-form-field-placement.vue';
genericFormsModule.api.registerField({
    match(){
        return this.editModeOff && this.field.renderer === 'PLACEMENT';
    },
    component: InvObjectFormFieldPlacement
});

import InvObjectFormFieldSyncOnly from './inv-object-form-field-sync-only.vue';
genericFormsModule.api.registerField({
    match(){
        return this.editModeOn && this.field.editor === 'SYNC_ONLY';
    },
    component: InvObjectFormFieldSyncOnly
});

// removes circular dependency errors
import InvObjectTabs from './inv-object-tabs.vue';
Vue.component('inv-object-tabs', InvObjectTabs);

import InvObjectFrom from './inv-object-form.vue'
import InvMiniObject from './mini-object/inv-mini-object.vue'

Vue.component('inv-object-form', InvObjectFrom)
Vue.component('inv-mini-object', InvMiniObject);
Vue.component('inv-object', InvObject);
