export default {
    keyPrefix: 'dms-',
    set(key, value) {
        let strVal = JSON.stringify(value);
        window.localStorage.setItem(this.keyPrefix + key, strVal);
        return value;
    },
    get(key) {
        let val = localStorage.getItem(this.keyPrefix + key);

        try { val = JSON.parse(val); }
        catch(err){}

        return val;
    },
    remove(key){
        localStorage.removeItem(this.keyPrefix + key);
    }
};