<template>
    <inv-object-tab-item 
        :tab="tab" 
        :tabIsLoading="tabIsLoading"
        data-test="tab-INTEGRATION"
    >
        <template v-if="expanded && tab" slot="header">
            <div class="inv-object-button-group">
                
                <v-tooltip top>
                    <template v-slot:activator="{on}">
                        <v-btn 
                            v-on="on"
                            v-if="!readonly"
                            @click="resetForm" 
                            :disabled="!isDirty"
                            :elevation="isDirty ? 24 : 0"
                            :outlined="isDirty"
                            color="error" 
                            icon
                            data-test="tab-discard-button"
                        >
                            <v-icon>fal fa-undo-alt</v-icon>
                        </v-btn>
                    </template>
                    <span>
                        {{'discard_changes'|translate}}
                    </span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{on}">
                        <v-btn 
                            v-on="on"
                            v-if="!readonly" 
                            @click="save" 
                            :disabled="!isDirty"
                            :elevation="isDirty ? 24 : 0"
                            :outlined="isDirty"
                            color="success"
                            icon
                            data-test="tab-save-button"
                        >
                            <v-icon>fal fa-save</v-icon>
                        </v-btn>
                    </template>
                    <span>
                        {{'save'|translate}}
                    </span>
                </v-tooltip>
                
            </div>
        </template>

        <template slot="body">
            <v-card flat>
                <v-card-text :class="{ 'tab-body-disabled' : tabIsLoading}" style="padding-bottom: 100px">
                    <inv-object-form :groups="fieldGroups" :edit-mode="!readonly" :edit-mode-switch="false" @field-input="changeFieldValue" :object-id="objectId"></inv-object-form>
                </v-card-text>
            </v-card>
        </template>
    </inv-object-tab-item>
</template>

<script>
    import backend from 'obj-fe/app/backend';

    import tabBehaviour from './inv-object-tab-behaviour';
    import InvObjectTabItem from './inv-object-tab-item.vue';
    import InvObjectForm from '../inv-object-form.vue';

    export default {
        mixins:[ tabBehaviour ],
        components:{
            InvObjectTabItem,
            InvObjectForm
        },
        props: {
            shouldReload: Boolean,
        },
        data(){
            return {
                fields: [],
                isDirty: false
            };
        },
        computed:{
            fieldGroups(){
                return [ { fields:this.fields } ];
            },
            dirtyFields(){
                return this.fields.filter(field => field.hasOwnProperty('origValue')).map(field => {
                    if(field.value === undefined) field.value = null; // undefined value will not be send by front end
                    return field;
                });
            }
        },
        methods:{
            onFirstTimeExpandLoad(successCb, errorCb){
                backend.objects.integrationPanel(
                    this.objectId, 
                    data => {
                        this.fields = data.fields;
                        successCb();
                    }, 
                    errorCb
                );
            },
            changeFieldValue(field, value){
                if(!field.hasOwnProperty('origValue')) field.origValue = field.value;
                field.value = value;
                this.isDirty = true;
            },
            save(){
                backend.objects.updateIntegrationFields(this.objectId, this.dirtyFields, data => {
                    this.fields = data.fields;
                    this.isDirty = false;
                });
            },
            resetForm(){
                this.dirtyFields.forEach(field => {
                    field.value = field.origValue;
                    delete field.origValue;
                });
                this.isDirty = false;
            }
        },
        watch: {
            shouldReload() {
                this.shouldReload ? this.resetForm() : null;
            }
        }
    };
</script>