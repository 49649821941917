import Vue from "vue";
import SockJS from "sockjs-client";
import { Stomp } from "stompjs/lib/stomp";

import store from "obj-fe/app/store";
import router from "obj-fe/app/router";
import backend from "obj-fe/app/backend";

let StompClient;

const MAX_CONNECTION_ATTEMPTS = 1;

store.registerModule("CHAT", {
    namespaced: true,
    state: {
        connected: false,
        reConnectionAttempts: 0,
        roomsById: {},
        unreadMessagesCount: 0,
        unreadMessagesCountByRoomId: {},
        noMoreMessagesByRoomId: {},
        messages: {},
    },
    actions: {
        ROUTE_CHANGE_START: {
            root: true,
            handler(ctx, route) {
                if(
                    store && 
                    store.state.auth.loggedIn &&
                    store.state.settings.chatEnabled && 
                    !ctx.state.connected
                ) 
                    ctx.dispatch("CONNECT")
                
                if (route.name === "chat") {
                    ctx.dispatch("LOAD_MY_CHATROOMS");
                }
            },
        },

        CONNECT(ctx) {
            ctx.commit("INCREMENT_RECONNECTION_ATTEMPTS");

            const onConnected = () => {
                ctx.commit("SET_CONNECTED", true);
                ctx.commit("SET_RECONNECTION_ATTEMPTS", 0);

                StompClient.subscribe(`/user/${ctx.rootState.user.login}/queue/notifications`, function(response) {
                    const message = JSON.parse(response.body);
                    ctx.commit("SET_UNREAD_MESSAGES_COUNT", message.count);
                });

                StompClient.subscribe(`/user/${ctx.rootState.user.login}/queue/messages`, function(response) {
                    const message = JSON.parse(response.body);
                    ctx.commit("ADD_MESSAGE", message);

                    const myMessage = String(message.senderId) === String(ctx.rootState.user.personId);

                    // Check if room exists - load otherwise
                    if (!ctx.state.roomsById[message.roomId]) {
                        ctx.dispatch("LOAD_CHATROOM", { roomId: message.roomId });
                    }

                    if (
                        !(
                            router.currentRoute.name === "chat-room" &&
                            String(router.currentRoute.params.roomId) === String(message.roomId)
                        )
                    ) {
                        if (!myMessage) {
                            ctx.commit("SET_UNREAD_MESSAGES_COUNT_BY_CHATROOM", {
                                roomId: message.roomId,
                                count: (ctx.state.unreadMessagesCountByRoomId[message.roomId] || 0) + 1,
                            });
                        }
                    }
                });
            };

            const onError = (error) => {
                console.error(error);
                ctx.commit("SET_CONNECTED", false);
                if (ctx.state.reConnectionAttempts < MAX_CONNECTION_ATTEMPTS) {
                    setTimeout(() => {
                        ctx.dispatch("CONNECT");
                    }, 2000 * ctx.state.reConnectionAttempts);
                } else {
                    console.error("Connection to websocket failed!");
                }
            };

            StompClient = Stomp.over(new SockJS(CONFIG.baseUrl + CONFIG.chatWsUrl));
            StompClient.connect({}, onConnected, onError);
        },

        ROOM_READ(ctx, { roomId }) {
            return new Promise((resolve, reject) => {
                const message = ctx.getters.lastMessagesByRoomId(roomId);

                if (!(message && message.id)) {
                    resolve(undefined);
                    return;
                }

                ctx.dispatch("MESSAGE_READ", { messageId: message.id })
                    .then((data) => {
                        ctx.commit("SET_UNREAD_MESSAGES_COUNT_BY_CHATROOM", {
                            roomId,
                            count: 0,
                        });
                        resolve(data);
                    })
                    .catch((error) => reject(error));
            });
        },

        MESSAGE_READ(ctx, { messageId }) {
            return new Promise((resolve, reject) => {
                backend.chat.messageRead(
                    { messageId },
                    (data) => {
                        resolve(data);
                    },
                    (error) => reject(error)
                );
            });
        },

        LOAD_CHATROOM(ctx, { roomId }) {
            return new Promise((resolve, reject) => {
                backend.chat.findChatroomById(
                    { roomId },
                    (data) => {
                        ctx.commit("ADD_CHATROOM", data);
                        resolve(data);
                    },
                    (error) => reject(error)
                );
            });
        },

        LOAD_MY_CHATROOMS(ctx) {
            backend.chat.findMyChatrooms((data) => {
                ctx.commit("ADD_CHATROOMS", data);
                ctx.dispatch("LOAD_UNREAD_MESSAGES_COUNT_BY_CHATROOM");
                ctx.dispatch("LOAD_LAST_MESSAGES_BY_CHATROOM", {
                    roomIds: data.map((room) => room.id),
                });
            });
        },

        LOAD_UNREAD_MESSAGES_COUNT(ctx) {
            return new Promise((resolve, reject) => {
                backend.chat.cntUnreadMessages(
                    (data) => {
                        ctx.commit("SET_UNREAD_MESSAGES_COUNT", data);
                        resolve(data);
                    },
                    (error) => reject(error)
                );
            });
        },

        LOAD_UNREAD_MESSAGES_COUNT_BY_CHATROOM(ctx) {
            return new Promise((resolve, reject) => {
                backend.chat.unreadMessages(
                    (data) => {
                        data.forEach((item) => {
                            ctx.commit("SET_UNREAD_MESSAGES_COUNT_BY_CHATROOM", {
                                roomId: item.roomId,
                                count: item.cntMessages,
                            });
                        });

                        resolve(data);
                    },
                    (error) => reject(error)
                );
            });
        },

        LOAD_LAST_MESSAGES_BY_CHATROOM(ctx, { roomIds }) {
            return new Promise((resolve, reject) => {
                backend.chat.lastMessageInRooms(
                    { roomIds },
                    (data) => {
                        data.forEach((item) => {
                            ctx.commit("ADD_MESSAGE", item);
                        });
                        resolve(data);
                    },
                    (error) => reject(error)
                );
            });
        },

        LOAD_MESSAGES_FOR_CHATROOM(ctx, { roomId, pageSize = 10, afterMessageId = null } = {}) {
            return new Promise((resolve, reject) => {
                backend.chat.findPreviousMessagesForChatroom(
                    {
                        roomId: roomId,
                        pageSize: pageSize,
                        firstDisplayedId: afterMessageId || null,
                    },
                    (data) => {
                        if (!data || data.length <= 0) {
                            ctx.commit("SET_NO_MORE_MESSAGES_BY_CHATROOM", { roomId });
                        } else {
                            data.forEach((message) => {
                                ctx.commit("ADD_MESSAGE", message);
                            });
                        }
                        resolve(data);
                    },
                    (error) => reject(error)
                );
            });
        },

        CREATE_TOPIC_CHAT_ROOM(ctx, { name, recipientIds = [] } = {}) {
            return new Promise((resolve, reject) => {
                backend.chat.createTopicChatroom(
                    { name, recipientIds },
                    (response) => {
                        ctx.commit("ADD_CHATROOM", response);
                        resolve(response);
                    },
                    (error) => reject(error)
                );
            });
        },

        CREATE_DIRECT_CHATROOM_WITH_RECIPIENT(ctx, { recipientId } = {}) {
            return new Promise((resolve, reject) => {
                backend.chat.createDirectChatroomWithRecipient(
                    { recipientId },
                    (response) => {
                        ctx.commit("ADD_CHATROOM", response);
                        resolve(response);
                    },
                    (error) => reject(error)
                );
            });
        },

        DELETE_CHATROOM(ctx, { roomId } = {}) {
            return new Promise((resolve, reject) => {
                backend.chat.deleteChatroom(
                    { roomId },
                    (response) => {
                        ctx.commit("DELETE_CHATROOM", roomId);
                        resolve(response);
                    },
                    (error) => reject(error)
                );
            });
        },

        SEND_MESSAGE(ctx, { roomId, content, ...rest }) {
            StompClient.send(
                "/app/chat",
                {},
                JSON.stringify({
                    ...rest,
                    roomId,
                    content,
                    timestamp: new Date(),
                })
            );
        },

        ADD_CHATROOM_RECIPIENTS(ctx, { roomId, recipientIds } = {}) {
            return new Promise((resolve, reject) => {
                backend.chat.addChatroomRecipients(
                    { roomId, recipientIds },
                    (response) => {
                        ctx.dispatch("LOAD_CHATROOM", { roomId });
                        resolve(response);
                    },
                    (error) => reject(error)
                );
            });
        },

        DELETE_CHATROOM_RECIPIENTS(ctx, { roomId, recipientIds } = {}) {
            return new Promise((resolve, reject) => {
                backend.chat.removeChatroomRecipients(
                    { roomId, recipientIds },
                    (response) => {
                        ctx.dispatch("LOAD_CHATROOM", { roomId });
                        resolve(response);
                    },
                    (error) => reject(error)
                );
            });
        },
    },
    mutations: {
        SET_MESSAGES(state, messages) {
            Vue.set(state, "messages", messages);
        },
        SET_UNREAD_MESSAGES_COUNT(state, count) {
            Vue.set(state, "unreadMessagesCount", count);
        },
        SET_UNREAD_MESSAGES_COUNT_BY_CHATROOM(state, { roomId, count }) {
            Vue.set(state.unreadMessagesCountByRoomId, roomId, count);
        },
        SET_NO_MORE_MESSAGES_BY_CHATROOM(state, { roomId, value = true }) {
            Vue.set(state.noMoreMessagesByRoomId, roomId, value);
        },
        ADD_MESSAGE(state, message) {
            Vue.set(state.messages, message.id, message);
        },
        DELETE_CHATROOM(state, roomId) {
            Vue.delete(state.roomsById, roomId);
        },
        ADD_CHATROOM(state, room) {
            Vue.set(state.roomsById, room.id, room);
        },
        ADD_CHATROOMS(state, rooms) {
            rooms.forEach((room) => {
                Vue.set(state.roomsById, room.id, room);
            });
        },
        SET_CONNECTED(state, value) {
            state.connected = value;
        },
        SET_RECONNECTION_ATTEMPTS(state, value) {
            state.reConnectionAttempts = value;
        },
        INCREMENT_RECONNECTION_ATTEMPTS(state) {
            state.reConnectionAttempts = state.reConnectionAttempts + 1;
        },
    },
    getters: {
        lastMessagesByRoomId: (state) => (roomId) => {
            return Object.values(state.messages)
                .filter((message) => String(message.roomId) === String(roomId))
                .sort((messageA, messageB) => String(messageA.id).localeCompare(messageB.id))
                .pop();
        },
    },
});
