<template>
    <div
        v-if="buttons"
        class="inv-object-button-group inv-object-button-group--column-oriented"
    >
        <v-tooltip
            v-for="(button,i) in buttons"
            :key="i" 
            :disabled="!button.toolTip"
            top
        >
            <template v-slot:activator="{ on, attrs }">
                
                <v-btn 
                    v-bind="attrs"
                    v-on="on"
                    @click="$emit('clicked', button)" 
                    text
                    :block="displayBlock"
                >
                    <v-icon>{{ button.icon ?  'fal ' + button.icon : 'fal fa-bullhorn' }}</v-icon>
                    {{button.displayString}}

                </v-btn>

            </template>

            <span>{{button.toolTip}}</span>

        </v-tooltip>
    </div>
</template>

<script>
export default {
    props:{
        buttons: Array,
        displayBlock: Boolean
    }
}
</script>

<style scoped>
.inv-object-button-group{
    flex-direction: column;
}
</style>
