<template>
    <div class="wrapper">
        <div class="line-wrapper">
            <div class="line" />
        </div>
        <div class="content-wrapper">
            <span class="content">
                {{ "chat_last_read" | translate }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: ["value"],
};
</script>

<style lang="scss" scoped>
.wrapper {
    position: relative;
}

.line-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
}

.line {
    width: 100%;
    border-top: 1px solid #f16e00;
}

.content-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
}

.content {
    padding: 0 1.5rem;
    background-color: white;
    color: #f16e00;
    font-size: 0.85rem;
    letter-spacing: 0.025em;
}
</style>
