import { CONTEXT_TYPES, checkUnquoteText } from './emdsl-types.js';

class DslTokenContext {
    constructor(data = {}){
        this.token = data.token;
        this.contextType = data.contextType;
        this.value = data.value;
        this.parent = data.parent;
        this.children = [];
    }

    isFirst(){
        if(!this.parent) return true;
        else return this.parent.children.indexOf(this) === 0;
    }

    isLast(){
        if(!this.parent) return true;
        else return this.parent.children.indexOf(this) === this.parent.children.length - 1;
    }

    getChildrenByContextType(contextType){
        return this.children.filter(tokenCtx => tokenCtx.contextType === contextType);
    }

    getParentByContextType(contextTypes){
        if(!Array.isArray(contextTypes)) contextTypes = [contextTypes];

        let parent = this.parent;
        while(parent){
            if(contextTypes.includes(parent.contextType)) return parent;
            parent = parent.parent;
        }
    }

    getThisOrParentByContextType(contextTypes){
        if(!Array.isArray(contextTypes)) contextTypes = [contextTypes];
        return contextTypes.includes(this.contextType) ? this : this.getParentByContextType(contextTypes);
    }

    getPreviousByContextType(contextTypes){
        if(!Array.isArray(contextTypes)) contextTypes = [contextTypes];

        let prev = this.getPrevious();
        while(prev){
            if(contextTypes.includes(prev.contextType)) return prev;
            prev = prev.getPrevious();
        }
    }

    getThisOrPreviousByContextType(contextTypes){
        if(!Array.isArray(contextTypes)) contextTypes = [contextTypes];
        return contextTypes.includes(this.contextType) ? this : this.getPreviousByContextType(contextTypes);
    }

    getAllDescendants(){
        let descendants = [];
        this.children.forEach(child => {
            descendants.push(child);
            descendants = descendants.concat(child.getAllDescendants());
        });
        return descendants;
    }

    getAllPrevious(){
        if(!this.parent) return [];
        let index = this.parent.children.indexOf(this);
        return this.parent.children.slice(0, index);
    }

    getPrevious(){
        if(!this.parent) return;
        let index = this.parent.children.indexOf(this);
        return this.parent.children[ index-1 ];
    }

    getNext(){
        if(!this.parent) return;
        let index = this.parent.children.indexOf(this);
        return this.parent.children[ index+1 ];
    }

    getTokenBoundaries(){
        const token = this.token;
        if(token && !isNaN(token.startOffset)) {
            return {
                startLine: token.startLine - 1,
                startColumn: token.startColumn - 1,
                endLine: token.endLine - 1,
                endColumn: token.endColumn
            };
        }
    }

    sortChildrenByTokenOrder(){
        this.children.forEach(child => child.sortChildrenByTokenOrder());

        this.children = this.children.sort((a,b) => {
            let aToken = a.getNearestToken();
            let bToken = b.getNearestToken();
            if(!aToken || !bToken) return 0;

            if(aToken.startLine < bToken.startLine) return -1;
            else if (aToken.startLine > bToken.startLine) return 1;
            else if (aToken.startColumn < bToken.startColumn) return -1;
            else if (aToken.startColumn > bToken.startColumn) return 1;
            else return 0;
        });

        return this;
    }

    debugChildTree(level = 0){
        let token = this.getNearestToken() || {};
        console.warn(Array(level).fill('  ').join('') + this.contextType, this.value, token.startLine, token.startColumn);
        this.children.forEach(child => child.debugChildTree(level+1));
    }

    debugChildrenTokenOrder(){
        this.children.forEach(child => console.warn(child.contextType, child.token, child));
    }

    getNearestToken(){
        return this.token || (this.children.find(child => child.getNearestToken()) || {}).token;
    }
}

function createInterpreter(BaseCstVisitor){

    // All our semantics go into the visitor, completly separated from the grammar.
    class DslInterpreter extends BaseCstVisitor {
        constructor() {
            super();

            this.rootTokenContext = new DslTokenContext({ contextType: CONTEXT_TYPES.ROOT });

            // This helper will detect any missing or redundant methods on this visitor
            this.validateVisitor();
        }

        /*
         * Interpreter Methods
         */

        /*
         * End methods in parsing chain, they only consume tokens and have no children, returning only parsed value and value token
         */

        identifierValue(ctx){
            let token = ctx.Identifier || ctx.StringQuoted;
            if(Array.isArray(token)) token = token[0];
            if(!token) return;

            return {
                token,
                value: checkUnquoteText(token.image)
            };
        }

        attributeValue(ctx){
            let token = Array.isArray(ctx.value) ? ctx.value[0] : ctx.value;
            if(!token) return;

            return {
                token,
                value: checkUnquoteText(token.image)
            };
        }

        attributeName(ctx, attribute){
            let attributeNameToken = ctx.attributeName ? ctx.attributeName[0] : null
            if(attributeNameToken){
                this.addTokenContext(attributeNameToken, CONTEXT_TYPES.ATTRIBUTE_NAME, attributeNameToken.image, attribute);
            }
        }

        /*
         * Non-end methods in parsing chain, they are generating result - data structure
         */

        rootExpression(ctx){
            if(ctx.entitiesDefinitions) {
                ctx.entitiesDefinitions.forEach(entityDefinition => this.visit(entityDefinition));
            }

            return this.rootTokenContext.sortChildrenByTokenOrder(); // ensure tokenCtx are sorted by their tokens positions
        }

        entitiesDefinitions(ctx){
            if(ctx.entity) ctx.entity.forEach(entity => this.visit(entity));
        }

        entity(ctx, referencedInRelation){
            let entity = this.addTokenContext(null, CONTEXT_TYPES.ENTITY, null, referencedInRelation);

            if(ctx.entityType) this.visit(ctx.entityType, entity);
            if(ctx.entityIdentifier) this.visit(ctx.entityIdentifier, entity);

            if(ctx.curlyBracket && ctx.curlyBracket[0]) {
                this.addTokenContext(ctx.curlyBracket[0], CONTEXT_TYPES.ENTITY_BODY_START, null, entity);
            }
            
            if(ctx.entityContent) {
                this.visit(ctx.entityContent, entity);
            }

            if(ctx.curlyBracket && ctx.curlyBracket[1]) {
                this.addTokenContext(ctx.curlyBracket[1], CONTEXT_TYPES.ENTITY_BODY_END, null, entity);
            }
        }

        entityType(ctx, parentEntity){
            let entityTypeToken = ctx.entityType ? ctx.entityType[0] : null
            if(entityTypeToken){
                this.addTokenContext(entityTypeToken, CONTEXT_TYPES.ENTITY_TYPE, entityTypeToken.image, parentEntity);
            }
        }

        entityIdentifier(ctx, parentEntity){
            let parsedIdentifier = this.visit(ctx.entityIdentifier);
            if(parsedIdentifier) {
                this.addTokenContext(parsedIdentifier.token, CONTEXT_TYPES.ENTITY_ALIAS, parsedIdentifier.value, parentEntity);
                return parsedIdentifier;
            }
        }

        entityContent(ctx, parentEntity){
            if(ctx.attribute) ctx.attribute.forEach(attribute => {
                this.visit(attribute, parentEntity);
            });
            if(ctx.relation) ctx.relation.forEach(relation => {
                this.visit(relation, parentEntity);
            });
            if(ctx.Comma) ctx.Comma.forEach(Comma => {
                this.addTokenContext(Comma, CONTEXT_TYPES.ATTRIBUTE_SEPARATOR, null, parentEntity);
            });
        }

        attributes(ctx, parentEntity){
            if(ctx.attribute) ctx.attribute.forEach(attribute => {
                this.visit(attribute, parentEntity);
            });
            if(ctx.Comma) ctx.Comma.forEach(Comma => {
                this.addTokenContext(Comma, CONTEXT_TYPES.ATTRIBUTE_SEPARATOR, null, parentEntity);
            });
        }

        attribute(ctx, parentEntity){
            let attribute = this.addTokenContext(null, CONTEXT_TYPES.ATTRIBUTE, null, parentEntity);
            
            if(ctx.attributeName) this.visit(ctx.attributeName, attribute);
            
            if(ctx.equal){
                this.addTokenContext(ctx.equal, CONTEXT_TYPES.ATTRIBUTE_OPERATOR, null, attribute);
            }
            
            let parsedValue = this.visit(ctx.attributeValue);
            if(parsedValue) {
                this.addTokenContext(parsedValue.token, CONTEXT_TYPES.ATTRIBUTE_VALUE, parsedValue.value, attribute);
            }
        }

        relation(ctx, parentEntity){
            let relation = this.addTokenContext(null, CONTEXT_TYPES.RELATION, null, parentEntity);

            let relationTypeNameToken = ctx.relationType ? ctx.relationType[0] : null
            if(relationTypeNameToken){
                this.addTokenContext(relationTypeNameToken, CONTEXT_TYPES.RELATION_TYPE, relationTypeNameToken.image, relation);
            }
            
            if(ctx.curlyBracket && ctx.curlyBracket[0]) {
                this.addTokenContext(ctx.curlyBracket[0], CONTEXT_TYPES.RELATION_ATTRIBUTES_START, null, relation);
            }
            if(ctx.attributes) this.visit(ctx.attributes, relation);
            if(ctx.curlyBracket && ctx.curlyBracket[1]) {
                this.addTokenContext(ctx.curlyBracket[1], CONTEXT_TYPES.RELATION_ATTRIBUTES_END, null, relation);
            }

            if(ctx.arrow) this.addTokenContext(ctx.arrow, CONTEXT_TYPES.RELATION_OPERATOR, null, relation);

            if(ctx.relationTarget) this.visit(ctx.relationTarget, relation);
        }

        relationTarget(ctx, relation){
            if(ctx.relationTo) {
                let parsedRelationTo = this.visit(ctx.relationTo);
                if(parsedRelationTo) {
                    this.addTokenContext(parsedRelationTo.token, CONTEXT_TYPES.RELATION_ENTITY_ALIAS, parsedRelationTo.value, relation);
                }
            }

            if(ctx.inlineEntity) this.visit(ctx.inlineEntity, relation);
            if(ctx.multipleRelationsTo) this.visit(ctx.multipleRelationsTo, relation);
        }

        multipleRelationsTo(ctx, referencedInRelation){
            if(ctx.squareBracket && ctx.squareBracket[0]) {
                this.addTokenContext(ctx.squareBracket[0], CONTEXT_TYPES.RELATION_LIST_START, null, referencedInRelation);
            }

            if(ctx.squareBracket && ctx.squareBracket[1]) {
                this.addTokenContext(ctx.squareBracket[1], CONTEXT_TYPES.RELATION_LIST_END, null, referencedInRelation);
            }

            if(ctx.Comma) ctx.Comma.forEach(Comma => {
                this.addTokenContext(Comma, CONTEXT_TYPES.RELATION_LIST_SEPARATOR, null, referencedInRelation);
            });

            if(ctx.relationTo) ctx.relationTo.forEach(relationTo => {
                let parsedRelationTo = this.visit(relationTo, referencedInRelation);
                if(parsedRelationTo){
                    this.addTokenContext(parsedRelationTo.token, CONTEXT_TYPES.RELATION_ENTITY_ALIAS, parsedRelationTo.value, referencedInRelation);
                }
            });

            if(ctx.inlineEntity) ctx.inlineEntity.forEach(inlineEntity => {
                this.visit(inlineEntity, referencedInRelation);
            });
        }

        /*
         * Helpers
         */

        addTokenContext(token, contextType, value, parent){
            if(Array.isArray(token)) token = token[0];

            // tokens with flag "isInsertedInRecovery" are virtual, without line and column, skip it
            if(token && token.isInsertedInRecovery) return;

            if(!CONTEXT_TYPES[ contextType ]) throw new Error('CONTEXT_TYPES does not contains "'+contextType+'"');

            const tokenContext = new DslTokenContext({
                token,
                contextType,
                value,
                parent: parent || this.rootTokenContext
            });

            if(parent) parent.children.push(tokenContext);
            this.rootTokenContext.children.push(tokenContext);

            return tokenContext;
        }
    }

    // We only need a single interpreter instance because our interpreter has no state.
    return new DslInterpreter();
}

export { createInterpreter };