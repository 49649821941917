<template>
    <inv-object-tab-item 
        :tab="tab" 
        :tabIsLoading="tabIsLoading"
        data-test="tab-ACCESS_RESTRICTIONS"
    >
        <template v-if="expanded && tab" slot="header">
            <div class="inv-object-button-group">

                <v-tooltip top>
                    <template v-slot:activator="{on}">
                        <v-btn 
                            v-on="on"
                            icon
                            @click="loadRestrictions" 
                            color="info"
                            data-test="tab-reload-button"
                        >
                            <v-icon>fal fa-sync</v-icon>
                        </v-btn>
                    </template>
                    <span>
                        {{'reload'|translate}}
                    </span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{on}">
                        <v-btn 
                            v-if="!readonly" 
                            v-on="on"
                            @click="save" 
                            icon
                            :disabled="!isDirty"
                            :elevation="isDirty ? 24 : 0"
                            :outlined="isDirty"
                            color="success"
                            data-test="tab-save-button"
                        >
                            <v-icon>fal fa-save</v-icon>
                        </v-btn>
                    </template>
                    <span>
                        {{'save'|translate}}
                    </span>
                </v-tooltip>

            </div>
        </template>

        <template slot="body">
            <obj-table fillParentHeight :items="restrictions" :class="{ 'tab-body-disabled' : tabIsLoading }">
                <template slot="headers">
                    <th>{{'domain_type' | translate}}</th>
                    <th>{{'name' | translate}}</th>
                    <th>{{'allow_read' | translate}}</th>
                    <th>{{'deny_read' | translate}}</th>
                    <th>{{'allow_write' | translate}}</th>
                    <th>{{'deny_write' | translate}}</th>
                </template>

                <template slot="row" slot-scope="row">
                    <template v-if="row.value.headerDivider">
                        <td class="inner-table-header" colspan="2">{{'functional_restrictions' | translate }}</td>
                        <td class="inner-table-header">{{'allow' | translate}}</td>
                        <td class="inner-table-header">{{'deny' | translate}}</td>
                        <td></td>
                        <td></td>
                    </template>
                    <template v-else>
                        <td><span class="nowrap">{{row.value.domainType}}</span></td>
                        <td><span class="nowrap">{{row.value.displayString}}</span></td>
                        <td data-test="access-restrictions_allow-read">
                            <v-checkbox :disabled="!editEnabled" 
                                        v-model="row.value.allow"
                                        style="margin-top:0px"
                                        hide-details 
                                        off-icon="fal fa-square"
                                        @change="row.value.deny = row.value.allow ? false : row.value.deny; row.value.allowDeny = row.value.allow ? 'ALLOW' : (row.value.deny ? 'DENY' : null)">
                            </v-checkbox>
                        </td>
                        <td>
                            <v-checkbox :disabled="!editEnabled" 
                                        v-model="row.value.deny" 
                                        style="margin-top:0px"
                                        hide-details 
                                        off-icon="fal fa-square"
                                        @change="row.value.allow = row.value.deny ? false : row.value.allow; row.value.allowDeny = row.value.allow ? 'ALLOW' : (row.value.deny ? 'DENY' : null)">
                            </v-checkbox>
                        </td>
                        <td>
                            <v-checkbox v-if="isAllowable(row.value.domainType)" 
                                        :disabled="!editEnabled" 
                                        v-model="row.value.allowWrite" 
                                        style="margin-top:0px"
                                        hide-details 
                                        off-icon="fal fa-square"
                                        @change="row.value.denyWrite = row.value.allowWrite ? false : row.value.denyWrite; row.value.allowDenyWrite = row.value.allowWrite ? 'ALLOW' : (row.value.denyWrite ? 'DENY' : null)">
                            </v-checkbox>
                        </td>
                        <td>
                            <v-checkbox v-if="isAllowable(row.value.domainType)" 
                                        :disabled="!editEnabled" 
                                        v-model="row.value.denyWrite" 
                                        style="margin-top:0px"
                                        hide-details 
                                        off-icon="fal fa-square"
                                        @change="row.value.allowWrite = row.value.denyWrite ? false : row.value.allowWrite; row.value.allowDenyWrite = row.value.allowWrite ? 'ALLOW' : (row.value.denyWrite ? 'DENY' : null)">
                            </v-checkbox>
                        </td>
                    </template>
                </template>
            </obj-table>
        </template>
    </inv-object-tab-item>
</template>

<style scoped>
    >>> .obj-table thead > tr:first-child th {
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
        background-color: #fff;
        color: inherit;
        font-weight: normal;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }

    >>> .obj-table thead > tr:first-child th .v-icon {
        color: inherit;
    }

    .inner-table-header {
        font-size: 15px !important;
        text-align: left;
        padding: 40px 0px 10px 6px !important;
    }
</style>

<script>
    import backend from 'obj-fe/app/backend';

    import tabBehaviour from './inv-object-tab-behaviour';
    import InvObjectTabItem from './inv-object-tab-item.vue';
    
    export default {
        mixins:[ tabBehaviour ],
        components:{
            InvObjectTabItem
        },
        data(){
            return {
                initRestrictions: [],
                restrictions: [],
                unallowableDomainTypes: ['FUNCTION', 'CELINE'],
            };
        },
        computed:{
            user(){
                return this.$store.state.user;
            },
            editEnabled(){
                return !this.readonly && this.user && this.user.roles['AdminPermission'];
            },
            isDirty(){
                let isDirty = false;

                for (let index = 0; index < this.restrictions.length; index++) {
                    const 
                        res = this.restrictions[index],
                        initRes = this.initRestrictions[index];
                        
                    if(res.headerDivider || initRes.headerDivider) continue;

                    if( res.allow !== initRes.allow ||
                        res.deny !== initRes.deny ||
                        res.allowWrite !== initRes.allowWrite ||
                        res.denyWrite !== initRes.denyWrite
                    ) isDirty = true;
                }

                return isDirty;
            },
        },
        methods:{
            onFirstTimeExpandLoad(successCb, errorCb){
                this.loadRestrictions(successCb, errorCb);
            },
            loadRestrictions(successCb, errorCb){
                let app = this;
                backend.orgEntities.allRestrictions(
                    this.objectId, 
                    // onSuccess
                    data => {
                        app.fillRestrictions(data)
                        if(successCb && typeof successCb  === 'function') successCb();
                    }, 
                    // onError
                    errorCb);
            },
            fillRestrictions(data){
                this.restrictions = [];
                this.initRestrictions = [];

                let initRestrictions = [];

                data.forEach((item, index, array) => {
                    let allow = item.allow = item.allowDeny === 'ALLOW';
                    let deny = item.deny = item.allowDeny === 'DENY';
                    let allowWrite = item.allowWrite = item.allowDenyWrite === 'ALLOW';
                    let denyWrite = item.denyWrite = item.allowDenyWrite === 'DENY'

                    if(!this.isAllowable(item.domainType) && this.isAllowable(array[ index-1 ].domainType)) {
                        initRestrictions.push({ headerDivider: true });
                        this.restrictions.push({ headerDivider: true });
                    }
                    
                    initRestrictions.push({ allow, deny, allowWrite, denyWrite });
                    this.restrictions.push(item);
                    
                })

                this.initRestrictions = initRestrictions;
            },
            save(){
                const restrictions = this.restrictions.filter(restriction => !restriction.headerDivider);
                
                backend.orgEntities.updateRestrictions(this.objectId, restrictions, data => this.fillRestrictions(data));
            },
            isAllowable(domainType) {
                return !this.unallowableDomainTypes.includes(domainType.toUpperCase());
            }
        }
    };
</script>