<template>
    <v-autocomplete
        :value="value"
        @input="$emit('input', $event)"
        :items="items"
        :loading="loading"
        :search-input.sync="search"
        item-value="id"
        item-text="name"
        placeholder="Enter name"
        cache-items
        v-bind="$attrs"
        ref="autocomplete"
    />
</template>

<script>
import backend from "obj-fe/app/backend";

export default {
    props: ["value"],

    data() {
        return {
            loading: false,
            select: "",
            search: "",
            items: [],
        };
    },

    watch: {
        value(val) {
            this.reset();
        },

        search(val) {
            val && this.querySelections(val);
        },
    },

    methods: {
        focus() {
            this.$refs.autocomplete.focus();
        },

        querySelections(query) {
            this.loading = true;

            backend.chat.findChatRecipient(
                { nameLike: query },
                (data) => {
                    this.items = data;
                    this.loading = false;
                },
                (error) => {
                    this.loading = false;
                }
            );
        },

        reset() {
            this.loading = false;
            this.select = "";
            this.search = "";
            this.items = [];
        },
    },
};
</script>

<style></style>
