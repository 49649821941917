import router from 'obj-fe/app/router';
import store from 'obj-fe/app/store';

import './store';
import InvImpactDiagrams from './inv-impact-diagrams.vue';
router.addRoutes([{
    name: 'impact-diagrams',
    path: '/impact-diagrams/:resourceId/:serviceId',
    component: InvImpactDiagrams,
    props: true
}]);