<template>
    <inv-object-tab-item :tab="tab" :tabIsLoading="tabIsLoading">
        <template v-if="expanded && tab" slot="header">
            <div class="inv-object-button-group">
                
                <v-tooltip top>
                    <template v-slot:activator="{on}">
                        <v-btn 
                            @click="reload" 
                            icon
                            v-on="on"    
                        >
                            <v-icon>fal fa-sync</v-icon>
                        </v-btn>
                    </template>
                    <span>
                        {{'reload'|translate}}
                    </span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{on}">
                        <v-btn 
                            v-on="on" 
                            @click="save" 
                            :disabled="!editorContentIsValid"
                            :elevation="editorContentIsValid ? 24 : 0"
                            :outlined="editorContentIsValid"
                            color="success"
                            icon
                        >
                            <v-icon>fal fa-save</v-icon>
                        </v-btn>
                    </template>
                    <span>
                        {{'save'|translate}}
                    </span>
                </v-tooltip>
                
            </div>
        </template>
        <template slot="body">
            <div class="inv-object-tab-graphviz-container" :class="{ 'tab-body-disabled' : tabIsLoading }">
                <div class="inv-object-tab-graphviz" ref="editor">
                    <inv-nwdsl-editor   v-model="graphvizCode" 
                                        :placeholder="'enter_graph_code_here' | translate"
                                        @valid="isValid => editorContentIsValid = isValid"
                                        auto-height 
                                        :validate-resources="validateResources"
                                        :suggest-resources="suggestResources"
                                        :suggest-attributes="suggestAttributes"
                                        :suggest-attribute-values="suggestAttributeValues"
                                        :suggest-resources-for-aliases="suggestResourcesForAliases"
                                        @parser-result="result => graphvizParserResult = result"
                                        @is-dirty="editorIsDirty = $event">
                    </inv-nwdsl-editor>
                </div>
                <div class="dragbar" ref="dragbar"/>
                <div class="inv-object-tab-graphviz-svg not-dragged" ref="graph">
                    <div id="svg-container" ref="svgContainer" v-html="svgContent"></div>
                </div>
            </div>
        </template>
    </inv-object-tab-item>
</template>

<style scoped>
    .inv-object-tab-graphviz-container{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
    }
    .inv-object-tab-graphviz-container .inv-object-tab-graphviz{
        max-width: 50%;
        width: 50%;
        overflow: hidden;
    }
    .dragbar{
        padding: 2px;
        cursor: col-resize;
        background-color: rgba(0, 0, 0, 0.12);;
        user-select: none;
        transition: all 200ms;
    }
    .dragbar:hover{
        background-color: rgba(0, 0, 0, 0.25);;
    }
    .inv-object-tab-graphviz-svg{
        display: flex;
        flex-grow: 10;
        max-height: 100%;
    }
    .inv-object-tab-graphviz-svg.not-dragged{
        width: 50%;
    }

    .inv-object-tab-graphviz{
        height: 100%;
        overflow: hidden;
    }
    .inv-object-tab-graphviz-container .inv-object-tab-graphviz-svg{
        overflow: auto;
    }
    .graph-preview {
        border-left: 1px solid #ddd;
        max-height: 100%;
    }
</style>

<script>
    import backend from 'obj-fe/app/backend';
    import notify from 'obj-fe/services/notifications';
    import object from 'obj-fe/utils/object';

    import tabBehaviour from './inv-object-tab-behaviour';
    import InvObjectTabItem from './inv-object-tab-item.vue';
    import InvNwdslEditor from '../../dsl/nwdsl/inv-nwdsl-editor.vue';
    
    export default {
        mixins:[ tabBehaviour ],
        components:{
            InvObjectTabItem,
            InvNwdslEditor
        },
        data(){
            return {
                graphvizCode: '',
                initValue: '',
                graphvizParserResult: null,
                graphvizErrors: [],
                svgContent: '',
                editorContentIsValid: false,
                cachedResourceDefinitions:{},
                firstTimeLoaded: true,

                editorIsDirty: false
            };
        },
        watch: {
            graphvizCode: function(){
                if(this.firstTimeLoaded) this.firstTimeLoaded = false;
                else{
                    let app = this;
                    setTimeout(function(){
                        if(app.editorContentIsValid) {
                            app.loadSvgDiagramPreview();
                        }
                    }, 1000)
                }  
            }
        },
        computed:{
            isDirty(){
                return this.editorIsDirty || this.initValue !== this.graphvizCode;
            }
        },
        methods:{
            processDrag(e){
                document.selection ? document.selection.empty() : window.getSelection().removeAllRanges();
                this.$refs['graph'].classList.remove('not-dragged');
                this.$refs['editor'].style.width = (e.pageX - this.$refs['dragbar'].offsetWidth / 2) + 'px';
            },
            onFirstTimeExpandLoad(successCb, errorCb){
                this.reload(successCb, errorCb);
            },
            reload(successCb, errorCb){
                backend.objects.graphviz({diagramId: this.objectId}, data => {
                    this.graphvizCode = data.src;
                    this.initValue = data.src;
                    this.graphvizErrors = data.errors;
                    this.loadSvgDiagram();
                    if(successCb && typeof successCb === 'function') successCb();
                }, errorCb);
                let app = this;
                setTimeout(function(){
                    app.$refs['dragbar'].addEventListener('mousedown', () => {
                        document.addEventListener('mousemove', app.processDrag);
                    });
        
                    window.addEventListener('mouseup', () => {
                        document.removeEventListener('mousemove', app.processDrag);
                    });
                }, 1000)
            },
            loadSvgDiagram(){
                backend.objects.svgDiagram({diagramId: this.objectId}, data => {
                    this.svgContent = data;
                });
            },
            loadSvgDiagramPreview(){
                backend.objects.svgPreview({diagramId: this.objectId}, { src:this.graphvizCode, data: this.graphvizParserResult.value.data }, data => {
                    this.svgContent = data;
                });
            },
            save(){
                backend.objects.saveGraphviz({diagramId: this.objectId}, { src:this.graphvizCode, data: this.graphvizParserResult.value.data }, data => {
                    // TODO: 
                    // this.graphvizCode = data.src;
                    // this.graphvizErrors = data.errors;

                    this.initValue = this.graphvizCode;
                    this.loadSvgDiagram();
                });
            },
            cacheResourceDefinitions(entity){
                this.cachedResourceDefinitions[ entity.globalId + ' (' + entity.displayString + ')' ] = entity;
            },
            getCleanResourceSuggestionText(resourceInfo, prependId){
                return /(\s)/.test(resourceInfo.displayString) ? 
                    '\"' + resourceInfo.displayString + "\"" + ( prependId ? ' [ id = ' + resourceInfo.globalId + ' ]' : '' ) : 
                    resourceInfo.displayString + ( prependId ? ' [ id = ' + resourceInfo.globalId + ' ]' : '' )
            },
            validateResources(resInfos, cb){
                let resDefinitions = resInfos.map(resInfo => {
                    return {
                        name: resInfo.name,
                        type: resInfo.type,
                        attributes: resInfo.attributes,
                        resolved: !!this.cachedResourceDefinitions[ resInfo.id + ' (' + resInfo.name + ')' ]
                    };
                });

                let unresolvedResDefinitions = resDefinitions.filter(a => !a.resolved);

                if(unresolvedResDefinitions.length === 0) cb([]);
                else backend.objects.diagramCheck(null, unresolvedResDefinitions, resInfos => {

                    resInfos.forEach((resInfo, index) => {
                        // TODO: implement attributes error viewing
                        if(!resInfo.entity){
                            let warningError = new Error('Resource "' + unresolvedResDefinitions[ index ].name + '" has not been found.');
                            warningError.isWarning = true;
                            unresolvedResDefinitions[ index ].err = warningError;
                        } 
                        else this.cacheResourceDefinitions(resInfo.entity); // entity found, cache it by id and name
                    });

                    cb(resDefinitions.map(resDefinition => resDefinition.err));
                });
            },

            suggestResources({ prefix, text, type, token }, cb){
                backend.objects.diagramAliasesSearch({ token:prefix, type }, resInfos => {
                    cb(resInfos.map(info => {
                        return {
                            displayText: info.displayString + '(' + info.objectTypeStr + ') [ id = ' + info.globalId + ']',
                            text: this.getCleanResourceSuggestionText(info, true), //+ comment,
                            exactText: true, // do not quote text before paste,
                            className: 'BE-suggestion custom-suggestion'
                        };
                    }));
                });
            },

            suggestResourcesForAliases({ prefix, text, type, token }, cb){
                backend.objects.diagramAliasesSearch({ token:prefix, type }, resInfos => {
                    cb(
                        resInfos.map(info => {
                            return {
                                displayText: info.displayString + '(' + info.objectTypeStr + ') [ id = ' + info.globalId + ']',
                                definitionText: '\t' + info.type.toLowerCase() + ' ' + this.getCleanResourceSuggestionText(info, true) + '\n', 
                                text: this.getCleanResourceSuggestionText(info, false), //+ comment,
                                exactText: true,
                                className: 'ALIAS-BE-suggestion custom-suggestion'
                            };
                        }
                    ));
                });
            },

            suggestAttributes(data, cb){
                backend.objects.suggestAttributes(data, attributes => {
                    if(attributes){
                        cb(attributes.map(att => {
                            return {
                                displayText: att,
                                text: att,
                                className: 'ATTRIBUTE-suggestion custom-suggestion'
                            };
                        }));
                    }
                })
            },
            
            suggestAttributeValues(data, cb){
                backend.objects.suggestAttributeValues(data, attributes => {
                    if(attributes){
                        cb(attributes.map(att => {
                            return {
                                displayText: att,
                                text: att,
                                className: 'ATTRIBUTE-VALUE-suggestion custom-suggestion'
                            };
                        }));
                    }
                })
            }
        },
    };
</script>