export const CONTEXT_TYPES = {
    ROOT: 'ROOT',

    // ENTITY: 'ENTITY',
    // ENTITY_TYPE: 'ENTITY_TYPE',
    // ENTITY_ALIAS: 'ENTITY_ALIAS',
    // ENTITY_BODY_START: 'ENTITY_BODY_START',
    // ENTITY_BODY_END: 'ENTITY_BODY_END',

    // ATTRIBUTE:'ATTRIBUTE',
    // ATTRIBUTE_NAME: 'ATTRIBUTE_NAME',
    // ATTRIBUTE_OPERATOR: 'ATTRIBUTE_OPERATOR',
    // ATTRIBUTE_VALUE: 'ATTRIBUTE_VALUE',
    // ATTRIBUTE_SEPARATOR: 'ATTRIBUTE_SEPARATOR',

    ATTRIBUTES: 'ATTRIBUTES',
    ATTRIBUTES_START: 'ATTRIBUTES_START',
    ATTRIBUTES_END: 'ATTRIBUTES_END',
    ATTRIBUTES_SEPARATOR: 'ATTRIBUTES_SEPARATOR',

    ATTRIBUTE: 'ATTRIBUTE',
    ATTRIBUTE_NAME: 'ATTRIBUTE_NAME',
    ATTRIBUTE_EQUAL: 'ATTRIBUTE_EQUAL',
    ATTRIBUTE_VALUE: 'ATTRIBUTE_VALUE',

    RELATION: 'RELATION',
    RELATION_NODE_LEFT: 'RELATION_NODE_LEFT',
    RELATION_NODE_RIGHT: 'RELATION_NODE_RIGHT',
    RELATION_EDGE: 'RELATION_EDGE',
    RELATION_VARS_DECLARATION: 'RELATION_VARS_DECLARATION',
    RELATION_LINE: 'RELATION_LINE',
    RELATION_ARROW_RIGHT: 'RELATION_ARROW_RIGHT',
    RELATION_ARROW_LEFT: 'RELATION_ARROW_LEFT',
    RELATION_NODE_START: 'RELATION_NODE_START',
    RELATION_NODE_END: 'RELATION_NODE_END',
    RELATION_EDGE_START: 'RELATION_EDGE_START',
    RELATION_EDGE_END: 'RELATION_EDGE_END',
    RELATION_VARS_DECLARATION_TAG: 'RELATION_VARS_DECLARATION_TAG',
    RELATION_VARS_DECLARATION_TYPE: 'RELATION_VARS_DECLARATION_TYPE',
    RELATION_VARS_DECLARATION_SEPARATOR: 'RELATION_VARS_DECLARATION_SEPARATOR',
    RELATION_VARS_DECLARATION_PIPE: 'RELATION_VARS_DECLARATION_PIPE',
    
    RENDERER: 'RENDERER',
    RENDERER_PREFIX: 'RENDERER_PREFIX',
    RENDERER_COLLON: 'RENDERER_COLLON',
    RENDERER_NAME: 'RENDERER_NAME',

    HOP: 'HOP',
    HOP_PREFIX: 'HOP_PREFIX',
    HOP_COLLON: 'HOP_COLLON',
    HOP_TYPE: 'HOP_TYPE',
    HOP_NAME: 'HOP_NAME',
};

export function checkUnquoteText(str = ''){
    let quoteChar = str[0];
    let lastChar = str[ str.length-1 ];
    if(quoteChar === '"') {
        return str.slice(1, lastChar === quoteChar ? str.length-1 : undefined).replace(/\\([^\\]|$)/g, '$1').replace(/\\\\/g,'\\');
    }
    else return str;
};

export function quoteText(text = ''){
    return '"' + text.replace(/\\/,'\\\\').replace(/"/g, '\\"') + '"';
}

export function checkQuoteText(text, alwaysQuote) {
    if (typeof text !== 'string') return text;
    
    if(alwaysQuote) {
        if(checkIfQuotedClosed(text)) return text;
        else if(!mustNotBeQuoted(text)) return quoteText(text);
        else return text;
    }

    // if text is not pure identifier, then must be quoted and escaped
    if (!text.match(/^[a-zA-Z0-9$\-_:@\.\\\/]*$/)) return quoteText(text);
    else return text;
};

export function checkIfQuotedClosed(text = ''){
    let escaped = false;
    let offset = 0;
    let char = text[offset];
    const QUOTE = '"';

    // every quoted string must start with quote
    if(char !== QUOTE) return null;

    while (char !== undefined) {
        offset++;
        char = text[offset];

        if(char === '\\') escaped = !escaped;
        else if(char === QUOTE && !escaped) {
            return offset === text.length-1;
        }
        else escaped = false;
    }

    return false;
}

export function checkUnquotePrefix(text){
    if (typeof text !== 'string') return text;

    if(checkIfQuotedClosed(text)) return checkUnquoteText(text);
    else if(checkIfQuotedClosed(text + '"')) return checkUnquoteText(text + '"');
    else return text;
}

function getNumericValueValidator(isInteger, canBeNegative){
    return {
        validate(value){
            let matchRegex = canBeNegative ? /^-?\d{1,}(\.\d{0,})?$/ : /^\d{1,}(\.\d{0,})?$/;
            if(isInteger) matchRegex = canBeNegative ? /^-?[0-9]+$/ : /^[0-9]+$/;
            
            return (value+'').match(matchRegex);
        },
        parse(value){
            return isInteger ? parseInt(value, 10) : parseFloat(value);
        }
    };
};

export const stringValueValidator = {
    validate(value){
        return typeof value === 'string';
    },
    parse(value){
        return value+'';
    }
};

export function mustNotBeQuoted(text){
    return !!getNumericValueValidator(false, true).validate(text);
}

export const ATTRIBUTE_VALUE_TYPES = {
    NUMERIC: getNumericValueValidator(false, true),
    NUMBER: getNumericValueValidator(false, true),
    REAL: getNumericValueValidator(false, true),
    INT: getNumericValueValidator(true, true),
    NUMBER_LONG: getNumericValueValidator(true, true),

    TEXT: stringValueValidator,
    TABLETEXT: stringValueValidator,
    MULTISTRING: stringValueValidator,
    STRING: stringValueValidator,
    REFERENCE: stringValueValidator,

    GLOBAL_ID: {
        ...getNumericValueValidator(true, false),
        parse(value){
            return value+'';
        }
    },
    
    BOOLEAN:{
        validate(value){
            value = (value+'').toLowerCase();
            return value === 'false' || value === 'true';
        },
        parse(value){
            value = (value+'').toLowerCase();
            return value === 'true' || false;
        }
    },

    EPOCH: {},
    TIMESTAMP: {},
    LIST_VALUE: {}
};