<template>
    <div class="inv-object-tab-graphviz-container">
        <div class="inv-object-tab-graphviz" ref="editor">
            <inv-emdsl-editor   
                v-model="emdslCode" 
                :placeholder="'enter_graph_code_here' | translate"
                @valid="isValid => editorContentIsValid = isValid"
                auto-height 
                @parser-result="result => emdslParserResult = result"
                :dslDefinition="emdslDefinition"
                :template="template"
            />
        </div>
        <div class="dragbar" ref="dragbar"/>
        <div class="inv-object-tab-graphviz-svg not-dragged" ref="graph">
            <div v-if="!svgContent || svgContent === ''" style="width: 100%">
                <v-alert
                    dense
                    type="info"
                    color="accent"
                    >
                        Preview is displayed after editing the document.
                </v-alert>
            </div>
            <div id="svg-container" v-html="svgContent" style="width: 100%; height: 100%; overflow: auto"></div>
        </div>
        <v-dialog 
            v-model="saveModal.opened" 
            width="500px"
            content-class="small-dialog"
            persistent
        >
            <v-card flat>
                <v-card-title><h2>{{'confirm_changes' | translate}}</h2></v-card-title>

                <v-card-text v-if="saveModal.message">
                    <strong 
                        v-for="(par, index) in saveModal.message.split('\n')"
                        :key="index" 
                        class="error--text"
                    >
                        {{ par }} <br>
                    </strong>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn 
                        text
                        @click="saveModal.opened = false"
                    >
                        {{'cancel'|translate}}
                    </v-btn>
                    <v-btn 
                        color="success" 
                        outlined
                        @click="forceSave"
                    >
                        {{'confirm'|translate}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>


<script>
    
    import backend from 'obj-fe/app/backend';
    import notify from 'obj-fe/services/notifications';
    
    import InvEmdslEditor from '../inv-emdsl-editor.vue';
    import JsonViewer from 'vue-json-viewer'


    export default {
        components:{
            InvEmdslEditor,
            JsonViewer
        },
        props:{
            template: Object,
            globalId: String,
            shouldSave: Boolean,
            objectContext: Boolean
        },
        data(){
            return {
                firstTimeLoaded: true,
                emdslCode: '',
                emdslParserResult: null,
                oldEmdslParserResult: null, 
                emdslDefinition: null,
                svgContent: '',
                editorContentIsValid: false,
                saveModal: {
                    opened: false,
                    message: ''
                },
            };
        },
        computed:{
            dslAST: function(){
                return this.emdslParserResult?.value?.data;
            },
            editingScope: function(){
                return this.emdslDefinition?.editingScope
            }
        },
        methods:{
            onFirstTimeExpandLoad(){
                backend.dsl.emdslDefinition(
                    { context: this.template.templateName || 'default' }, 
                    data => {
                        this.emdslDefinition = data;
                    },  
                    err => {
                        console.log(err)
                    }
                );
                let app = this
                backend.dsl.emdslTemplate(
                    this.objectContext ? 
                        { context: this.template.templateName || 'default', globalId: this.globalId} : 
                        { context: this.template.templateName || 'default'},
                    data => app.emdslCode = data || '',
                    err => {
                        console.log(err)
                    }
                )
                setTimeout(function(){
                    app.$refs['dragbar'].addEventListener('mousedown', () => {
                        document.addEventListener('mousemove', app.processDrag);
                    });
        
                    window.addEventListener('mouseup', () => {
                        document.removeEventListener('mousemove', app.processDrag);
                    });
                }, 1000)
            },
            processDrag(e){
                document.selection ? document.selection.empty() : window.getSelection().removeAllRanges();
                this.$refs['graph'].classList.remove('not-dragged');
                this.$refs['editor'].style.width = (e.pageX - this.$refs['dragbar'].offsetWidth / 2) + 'px';
            },
            save(){
                if(!this.emdslParserResult.value.data.entitiesDefinitions) return notify.error('cant_save_empty_code')
                
                if(this.objectContext){
                    backend.dsl.emdslCreate(
                        { 
                            context: this.template.templateName || 'default',
                            globalId: this.globalId,
                        }, 
                        { src:this.emdslCode, data: this.emdslParserResult.value.data }, 
                        data => {
                            // re-enables save changes button
                            this.$emit('saved');
                            notify.success('saved');
                        },
                        (err, status) => {
                            // re-enables save changes button
                            this.$emit('saved');

                            if(status === 412) {
                                this.saveModal.message = err;
                                this.saveModal.opened = true;
                            }
                        }
                    );
                }
                else {
                    backend.dsl.emdslCreate(
                        { 
                            context: this.template.templateName || 'default',
                        }, 
                        { src:this.emdslCode, data: this.emdslParserResult.value.data }, 
                        data => {
                            // re-enables save changes button
                            this.$emit('saved');
                            notify.success('saved');
                        },
                        (err, status) => {
                            this.$emit('saved');
                            
                            // re-enables save changes button
                            if(status === 412) {
                                this.saveModal.message = err;
                                this.saveModal.opened = true;
                            }
                        }
                    );
                }
            },
            forceSave(){
                if(this.objectContext){
                    backend.dsl.emdslCreateForce(
                        { 
                            context: this.template.templateName || 'default',
                            globalId: this.globalId,
                        }, 
                        { 
                            src:this.emdslCode, 
                            data: this.emdslParserResult.value.data 
                        }, 
                        data => {
                            this.saveModal.opened = false;
                            notify.success('saved');
                            this.$emit('saved');
                        },
                        err => {
                            this.saveModal.opened = false;
                            notify.error('saving_grid_error')
                            this.$emit('save-error')
                        }
                    );
                }
                else {
                    backend.dsl.emdslCreateForce(
                        { 
                            context: this.template.templateName || 'default',
                        }, 
                        { 
                            src:this.emdslCode, 
                            data: this.emdslParserResult.value.data 
                        }, 
                        data => {
                            this.saveModal.opened = false;
                            notify.success('saved');
                            this.$emit('saved');
                        },
                        err => {
                            notify.error('saving_grid_error')
                            this.$emit('save-error')
                        }
                    );
                }
            },
            loadSvgDiagramPreview(){
                if(!!this.oldEmdslParserResult && !!this.oldEmdslParserResult.entitiesDefinitions){
                    backend.dsl.emdslPreview(
                        { context: this.template.templateName || 'default'}, 
                        { data: this.oldEmdslParserResult }, 
                        data => this.svgContent = data
                    );
                }
                else{
                    let app = this;
                    setTimeout(function(){
                        if(app.svgContent === ''){
                            let before = app.emdslCode;
                            app.emdslCode = app.emdslCode + ' '; 
                            app.emdslCode = before;
                        } 
                    }, 1000);
                }
            },
        },
        watch:{
            emdslCode: function(){
                if(this.firstTimeLoaded) this.firstTimeLoaded = false;    
            },
            dslAST:{
                immediate: true,
                deep: true,
                handler: function(val){
                    if(JSON.stringify(val) === JSON.stringify(this.oldEmdslParserResult)) return;
                    else this.oldEmdslParserResult = val;
                } 
            },
            oldEmdslParserResult:{
                immediate: true,
                handler: function(){
                    this.loadSvgDiagramPreview();
                },
            },
            editorContentIsValid:{
                immediate: true,
                handler: function(){
                    this.$emit('valid', this.editorContentIsValid)
                }   
            },
            template: {
                immediate: true,
                handler: function(){
                    this.onFirstTimeExpandLoad()
                },
            },
            shouldSave: function(){
                if(this.shouldSave) this.save();
            },
            editingScope: function(val){
                this.$emit('editing-scope', val);
            }
        }
    };
</script>

<style scoped>
    .inv-object-tab-graphviz-container{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
    }
    .inv-object-tab-graphviz-container .inv-object-tab-graphviz{
        max-width: 50%;
        width: 50%;
        overflow: hidden;
    }
    .dragbar{
        padding: 2px;
        cursor: col-resize;
        background-color: rgba(0, 0, 0, 0.12);;
        user-select: none;
        transition: all 200ms;
    }
    .dragbar:hover{
        background-color: rgba(0, 0, 0, 0.25);;
    }
    .inv-object-tab-graphviz-svg{
        display: flex;
        flex-grow: 10;
        max-height: 100%;
        width: 50%;
        flex-direction: column;
        flex-wrap: wrap;
    }

    .inv-object-tab-graphviz{
        height: 100%;
        overflow: hidden;
    }
    .inv-object-tab-graphviz-container .inv-object-tab-graphviz-svg{
        overflow: auto;
    }
    .graph-preview {
        border-left: 1px solid #ddd;
        max-height: 100%;
    }
</style>
