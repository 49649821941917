<template>
    <inv-object-tab-item :tab="tab" :tabIsLoading="tabIsLoading">
        <template v-if="expanded && tab" slot="header">
            <div class="inv-object-button-group">

                <v-tooltip top>
                    <template v-slot:activator="{on}">
                        <v-btn 
                            v-on="on"
                            @click="saveRichtext"
                            :disabled="!isDirty"
                            :outlined="isDirty"
                            :elevation="isDirty ? 24 : 0"
                            color="success"
                            icon
                        >
                            <v-icon>fal fa-save</v-icon>
                        </v-btn>
                    </template>
                    <span>
                        {{'save'| translate}}
                    </span>
                </v-tooltip>

            </div>
        </template>
        <template slot="body">
            <InvRichtextEditor v-model="richtextValue" editable basic/>
        </template>
    </inv-object-tab-item>
</template>

<script>
    import backend from 'obj-fe/app/backend';
    import notify from 'obj-fe/services/notifications';

    import tabBehaviour from './inv-object-tab-behaviour';
    import InvObjectTabItem from './inv-object-tab-item.vue';
    import InvRichtextEditor from './richtext-editor/inv-richtext-editor.vue'


    export default {
        mixins:[ tabBehaviour ],
        components:{
            InvObjectTabItem,
            InvRichtextEditor
        },
        data(){
            return {
                richtextValue: '',
                initValue: ''
            };
        },
        computed:{
            isDirty(){
                return this.richtextValue !== this.initValue;
            }
        },
        methods:{
            onFirstTimeExpandLoad(successCb, errorCb){
                let restUrl = this.tab.parameters.readerUrl
                
                backend.objects.customGetRestUrl(
                    { customRestUrl: restUrl }, 
                    data => {
                        this.richtextValue = data;
                        this.initValue = data;
                        successCb();
                    }, 
                    errorCb);
            },
            saveRichtext(){
                let restUrl = this.tab.parameters.writerUrl;
                let data = this.richtextValue;
                backend.objects.customPostRestUrl(
                    { customRestUrl: restUrl }, 
                    { html: data }, 
                    res => {
                        notify.success(res);
                        this.initValue = this.richtextValue;
                    }
                );
            }
        }
    };
</script>