<template>
    <div class="buttons-container">
        <inv-report-buttons
            :buttons="reportButtons"
            :icon-sizes="iconSizes"
            @clicked="button => $emit('clicked', button)"
        />

        <inv-more-action-buttons
            :buttons="moreActionButtons"
            :icon-sizes="iconSizes"
            @clicked="button => $emit('clicked', button)"
        />
    </div>
</template>

<script>
import InvReportButtons from "./inv-report-buttons.vue";
import InvMoreActionButtons from "./inv-more-action-buttons.vue";

export default {
    components: {
        InvMoreActionButtons,
        InvReportButtons
    },
    props: {
        buttons: Array,
        iconSize: String
    },
    computed: {
        reportButtons() {
            return this.buttons.filter(button => button.fePlacement === 'REPORT_BUTTON')
        },
        moreActionButtons() {
            return this.buttons.filter(button => button.fePlacement === 'MORE_ACTIONS')
        },
        iconSizeOrDefault() {
            if (!this.iconSize) {
                return 'large';
            }

            return this.iconSize;
        },
        iconSizes() {
            return {
                iconSizeXSmall: this.iconSizeOrDefault === 'x-small',
                iconSizeSmall: this.iconSizeOrDefault === 'small',
                iconSizeLarge: this.iconSizeOrDefault === 'large',
                iconSizeXLarge: this.iconSizeOrDefault === 'x-large'
            }
        }
    }
}
</script>

<style scoped>
.buttons-container {
    display: flex;
    flex-direction: row;
}
</style>
