<template>
    <div>
        <v-dialog
            v-model="open"
            fullscreen
            persistent
            hide-overlay
            transition="dialog-bottom-transition"
            content-class="editor-dialog"
        >
            <v-toolbar 
                dark 
                color="primary"
                flat   
            >
                <v-btn 
                    icon
                    outlined
                    color="error"
                    @click="finishEditing"
                >
                    <v-icon>fal fa-times</v-icon>
                </v-btn>

                <v-toolbar-title>{{ 'grid_dsl_editor' | translate }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items class="editor-dialog-toolbar-items">
                    <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                            <v-btn
                                v-on="on"
                                x-large
                                icon
                                color="error"
                                @click="revertChanges"
                            >
                                <v-icon>fal fa-undo-alt</v-icon>
                            </v-btn>
                        </template>
                        <span>
                            {{'revert_to_origin' | translate}}
                        </span> 
                    </v-tooltip>
                    
                    <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                            <v-btn 
                                v-on="on"
                                x-large
                                icon
                                color="success"
                                @click="save"
                                :disabled="!editorContentIsValid || changesSaved"
                            >
                                <v-icon>fal fa-save</v-icon>
                            </v-btn>
                        </template>
                        <span>
                            {{ 'save' | translate}}
                        </span> 
                    </v-tooltip>
                </v-toolbar-items>
            </v-toolbar>

            <v-card>
                <div class="dsl-editor-container">
                    
                    <div 
                        class="grid-dsl-dsl-editor" 
                        :class="{ 'equal-size': modalContentEqualSize }"
                        ref="editor" 
                    >
                        
                        <inv-ihi-dsl-editor   
                            v-model="ihiDslCode" 
                            :revertCodeFlag="revertCodeFlag"
                            :originValue="oldIhiDslCode"
                            :placeholder="'enter_graph_code_here' | translate"
                            @reverted="processRevertChanges"
                            @valid="isValid => editorContentIsValid = isValid"
                            auto-height 
                            @parser-result="result => ihiParserResult = result"
                        />
                    
                    </div>
                    <div 
                        class="grid-dsl-dsl-table" 
                        :class="{ 'equal-size': modalContentEqualSize }"
                        v-if="previewReportId"
                    >
                        <v-toolbar 
                            dark 
                            color="primary"
                            dense
                        >
                            <v-toolbar-title>{{ 'table_preview' | translate }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-items class="editor-dialog-toolbar-items">
                                
                                <v-tooltip top>
                                    <template v-slot:activator="{on}">
                                        <v-btn 
                                            v-on="on"
                                            x-large
                                            icon
                                            color="white"
                                            @click="attemptReload"
                                        >
                                            <v-icon>fal fa-sync</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>
                                        {{ 'reload_table_preview' | translate}}
                                    </span> 
                                </v-tooltip>
                                
                                <v-tooltip top>
                                    <template v-slot:activator="{on}">
                                        <v-btn
                                            icon
                                            x-large
                                            v-on="on" 
                                            color="white"
                                            @click="toggleModalContentResize"
                                        >
                                            <v-icon>{{ modalContentEqualSize ? 'fal fa-compress-alt' : 'fal fa-expand-alt'}}</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>
                                        {{ (modalContentEqualSize ? 'collapse' : 'expand') | translate}}
                                    </span> 
                                </v-tooltip>
                                
                            </v-toolbar-items>
                        </v-toolbar>

                        <div class="loading-indicator">
                            <v-progress-linear indeterminate color="var(--v-accent-base)"></v-progress-linear>
                        </div>
            
                        <obj-report 
                            full-height
                            v-if="reportServicesInited" 
                            load-immediately 
                            :report-id="previewReportId" 
                            :readonly="false" 
                            :state="reportState"
                            @input="query => $emit('state-change', query)" 
                            :data-test="'obj-report_' + previewReportId"
                        />
                    </div>        
                </div>
            </v-card>
        </v-dialog>
        <v-dialog 
            content-class="small-dialog"
            width="500px"
            v-model="closeDialog"
        >
            <v-card>
                <v-card-title><h2>{{ 'warning' | translate }}</h2></v-card-title>
                <v-card-text>
                    {{ 'discarding_unsaved_changes' | translate}}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        text
                        @click="discard"
                    >
                        {{ 'exit_editor_discard' | translate }}
                    </v-btn>

                    <v-btn
                        outlined
                        color="green"
                        @click="closeDialog = false"
                    >
                        {{ 'return_to_code' | translate}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import backend from 'obj-fe/app/backend';
import notify from 'obj-fe/services/notifications';

import InvIhiDslEditor from './inv-ihidsl-editor.vue';
    
    

export default {
    components: {
        InvIhiDslEditor
    },
    props: {
        active: Boolean,
        oldIhiDslCode: String,
        previewReportId: String,
        objectId: String,
        reportId: String
    },
    data(){
        return {
            firstTimeLoaded: true,
            open: false,

            reportServicesInited: false,

            closeDialog: false,
            // dsl specific data
            ihiDslCode: '',
            ihiParserResult: null,
            ihiErrors: [],
            editorContentIsValid: false,
            cachedResourceDefinitions:{},
            firstTimeLoaded: true,
            modalContentEqualSize: false,
            changesSaved: true,
            ihiDslCodeDirty: false,
            revertCodeFlag: false,
            reportState: {}
        }
    },
    methods: {
        discard(){
            this.closeDialog = false; 
            this.$emit('closed');
        },
        finishEditing(){
            if(this.changesSaved) this.$emit('closed');
            else this.promptCloseDialog();
        },
        promptCloseDialog(){
            this.closeDialog = true;
        },
        revertChanges(){
            this.revertCodeFlag = true;
        },
        processRevertChanges(){
            this.revertCodeFlag = false;
            this.save();
            this.attemptReload();
        },
        save(){
            if(this.previewReportId){
                backend.customGrids.updateGrid(
                    {customGridId: this.previewReportId}, { src:this.ihiDslCode, data: this.ihiParserResult.value.data }, 
                    data => {},
                    err => {}
                );
            }

            backend.customGrids.updateGrid(
                {customGridId: this.reportId}, { src:this.ihiDslCode, data: this.ihiParserResult.value.data }, 
                data => {
                    notify.success('saved')
                    this.attemptReload();
                    this.changesSaved = true;
                    this.ihiDslCodeDirty = false;
                },
                err => notify.error('saving_grid_error')
            );
        },
        attemptReload(){
            if(!this.previewReportId) return;

            let reportId = this.previewReportId;
            let customReportDescriptionService = this.$store.state.REPORTS.reportServicesById[ reportId ].description;
            let customReportLoadDataService = this.$store.state.REPORTS.reportServicesById[ reportId ].loadData;
            
            // we registered services in setupReportConfig and so we make use of them
            if(
                !!customReportDescriptionService && 
                !!customReportLoadDataService
            ) {
                customReportDescriptionService(reportId, (data)=>{
                    this.$store.commit('REPORTS/SET_REPORT_DESCRIPTION', { reportId, description: data });
                    this.$store.dispatch('REPORTS/LOAD_REPORT_DATA', reportId)
                })
            }
        },
        setupReportConfig(){
            let objectId = this.objectId;
            let reportId = this.previewReportId;
            let app = this;

            this.$store.dispatch('REPORTS/SET_REPORT_BACKEND_SERVICES', {
                reportId,
                loadData(reportId, query, cb){
                    app.tabIsLoading = true;
                    backend.objects.reportData({ 
                            objectId: query.parentObjectId || objectId , 
                            reportId
                        }, 
                        cb,
                        query
                    );
                },
                save(reportId, data, cb){
                    backend.objects.saveReport({ objectId , reportId }, data, cb);
                },
                description(reportId, cb){
                    backend.objects.reportDescription({ objectId , reportId }, cb);
                },
                enumLoaderUrl(reportId, columnName){
                    return '/rest/invObjs/grid/' + reportId + '/distinct/' + objectId + '/' + columnName;
                },
                exportExcel(reportId, data, cb){
                    backend.objects.exportExcel({ objectId , reportId }, data, cb);
                },
                exportSend(reportId, data, cb){
                    backend.objects.exportExcelAsync({ objectId , reportId }, data, cb);
                },
                exportData(reportId, data, cb){
                    backend.objects.exportDataAsync({ objectId , reportId }, data, cb);
                },
                importData(reportId, data, cb){
                    backend.objects.importDataAsync({ objectId , reportId }, data, cb);
                },
                rowTemplate(reportId, cb){
                    backend.reports.rowTemplate(reportId, fields => {
                        // overriding default editorValuesUrl, because '{id}' must be objectId of object page in which report tab is rendered
                        cb(fields.map(field => {
                            if(field.editorValuesUrl) field.editorValuesUrl = field.editorValuesUrl.replace('{id}', objectId)
                            return field
                        }))
                    })
                }
            });

            this.reportServicesInited = true;

        },
            
        toggleModalContentResize(){
            this.modalContentEqualSize = !this.modalContentEqualSize;

            if(this.modalContentEqualSize){
                let app = this;
                backend.customGrids.updateGrid(
                    { customGridId: this.previewReportId }, { src:this.ihiDslCode, data: this.ihiParserResult.value.data }, 
                    data => {
                        app.attemptReload();
                    },
                    err => notify.error('saving_grid_error')
                );
            }

            setTimeout(()=>{ window.dispatchEvent(new Event('resize'))}, 100)
        },
    },
    watch: {
        previewReportId:{
            immediate: true,
            handler: function(){
                if(this.previewReportId) this.setupReportConfig();
            }
        },
        oldIhiDslCode:{
            immediate: true,
            handler: function(oldIhiDslCode){
                this.ihiDslCode = oldIhiDslCode;
            }
        },
        ihiDslCode: {
            deep: true,
            handler: function(){
                if(this.firstTimeLoaded) {
                    this.firstTimeLoaded = false;
                }
                else{
                    this.changesSaved = false;
                    this.ihiDslCodeDirty = true;  
                }
            }
        },
        active: {
            immediate: true,
            handler: function(){
                this.open = this.active;
            }
        }
            
    },
    
}
</script>

<style scoped>
.dsl-editor-container {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-content: space-between;
}
.dsl-editor-container .grid-dsl-dsl-editor,
.dsl-editor-container .grid-dsl-dsl-table{
    width: 100%;
}
.dsl-editor-container .grid-dsl-dsl-editor{
    max-height: 70%;
    flex-grow: 3;
}
.dsl-editor-container .grid-dsl-dsl-table{
    padding: 0;
    min-height: 30%;
    flex-grow: 0;
}
.dsl-editor-container .grid-dsl-dsl-editor.equal-size{
    height: 20%;
    max-height: 20%;
    flex-grow: unset !important;
}

.dsl-editor-container .grid-dsl-dsl-table.equal-size{
    height: 80%;
    max-height: 80%;
    flex-grow: unset !important;
}
.editor-dialog-toolbar-items{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.editor-dialog-toolbar-items .v-btn{
    margin-right: 10px;
}
.editor-dialog-toolbar-items .v-btn:last-child{
    margin-right: 0px;
}
.editor-dialog-toolbar-items button.v-btn.toolbar-control-btn{
    background: white !important;
    color: #333; 
}
.editor-dialog-toolbar-items button.v-btn.toolbar-control-btn:hover{
    background: var(--v-accent-base) !important;
    color: white !important 
}
/* .editor-dialog-toolbar-items button.v-btn.toolbar-control-btn-relevant{
    background: var(--v-accent-base) !important;
    color: white; 
}
.editor-dialog-toolbar-items button.v-btn.toolbar-control-btn-relevant:hover{
    background: var(--v-accent-base) !important;
    color: white !important 
} */
</style>
