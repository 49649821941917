<template>
    <v-input    :label="label" 
                class="v-text-field"
                :class="{ 'has-content': hasContent, 'v-input--is-focused':focused, 'primary--text':focused }" 
                :readonly="readonly" 
                :disabled="disabled" 
                :messages="messages"
                :hide-details="hideDetails"
                :append-icon="appendIcon"
                @click:append="$emit('click:append')"
                :prepend-icon="prependIcon"
                @click:prepend="$emit('click:prepend')"
                :style="{ padding: hideLabel ? '0px' : 'auto' }"
                :error-messages="errorMessages">

        <v-row no-gutters align="baseline">
            <v-col class="flex-grow-0" v-if="prependInner">
                <v-icon @click="$emit('click:prepend-inner')" :color="prependInnerColor">{{prependInner}}</v-icon>
            </v-col>
            
            <v-col>
                <slot></slot>
            </v-col>

            <v-col class="flex-grow-0" v-if="appendInner" :color="appendInnerColor">
                <v-icon @click="$emit('click:append-inner')">{{appendInner}}</v-icon>
            </v-col>
        </v-row>
    </v-input>

    <!-- 
    :messages="['Messages']"
    :success="success"
    :success-messages="successMsg"
    :error="error"
    :error-messages="errorMsg"
    :error-count="errorCount"
    hint="I am hint"
    :persistent-hint="persistentHint"
    -->
</template>

<style scoped>
    >>> .v-input__slot {
        min-height: 24px;    
    }

    >>> .v-input__slot > .v-label {
        margin-top: -6px;
    }

    .has-content >>> .v-input__slot > .v-label {
        margin-top: 0px;
        position: absolute !important;
        max-width: 133%;
        transform: translateY(-18px) scale(0.75);
    }
</style>

<script>
    export default {
        props:{
            label: String,
            focused: Boolean,
            readonly: Boolean,
            disabled: Boolean,
            messages: Array,
            hideDetails: Boolean,
            hideLabel: Boolean,
            appendIcon: String,
            prependIcon: String,
            prependInner: String,
            prependInnerColor: {},
            appendInner: String,
            appendInnerColor: {},
            errorMessages:Array
        },
        computed:{
            hasContent(){
                return this.$slots.default && this.$slots.default.filter(vnode => vnode.tag || vnode.text).length > 0;
            }
        }
    }
</script>